import {
    Box,
    Container,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { IStarRatingProps } from 'core/interfaces/survey-answering.interface';
import { Form, useFormikContext } from 'formik';
import { FC, useContext } from 'react';
import { filterTranslatesBuilder } from './filterTranslatesBuilderHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    ratingContainer: {
        maxWidth: '864px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
            padding: '0',
        },
    },
    stepIntro: {
        padding: '48px 0',
        [theme.breakpoints.down('xs')]: {
            padding: '24px 0',
        },
    },
    stepHeading: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
            lineHeight: '24px',
        },
    },
    ratingBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.common.white,
        padding: '24px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '8px',
        },
    },
    bottomAction: {
        height: theme.spacing(12),
        position: 'sticky',
        left: 0,
        bottom: 0,
        width: '100%',
        borderTop: `1px solid ${theme.palette.divider}`,
        zIndex: 100,
        backgroundColor: theme.palette.common.white,
    },
    form: {
        width: '100%',
    },
    ratingText: {
        maxWidth: '60%',
        textAlign: 'left',
        fontSize: '19px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            textAlign: 'center',
        },
    },
    ratingIcon: {
        marginLeft: '20px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0',
            marginTop: '10px',
        },

        '& .MuiSvgIcon-root': {
            width: '60px',
            height: '60px',
            // pointer events none solves the issue for now.
            // For original post to issue please check: https://github.com/mui/material-ui/issues/32557
            pointerEvents: 'auto',

            [theme.breakpoints.down('xs')]: {
                width: '67px',
            },
        },
    },
    questionNumber: {
        color: '#C1C7D0',
        marginRight: '5px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            fontSize: '12px',
            lineHeight: '16px',
            position: 'absolute',
            top: '8px',
            right: '8px',
            marginRight: '0',
        },
    },
}));

export const StarRating: FC<IStarRatingProps> = ({
    surveyQuestions,
    surveyLabelTranslations,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();
    const { values, setFieldValue } = useFormikContext<{
        [key: string]: number;
    }>();

    const filterTranslates = filterTranslatesBuilder(surveyLabelTranslations);

    const theme = useTheme();
    const breakpointSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={classes.root}>
            <Form className={classes.form}>
                <Container>
                    <Box className={classes.stepIntro}>
                        <Typography
                            variant="h2"
                            align="center"
                            className={classes.stepHeading}
                        >
                            {filterTranslates(
                                'pulseQuestionsHeadline',
                                t('pulse-questions-headline')
                            )}
                        </Typography>
                    </Box>

                    <Container className={classes.ratingContainer}>
                        {surveyQuestions.map(
                            ({ id, translated_phrase }, index) => (
                                <Box
                                    className={classes.ratingBox}
                                    mb={1}
                                    key={id}
                                >
                                    <Typography
                                        variant="h6"
                                        className={classes.ratingText}
                                    >
                                        <span
                                            className={classes.questionNumber}
                                        >
                                            {breakpointSm
                                                ? `${index + 1} / ${
                                                      surveyQuestions.length
                                                  }`
                                                : `${index + 1}.`}
                                        </span>
                                        {translated_phrase}
                                    </Typography>
                                    <Rating
                                        className={classes.ratingIcon}
                                        precision={0.5}
                                        name={id.toString()}
                                        value={
                                            values[id] ? values[id] / 2 : null
                                        }
                                        onChange={(_e, value) => {
                                            setFieldValue(
                                                id.toString(),
                                                (value ?? 0) * 2
                                            );
                                        }}
                                    />
                                </Box>
                            )
                        )}
                    </Container>
                </Container>
            </Form>
        </Box>
    );
};
