import {
    Box,
    Button,
    Grid,
    makeStyles,
    Tooltip,
    Typography,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EditIcon from '@material-ui/icons/Edit';
import MuiAlert from '@material-ui/lab/Alert';
import Alert from '@material-ui/lab/Alert';
import { pageRowNumber } from '@qagency/react-shared-library';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { SearchInput } from 'core/components/shared/SearchInput';
import { fetchById } from 'core/hooks/crud.hook';
import { useEntityById } from 'core/hooks/entity-by-id.hook';
import { usePagination } from 'core/hooks/pagination.hook';
import { EmailContent } from 'modules/customMessage/components/EmailContent';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import { ParticipantFormDialog } from 'modules/participants/component/ParticipantFormDialog';
import {
    ParticipantFormContext,
    ParticipantFormProvider,
} from 'modules/participants/component/ParticipantFormProvider';
import { IProject } from 'modules/projects/models/project.model';
import { projectsQuery } from 'modules/projects/state/projects.query';
import { projectsStore } from 'modules/projects/state/projects.store';
import { pulseParticipantsQuery } from 'modules/pulse-participants/state/pulse-participants.query';
import {
    CurrentPageQuestionsContext,
    CurrentPageQuestionsProvider,
} from 'modules/pulse-questions/components/CurrentPageQuestionsProvider';
import { ScaleQuestionsListItem } from 'modules/pulse-questions/components/QuestionsListItem';
import { scaleQuestionsQuery } from 'modules/scale-questions/state/scale-questions.query';
import { factorsQuery } from 'modules/success-factors/state/factors.query';
import css from 'styles/material-ui/cssVars';

import {
    IPageTabsPropsItem,
    PageTabs,
} from '../../../core/components/mock/tabs/PageTabs';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { EntityListHeaderTemplate } from '../../../core/components/shared/EntityListHeaderTemplate';
import { ICrumb } from '../../../core/components/shared/topBars/BreadcrumbsContainer';
import {
    PageTitleBar,
    PageTitleBarButton,
} from '../../../core/components/shared/topBars/PageTitleBar';
import { IEntity } from '../../../core/interfaces/entity.interface';
import { apiService } from '../../../core/services/apiService';
import { ROUTES } from '../../navigation/enums/routes.enum';
import { useDialog } from '../../projects/components/use-dialog.hook';
import { PulseParticipantsList } from '../../pulse-participants/components/PulseParticipantsList';
import { QuestionsList } from '../../pulse-questions/components/QuestionsList';
import { FactorsList } from '../../success-factors/components/FactorsList';
import { FactorsListItemTemplate } from '../../success-factors/components/FactorsListItemTemplate';
import { PulseDetailsCard } from '../components/PulseDetailsCard';
import { PulseDialogContent } from '../components/PulseDialogContent';
import {
    PulseDialogContext,
    PulseDialogProvider,
} from '../components/PulseDialogProvider';
import { SendPulse } from '../components/SendPulse';
import { IPulse } from '../models/pulse.model';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    boxHolder: {
        textAlign: 'right',
        display: 'block',
    },
    divWrapper: {
        textAlign: 'left',
        marginBottom: '-30px',
        marginTop: '-14px',
    },
    rootModal: {
        width: '90%',
        height: '90%',
        maxWidth: '1360px',
        maxHeight: '1000px',
        margin: 'auto',
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.spacing(0.5),
    },
    modalContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
        position: 'relative',
    },
    titleCotainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    alert: {
        maxWidth: '600px',
        width: '100%',
        height: 'fit-content',
        margin: 'auto',
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.spacing(0.5),
        '& .MuiDialogContent-root:first-child': {
            margin: 0,
            padding: 0,
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
    },
    modalActions: {
        position: 'relative',
        backgroundColor: css.extraColors.text.darkPrimary,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing(2)}px  ${theme.spacing(1)}px `,

            '& div:last-child': {
                marginTop: 0,
            },
        },
    },
}));

interface IEditButtonProps {
    tooltip: string;
    label: string;
    onClick: () => void;
}

const EditButton: FC<IEditButtonProps> = ({ tooltip, label, onClick }) => {
    return (
        <Tooltip title={tooltip}>
            <Button
                variant="text"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                onClick={onClick}
            >
                {label}
            </Button>
        </Tooltip>
    );
};

interface ISearchAndEditToolbarProps {
    initialSearchInputValue: string;
    saerchInputPlaceholder: string;
    editButtonLabel: string;
    onSearchChange: (searchTerm: string) => void;
    onEditClick: () => void;
    searchVisible: boolean;
    pulse?: Partial<IPulse>;
}

interface IEditToolbarProps {
    onEditClick: () => void;
    editButtonLabel: string;
}

const SearchAndEditToolbar: FC<ISearchAndEditToolbarProps> = ({
    initialSearchInputValue,
    saerchInputPlaceholder,
    editButtonLabel,
    onSearchChange,
    onEditClick,
    searchVisible,
    pulse,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { isOpen, close, participantId, open } = useContext(
        ParticipantFormContext
    );
    const classes = useStyles();
    const [openSnackbar, setOpenSnackbar] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const handleSnackbarClick = () => {
        setOpenSnackbar({ ...openSnackbar, open: true });
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar({ ...openSnackbar, open: false });
    };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            className={classes.boxHolder}
        >
            {searchVisible ? (
                <div className={classes.divWrapper}>
                    <SearchInput
                        initialValue={initialSearchInputValue}
                        variant="outlined"
                        label={saerchInputPlaceholder}
                        onChange={onSearchChange}
                    />
                </div>
            ) : (
                <></>
            )}

            {pulse?.active ? (
                <EditButton
                    tooltip={'Add new participant to pulse in progress'}
                    label={'Add new participant to pulse in progress'}
                    onClick={() => open({})}
                />
            ) : (
                <EditButton
                    tooltip={t('tooltip-edit')}
                    label={editButtonLabel}
                    onClick={onEditClick}
                />
            )}
            <ParticipantFormDialog
                isActive={true}
                handleSuccessMessage={handleSnackbarClick}
            />

            <Snackbar
                open={openSnackbar.open}
                autoHideDuration={3000}
                onClose={() => handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="success">
                    Successfully sent Pulse!
                    {/* TODO: translate */}
                </Alert>
            </Snackbar>
        </Box>
    );
};

const EditMessage: FC<IEditToolbarProps> = ({ onEditClick }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            className={classes.boxHolder}
        >
            <EditButton
                tooltip={t('tooltip-edit-introduction-message')}
                label={t('customize-message')}
                onClick={onEditClick}
            />
        </Box>
    );
};

const SinglePulsePageContainer = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { id } = useParams<IEntity>();
    const { open: openSendPulseDialog, handleOpen, handleClose } = useDialog();
    const [pulse] = useEntityById<IPulse>({ id, forceFetch: true });
    const { isOpen, close, open } = useContext(PulseDialogContext);
    const { push } = useHistory();
    const [shouldRefetchParticipants, setShouldRefetchParticipants] = useState(
        false
    );
    const { currentPageNumber, currentPageSize } = usePagination();
    const [openSnackbar, setOpenSnackbar] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const [pulseLanguageId, setPulseLanguageId] = useState('');

    const { currentPageEntities } = useContext(CurrentPageQuestionsContext);

    useEffect(() => {
        setPulseLanguageId(pulse?.languageId ?? '');
    }, [pulse?.languageId]);

    const [project, setProject] = useState<IProject | null>(null);

    const getProjectData = () => {
        const getEntitybyId = async (projectId: string) =>
            projectsQuery.getEntity(projectId) ??
            fetchById(projectsStore, projectsStore.crudApiService)(projectId);

        if (!pulse?.projectId) {
            // setProject(null);
        } else {
            getEntitybyId(pulse.projectId).then((tempProject) =>
                setProject(tempProject)
            );
        }
    };

    useEffect(() => {
        getProjectData();
    }, [pulse?.projectId]);

    function Alert(props: any) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleSnackbarClick = () => {
        setOpenSnackbar({ ...openSnackbar, open: true });
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar({ ...openSnackbar, open: false });
    };

    const sendPulse = async () => {
        await apiService.post(`/pulses/${id}/send`, {});
        handleSnackbarClick();
    };

    const items = useMemo(() => {
        const temp: IPageTabsPropsItem[] = [
            {
                headerLabel: 'pulse-questions',
                table: (
                    <QuestionsList
                        currentPageEntities={currentPageEntities}
                        pulseId={id}
                        renderToolbar={({ setSearchTerm }) => (
                            <SearchAndEditToolbar
                                initialSearchInputValue={
                                    scaleQuestionsQuery.getValue().ui.searchTerm
                                }
                                saerchInputPlaceholder={t(
                                    'search-pulse-questions'
                                )}
                                editButtonLabel={t('edit-pulse-questions')}
                                onEditClick={() => pulse && open(pulse, 2)}
                                onSearchChange={setSearchTerm}
                                searchVisible={false}
                            />
                        )}
                        HeaderListItem={() => (
                            <EntityListHeaderTemplate
                                index={{ label: '#' }}
                                phrase={{ label: t('question') }}
                                name={{
                                    label: `${t('title')} (${t(
                                        'survey-language'
                                    )})`,
                                }}
                                transalte={{
                                    label: `${t('question')} (${t(
                                        'survey-language'
                                    )})`,
                                }}
                            />
                        )}
                        ListItem={(entity, index) => (
                            <ScaleQuestionsListItem
                                entity={entity}
                                pulseLanguageId={pulseLanguageId}
                                index={pageRowNumber(
                                    currentPageNumber,
                                    currentPageSize,
                                    index
                                )}
                            />
                        )}
                    />
                ),
            },
            {
                headerLabel: 'success-factors',
                table: (
                    <FactorsList
                        pulseId={id}
                        renderToolbar={({ setSearchTerm }) => (
                            <SearchAndEditToolbar
                                initialSearchInputValue={
                                    factorsQuery.getValue().ui.searchTerm
                                }
                                saerchInputPlaceholder={t(
                                    'search-success-factors'
                                )}
                                editButtonLabel={t('edit-success-factors')}
                                onEditClick={() => pulse && open(pulse, 3)}
                                onSearchChange={setSearchTerm}
                                searchVisible={false}
                            />
                        )}
                        HeaderListItem={() => (
                            <EntityListHeaderTemplate
                                icon={{ label: `${t('icon')}` }}
                                title={{ label: `${t('title')}` }}
                                title_translation={{
                                    label: `${t('title-translation')}`,
                                }}
                            />
                        )}
                        ListItem={(entity) => {
                            return (
                                <FactorsListItemTemplate
                                    fontAwesomeIcon={
                                        entity.factor.fontAwesomeIcon
                                    }
                                    title={entity.factor.title}
                                    translation={entity.factor.translations.find(
                                        (item) =>
                                            item.language.id ===
                                            pulse?.languageId
                                    )}
                                />
                            );
                        }}
                    />
                ),
            },
            {
                headerLabel: 'invitation-email',
                table: (
                    <EmailContent
                        pulseId={id}
                        renderToolbar={() => (
                            <EditMessage
                                editButtonLabel={t('customize-message')}
                                onEditClick={() => pulse && open(pulse, 4)}
                            />
                        )}
                    />
                ),
            },
        ];

        if (pulse) {
            temp.unshift({
                headerLabel: 'participants',
                table: (
                    <PulseParticipantsList
                        pulse={pulse}
                        triggerParticipantsRefetch={shouldRefetchParticipants}
                        renderToolbar={({ setSearchTerm }) => (
                            <SearchAndEditToolbar
                                initialSearchInputValue={
                                    pulseParticipantsQuery.getValue().ui
                                        .searchTerm
                                }
                                saerchInputPlaceholder={t(
                                    'search-current-participants'
                                )}
                                editButtonLabel={t('add-participants')}
                                onEditClick={() => pulse && open(pulse, 1)}
                                onSearchChange={setSearchTerm}
                                searchVisible={true}
                                pulse={pulse}
                            />
                        )}
                    />
                ),
            });
        }

        return temp;
    }, [
        id,
        pulse,
        pulseLanguageId,
        shouldRefetchParticipants,
        currentPageEntities,
    ]);

    const buttons: PageTitleBarButton[] = [
        {
            component: (
                <SendPulse
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    isOpen={openSendPulseDialog}
                    handleSubmit={sendPulse}
                    isIcon={false}
                    hideButton={false}
                    getProjectData={getProjectData}
                />
            ),
        },
    ];

    const pageCrumbs = useMemo(() => {
        const breadcrumbs: ICrumb[] = [
            { label: t('projects'), link: ROUTES.PROJECTS },
        ];

        if (project) {
            breadcrumbs.push({
                label: project.name,
                link: pathBuilder(ROUTES.PROJECT, ':id', project.id),
            });
        }

        return breadcrumbs;
    }, [project]);

    return !pulse ? (
        <>
            <Typography variant="h1" align="center">
                Pulse is Deleted
                {/* TODO: translate */}
            </Typography>
            <Box display="flex" justifyContent="center">
                <Button
                    color="primary"
                    variant="outlined"
                    disableElevation
                    onClick={() => push({ pathname: ROUTES.PROJECTS })}
                >
                    Go Back to Projects
                    {/* TODO: translate */}
                </Button>
            </Box>
        </>
    ) : (
        <>
            <FormDialogWrapper
                isOpen={isOpen}
                handleClose={close}
                setShouldRefetchParticipants={setShouldRefetchParticipants}
            >
                <PulseDialogContent />
            </FormDialogWrapper>
            <PageTitleBar
                title={pulse.name}
                icon={<AssignmentIcon />}
                buttons={buttons}
                crumbs={pageCrumbs}
            />
            <Box px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <PulseDetailsCard
                            pulse={pulse}
                            onEditClick={() => pulse && open(pulse)}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <ParticipantFormProvider>
                            <PageTabs items={items} />
                        </ParticipantFormProvider>
                    </Grid>
                </Grid>
            </Box>
            <Snackbar
                open={openSnackbar.open}
                autoHideDuration={3000}
                onClose={() => handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="success">
                    Successfully sent Pulse!
                    {/* TODO: translate */}
                </Alert>
            </Snackbar>
        </>
    );
};

export const SinglePulsePage: FC = () => (
    <CurrentPageQuestionsProvider>
        <PulseDialogProvider>
            <SinglePulsePageContainer />
        </PulseDialogProvider>
    </CurrentPageQuestionsProvider>
);
