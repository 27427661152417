import { IScaleQuestion } from 'modules/scale-questions/models/scale-question.model';
import React, { FC } from 'react';
import { IEntityListItemProps } from '../../../../core/interfaces/entity-list-item-props.interface';
import { ScaleQuestionListItemTemplate } from './QuestionListItemTemplate';

export const ScaleQuestionsListItem: FC<
    IEntityListItemProps<IScaleQuestion>
> = ({
    entity: { organizationId, useAsDefault, translations, order, ...props },
    index,
}) => (
    <ScaleQuestionListItemTemplate
        library={organizationId ? 'Account' : 'System'}
        defaultItem={useAsDefault}
        missTranslation={translations.some((trans) => !trans.name)}
        index={index}
        {...props}
    />
);
