import {
    EntityFilters,
    IEntityTableFiltersProps,
} from 'core/components/EntityFilters';
import React, { FC } from 'react';

export enum QuestionsListFilter {
    ALL = 'ALL',
    SYSTEM = 'SYSTEM',
    ACCOUNT = 'ACCOUNT',
}

type QuestionTableFiltersProps = Omit<
    IEntityTableFiltersProps<QuestionsListFilter>,
    'options'
>;

export const QuestionFilters: FC<QuestionTableFiltersProps> = (props) => (
    <EntityFilters
        {...props}
        options={[
            { label: 'All', type: QuestionsListFilter.ALL },
            { label: 'System', type: QuestionsListFilter.SYSTEM },
            { label: 'Account', type: QuestionsListFilter.ACCOUNT },
        ]}
    />
);
