import {
    applyTransaction,
    arrayRemove,
    MultiActiveState,
} from '@datorama/akita';
import { AxiosResponse } from 'axios';
import { eqSet } from 'core/helpers/eqSet';
import { ICreateEntityOptions } from 'core/interfaces/create-entity-options.interface';
import { IDeleteEntitiesOptions } from 'core/interfaces/delete-entities-options.interface';
import { IUpdateEntityOptions } from 'core/interfaces/update-entity-options.interface';
import {
    CrudApiService,
    ICrudApiServiceOptions,
} from 'core/services/crudApiService';
import { initialEntitiesState } from '../constants/initial-entities-state';
import { IEntity } from '../interfaces/entity.interface';
import { IReadAllEntitiesResponse } from '../interfaces/read-all-entities-response.interface';
import {
    EntityStateWithHighlight,
    EntityStoreWithHighlight,
} from './highlight.store';

export type OrderDirection = 'asc' | 'desc' | null;

export interface IEntityUIState {
    searchTerm: string;
    pageNumber: number;
    pageSize: number;
    filter?: any;
    orderBy?: string;
    orderDirection: OrderDirection;
}

export interface IEntitiesStoreConfig<T, R>
    extends ICrudApiServiceOptions<T, R> {
    crudIdKey?: keyof T;
    searchTermParamKeys?: string[];
    listResponseMapper?: (
        callback: (partialState: Partial<EntitiesState<T>>) => void
    ) => (x: any) => R[];
}

export interface IActionStatus {
    action: 'create' | 'update' | 'delete' | 'restore' | 'archive';
    success: boolean;
    entity: any;
}

export interface EntitiesState<T, UI extends IEntityUIState = IEntityUIState>
    extends EntityStateWithHighlight<T, string>,
        MultiActiveState<string> {
    ui: UI;
    total: number | null;
    highlightNext: 'first' | 'last' | null;
    currentPageIds: string[];
    pagesIds: string[][];
    actionStatus?: IActionStatus;
}

export abstract class EntitiesStore<
    T extends IEntity,
    R,
    RA = IReadAllEntitiesResponse<R>
> extends EntityStoreWithHighlight<EntitiesState<T>> {
    private _listResponseMapper: (
        callback: (partialState: Partial<EntitiesState<T>>) => void
    ) => (x: any) => R[] = (callback) => ({ results, ...meta }) => {
        const { total } = meta;
        callback({ total });
        return results;
    };

    private _searchTermParamKeys?: string[];

    get searchTermParamKeys(): string[] {
        return this._searchTermParamKeys ?? [];
    }

    get entitiesSlug(): string {
        return this._entitiesSlug ?? this.storeName;
    }

    private _crudIdKey: keyof T;
    private _crudApiService: CrudApiService<T, R>;

    get crudApiService(): CrudApiService<T, R> {
        return this._crudApiService;
    }

    listSlug = '';

    constructor(
        {
            crudIdKey = 'id',
            searchTermParamKeys,
            listResponseMapper,
            ...config
        }: IEntitiesStoreConfig<T, R>,
        private _entitiesSlug?: string
    ) {
        super({ ...initialEntitiesState });

        this._crudApiService = new CrudApiService<
            T,
            R,
            Omit<T, typeof crudIdKey>
        >(this.entitiesSlug, config);

        this._crudIdKey = crudIdKey;

        this._searchTermParamKeys = searchTermParamKeys;

        if (listResponseMapper) {
            this._listResponseMapper = listResponseMapper;
        }
    }

    patchUIState(partial: Partial<IEntityUIState>): void {
        const { ui } = this.getValue();

        this.update({
            ui: {
                ...ui,
                ...partial,
            },
        });
    }

    setCurrentPageEntities(entities: T[], params?: IEntityUIState): void {
        if (!entities.length) {
            this.update({
                highlightNext: null,
                currentPageIds: [],
                pagesIds: [],
                highlightedId: null,
            });
            return;
        }

        const {
            highlightNext,
            highlightedId,
            pagesIds: oldPagesIds,
        } = this.getValue();

        applyTransaction(() => {
            this.upsertMany(entities);

            const currentPageIds = entities.map(({ id }) => id);

            const pagesIds =
                params?.pageNumber &&
                !eqSet(
                    new Set(currentPageIds),
                    new Set(oldPagesIds[params.pageNumber - 1])
                )
                    ? oldPagesIds.slice(0, params.pageNumber)
                    : [...oldPagesIds];

            if (params) {
                pagesIds.splice(
                    params.pageNumber - 1,
                    oldPagesIds.length <= params.pageNumber ? 1 : 0,
                    currentPageIds
                );
            }

            this.update({
                highlightNext: null,
                currentPageIds,
                pagesIds,
            });

            switch (highlightNext) {
                case 'first':
                    this.setHighlight(currentPageIds[0]);
                    break;
                case 'last':
                    this.setHighlight(
                        currentPageIds[currentPageIds.length - 1]
                    );
                    break;
                default:
                    if (
                        highlightedId &&
                        currentPageIds.indexOf(highlightedId) === -1
                    ) {
                        this.setHighlight();
                    }

                    break;
            }

            this.setLoading(false);
        });
    }

    fetchEntities = async (
        params: IEntityUIState = this.getValue().ui,
        responseHandler?: (res: AxiosResponse) => R[],
        skipSetCurrentPageEntities?: boolean
    ): Promise<T[] | null> => {
        try {
            let slug!: string;

            if (this.listSlug) {
                slug = this.listSlug;
            }

            this.setLoading(true);

            if (!responseHandler) {
                responseHandler = ({ data }: AxiosResponse) => {
                    return this._listResponseMapper((partialState) => {
                        this.update(partialState);
                    })(data);
                };
            }

            const entities = await this._crudApiService.readAll<RA>(params, {
                responseHandler,
                slug,
            });

            if (!skipSetCurrentPageEntities) {
                this.setCurrentPageEntities(entities, params);
            } else {
                this.add(entities);
                this.setLoading(false);
            }

            return entities;
        } catch (e: any) {
            if (e instanceof Response) {
                throw e;
            }
        }

        return null;
    };

    updateEntity = async (
        entity: T,
        options?: Partial<IUpdateEntityOptions>
    ): Promise<T | null> => {
        const { shouldFetchAfterSuccess } = {
            shouldFetchAfterSuccess: false,
            ...options,
        };

        try {
            const { [this._crudIdKey]: id, ...details } = entity;
            const updatedEntity = await this._crudApiService.update(
                id,
                details
            );

            this.update({
                actionStatus: {
                    action: 'update',
                    success: true,
                    entity: updatedEntity,
                },
            });

            this.upsert(updatedEntity.id, updatedEntity);

            if (shouldFetchAfterSuccess) {
                this.fetchEntities();
            }

            return updatedEntity;
        } catch (e: any) {
            this.update({
                actionStatus: {
                    action: 'update',
                    success: false,
                    entity,
                },
            });

            if (e instanceof Response) {
                throw e;
            }

            console.log(this.storeName, 'updateEntity', e);
        }

        return null;
    };

    createEntity = async (
        entity: Omit<T, 'id'>,
        options?: Partial<ICreateEntityOptions>
    ): Promise<T | null> => {
        const { shouldFetchAfterSuccess, slug } = {
            shouldFetchAfterSuccess: false,
            ...options,
        };

        try {
            const createdEntity = await this._crudApiService.create(entity, {
                slug,
            });

            this.update({
                actionStatus: {
                    action: 'create',
                    success: true,
                    entity: createdEntity,
                },
            });

            this.upsert(createdEntity.id, createdEntity);

            if (shouldFetchAfterSuccess) {
                this.fetchEntities();
            }

            return createdEntity;
        } catch (e: any) {
            this.update({
                actionStatus: {
                    action: 'create',
                    success: false,
                    entity,
                },
            });

            if (e instanceof Response) {
                throw e;
            }

            console.log(this.storeName, 'createEntity', e);
        }

        return null;
    };

    archiveEntity = async (
        entity: T,
        options?: Partial<IDeleteEntitiesOptions>
    ): Promise<T | null> => {
        const { shouldFetchAfterSuccess } = {
            shouldFetchAfterSuccess: false,
            ...options,
        };

        try {
            await this._crudApiService.deleteById(entity.id);

            const updatedEntity = { ...entity, deletedAt: new Date() };

            this.update({
                actionStatus: {
                    action: 'archive',
                    success: true,
                    entity: updatedEntity,
                },
            });

            this.upsert(entity.id, updatedEntity);

            if (shouldFetchAfterSuccess) {
                const { ui } = this.getValue();

                if (ui.pageNumber === 1) {
                    this.fetchEntities(ui);
                } else {
                    this.patchUIState({ pageNumber: 1 });
                }
            }

            return updatedEntity;
        } catch (e: any) {
            this.update({
                actionStatus: {
                    action: 'archive',
                    success: false,
                    entity,
                },
            });

            if (e instanceof Response) {
                throw e;
            }

            console.log(this.storeName, 'archiveEntiity', e);
        }

        return null;
    };

    restoreEntity = async (
        entity: T,
        options?: Partial<IDeleteEntitiesOptions>
    ): Promise<T | null> => {
        const { shouldFetchAfterSuccess } = {
            shouldFetchAfterSuccess: false,
            ...options,
        };
        try {
            const restoredEntity = await this._crudApiService.restore(
                entity.id
            );

            this.update({
                actionStatus: {
                    action: 'restore',
                    success: true,
                    entity: restoredEntity,
                },
            });

            this.upsert(entity.id, restoredEntity);

            if (shouldFetchAfterSuccess) {
                const { ui } = this.getValue();

                if (ui.pageNumber === 1) {
                    this.fetchEntities(ui);
                } else {
                    this.patchUIState({ pageNumber: 1 });
                }
            }

            return restoredEntity;
        } catch (e: any) {
            this.update({
                actionStatus: {
                    action: 'restore',
                    success: false,
                    entity,
                },
            });

            if (e instanceof Response) {
                throw e;
            }

            console.log(this.storeName, 'restoreEntiity', e);
        }

        return null;
    };

    deleteById = async (
        id: string,
        options?: Partial<IDeleteEntitiesOptions>
    ): Promise<void> => {
        const { shouldFetchAfterSuccess } = {
            shouldFetchAfterSuccess: false,
            ...options,
        };

        try {
            await this._crudApiService.deleteById(id);

            this.update({
                actionStatus: {
                    action: 'delete',
                    success: true,
                    entity: { id },
                },
            });

            applyTransaction(async () => {
                const { currentPageIds, pagesIds } = this.getValue();

                this.remove(id);
                this.update({
                    currentPageIds: arrayRemove(currentPageIds, id),
                    pagesIds: pagesIds.map((pageIds) =>
                        arrayRemove(pageIds, id)
                    ),
                });
            });
        } catch (e: any) {
            this.update({
                actionStatus: {
                    action: 'delete',
                    success: false,
                    entity: { id },
                },
            });

            if (e instanceof Response) {
                throw e;
            }

            console.log(this.storeName, 'deleteById', e);
        }

        if (shouldFetchAfterSuccess) {
            const { ui } = this.getValue();

            if (ui.pageNumber === 1) {
                this.fetchEntities(ui);
            } else {
                this.patchUIState({ pageNumber: 1 });
            }
        }
    };

    deleteMultiple = async (
        ids: string[],
        options?: Partial<IDeleteEntitiesOptions>
    ): Promise<void> => {
        const { shouldFetchAfterSuccess } = {
            shouldFetchAfterSuccess: false,
            ...options,
        };

        try {
            const responses = await Promise.all(
                ids.map(async (id) => ({
                    id,
                    res: await this._crudApiService.deleteById(id),
                }))
            );

            const deletedIds = responses
                .filter(({ res }) => res.status === 204)
                .map(({ id }) => id);

            const { currentPageIds, pagesIds } = this.getValue();
            this.update({
                currentPageIds: arrayRemove(currentPageIds, deletedIds),
                pagesIds: pagesIds.map((pageIds) =>
                    arrayRemove(pageIds, deletedIds)
                ),
            });
            setTimeout(() => this.remove(deletedIds));
        } catch (e: any) {
            if (e instanceof Response) {
                throw e;
            }

            console.log(this.storeName, 'deleteById', e);
        }

        if (shouldFetchAfterSuccess) {
            const { ui } = this.getValue();

            if (ui.pageNumber === 1) {
                this.fetchEntities(ui);
            } else {
                this.patchUIState({ pageNumber: 1 });
            }
        }
    };
}
