import { ITokenResponse } from 'core/interfaces/login-response-model.interface';
import { ILoginCredentials } from 'modules/login/models/login.model';
import { ISession } from 'modules/login/models/session.model';
import {
    createUserFromResponse,
    IUser,
    IUserResponse,
} from 'modules/users/models/user.model';
import { IForgotPasswordFormData } from '../interfaces/forgot-password-form-data.interface';
import { apiService } from './apiService';

export interface IToken extends Omit<ISession, 'me'> {
    user: IUser;
}

class SessionAPIService {
    async current(): Promise<IUser> {
        const data = apiService.responseHandler(
            await apiService.get<IUserResponse>('/me')
        );
        return createUserFromResponse(data);
    }

    async login(credentials: ILoginCredentials): Promise<IToken> {
        const {
            user,
            token_key,
            refresh_token_key,
        } = apiService.responseHandler(
            await apiService.post<ITokenResponse, ILoginCredentials>(
                '/token',
                credentials
            )
        );

        return {
            token: token_key,
            refreshToken: refresh_token_key,
            user: createUserFromResponse(user),
        };
    }

    async refreshToken(refreshToken: string): Promise<IToken> {
        const {
            user,
            token_key,
            refresh_token_key,
        } = apiService.responseHandler(
            await apiService.get<ITokenResponse>(
                `/token/refresh/${refreshToken}`
            )
        );

        return {
            token: token_key,
            refreshToken: refresh_token_key,
            user: createUserFromResponse(user),
        };
    }

    async requestPasswordReset(
        email: string,
        callback_url: string
    ): Promise<boolean> {
        try {
            await apiService.post<null, IForgotPasswordFormData>(
                '/reset-password',
                {
                    email,
                    callback_url,
                }
            );
            return true;
        } catch (e: any) {
            console.warn('SessionAPIService.requestPasswordReset', e);

            return false;
        }
    }

    async resetPassword(hash: string, password: string): Promise<boolean> {
        try {
            await apiService.put<null, { password: string }>(
                `/reset-password/${hash}`,
                {
                    password,
                }
            );

            return true;
        } catch (e: any) {
            console.warn('SessionAPIService.resetPassword', e);

            return false;
        }
    }
}

export const sessionAPIService = new SessionAPIService();
