import { IFAIcon } from 'core/components/IconPickerProvider';
import { useMemo, useReducer } from 'react';

interface IReducerState {
    total: number;
    numberOfBufferPages: number;
    searchTerm: string;
    pageIndex: number;
    pageSize: number;
}

interface IReducerAction<T = any> {
    type:
        | 'setSearchTerm'
        | 'setPageSize'
        | 'setPageIndex'
        | 'increasePageIndex'
        | 'decreasePageIndex';
    payload?: T;
}

const getEndIndex = (
    pageIndex: number,
    pageSize: number,
    numberOfBufferPages: number
): number => (pageIndex + 1 + numberOfBufferPages) * pageSize;

const getStartIndex = (
    endIndex: number,
    pageSize: number,
    numberOfBufferPages: number
): number => endIndex + pageSize * (-2 - numberOfBufferPages);

const reducer = (state: IReducerState, action: IReducerAction) => {
    const endIndex = getEndIndex(
        state.pageIndex + 1,
        state.pageSize,
        state.numberOfBufferPages
    );

    const startIndex = getStartIndex(
        endIndex,
        state.pageSize,
        state.numberOfBufferPages
    );

    switch (action.type) {
        case 'setSearchTerm':
            return { ...state, searchTerm: action.payload };
        case 'setPageIndex':
            return { ...state, pageIndex: action.payload };
        case 'increasePageIndex':
            if (state.total < endIndex) {
                return state;
            }

            return { ...state, pageIndex: state.pageIndex + 1 };
        case 'decreasePageIndex':
            if (startIndex < 0) {
                return state;
            }
            return { ...state, pageIndex: state.pageIndex - 1 };
        default:
            return state;
    }
};

export const useIcons = (
    icons: IFAIcon[],
    numberOfBufferPages = 0,
    initPageSize = 100,
    initPageIndex = 0,
    initSearchTerm = ''
): [
    IFAIcon[],
    (term: string) => void,
    (index: number) => void,
    (size: number) => void,
    () => void,
    () => void
] => {
    const [{ searchTerm, pageIndex, pageSize }, dispatch] = useReducer<
        (state: IReducerState, action: IReducerAction) => IReducerState
    >(reducer, {
        total: icons.length,
        numberOfBufferPages,
        searchTerm: initSearchTerm,
        pageIndex: initPageIndex,
        pageSize: initPageSize,
    });

    const filteredIcons = useMemo(
        () =>
            icons
                .filter(({ label }) => label.includes(searchTerm))
                .filter((_icon, index) => {
                    const endIndex = Math.min(
                        icons.length,
                        getEndIndex(pageIndex, pageSize, numberOfBufferPages)
                    );
                    // const startIndex = Math.max(
                    //     getStartIndex(endIndex, pageSize, numberOfBufferPages),
                    //     0
                    // );

                    return index >= 0 && index < endIndex;
                }),
        [icons, searchTerm, pageIndex, pageSize]
    );

    return [
        filteredIcons,
        (payload: string) => {
            dispatch({ type: 'setSearchTerm', payload });
        },
        (payload: number) => {
            dispatch({ type: 'setPageIndex', payload });
        },
        (payload: number) => {
            dispatch({ type: 'setPageSize', payload });
        },
        () => {
            dispatch({ type: 'increasePageIndex', payload: pageIndex + 1 });
        },
        () => {
            dispatch({ type: 'decreasePageIndex', payload: pageIndex - 1 });
        },
    ];
};
