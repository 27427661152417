import { Box, Typography } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { asDateString } from 'core/helpers/asDateString';
import { IPulseDetails } from 'modules/pulse/models/pulse.model';
import React, { FC, useContext, useMemo } from 'react';
import { ValidTranslationKeys } from 'react-i18next';
import { usePulseCardStyles } from '../../pulse/hooks/pulse-card-styles.hook';

export const PulseCardScheduleSection: FC<IPulseDetails> = ({
    startDate,
    frequency,
    lastPulseEvent,
    nextPulseEvent,
}) => {
    const classes = usePulseCardStyles();
    const { t } = useContext(MultiLanguageSupportContext);

    const scheduleTitle = lastPulseEvent
        ? asDateString(new Date(lastPulseEvent), { format: 'date' })
        : t(!startDate ? 'pulse-not-sent-or-scheduled' : 'pulse-not-sent');

    const scheduleDescription = useMemo(() => {
        let descritption = startDate
            ? t(`frequency-${frequency}` as keyof ValidTranslationKeys)
            : t('set-a-schedule-by-editing-or-send-your-pulse-now');

        if (nextPulseEvent) {
            descritption += ` • ${t('next-pulse')}: ${asDateString(
                new Date(nextPulseEvent),
                { format: 'date' }
            )}`;
        }

        return descritption;
    }, [startDate, frequency, nextPulseEvent, t]);

    return (
        <Box className={classes.info}>
            <div>
                <DateRangeIcon className={classes.icon} />
            </div>

            <div>
                <Typography variant="h6">{scheduleTitle}</Typography>

                <Typography variant="body2" color="textSecondary">
                    {scheduleDescription}
                </Typography>
            </div>
        </Box>
    );
};
