import { convertDate } from 'shared/yup/utils/convertDate';

const prefixWithZeroIfSingleDigitNumber = (
    n: number,
    expectedNumberOfDigits: number
): string => {
    const s = '' + n;
    return s;

    return (
        s && new Array(expectedNumberOfDigits - s?.length).fill('0').join() + s
    );
};

type TimeOnlyFormat = 'HH:mm' | 'HH:mm:SS' | 'HH:mm:SS:sss';

interface ITimeOnlyOptions {
    format?: TimeOnlyFormat;
    separator?: string;
}

const getTimeOnlyFromDate = (
    d: Date,
    { format = 'HH:mm', separator = ':' }: ITimeOnlyOptions = {}
): string => {
    const hours = d.getHours(),
        minues = d.getMinutes(),
        secunds = d.getSeconds(),
        milisecunds = d.getMilliseconds();

    return format
        .replace('HH', prefixWithZeroIfSingleDigitNumber(hours, 2))
        .replace('mm', prefixWithZeroIfSingleDigitNumber(minues, 2))
        .replace('SS', prefixWithZeroIfSingleDigitNumber(secunds, 2))
        .replace('sss', prefixWithZeroIfSingleDigitNumber(milisecunds, 3))
        .replaceAll(':', separator);
};

type DateOnlyFormat = 'YYYY-MM-DD' | 'DD-MM-YYYY' | 'MM-DD-YYYY';

interface IDateOnlyOptions {
    format?: DateOnlyFormat;
    separator?: string;
}

const getDateOnlyFromDate = (
    d: Date,
    { format = 'DD-MM-YYYY', separator = '/' }: IDateOnlyOptions = {}
): string => {
    const month = d.getMonth() + 1,
        day = d.getDate(),
        year = d.getFullYear();

    return format
        .replace('YYYY', prefixWithZeroIfSingleDigitNumber(year, 4))
        .replace('MM', prefixWithZeroIfSingleDigitNumber(month, 2))
        .replace('DD', prefixWithZeroIfSingleDigitNumber(day, 2))
        .replaceAll('-', separator);
};

type DateAsStringFormat = 'date-time' | 'time-date' | 'date' | 'time';

interface IDateAsStringOptions {
    format?: DateAsStringFormat;
    dateOptions?: IDateOnlyOptions;
    timeOptions?: ITimeOnlyOptions;
}

export const asDateString = (
    d: Date,
    { format, dateOptions, timeOptions }: IDateAsStringOptions = {}
): string => {
    switch (format) {
        case 'date':
            return getDateOnlyFromDate(convertDate(d), dateOptions);
        case 'time':
            return getTimeOnlyFromDate(d, timeOptions);
        case 'time-date':
            return `${getTimeOnlyFromDate(
                d,
                timeOptions
            )} ${getDateOnlyFromDate(d, dateOptions)}`;
        case 'date-time':
        default:
            return `${getDateOnlyFromDate(
                d,
                dateOptions
            )} ${getTimeOnlyFromDate(d, timeOptions)}`;
    }
};
