import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Dropdown, Paginator } from '@qagency/react-shared-library';
import { useCurrentPage } from 'core/hooks/current-page.hook';
import React, { useContext } from 'react';
import { pageSizeOptions } from '../constants/page-size-options';
import { useCrud } from '../hooks/crud.hook';
import { usePagination } from '../hooks/pagination.hook';
import { ErrorBoundary } from './ErrorBoundary';
import { MultiLanguageSupportContext } from './MultiLanguageSupportProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: 'Nunito Sans',
        marginTop: theme.spacing(1),
    },
    right: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& > *:first-child': {
            marginRight: theme.spacing(2),
        },
    },
    button: {
        padding: `${theme.spacing(1.5)}px 0`,
        minWidth: theme.spacing(3),
    },
    typoButton: {
        maxHeight: 12,
    },
}));

const getIconComponent = (label: string) => {
    const classes = useStyles();

    switch (label) {
        case 'prev':
            return <ArrowBackIosIcon style={{ fontSize: 12 }} />;
        case 'next':
            return <ArrowForwardIosIcon style={{ fontSize: 12 }} />;
        default:
            return (
                <Typography variant="body2" className={classes.typoButton}>
                    {label}
                </Typography>
            );
    }
};

export const EntityListPagination = () => {
    const { fetch } = useCrud();
    const { t } = useContext(MultiLanguageSupportContext);
    const { setPageNumber, setPageSize } = useCurrentPage();
    const {
        currentPageSize,
        currentPageNumber,
        totalNumberOfEntities,
    } = usePagination();

    const numberOfPreviousItems = (currentPageNumber - 1) * currentPageSize;

    const upperPageLimit = !totalNumberOfEntities
        ? null
        : numberOfPreviousItems + currentPageSize >= totalNumberOfEntities
        ? totalNumberOfEntities
        : numberOfPreviousItems + currentPageSize;

    const classes = useStyles();

    return (
        <ErrorBoundary>
            <div className={classes.root}>
                <div>
                    <span color="textSecondary">
                        Rows per page:{' '}
                        <Dropdown
                            option={currentPageSize}
                            availableOptions={pageSizeOptions.map((value) => ({
                                displayValue: value.toString(),
                                value,
                            }))}
                            onSelect={(nextPageSize) =>
                                setPageSize(nextPageSize)
                            }
                        />
                    </span>
                </div>
                <div className={classes.right}>
                    <div>
                        {numberOfPreviousItems + 1}-{upperPageLimit} of{' '}
                        {totalNumberOfEntities}
                    </div>
                    {!totalNumberOfEntities ? null : (
                        <Paginator
                            pageSize={currentPageSize}
                            pageNumber={currentPageNumber}
                            pageSizeOptions={pageSizeOptions}
                            totalNumberOfItems={totalNumberOfEntities}
                            buttonIdentifiers={[
                                'first',
                                'prev',
                                'next',
                                'last',
                            ]}
                            buttonIdentifierLabels={{
                                first: t('first'),
                                last: t('last'),
                            }}
                            selectPageNumber={(pageNumber) => {
                                switch (pageNumber) {
                                    case currentPageNumber:
                                        fetch();
                                        break;
                                    default: {
                                        setPageNumber(pageNumber);
                                    }
                                }
                            }}
                            renderSelectPageNumberButton={({
                                label,
                                ...props
                            }) => (
                                <Button
                                    size="small"
                                    color="default"
                                    variant="text"
                                    disableElevation
                                    className={classes.button}
                                    {...props}
                                >
                                    {getIconComponent(label)}
                                </Button>
                            )}
                            renderSelectPageSizeDropdown={() => <></>}
                        />
                    )}
                </div>
            </div>
        </ErrorBoundary>
    );
};
