import { FC } from 'react';
import { IEntityListItemProps } from '../../../core/interfaces/entity-list-item-props.interface';
import { ILanguage } from '../models/language.model';
import { LanguageListItemTemplate } from './LanguageListItemTemplate';

export const LanguageListItem: FC<IEntityListItemProps<ILanguage>> = ({
    entity: language,
}) => (
    <LanguageListItemTemplate name={language.name} locale={language.locale} />
);
