import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { WithProvider } from 'core/hocs/WithProvider';
import { Form, useFormikContext } from 'formik';
import { IScaleQuestionDetails } from 'modules/scale-questions/models/scale-question.model';
import { scaleQuestionsQuery } from 'modules/scale-questions/state/scale-questions.query';
import { scaleQuestionsStore } from 'modules/scale-questions/state/scale-questions.store';
import React, { FC, useContext, useEffect } from 'react';
import { QuestionsDialogContext } from './QuestionsDialogProvider';

interface ICreateScaleQuestionFormProps {
    areQuestionFieldsDisabled?: boolean;
}

export const CreateScaleQuestionFormSystem: FC<ICreateScaleQuestionFormProps> = WithProvider(
    ({ areQuestionFieldsDisabled = false }) => {
        const { t } = useContext(MultiLanguageSupportContext);
        const {
            values,
            handleBlur,
            handleChange,
            errors,
            setFieldValue,
        } = useFormikContext<IScaleQuestionDetails>();

        const { scaleQuestionPreferenceId } = useContext(
            QuestionsDialogContext
        );

        useEffect(() => {
            if (values.mandatory) {
                setFieldValue('useAsDefault', true);
            }
        }, [values.mandatory]);

        useEffect(() => {
            if (!values.useAsDefault) {
                setFieldValue('mandatory', false);
            }
        }, [values.useAsDefault]);

        // const positionSelectLabel = `Position of the question (1 - ${
        //     scaleQuestionsStore.getValue().total
        // })`;

        // const positionSelectOptions = () => {
        //     const options = [];
        //     for (let i = 1; i <= scaleQuestionsStore.getValue().total!; i++) {
        //         options.push(i);
        //     }

        //     return options.map((value) => (
        //         <MenuItem value={value} key={value}>
        //             {value}
        //         </MenuItem>
        //     ));
        // };

        return (
            <Form>
                <Box>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            label={`${t('title')} (${t('survey-language')})`}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="name"
                            type="text"
                            name="name"
                            disabled={areQuestionFieldsDisabled}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            error={!!errors.name}
                        />
                    </Grid>
                    <Grid>
                        <Typography variant="body2">
                            {t('short-headline-for-use-in-graphs-and-dataset')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label={`${t('question')} (${t('survey-language')})`}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="phrase"
                            type="text"
                            name="phrase"
                            disabled={areQuestionFieldsDisabled}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phrase}
                            error={!!errors.phrase}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Position of the question"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="order"
                            type="number"
                            name="order"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.order}
                            error={!!errors.order}
                            inputProps={{
                                min: 0,
                            }}
                        />
                    </Grid>

                    {/* {scaleQuestionPreferenceId && ( */}
                    {/* <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>{positionSelectLabel}</InputLabel>
                            <Select
                                value={values.order}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={positionSelectLabel}
                                id="order"
                                name="order"
                                variant="outlined"
                                error={!!errors.order}
                                fullWidth
                            >
                                {positionSelectOptions()}
                            </Select>
                        </FormControl>
                    </Grid> */}
                    {/* )} */}

                    <Grid item xs={12}>
                        <Box display="flex">
                            <Typography>{t('mandatory-question')}</Typography>
                            <Switch
                                checked={values.mandatory}
                                onChange={handleChange}
                                name="mandatory"
                                color="primary"
                                inputProps={{
                                    'aria-label': 'secondary checkbox',
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex">
                            <Typography>{t('default-question')}</Typography>
                            <Switch
                                checked={values.useAsDefault}
                                onChange={handleChange}
                                name="useAsDefault"
                                color="primary"
                                inputProps={{
                                    'aria-label': 'secondary checkbox',
                                }}
                            />
                        </Box>
                    </Grid>
                </Box>
            </Form>
        );
    },
    scaleQuestionsQuery
);
