import { IconName } from '@fortawesome/fontawesome-common-types';
import { IconPrefix } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { IFactor } from '../models/success-factor.model';

type IFactorsFormProps = Pick<IFactor, 'fontAwesomeIcon'>;

export const FactorIcon: FC<IFactorsFormProps> = ({ fontAwesomeIcon }) => {
    return !fontAwesomeIcon ? (
        <></>
    ) : (
        <FontAwesomeIcon
            icon={[
                fontAwesomeIcon.split('-')[0] as IconPrefix,
                fontAwesomeIcon.split('-').slice(1).join('-') as IconName,
            ]}
        />
    );
};
