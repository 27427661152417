import {
    EntityFilters,
    IEntityTableFiltersProps,
} from 'core/components/EntityFilters';
import { FC } from 'react';

export enum UsersListFilter {
    ALL = 'ALL',
    PORTFOLIO = 'PORTFOLIO',
    ACCOUNT = 'ACCOUNT',
}

type UserTableFiltersProps = Omit<
    IEntityTableFiltersProps<UsersListFilter>,
    'options'
>;

export const UserFilters: FC<UserTableFiltersProps> = (props) => (
    <EntityFilters
        {...props}
        options={[
            { label: 'All', type: UsersListFilter.ALL },
            { label: 'Portfolio', type: UsersListFilter.PORTFOLIO },
            { label: 'Account', type: UsersListFilter.ACCOUNT },
        ]}
    />
);
