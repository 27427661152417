import { Typography } from '@material-ui/core';
import { ApexOptions } from 'apexcharts';
import { IGraphData } from 'modules/dashboard/models/dashboard.models';
import { FC, useContext } from 'react';
import Chart from 'react-apexcharts';
import { MultiLanguageSupportContext } from '../../../../core/components/MultiLanguageSupportProvider';

export interface SuccessFactorsGraphProps {
    data: IGraphData<number | null>[];
    options: ApexOptions;
}

export const SuccessFactorsGraph: FC<SuccessFactorsGraphProps> = ({
    data,
    options,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);

    return (
        <>
            <Typography align="center" variant="h4">
                {t('success-factors-graph')}
            </Typography>
            <Chart
                series={data}
                options={options}
                type="bar"
                height={'300px'}
            />
        </>
    );
};
