import { subject } from '@casl/ability';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ICrumb } from 'core/components/shared/topBars/BreadcrumbsContainer';
import { useSession } from 'core/hooks/session.hook';
import { CategoriesPage } from 'modules/categories/pages/CategoriesPage';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { ParticipantFormProvider } from 'modules/participants/component/ParticipantFormProvider';
import { RespondentsPageConatiner } from 'modules/participants/component/RespondentsPage';
import { QuestionsListFilter } from 'modules/pulse-questions/components/QuestionFilters';
import { QuestionsDialogProvider } from 'modules/pulse-questions/components/system-wide/QuestionsDialogProvider';
import { QuestionsPage } from 'modules/pulse-questions/components/system-wide/QuestionsPage';
import { SuccessFactorsListFilter } from 'modules/success-factors/components/FactorFilters';
import React, { FC, useContext, useMemo } from 'react';
import {
    IPageTabsPropsItem,
    PageTabs,
} from '../../../core/components/mock/tabs/PageTabs';
import { PageTitleBar } from '../../../core/components/shared/topBars/PageTitleBar';
import { SuccessFactorFormProvider } from '../../success-factors/components/SuccessFactorsFormProvider';
import { FactorsPage } from '../../success-factors/pages/FactorsPage';
import { UsersPageContainer } from '../../users/pages/UsersPage';

const items: IPageTabsPropsItem[] = [
    {
        headerLabel: 'users',
        table: <UsersPageContainer />,
    },
    {
        headerLabel: 'categories',
        table: <CategoriesPage />,
    },
    {
        headerLabel: 'respondents',
        table: (
            <ParticipantFormProvider>
                <RespondentsPageConatiner />
            </ParticipantFormProvider>
        ),
    },
    {
        headerLabel: 'pulse-questions',
        table: (
            <QuestionsDialogProvider>
                <QuestionsPage
                    showFilters
                    initialFilter={QuestionsListFilter.ALL}
                    createButtonLabelTranslationKey="create-question"
                />
            </QuestionsDialogProvider>
        ),
    },
    {
        headerLabel: 'success-factors',
        table: (
            <SuccessFactorFormProvider isSystemWide={false}>
                <FactorsPage
                    showFilters
                    initialFilter={SuccessFactorsListFilter.ALL}
                    createButtonLabelTranslationKey="create-factor"
                />
            </SuccessFactorFormProvider>
        ),
    },
];

export const AccountsSettingsPage: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { me, abilities } = useSession();

    const pageCrumbs: ICrumb[] = [
        { label: t('accounts-settings'), link: ROUTES.ACCOUNT_SETTINGS },
        { label: t('users'), link: '' },
    ];

    const filteredItems = useMemo(
        () =>
            items.filter(({ headerLabel }) => {
                switch (headerLabel) {
                    case 'users':
                        return abilities.can('crud', 'IUser');
                    case 'categories':
                        return abilities.can('crud', 'ICategory');
                    case 'respondents':
                        return abilities.can('crud', 'IParticipant');
                    case 'pulse-questions':
                        return !me
                            ? false
                            : abilities.can(
                                'crud',
                                subject('IScaleQuestion', {
                                    organizationId: me.organizationId,
                                })
                            );
                    case 'success-factors':
                        return !me
                            ? false
                            : abilities.can(
                                'crud',
                                subject('ISuccessFactor', {
                                    organizationId: me.organizationId,
                                })
                            );
                    default:
                        return true;
                }
            }),
        [me, abilities]
    );

    return (
        <>
            <PageTitleBar
                title={t('accounts-settings')}
                icon={
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="40" height="40" rx="4" fill="#172B4D" />
                        <path
                            d="M24 19C25.66 19 26.99 17.66 26.99 16C26.99 14.34 25.66 13 24 13C22.34 13 21 14.34 21 16C21 17.66 22.34 19 24 19ZM16 19C17.66 19 18.99 17.66 18.99 16C18.99 14.34 17.66 13 16 13C14.34 13 13 14.34 13 16C13 17.66 14.34 19 16 19ZM16 21C13.67 21 9 22.17 9 24.5V27H23V24.5C23 22.17 18.33 21 16 21ZM24 21C23.71 21 23.38 21.02 23.03 21.05C24.19 21.89 25 23.02 25 24.5V27H31V24.5C31 22.17 26.33 21 24 21Z"
                            fill="white"
                        />
                    </svg>
                }
                crumbs={pageCrumbs}
            />
            {!filteredItems.length ? (
                <span>There are not tabs available. </span> // TODO: add translation or better content
            ) : (
                <PageTabs items={filteredItems} />
            )}
        </>
    );
};
