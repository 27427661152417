import {
    Box,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useRemindOn } from 'core/hooks/shared/remind-on.hook';
import { Form, useFormikContext } from 'formik';
import { useLanguages } from 'modules/languages/hooks/languages.hook';
import React, { FC, useContext, useEffect } from 'react';
import { PulseScheduleForm } from '../../pulse-schedules/components/PulseScheduleForm';
import { maxDaysToRespond } from '../constants';
import { IPulseDetails } from '../models/pulse.model';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '760px',
    },
    form: {
        width: '100%',
    },
}));

const availableDaysToRespond = new Array(maxDaysToRespond).fill(null);

export const PulseForm: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);

    const {
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
    } = useFormikContext<IPulseDetails>();

    const [languages] = useLanguages();

    const classes = useStyles();

    const daysToRemindOn = useRemindOn(values.daysToRespond);

    useEffect(() => {
        if (values.reminder > daysToRemindOn.length) {
            setFieldValue('reminder', daysToRemindOn.length);
        }
    }, [daysToRemindOn]);

    return (
        <>
            <Box my={3}>
                <Typography variant="h1" align="center">
                    {t('pulse-details')}
                </Typography>
            </Box>
            <Container className={classes.container}>
                <Form className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="name"
                                name="name"
                                required
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                label={t('pulse-name')}
                                error={!!errors.name}
                            />
                            <Typography
                                className="MuiTypography-badge"
                                color="textSecondary"
                            >
                                {t(
                                    'pulse-name-is-not-visible-for-participants'
                                )}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth required>
                                <InputLabel>{t('language')}</InputLabel>
                                <Select
                                    id="languageId"
                                    name="languageId"
                                    value={values.languageId ?? ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label={t('language')}
                                    error={!!errors.languageId}
                                >
                                    {languages.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" fullWidth required>
                                <InputLabel>{t('days-to-respond')}</InputLabel>
                                <Select
                                    id="daysToRespondd"
                                    name="daysToRespond"
                                    value={values.daysToRespond ?? ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={t('days-to-respond')}
                                    error={!!errors.daysToRespond}
                                >
                                    {availableDaysToRespond.map(
                                        (_item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={index + 1}
                                            >
                                                {index + 1}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                // error={!!values.reminder}
                            >
                                <InputLabel>{t('reminder')}</InputLabel>
                                <Select
                                    value={values.reminder ?? ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={t('reminder')}
                                    name="reminder"
                                    labelId="reminder"
                                    id="reminder"
                                >
                                    {daysToRemindOn.map((label, index) => (
                                        <MenuItem key={index} value={index + 1}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <PulseScheduleForm />
                </Form>
            </Container>
        </>
    );
};
