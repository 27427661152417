import { useCurrentPageEntities } from 'core/hooks/current-page-entities.hook';
import { useCurrentPage } from 'core/hooks/current-page.hook';
import { usePagination } from 'core/hooks/pagination.hook';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

export const EntityInfiniteScroll: FC = ({ children }) => {
    const { incrementPageNumber } = useCurrentPage();
    const { fetching, allPagesEntities } = useCurrentPageEntities();
    const { totalNumberOfEntities } = usePagination();

    return (
        <InfiniteScroll
            fetching={fetching}
            items={allPagesEntities}
            total={totalNumberOfEntities}
            handleObserver={(entries: IntersectionObserverEntry[]) => {
                const entry = entries[0];

                if (entry.isIntersecting) {
                    incrementPageNumber();
                }
            }}
            Loader={() =>
                fetching ? (
                    <h2>Loading...</h2>
                ) : (
                    <h2 onClick={() => incrementPageNumber()}>Load More</h2>
                )
            }
        >
            {children}
        </InfiniteScroll>
    );
};

const options = {
    root: null,
    rootMargin: '500px',
    threshold: 1.0,
};

interface IInfiniteScrollProps<T = any> {
    fetching?: boolean;
    items: T[];
    total: number | null;
    handleObserver: (entries: IntersectionObserverEntry[]) => void;
    Loader: FC;
}

export const InfiniteScroll: FC<IInfiniteScrollProps> = ({
    children,
    total,
    items,
    handleObserver,
    Loader,
}) => {
    const loader = useRef<HTMLDivElement>(null);

    const [observer, setObserver] = useState<IntersectionObserver | null>(null);

    useEffect(() => {
        if (loader.current && !observer) {
            setObserver(new IntersectionObserver(handleObserver, options));
        }
    }, [loader.current]);

    useEffect(() => {
        if (observer && loader.current) {
            observer.observe(loader.current);
        }
    }, [observer]);

    return (
        <>
            {children}
            {!total || total <= items.length ? (
                <></>
            ) : (
                <div style={{ textAlign: 'center' }} ref={loader}>
                    <Loader />
                </div>
            )}
        </>
    );
};
