import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { initializeApiInterceptors } from './axiosInterceptors';
import { HttpClient } from './httpClient';

export type ResponseHandler<T = any, R = T> = (response: AxiosResponse<T>) => R;

function statusChecker<T>(response: AxiosResponse<T>) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    throw response.data;
}

class ApiService {
    private _httpClient = HttpClient.getInstance();
    constructor() {
        initializeApiInterceptors(this._httpClient);
    }

    responseHandler = <T = any>({ data }: AxiosResponse<T>) => data;

    async get<T = any>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return statusChecker(await this._httpClient.get<T>(url, config));
    }

    async post<T = any, B = any>(
        url: string,
        body: B
    ): Promise<AxiosResponse<T>> {
        return statusChecker(await this._httpClient.post<T>(url, body));
    }

    async put<T = any, B = any>(
        url: string,
        body: B
    ): Promise<AxiosResponse<T>> {
        return statusChecker(await this._httpClient.put<T>(url, body));
    }

    async delete(url: string): Promise<AxiosResponse<null>> {
        return statusChecker(await this._httpClient.delete(url));
    }
}

export const apiService = new ApiService();
