export enum ROUTES {
    NOT_FOUND = '*',
    DEFAULT = '/',
    LOGIN = '/login',
    CREATE_USER = '/create-user',
    ACTIVATE_ACCOUNT = '/activate/:id',
    PROJECTS = '/projects',
    ANALYTICS = '/analytics',
    PULSE = '/projects/pulses/:id',
    PROJECT = '/projects/:id',
    ORGANIZATION = '/organization',
    SUPER_ADMIN = '/super-admin',
    RESET_PASSWORD_HASH = '/reset-password/:id',
    RESET_PASSWORD = '/reset-password',
    REFRESH_PASSWORD = '/refresh-password',
    USERS = '/users',
    USER = '/users/:id',
    ABOUT_NEW_ORGANIZATION = '/about-new-organization',
    CREATE_NEW_ORGANIZATION = '/create-new-organization',
    TBD = '/tbd',
    ACCOUNT_SETTINGS = '/accounts-settings',
    SUCCESS_PASSWORD = '/reset-password/success-password',
    SUCCESS_REQUEST_PASSWORD = '/reset-password/success-request-password',
    SURVEY = '/survey/:id',
    OLD_SURVEY = '/pulses/:id',
    FEEDBACK = '/feedback/:id',
    DATA_PROTECTION_POLICY = '/data-protection-policy',
}
