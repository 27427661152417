import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useContext, useMemo } from 'react';
import { ValidTranslationKeys } from 'react-i18next';

export const useRemindOn = (daysToRespond: number) => {
    const { t } = useContext(MultiLanguageSupportContext);

    return useMemo(
        () =>
            new Array(daysToRespond).fill(null).map((_, index) =>
                !index
                    ? t('no-reminder')
                    : index === daysToRespond - 1
                    ? `${t('last')} ${t('day').toLowerCase()}`
                    : `${t(
                          `ordinal-${
                              index < 3 ? index + 1 : 'n'
                          }` as keyof ValidTranslationKeys,
                          {
                              count: index + 1,
                          }
                      )} ${t('day').toLowerCase()}`
            ),
        [t, daysToRespond]
    );
};
