import { combineQueries, HashMap } from '@datorama/akita';
import { IParticipant } from 'modules/participants/models/participant.model';
import { participantsQuery } from 'modules/participants/state/participants.query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntitiesQuery } from '../../../core/state/entities.query';
import {
    createPulseParticipant,
    IPulseParticipant,
} from '../models/pulse-participant.model';
import { pulseParticipantsStore } from './pulse-participants.store';

const getParticipants = (
    ids: string[],
    pulseParticipants: HashMap<IPulseParticipant>,
    participants: HashMap<IParticipant>
): IPulseParticipant[] =>
    ids.reduce((list, id) => {
        const pulseParticipant = pulseParticipants[id];

        if (!pulseParticipant) {
            return list;
        }

        const { respondentId } = pulseParticipant;

        return !respondentId
            ? list
            : [
                  ...list,
                  createPulseParticipant({
                      ...participants[respondentId],
                      ...pulseParticipant,
                  }),
              ];
    }, [] as IPulseParticipant[]);

export class PulseParticipantsQuery extends EntitiesQuery<IPulseParticipant> {
    readonly currentPageParticipants$: Observable<
        IPulseParticipant[]
    > = combineQueries([
        this.currentPageIds$,
        this.selectAll({ asObject: true }),
        participantsQuery.selectAll({ asObject: true }),
    ]).pipe(
        map(([ids, pulseParticipants, participants]) =>
            getParticipants(ids, pulseParticipants, participants)
        )
    );

    getCurrentPageParticipants = () => {
        const { entities, currentPageIds } = this.getValue();

        return getParticipants(
            currentPageIds,
            entities ?? {},
            participantsQuery.getAll({ asObject: true })
        );
    };
}

export const pulseParticipantsQuery = new PulseParticipantsQuery(
    pulseParticipantsStore
);
