import React, { FC, useCallback, useContext } from 'react';
import {
    IComponentWrappedWithEntityByIdProps,
    WithEntityById,
} from '../../../core/hocs/WithEntityById';
import { ILanguage } from '../models/language.model';
import { EntityListActions } from '../../../core/components/EntityListActions';
import { useHighlight } from '../../../core/hooks/highlight.hook';
import { ListActionIcon } from '../../../core/components/shared/ListActionIcon';
import EditIcon from '@material-ui/icons/Edit';
import { Box } from '@material-ui/core';
import { LanguageDialogContext } from './LanguageDialogProvider';

export const LanguageActions: FC<
    IComponentWrappedWithEntityByIdProps<ILanguage>
> = ({ entity: language }) => {
    const { highlight } = useHighlight();

    const { open } = useContext(LanguageDialogContext);

    const getActions = useCallback(
        () => [
            {
                callback: () => open(language),
                component: (
                    <>
                        <Box display="flex" justifyContent={'flex-end'}>
                            <ListActionIcon iconComponent={<EditIcon />} />
                        </Box>
                    </>
                ),
            },
        ],
        [language, highlight]
    );

    return <EntityListActions actions={getActions()} />;
};

export const LanguageActionById = WithEntityById(LanguageActions);
