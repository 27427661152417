import { Query, selectPersistStateInit } from '@datorama/akita';
import { sessionAPIService } from 'core/services/sessionAPIService';
import { IUser } from 'modules/users/models/user.model';
import { usersQuery } from 'modules/users/state/users.query';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ISession } from '../models/session.model';
import { sessionStore } from './session.store';

export class SessionQuery extends Query<ISession> {
    readonly token$ = this.select('token');
    readonly isLoggedIn$ = this.select((state) => !!state.token);
    readonly me$ = this.select('me').pipe(
        switchMap((id) =>
            !id
                ? of(null)
                : usersQuery.selectEntity(id).pipe(map((x) => x ?? null))
        )
    );

    constructor() {
        super(sessionStore);

        /**
         * This observable emits after Akita initialized the stores based on the storage's value.
         * We do NOT want to show persisted errors
         */
        selectPersistStateInit().subscribe(() => {
            this.store.setError(null);
        });

        this.isLoggedIn$.subscribe(
            async (value) =>
                value &&
                sessionStore.updateCurrent(await sessionAPIService.current())
        );
    }

    get refreshToken(): string | null {
        const { refreshToken } = this.getValue();

        return refreshToken;
    }

    get token(): string | null {
        const { token } = this.getValue();

        return token;
    }

    get isLoggedIn(): boolean {
        return !!this.token;
    }

    get me(): IUser | null {
        const { me } = this.getValue();

        return !me ? null : usersQuery.getEntity(me) ?? null;
    }
}

export const sessionQuery = new SessionQuery();
