import { Box, Typography } from '@material-ui/core';
import { FC, useContext } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';

interface IFeedbacksDashboardProps {
    feedback: string[];
}

export const FeedbacksDashboard: FC<IFeedbacksDashboardProps> = ({
    feedback,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);

    const feedbackItems = feedback
        ?.filter((item) => item !== ' ')
        ?.map((item, index) => {
            return <li key={index}>{item}</li>;
        });

    return (
        <Box border={'1px solid #DFE1E6'} p={5} borderRadius="8px" mt={5}>
            <Box mb={2}>
                <Typography>
                    <Typography component="span" variant="h5">
                        <strong>{`${t('feedback')} (${t(
                            'public'
                        ).toLowerCase()})`}</strong>
                    </Typography>
                </Typography>
            </Box>

            {feedbackItems?.length > 0 && <ul>{feedbackItems}</ul>}
        </Box>
    );
};
