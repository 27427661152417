import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useDebounce } from 'core/hooks/shared/debounce.hook';
import React, { FC, useEffect, useState } from 'react';

export interface ISearchInputProps
    extends Omit<TextFieldProps, 'value' | 'InputProps' | 'onChange'> {
    initialValue: string;
    onChange: (searchTerm: string) => void;
}

export const SearchInput: FC<ISearchInputProps> = ({
    initialValue,
    onChange,
    ...props
}) => {
    const [inputValue, setInputValue] = useState(initialValue);
    const debouncedSearchTerm = useDebounce(inputValue, 500);

    useEffect(() => {
        onChange(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    return (
        <TextField
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
};
