import { IEntity } from '../../../core/interfaces/entity.interface';

export interface IAccountDetails {
    name: string;
    logoLink: string;
    uploadedImage: string;
    actionTextColor: string;
    headerBackgroundColor: string;
    primaryColor: string;
    ownerName: string;
    email: string;
    address: string;
    subscription: string;
    comments: string;
}

export interface IAccount extends IEntity, IAccountDetails {}

export interface IAccountResponse extends IEntity<number> {
    name: string;
    logo_link: string;
    action_text_color: string;
    header_background_color: string;
    primary_color: string;
    owner_name: string;
    email: string;
    address: string;
    subscription: string;
    comments: string;
}

export const emptyAccount: Omit<IAccount, 'id'> = {
    name: '',
    logoLink: '',
    uploadedImage: '',
    actionTextColor: '',
    headerBackgroundColor: '',
    primaryColor: '',
    ownerName: '',
    email: '',
    address: '',
    subscription: '',
    comments: '',
};

export const createAccount = ({
    id,
    ...props
}: Partial<IAccount>): IAccount => {
    if (!id) throw new Error('Unable to create a Account without an ID!');

    return {
        id,
        ...emptyAccount,
        ...props,
    };
};

export const createAccountFromResponse = ({
    id,
    logo_link,
    action_text_color,
    header_background_color,
    primary_color,
    owner_name,
    ...props
}: IAccountResponse): IAccount => {
    return createAccount({
        id: id.toString(),
        logoLink: logo_link,
        actionTextColor: action_text_color.replace('#', ''),
        headerBackgroundColor: header_background_color.replace('#', ''),
        primaryColor: primary_color.replace('#', ''),
        ownerName: owner_name,
        ...props,
    });
};

export interface IAccountCommand {
    name: string;
    logo_link: string;
    uploaded_image: string;
    actionTextColor: string;
    headerBackgroundColor: string;
    primaryColor: string;
    ownerName: string;
    email: string;
    address: string;
    subscription: string;
    comments: string;
}

export const createAccountCommand = ({
    uploadedImage,
    logoLink,
    ...props
}: IAccountDetails): IAccountCommand => {
    return {
        uploaded_image: uploadedImage,
        logo_link: logoLink,
        ...props,
    };
};
