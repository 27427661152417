import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { useSession } from 'core/hooks/session.hook';
import { requiredField } from 'core/validations';
import { FormikProvider, useFormik } from 'formik';
import {
    emptyScaleQuestionDetails,
    IScaleQuestion,
    IScaleQuestionDetails,
} from 'modules/scale-questions/models/scale-question.model';
import React, {
    createContext,
    FC,
    useCallback,
    useMemo,
    useState,
} from 'react';
import * as Yup from 'yup';
import { scaleQuestionsQuery } from '../../../scale-questions/state/scale-questions.query';

interface IQuestionsDialogContext {
    scaleQuestionPreferenceId: string | null;
    isOpen: boolean;
    stepIndex: number;
    setStepIndex: (stepNumber: number) => void;
    close: () => void;
    open: (question: Partial<IScaleQuestion>, stepIndex?: number) => void;
    preferenceOrganizationId: string | null;
}

export interface IScaleQuestionFormik extends IScaleQuestionDetails {
    openNewQuestion?: boolean;
}

export const QuestionsDialogContext = createContext<IQuestionsDialogContext>(
    {} as IQuestionsDialogContext
);

const validationSchema = Yup.object({
    name: requiredField,
    phrase: requiredField,
    order: Yup.number().min(1).required(),
});

interface IQuestionsDialogProviderProps {
    isSystemWide?: boolean;
}

export const QuestionsDialogProvider: FC<IQuestionsDialogProviderProps> = WithProvider(
    ({ children, isSystemWide }) => {
        const [isOpen, setIsOpen] = useState(false);
        const { me } = useSession();
        const [
            scaleQuestionPreferenceId,
            setScaleQuestionPreferenceId,
        ] = useState<string | null>(null);
        const [stepIndex, setStepIndex] = useState(0);
        const [
            initialValues,
            setInitialValues,
        ] = useState<IScaleQuestionFormik>({
            ...emptyScaleQuestionDetails,
            questionId: '',
        });

        const preferenceOrganizationId = useMemo(
            () => (isSystemWide ? null : me?.organizationId ?? null), // FIXME: should not be null unless isSystemWide === true
            [isSystemWide, me]
        );

        const close = () => {
            setIsOpen(false);
            setScaleQuestionPreferenceId(null);
        };

        const open = (
            { id, ...details }: Partial<IScaleQuestion>,
            newStepIndex = 0
        ) => {
            setIsOpen(true);
            setScaleQuestionPreferenceId(id ?? null);

            setInitialValues({
                ...emptyScaleQuestionDetails,
                preferenceOrganizationId,
                organizationId: preferenceOrganizationId,
                ...details,
            });
            setStepIndex(newStepIndex);
        };

        const { createSingle, updateSingle } = useCrud<IScaleQuestion>();

        const onSubmit = useCallback(
            async ({
                openNewQuestion,
                preferenceOrganizationId: _temp,
                ...data
            }: IScaleQuestionFormik) => {
                const question = scaleQuestionPreferenceId
                    ? await updateSingle(
                          {
                              ...data,
                              id: scaleQuestionPreferenceId,
                              preferenceOrganizationId,
                          },
                          {
                              shouldFetchAfterSuccess: true,
                          }
                      )
                    : await createSingle(
                          { ...data, preferenceOrganizationId },
                          {
                              shouldFetchAfterSuccess: true,
                          }
                      );
                if (question) {
                    const { id, ...details } = question;
                    setScaleQuestionPreferenceId(id);
                    setInitialValues(details);
                }
                return question;
            },
            [scaleQuestionPreferenceId, preferenceOrganizationId]
        );

        const formik = useFormik<IScaleQuestionFormik>({
            initialValues,
            enableReinitialize: true,
            validateOnBlur: true,
            validateOnChange: true,
            validateOnMount: !!scaleQuestionPreferenceId,
            onSubmit,
            validationSchema,
        });

        return (
            <QuestionsDialogContext.Provider
                value={{
                    isOpen,
                    close,
                    open,
                    scaleQuestionPreferenceId,
                    stepIndex,
                    setStepIndex,
                    preferenceOrganizationId,
                }}
            >
                <FormikProvider value={formik}>{children}</FormikProvider>
            </QuestionsDialogContext.Provider>
        );
    },
    scaleQuestionsQuery
);
