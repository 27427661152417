import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSession } from '../../../core/hooks/session.hook';
import { ROUTES } from '../../navigation/enums/routes.enum';
import { RequestResetPasswordForm } from '../components/RequestPasswordForm';
import { IResetPasswordCredentials } from '../models/reset.password.model';

export const RequestResetPasswordPage: FC = () => {
    const { requestResetPassword } = useSession();
    const { push } = useHistory();

    useEffect(() => {
        localStorage.removeItem("CURRENT");
    }, [])

    const onSubmitClick = async (credentials: IResetPasswordCredentials) => {
        await requestResetPassword(credentials).then(() =>
            push({ pathname: ROUTES.SUCCESS_REQUEST_PASSWORD })
        );
    };

    return <RequestResetPasswordForm onSubmitClick={onSubmitClick} />;
};
