import { Container } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useObservable } from '@mindspace-io/react';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { ICrumb } from 'core/components/shared/topBars/BreadcrumbsContainer';
import {
    PageTitleBar,
    PageTitleBarButton,
} from 'core/components/shared/topBars/PageTitleBar';
import { WithCurrentPageListener } from 'core/hocs/WithListContainer';
import { WithProvider } from 'core/hocs/WithProvider';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { useContext } from 'react';
import { apiService } from '../../../core/services/apiService';
import { ProjectDialogForm } from '../components/ProjectDialogForm';
import {
    ProjectDialogContext,
    ProjectDialogProvider,
} from '../components/ProjectDialogProvider';
import { ProjectList } from '../components/ProjectList';
import { projectsQuery } from '../state/projects.query';

const ProjectsPage = () => {
    const [activeProjectStatus] = useObservable(
        projectsQuery.filter$,
        projectsQuery.getValue().ui.filter
    );
    const { t } = useContext(MultiLanguageSupportContext);
    const { isOpen, close, open, projectId } = useContext(ProjectDialogContext);

    const exportCsvExcel = async (
        exportType: 'excel' | 'csv',
        exportFilter: 'all' | 'deleted' | 'active'
    ) => {
        await apiService
            .get<string>(
                `/projects/export/${exportType}?filter=${exportFilter.toLowerCase()}`,
                exportType === 'excel'
                    ? {
                          responseType: 'arraybuffer',
                      }
                    : undefined
            )
            .then((resp) => {
                /**
                 * works for EXCEL files becouse of this: https://github.com/axios/axios/issues/895
                 * the file name logic is also picked from the link but seems to need a BE tweek: https://stackoverflow.com/questions/43912862/axios-expose-response-headers-content-disposition
                 */
                const dirtyFileName = resp.headers['content-disposition'];
                const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                const fileName = dirtyFileName?.match(regex)[3] ?? 'export';

                const file = new Blob([resp.data], {
                    type: resp.headers['content-type'] ?? 'text/plain',
                });
                const url = window.URL.createObjectURL(file);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = `${fileName}.${
                    exportType === 'excel' ? 'xls' : 'csv'
                }`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
    };

    const buttons: PageTitleBarButton[] = [
        {
            label: t('excel-export'),
            color: 'secondary',
            callback: () => exportCsvExcel('excel', activeProjectStatus),
        },
        {
            label: t('csv-export'),
            color: 'secondary',
            callback: () => exportCsvExcel('csv', activeProjectStatus),
        },
        {
            label: t('create-new-project'),
            callback: () => open({}),
            icon: <AddIcon />,
        },
    ];

    const pageCrumbs: ICrumb[] = [
        { label: t('projects'), link: ROUTES.PROJECTS },
    ];

    return (
        <>
            <FormDialogWrapper
                isOpen={isOpen}
                handleClose={close}
                title={!projectId ? t('create-new-project') : t('edit-project')}
            >
                <ProjectDialogForm />
            </FormDialogWrapper>
            <PageTitleBar
                title={t('projects')}
                noTabs={true}
                icon={
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="40" height="40" rx="4" fill="#172B4D" />
                        <path
                            d="M12 14H10V28C10 29.1 10.9 30 12 30H26V28H12V14ZM28 10H16C14.9 10 14 10.9 14 12V24C14 25.1 14.9 26 16 26H28C29.1 26 30 25.1 30 24V12C30 10.9 29.1 10 28 10ZM27 19H17V17H27V19ZM23 23H17V21H23V23ZM27 15H17V13H27V15Z"
                            fill="white"
                        />
                    </svg>
                }
                buttons={buttons}
                crumbs={pageCrumbs}
            />
            <Container maxWidth="xl">
                <ProjectList />
            </Container>
        </>
    );
};

export const ProjectsPageConatiner = WithProvider(
    WithCurrentPageListener(() => (
        <ProjectDialogProvider>
            <ProjectsPage />
        </ProjectDialogProvider>
    )),
    projectsQuery
);
