import { StoreConfig } from '@datorama/akita';
import {
    EntitiesState,
    EntitiesStore,
} from '../../../core/state/entities.store';
import {
    createSuccessFactorCommand,
    createSuccessFactorFromResponse,
    ISuccessFactor,
    ISuccessFactorResponse,
} from '../models/success-factor.model';

export type FactorsState = EntitiesState<ISuccessFactor>;

@StoreConfig({
    name: 'factors',
    resettable: true,
})
class UsersStore extends EntitiesStore<
    ISuccessFactor,
    ISuccessFactorResponse
> {}

export const factorsStore = new UsersStore({
    responseMapper: createSuccessFactorFromResponse,
    commandMapper: createSuccessFactorCommand,
    searchTermParamKeys: ['phrase'],
});
