import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { ROUTES } from '../../../../modules/navigation/enums/routes.enum';
import { NavLink } from 'react-router-dom';

interface ChipData {
    key: number;
    label: string;
    route?: any;
}

const useStyles = makeStyles((theme) => ({
    menu: {
        marginRight: theme.spacing(1),
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
    },
}));

export default function SimpleMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const classes = useStyles();
    const [chipData] = React.useState<ChipData[]>([
        { key: 0, label: 'Projects', route: ROUTES.PROJECTS },
        { key: 1, label: 'Analytics', route: ROUTES.ANALYTICS },
        { key: 2, label: 'Account Settings', route: ROUTES.ACCOUNT_SETTINGS },
        { key: 3, label: 'Super Admin', route: ROUTES.SUPER_ADMIN },
        // { key: 4, label: 'Pulse TEST', route: ROUTES.PULSE },
    ]);

    return (
        <div>
            <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.menu}
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {chipData.map((data) => {
                    return (
                        <MenuItem key={data.key}>
                            <NavLink
                                to={data.route}
                                className={classes.link}
                                onClick={handleClose}
                            >
                                {data.label}
                            </NavLink>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}
