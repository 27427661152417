import React, { createContext, FC, useState } from 'react';

type Theme = 'light' | 'dark';

const ThemeSetterContext = createContext<[Theme, (theme: Theme) => void]>([
    'light',
    (theme) => {
        console.log(theme);
    },
]);

const ThemeSetterProvider: FC = ({ children }) => (
    <ThemeSetterContext.Provider value={useState<Theme>('light')}>
        {children}
    </ThemeSetterContext.Provider>
);

export { ThemeSetterProvider, ThemeSetterContext };
