import { StoreConfig } from '@datorama/akita';
import {
    EntitiesState,
    EntitiesStore,
} from '../../../core/state/entities.store';
import {
    createNewActivityFromResponse,
    IActivity,
    IActivityResponse,
} from '../models/activity.model';

export type ActivityState = EntitiesState<IActivity>;

@StoreConfig({
    name: 'activity',
    resettable: true,
})
class ActivityStore extends EntitiesStore<IActivity, IActivityResponse> {}

export const activityStore = new ActivityStore({
    responseMapper: createNewActivityFromResponse,
});
