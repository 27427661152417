import { SuccessFactorsGraphProps } from '../components/Graphs/SuccessFactorsGraph';
import { IPulseAnswersSuccessFactors } from '../models/dashboard.models';
import { getSuccessFactorsOptions } from './getSuccessFactorsOptions';

export const getSuccessFactorsGraphProps = (
    categories: string[],
    graphData: IPulseAnswersSuccessFactors = {}
): SuccessFactorsGraphProps => {
    let biggestGreat = 0;
    let biggestImprove = 0;

    const successFactorsMap = Object.entries(graphData).reduce(
        (tempMap, [name, { improvable, great }]) => {
            if (great > biggestGreat) {
                biggestGreat = great;
            }

            if (improvable > biggestImprove) {
                biggestImprove = improvable;
            }

            return tempMap.set(name, [improvable ?? 0, great ?? 0]);
        },
        new Map<string, [number, number]>()
    );

    return {
        data: [
            {
                name: 'Improvable',
                data: categories?.map(
                    (item) => successFactorsMap?.get(item)?.[0] ?? null
                ),
            },
            {
                name: 'Great',
                data: categories?.map(
                    (item) => successFactorsMap?.get(item)?.[1] ?? null
                ),
            },
        ],
        options: getSuccessFactorsOptions({ xaxis: { categories } }),
    };
};
