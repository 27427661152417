import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { AlertModal } from 'modules/pulse/components/AlertModal';
import React, { useContext } from 'react';
import { ParticipantFormContext } from './ParticipantFormProvider';

export const ParticipantDeleteDialog = () => {
    const { t } = useContext(MultiLanguageSupportContext);

    const { deleteModalOpen, deleteItem, closeDeleteModal } = useContext(
        ParticipantFormContext
    );

    return (
        <FormDialogWrapper
            isOpen={deleteModalOpen}
            handleClose={closeDeleteModal}
            alertModal
        >
            <AlertModal
                title={`${t('delete-participant')}?`}
                contentText={t('delete-participant-content-message')}
                handleClose={closeDeleteModal}
                handleSubmit={deleteItem}
                submitModalText={t('yes-delete-participant')}
                closeModalText={t('keep-participant')}
                isActionDestructive={true}
            />
        </FormDialogWrapper>
    );
};
