import { IEntity } from 'core/interfaces/entity.interface';
import {
    createCategoryFromResponse,
    ICategory,
    ICategoryResponse,
} from './category.model';

export interface IDimension extends IEntity {
    name: string;
    categories: ICategory[];
}

export interface IDimensionResponse {
    key: number;
    name: string;
    items?: ICategoryResponse[];
}

export const createDimensionFromResponse = ({
    key,
    items,
    ...rest
}: IDimensionResponse): IDimension => {
    return {
        id: key.toString(),
        ...rest,
        categories: !items
            ? []
            : items.map((item) =>
                  createCategoryFromResponse(item, 0, rest.name)
              ),
    };
};
