import { Box, Button, DialogActions, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos } from '@material-ui/icons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { ValidTranslationKeys } from 'react-i18next';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { FormDialogWrapper } from '../../../core/components/shared/FormDialogWrapper';
import { StepperComponent } from '../../../core/components/shared/Stepper';
import { useCrud } from '../../../core/hooks/crud.hook';
import { apiService } from '../../../core/services/apiService';
import css from '../../../styles/material-ui/cssVars';
import { useDialog } from '../../projects/components/use-dialog.hook';
import { AlertModal } from '../../pulse/components/AlertModal';
import { FactorsForm } from './FactorsForm';
import {
    ISuccessFactorsFormik,
    ITranslationsFormik,
    SuccessFactorsFormContext,
} from './SuccessFactorsFormProvider';
import { SuccessFactorsTranslationsPage } from './SuccessFactorsTranslationsPage';

interface IStepConfig {
    content: JSX.Element;
    submitLabel?: string;
    discardLabel?: string;
    backLabel?: string;
    nextLabel?: string;
    disableSubmit?: boolean;
    disableBackAndNextIfSubmitDisabled?: boolean;
    onSubmit?: (shouldCloseDialog: boolean) => Promise<void>;
    onDiscard?: () => void;
}

interface IStep {
    title: keyof ValidTranslationKeys;
    description: keyof ValidTranslationKeys;
}

const useStyles = makeStyles((theme) => ({
    stepWrapper: {
        width: '50%',
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    form: {
        width: '100%',
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        backgroundColor: css.extraColors.text.darkPrimary,
        alignItems: 'center',
        width: '100%',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    buttonWrap: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            marginTop: `${theme.spacing(2)}px`,
        },
        '& button': {
            marginRight: theme.spacing(2),

            '&:last-child': {
                marginRight: 0,
                [theme.breakpoints.down('xs')]: {
                    marginBottom: 0,
                },
            },

            [theme.breakpoints.down('xs')]: {
                width: '100%',
                marginRight: '0',
                marginBottom: theme.spacing(2),
            },
        },
    },
    drop: {
        backgroundColor: 'transparent',
        width: '100%',
        height: '134px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px dashed',
        borderColor: theme.palette.divider,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    colors: {
        height: '134px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(0.5),
    },
    searchfield: {
        marginRight: theme.spacing(4),
        '& input': {
            padding: `14.5px ${theme.spacing(2)}px 14.5px 0`,
        },
    },
}));

export const FactorsDialogContent = () => {
    const classes = useStyles();

    const {
        close,
        factorsId,
        stepIndex: currentStepIndex,
        setStepIndex: setCurrentStepIndex,
        preferenceOrganizationId,
    } = useContext(SuccessFactorsFormContext);
    const { deleteMultiple, fetch } = useCrud();
    const detailsFormik = useFormikContext<ISuccessFactorsFormik>();

    const areFactorFieldsDisabled = useMemo(
        () =>
            detailsFormik.values.factor.organizationId === null &&
            preferenceOrganizationId !== null,
        [detailsFormik.values, preferenceOrganizationId]
    );

    const [initialValues, setInitialValues] = useState<ITranslationsFormik>({});
    const [
        languageTranslationRelation,
        setLanguageTranslationRelation,
    ] = useState<ITranslationsFormik>({});

    const steps: IStep[] = useMemo(
        () => [
            {
                title: factorsId
                    ? 'edit-success-factor'
                    : 'create-success-factor',
                description: 'system-wide',
            },
            {
                title: 'edit-translations',
                description: 'optional',
            },
        ],
        [factorsId]
    );

    const translationsFormik = useFormik<ITranslationsFormik>({
        initialValues,
        enableReinitialize: true,
        onSubmit: (values) => {
            const promises = Object.keys(values).map((translationId) => {
                const title = values[translationId];

                return apiService.put(`/factor-translations/${translationId}`, {
                    title,
                });
            });

            return Promise.all(promises);
        },
    });

    useEffect(() => {
        const tempTranslations: ITranslationsFormik = {};
        const tempLanguageTranslationRelation: ITranslationsFormik = {};
        const tempLanguageTranslation: ITranslationsFormik = {};
        detailsFormik.values.factor?.translations.forEach(
            ({ language, title, id }) => {
                tempLanguageTranslationRelation[language.id] = id;
                tempLanguageTranslation[id] = language.id;
                tempTranslations[id] = title;
            }
        );
        setInitialValues(tempTranslations);
        setLanguageTranslationRelation(tempLanguageTranslationRelation);
    }, [detailsFormik.values.factor]);

    const { t } = useContext(MultiLanguageSupportContext);

    const createSuccessFactor: IStepConfig = useMemo(() => {
        return {
            content: (
                <FormikProvider value={detailsFormik}>
                    <FactorsForm
                        areFactorFieldsDisabled={areFactorFieldsDisabled}
                    />
                </FormikProvider>
            ),
            submitLabel: t(
                factorsId ? 'save-changes' : 'create-success-factor'
            ),
            discardLabel: t('discard-changes'),
            nextLabel: t(factorsId ? 'save-next' : 'create-next'),
            disableSubmit: !detailsFormik.dirty || !detailsFormik.isValid,
            disableBackAndNextIfSubmitDisabled: !factorsId,
            onDiscard: () => detailsFormik.resetForm(),
            onSubmit: async (shouldCloseDialog) => {
                await detailsFormik.submitForm();

                if (shouldCloseDialog) {
                    close();
                }
            },
        };
    }, [detailsFormik, factorsId, t, areFactorFieldsDisabled]);

    const factorsStepTranslations: IStepConfig = useMemo(
        () => ({
            content: !factorsId ? (
                <></>
            ) : (
                <FormikProvider value={translationsFormik}>
                    <SuccessFactorsTranslationsPage
                        disabled={areFactorFieldsDisabled}
                        languageTranslationRelations={
                            languageTranslationRelation
                        }
                    />
                </FormikProvider>
            ),
            disableSubmit:
                !translationsFormik.dirty || !translationsFormik.isValid,
            submitLabel: t('save-changes'),
            discardLabel: t('discard-changes'),
            backLabel: t('save-back'),
            onDiscard: () => translationsFormik.resetForm(),
            onSubmit: async (shouldCloseDialog) => {
                await translationsFormik.submitForm();

                await fetch();

                if (shouldCloseDialog) {
                    close();
                }
            },
        }),
        [translationsFormik, t, areFactorFieldsDisabled]
    );

    const {
        content,
        submitLabel,
        discardLabel,
        backLabel,
        nextLabel,
        disableSubmit,
        disableBackAndNextIfSubmitDisabled,
        onSubmit,
        onDiscard,
    }: IStepConfig = useMemo(() => {
        switch (currentStepIndex) {
            case 0:
                return createSuccessFactor;
            case 1:
                return factorsStepTranslations;
            default:
                return {
                    content: <></>,
                    onSubmit: async () => console.log('onSubmit'),
                };
        }
    }, [currentStepIndex, createSuccessFactor, factorsStepTranslations]);

    const nextComponent = useCallback(() => {
        setCurrentStepIndex(currentStepIndex + 1);
    }, [currentStepIndex]);

    const previousComponent = useCallback(() => {
        setCurrentStepIndex(currentStepIndex - 1);
    }, [currentStepIndex]);

    const {
        open: showDeleteConfirmation,
        handleClose: handleDeleteConfirmationClose,
        handleOpen: handleDeleteConfirmationOpen,
    } = useDialog();

    return (
        <>
            <DialogContent>
                <Box className={classes.stepWrapper}>
                    <StepperComponent
                        activeStep={currentStepIndex}
                        steps={steps.map(({ title, description }) => ({
                            title: t(title),
                            description: t(description),
                        }))}
                        onClick={(index) =>
                            factorsId && setCurrentStepIndex(index)
                        }
                    />
                </Box>
                {content}
            </DialogContent>

            <DialogActions className={classes.modalActions}>
                <Button type="reset" disableElevation onClick={close}>
                    {factorsId ? t('close') : t('cancel')}
                </Button>

                <Box className={classes.buttonWrap}>
                    {factorsId && (
                        <Button
                            variant="contained"
                            disableElevation
                            className="MuiButton-colorWarn"
                            disabled={areFactorFieldsDisabled}
                            onClick={() => {
                                handleDeleteConfirmationOpen();

                                setTimeout(() => {
                                    fetch();
                                }, 3000);
                            }}
                        >
                            {t('delete-success-factor')}
                        </Button>
                    )}
                    {currentStepIndex > 0 && (
                        <Button
                            variant="contained"
                            type="submit"
                            disableElevation
                            onClick={previousComponent}
                            startIcon={<ArrowBackIos />}
                            disabled={
                                disableSubmit &&
                                disableBackAndNextIfSubmitDisabled
                            }
                        >
                            {onSubmit && !disableSubmit ? backLabel : t('back')}
                        </Button>
                    )}
                    {currentStepIndex < steps.length - 1 && (
                        <Button
                            variant="contained"
                            disableElevation
                            endIcon={<ArrowForwardIosIcon />}
                            disabled={
                                disableSubmit &&
                                disableBackAndNextIfSubmitDisabled
                            }
                            onClick={async () => {
                                onSubmit &&
                                    !disableSubmit &&
                                    (await onSubmit(false));

                                nextComponent();
                            }}
                        >
                            {onSubmit && (!factorsId || !disableSubmit)
                                ? nextLabel
                                : t('next')}
                        </Button>
                    )}
                    {onDiscard && factorsId && (
                        <Button
                            variant="contained"
                            disableElevation
                            className="MuiButton-colorWarn"
                            disabled={disableSubmit}
                            onClick={onDiscard}
                        >
                            {discardLabel}
                        </Button>
                    )}
                    {onSubmit && (
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disableElevation
                            disabled={disableSubmit}
                            onClick={() => {
                                onSubmit(true);
                            }}
                        >
                            {submitLabel}
                        </Button>
                    )}
                </Box>
            </DialogActions>

            {factorsId && (
                <FormDialogWrapper
                    isOpen={showDeleteConfirmation}
                    handleClose={handleDeleteConfirmationClose}
                    alertModal
                >
                    <AlertModal
                        title={t('delete-factors-title')}
                        isActionDestructive={true}
                        contentText={t(
                            'delete-factor-confirmation-description'
                        )}
                        closeModalText={t('delete-factor-decline')}
                        submitModalText={t('delete-factor-accept')}
                        handleClose={handleDeleteConfirmationClose}
                        handleSubmit={async () => {
                            factorsId && (await deleteMultiple([factorsId]));

                            handleDeleteConfirmationClose();

                            setTimeout(() => {
                                close();
                            }, 3000);
                        }}
                    />
                </FormDialogWrapper>
            )}
        </>
    );
};
