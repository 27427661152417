import { useObservable } from '@mindspace-io/react';
import { OrderDirection } from 'core/state/entities.store';
import { useContext, useMemo } from 'react';
import { map } from 'rxjs/operators';
import { EntitiesContext } from '../constants/entities.context';

export interface IUseOrder {
    orderBy: string | null;
    orderDirection: OrderDirection;
}

export const useOrder = (): IUseOrder => {
    const { query } = useContext(EntitiesContext);
    const {
        ui: { orderBy: initialOrderBy, orderDirection: initialOrderDirection },
    } = useMemo(() => query.getValue(), [query]);

    const [orderBy] = useObservable(
        query.select(({ ui }) => ui.orderBy).pipe(map((x) => x ?? null)),
        initialOrderBy ?? null
    );

    const [orderDirection] = useObservable(
        query.select(({ ui }) => ui.orderDirection),
        initialOrderDirection
    );

    return {
        orderBy,
        orderDirection,
    };
};
