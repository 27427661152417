import { Grid, GridSize } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FC, useContext } from 'react';
import { ICategory } from '../models/category.model';
import { IDimension } from '../models/dimension.model';
import { CategoryAutocomplete } from './CategoryAutocomplete';
import { DimensionsContext } from './DimensionsProvider';

export const getCategoriesByDimensionId = (
    categories?: ICategory[]
): ICategoriesByDimensionId =>
    categories?.reduce((obj: ICategoriesByDimensionId, category) => {
        obj[category.dimensionId] = category;

        return obj;
    }, {}) ?? {};

export interface ICategoriesByDimensionId {
    [key: string]: ICategory | null;
}

interface IDimenstionInputsProps {
    inputSize?: { [key in Breakpoint]?: GridSize };
    categoriesByDimensionId?: ICategoriesByDimensionId;
    disabled?: boolean;
    emptyName: string;
    onChange: (
        dimension: IDimension,
        category: ICategory | null
    ) => Promise<void>;
}

export const DimenstionInputs: FC<IDimenstionInputsProps> = ({
    inputSize = { xs: 12, md: 6 },
    categoriesByDimensionId,
    onChange,
    ...props
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { dimensions } = useContext(DimensionsContext);

    return (
        <>
            {dimensions.map((dimension, index) => (
                <Grid key={index} item {...inputSize}>
                    <CategoryAutocomplete
                        categories={dimension.categories}
                        label={dimension.name}
                        value={
                            categoriesByDimensionId?.[dimension.id]?.id ?? ''
                        }
                        onSelect={(category) => onChange(dimension, category)}
                        {...props}
                    />
                </Grid>
            ))}
        </>
    );
};
