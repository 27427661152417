import { IEntity } from 'core/interfaces/entity.interface';

export interface ICategoryDetails {
    dimensionId: string;
    level: number;
    name: string;
    path: string;
    parentId: string | null;
}

export interface ICategory extends IEntity, ICategoryDetails {
    calculatedPath?: string;
    children?: ICategory[];
}

export interface ICategoryCommand {
    dimension: number;
    name: string;
    path: string;
    parent: IEntity<number> | null;
}

export interface ICategoryResponse
    extends IEntity<number>,
        Omit<ICategoryCommand, 'parent'> {
    parent?: number;
    children?: Omit<ICategoryResponse, 'dimension'>[];
}

export const emptyCategory: ICategoryDetails = {
    dimensionId: '',
    level: 0,
    name: '',
    path: '',
    parentId: null,
};

export const calculateParentsPath = (
    name: string,
    calculatedPath?: string
): string => (calculatedPath ? calculatedPath + ' / ' : '') + name;

export const createCategoryFromResponse = (
    categoryResponse: ICategoryResponse,
    parentLevel = 0,
    calculatedPath = '',
    parentId: string | null = null
): ICategory => {
    const { id, children, dimension, parent, ...response } = categoryResponse;
    const level = parentLevel + 1;

    return {
        ...response,
        id: id.toString(),
        dimensionId: dimension.toString(),
        parentId: parent?.toString() ?? parentId,
        calculatedPath,
        level,
        children: !children
            ? []
            : children.map((child) =>
                  createCategoryFromResponse(
                      { dimension, ...child },
                      level,
                      calculateParentsPath(response.name, calculatedPath),
                      id.toString()
                  )
              ),
    };
};

export const createCategoryCommand = ({
    parentId,
    dimensionId,
    ...rest
}: ICategoryDetails): ICategoryCommand => {
    return {
        ...rest,
        parent: parentId ? { id: +parentId } : null,
        dimension: +dimensionId,
    };
};
