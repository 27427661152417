import {
    Box,
    Container,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ISurveyFeedbackProps } from 'core/interfaces/survey-answering.interface';
import { Form, useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { filterTranslatesBuilder } from './filterTranslatesBuilderHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    feedbackContainer: {
        maxWidth: '660px',
    },
    stepIntro: {
        padding: '48px 0',
        [theme.breakpoints.down('xs')]: {
            padding: '24px 0',
        },
    },
    stepHeading: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
            lineHeight: '24px',
        },
    },
    feedbackBox: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.spacing(0.5),
    },
    form: {
        width: '100%',
    },
}));

export const SurveyFeedback = ({
    surveyLabelTranslations,
}: ISurveyFeedbackProps) => {
    const { t } = useContext(MultiLanguageSupportContext);

    const { values, handleChange, setFieldValue } = useFormikContext<{
        [key: string]: string;
    }>();

    const classes = useStyles();

    const filterTranslates = filterTranslatesBuilder(surveyLabelTranslations);

    return (
        <Box className={classes.root}>
            <Form className={classes.form}>
                <Container>
                    <Box className={classes.stepIntro}>
                        <Typography
                            variant="h2"
                            align="center"
                            className={classes.stepHeading}
                        >
                            {filterTranslates(
                                'feedbackHeadline',
                                t('feedback-headline')
                            )}
                        </Typography>
                    </Box>

                    <Container className={classes.feedbackContainer}>
                        <Box
                            className={classes.feedbackBox}
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            p={2}
                            mb={2}
                        >
                            <TextField
                                variant="outlined"
                                placeholder={filterTranslates(
                                    'suggestionPlaceholder',
                                    t('suggestion-placeholder')
                                )}
                                multiline
                                rows={12}
                                fullWidth
                                name="text"
                                value={values.text}
                                onChange={handleChange}
                            ></TextField>
                            <Box display="flex" alignItems="center" mt={2}>
                                <Typography variant="body2">
                                    {filterTranslates(
                                        'privateFeedback',
                                        t('private-feedback')
                                    )}
                                </Typography>
                                <Switch
                                    color="primary"
                                    formNoValidate
                                    checked={!values.public}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'public',
                                            !e.target.checked
                                        );
                                    }}
                                    name="public"
                                    inputProps={{
                                        'aria-label': 'secondary checkbox',
                                    }}
                                />
                            </Box>
                        </Box>
                    </Container>
                </Container>
            </Form>
        </Box>
    );
};
