import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Typography,
} from '@material-ui/core';
import { EntityListHeaderTemplate } from 'core/components/shared/EntityListHeaderTemplate';
import React, { FC, useContext, useEffect } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { ListHeader } from '../../../core/components/shared/ListHeader';
import { ListItemContainer } from '../../../core/components/shared/ListItemContainer';
import { primaryOrderByProperty } from '../../../core/constants/default-order-properties';
import { useCurrentPageEntities } from '../../../core/hooks/current-page-entities.hook';
import { useCurrentPage } from '../../../core/hooks/current-page.hook';
import { IAccount } from '../models/account.model';
import { AccountDialogContext } from './AccountDialogProvider';
import { AccountsListActionsById } from './AccountListAction';
import { AccountsListItem } from './AccountsListItem';

export const AccountsList: FC = () => {
    const { currentPageEntities } = useCurrentPageEntities<IAccount>();
    const { t } = useContext(MultiLanguageSupportContext);
    const { open } = useContext(AccountDialogContext);
    const { setOrderBy } = useCurrentPage();

    useEffect(() => {
        setOrderBy(primaryOrderByProperty, 'desc');
    }, []);

    if (currentPageEntities.length === 0) {
        return (
            <>
                <Typography variant="h1" align="center">
                    Accounts list is empty
                </Typography>
                <Typography variant="body1" align="center">
                    Simpy click on the button below and create new Account
                </Typography>
                <Box display="flex" justifyContent={'center'}>
                    <Button
                        onClick={() => open({})}
                        disableElevation
                        variant="contained"
                        color="primary"
                        style={{
                            textTransform: 'capitalize',
                            margin: '10px 0',
                        }}
                    >
                        Create new Account
                        {/*TODO: Translate*/}
                    </Button>
                </Box>
            </>
        );
    } else {
        return (
            <>
                <TableContainer component={Paper} variant="outlined">
                    <Table>
                        <TableHead>
                            <ListHeader
                                renderLastColumn={() => (
                                    <span style={{ visibility: 'hidden' }}>
                                        {t('actions')}
                                    </span>
                                )}
                            >
                                <EntityListHeaderTemplate
                                    name={{
                                        label: t('name'),
                                    }}
                                    ownerName={{
                                        label: t('owner-name'),
                                    }}
                                    email={{
                                        label: t('email'),
                                    }}
                                    subscription={{
                                        label: t('subscription'),
                                    }}
                                />
                            </ListHeader>
                        </TableHead>
                        <TableBody>
                            {currentPageEntities.map((entity, index) => (
                                <ListItemContainer
                                    key={entity.id}
                                    entityId={entity.id}
                                    enableHover={true}
                                    renderLastColumn={(entityId: string) => (
                                        <AccountsListActionsById
                                            id={entityId}
                                        />
                                    )}
                                >
                                    <AccountsListItem
                                        entity={entity}
                                        index={index}
                                    />
                                </ListItemContainer>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }
};
