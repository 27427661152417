import { ApexOptions } from 'apexcharts';
import { GraphBarColor } from '../constants/GraphBarColor';

export const getPulseResultsAvgOptions = (
    partial?: Partial<ApexOptions>
): ApexOptions => {
    return {
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'center',
            fontSize: '16px',
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        chart: {
            stacked: true,
        },
        xaxis: {
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        yaxis: {
            min: 0,
            max: 5,
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        colors: [
            GraphBarColor.GREEN,
            GraphBarColor.GREEN_LIGHT,
            GraphBarColor.YELLOW,
            GraphBarColor.RED_LIGHT,
            GraphBarColor.RED,
        ],
        fill: {
            colors: [
                GraphBarColor.GREEN,
                GraphBarColor.GREEN_LIGHT,
                GraphBarColor.YELLOW,
                GraphBarColor.RED_LIGHT,
                GraphBarColor.RED,
            ],
        },
        ...partial,
    };
};
