import { Box, Button, Container, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { ImportParticipants } from 'core/components/ImportParticipants';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { EntityInfiniteScroll } from 'core/components/shared/InfiniteScroll';
import { SearchInput } from 'core/components/shared/SearchInput';
import { defaultOrderByProperty } from 'core/constants/default-order-properties';
import { WithCurrentPageListener } from 'core/hocs/WithListContainer';
import { WithProvider } from 'core/hocs/WithProvider';
import { useCurrentPageEntities } from 'core/hooks/current-page-entities.hook';
import { useCurrentPage } from 'core/hooks/current-page.hook';
import React, { FC, useContext, useEffect, useState } from 'react';
import { PulseDialogContext } from '../../pulse/components/PulseDialogProvider';
import { IParticipant } from '../models/participant.model';
import { participantsQuery } from '../state/participants.query';
import { ParticipantFormDialog } from './ParticipantFormDialog';
import {
    IParticipantFormProviderProps,
    ParticipantFormContext,
    ParticipantFormProvider,
} from './ParticipantFormProvider';
import { SelectParticipantsList } from './SelectParticipantsList';
import { IPulse } from '../../pulse/models/pulse.model';
import { apiService } from 'core/services/apiService';

const useStyles = makeStyles((theme: Theme) => ({
    searchfield: {
        marginRight: theme.spacing(4),
        width: '240px',
        [theme.breakpoints.up('lg')]: {
            width: '440px',
        },
        '& input': {
            padding: `14.5px ${theme.spacing(2)}px 14.5px 0`,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
    },
    participantsFilter: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
        },
    },
    buttons: {
        display: 'flex',
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    importParticipantsMessage: {
        display: 'block',
        padding: '16px',
        margin: '24px 0',
        backgroundColor: '#E2FFE4',
        borderRadius: '4px 4px 0 0',
    },
    importParticipantsMessageContent: {
        fontFamily: 'Nunito Sans',
        fontSize: '16px',
        fontWeight: 400,
        color: '#388E3C',
        margin: 0,
    },
}));

const SelectParticipantsContainer: FC = () => {
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);

    const { allPagesEntities, store } = useCurrentPageEntities<IParticipant>();
    const { open } = useContext(ParticipantFormContext);
    const { clearTotal, setSearchTerm, setOrderBy } = useCurrentPage();
    const {
        pulseId,
        close: closeSelectParticipantsModal,
        open: openSelectParticipantsModal,
    } = useContext(PulseDialogContext);
    const [pulse, setPulse] = useState<IPulse>();

    const [showImportParticipants, setShowImportParticipants] = useState(false);
    const [importParticipantsMessage, setImportParticipantsMessage] = useState(
        ''
    );
    const toggleImportParticipants = () => {
        setShowImportParticipants(
            (showImportParticipants) => !showImportParticipants
        );
    };

    const importResponseSuccessHandler = async (message: any) => {
        setImportParticipantsMessage(message);
        if (message === '') {
            return;
        }

        toggleImportParticipants();
        await store.fetchEntities();
        await store.patchUIState({
            pageNumber: 1,
        });
        closeSelectParticipantsModal();
        if (pulse) {
            openSelectParticipantsModal(pulse, 1);
        }
    };

    useEffect(() => {
        pulseId &&
            apiService.get(`/pulses/${pulseId}`).then(({ data }) => {
                setPulse(data);
            });
    }, [pulseId]);

    useEffect(() => {
        pulseId &&
            apiService.get(`/pulses/${pulseId}`).then(({ data }) => {
                setPulse(data);
            });
    }, [pulseId]);

    useEffect(() => {
        setOrderBy(`pulseId-${pulseId}`, 'desc' || defaultOrderByProperty);

        return () => {
            clearTotal();
        };
    }, []);

    return (
        <>
            <Box marginY={5}>
                <Typography variant="h1" align="center">
                    {t('select-participants')}
                </Typography>
            </Box>
            <Container maxWidth="lg">
                {importParticipantsMessage !== '' ? (
                    <Box className={classes.importParticipantsMessage}>
                        <p className={classes.importParticipantsMessageContent}>
                            {importParticipantsMessage}
                        </p>
                    </Box>
                ) : (
                    ''
                )}
                <Box width={'100%'}>
                    <Box className={classes.participantsFilter}>
                        <SearchInput
                            initialValue={
                                participantsQuery.getValue().ui.searchTerm
                            }
                            variant="outlined"
                            className={classes.searchfield}
                            label={t('search-participants')}
                            onChange={setSearchTerm}
                        />
                        <div className={classes.buttons}>
                            <Button
                                variant="text"
                                disableElevation
                                color="primary"
                                style={
                                    showImportParticipants
                                        ? { backgroundColor: '#CDBAF3' }
                                        : undefined
                                }
                                endIcon={
                                    showImportParticipants ? (
                                        <KeyboardArrowUp />
                                    ) : (
                                        <KeyboardArrowDown />
                                    )
                                }
                                onClick={toggleImportParticipants}
                            >
                                {t('import-participants')}
                            </Button>
                            {/* // TODO: KLIS Create New Participant */}
                            <Button
                                variant="contained"
                                disableElevation
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() => open({})}
                            >
                                {t('create-new-participant')}
                            </Button>
                        </div>
                    </Box>
                    {showImportParticipants && (
                        <ImportParticipants
                            handleSuccess={importResponseSuccessHandler}
                        />
                    )}
                    <EntityInfiniteScroll>
                        <SelectParticipantsList
                            entities={allPagesEntities}
                            showFirstColumn
                        />
                    </EntityInfiniteScroll>
                </Box>
            </Container>

            <ParticipantFormDialog />
        </>
    );
};

export const SelectParticipants = WithProvider(
    WithCurrentPageListener(
        (props: IParticipantFormProviderProps) => (
            <ParticipantFormProvider {...props}>
                <SelectParticipantsContainer />
            </ParticipantFormProvider>
        ),
        0
    ),
    participantsQuery
) as FC<IParticipantFormProviderProps>;
