import { useObservable } from '@mindspace-io/react';
import { useContext, useEffect } from 'react';
import { map } from 'rxjs/operators';
import { EntitiesContext } from '../constants/entities.context';
import { IEntity } from '../interfaces/entity.interface';
import { fetchById } from './crud.hook';

export interface IUseEntityByIdConfig<T> {
    id: string;
    forceFetch?: boolean;
    successCallback?: (entity: T) => any;
    failureCallback?: (id: string, e: any) => any;
}

export const useEntityById = <T extends IEntity>({
    id,
    forceFetch,
    successCallback,
    failureCallback,
}: IUseEntityByIdConfig<T>): [T | null, (entity: T) => Promise<T>] => {
    const { query, store } = useContext(EntitiesContext);
    const [entity] = useObservable<T | null>(
        query.selectEntity(id).pipe(map((x) => (x as T) ?? null)),
        (query.getEntity(id) as T) ?? null
    );

    useEffect(() => {
        if (!entity || forceFetch) {
            fetchById(
                store,
                store.crudApiService
            )(id)
                .then((result) => {
                    if (successCallback) {
                        successCallback(result as T);
                    }
                })
                .catch((e) => {
                    if (failureCallback) {
                        failureCallback(id, e);
                    }
                });
        }
    }, [forceFetch]);

    return [entity, (store.updateEntity as never) as (entity: T) => Promise<T>];
};
