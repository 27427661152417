import { StoreConfig } from '@datorama/akita';
import { createParticipantFromResponse } from 'modules/participants/models/participant.model';
import { participantsStore } from 'modules/participants/state/participants.store';
import { EntitiesStore } from '../../../core/state/entities.store';
import {
    createPulseParticipantFromResponse,
    IPulseParticipant,
    IPulseParticipantResponse,
} from '../models/pulse-participant.model';

@StoreConfig({
    name: 'pulse-participants',
    resettable: true,
})
class PulseParticipantsStore extends EntitiesStore<
    IPulseParticipant,
    IPulseParticipantResponse
> {}

export const pulseParticipantsStore = new PulseParticipantsStore(
    {
        responseMapper: (response) => {
            participantsStore.upsertMany([
                createParticipantFromResponse(response.respondent),
            ]);
            return createPulseParticipantFromResponse(response);
        },
    },
    'respondents'
);
