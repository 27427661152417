import PulseButtons from 'core/components/mock/buttons/Buttons';
import PulseSelect from 'core/components/mock/forms/PulseSelect';
import PulseTextarea from 'core/components/mock/forms/PulseTextArea';
import PulseTextfield from 'core/components/mock/forms/PulseTextfield';
import PulseEmptyState from 'core/components/mock/screens/PulseEmptyState';
import { StepperComponent } from 'core/components/shared/Stepper';
import ProjectCard from 'core/components/mock/cards/ProjectCard';
import { AccountLocked } from 'core/components/mock/screens/PulseAutoLock';
import PulseCard from 'core/components/mock/cards/PulseCard';
import { PulseSingle } from 'core/components/mock/screens/PulseSingle';
import StarRating from 'core/components/mock/pulse-survey/starRating';
import SuccessFactors from 'core/components/mock/pulse-survey/successFactors';
import SurveyFeedback from 'core/components/mock/pulse-survey/surveyFeedback';
import SurveyOverview from 'core/components/mock/pulse-survey/surveyOverview';
import { HelpFeedbackModalContentFC } from 'core/components/mock/screens/helpFeedbackModalContent';
import CategoriesTreeView from 'core/components/mock/accordions/categoriesTree';
import { Container } from '@material-ui/core';
import React, { FC } from 'react';

export const ToBeDefined: FC = () => {
    return (
        <div>
            {/* <h3>Stepper:</h3>
            <StepperComponent
                activeStep={1}
                steps={[
                    { title: 'Step 1', description: 'Info Text' },
                    { title: 'Step 2', description: 'Another Info Text' },
                ]}
            />
            <h3>Empty state screen mock:</h3>
            <PulseEmptyState />
            <h3>Textfields variants:</h3>
            <PulseTextfield />
            <h3>Select Variants:</h3>
            <PulseSelect />
            <h3>Textarea example</h3>
            <PulseTextarea />
            <h3>Poject Card:</h3>
            <ProjectCard />
            <h3>Account locked mock:</h3>
            <p>uncomment in: src/core/pages/ToBeDefined.tsx</p> */}
            {/* <AccountLocked /> */}
            {/* <h3>Pulse Card:</h3>
            <PulseCard /> */}
            {/* <PulseSingle /> */}

            {/* <h3>Buttons</h3>
            <PulseButtons /> */}

            {/* <h3>help and feedback (use as dialog content):</h3>
            <HelpFeedbackModalContent /> */}

            <h3>Categories Tree view:</h3>
            <Container>
                <CategoriesTreeView />
            </Container>

            {/* pulse survey: */}
            {/* <StarRating /> */}
            {/* <SuccessFactors /> */}
            {/* <SurveyFeedback /> */}
            {/* <SurveyOverview /> */}
        </div>
    );
};
