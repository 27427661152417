import { Box } from '@material-ui/core';
import { useSession } from 'core/hooks/session.hook';
import { ActivityDialogProvider } from 'modules/activity/components/ActivityDialogProvider';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { UserFormProvider } from 'modules/users/components/UserFormProvider';
import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { navigationItems } from '../../modules/navigation/constants/navigation-items';
import { darkTheme, lightTheme, ThemeType } from '../constants/themes';
import { ActionStatuses } from './ActionStatusSnackbar';
import { GeneralDialogContainer } from './shared/GeneralDialog/GeneralDialogContainer';
import { PulseAppBar } from './shared/topBars/AppBar';
import { ThemeSetterContext } from './ThemeSetterProvider';

interface GlobalStylesProps {
    theme: ThemeType;
}

const privateContentRoutes = navigationItems.filter(
    ({ contentComponent }) => contentComponent
);

const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: Tahoma, Helvetica, Arial, Roboto, sans-serif;
    transition: all 0.50s linear;
  }
  `;

const isExact = (path: string) => {
    switch (path) {
        case ROUTES.USER:
            return false;
        default:
            return true;
    }
};

export const Layout = () => {
    const [theme] = useContext(ThemeSetterContext);
    const { abilities } = useSession();

    return (
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <>
                <GlobalStyles />
                <Box style={{ width: '100%' }}>
                    <ActivityDialogProvider>
                        <UserFormProvider>
                            <PulseAppBar />
                        </UserFormProvider>
                    </ActivityDialogProvider>
                    <Switch>
                        <Redirect
                            exact
                            from={ROUTES.DEFAULT}
                            to={ROUTES.PROJECTS}
                        />
                        {privateContentRoutes
                            .filter(({ path }) =>
                                abilities.can('navigateTo', path)
                            )
                            .map(({ path, contentComponent }, index) => (
                                <Route
                                    key={index}
                                    exact={isExact(path)}
                                    path={path}
                                    component={contentComponent}
                                />
                            ))}
                    </Switch>
                </Box>
                <GeneralDialogContainer />
                <ActionStatuses />
            </>
        </ThemeProvider>
    );
};
