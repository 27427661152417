import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ToBeDefined } from 'core/pages/ToBeDefined';
import { FeedbackLoopPage } from 'modules/feedback-loop/pages/FeedbackLoopPage';
import { PrivateRoute } from 'modules/login/components/privateRoute/PrivateRoute';
import { LoginPage } from 'modules/login/pages/LoginPage';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import { SurveyPage } from 'modules/pulse-answering/pages/SurveyPage';
import { ResetPasswordPage } from 'modules/resetpassword/pages/ResetPasswordPage';
import React from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import './App.scss';
import { Layout } from './core/components/Layout';
import { DataProtectionPolicy } from './modules/help-feedback/pages/DataProtectionPolicy';
import { SetupAccount } from './modules/login/pages/SetupAccount';
import { RequestResetPasswordPage } from './modules/resetpassword/pages/RequestPassowrdPage';
import { SuccessRequestPasswordPage } from './modules/resetpassword/pages/SuccesRequestPasswordPage';
import { SuccessNewPasswordPage } from './modules/resetpassword/pages/SuccessNewPasswordPage';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://a2b412e943d4498e915253cf978c24c4@sentry.q-tests.com/30',
        release: 'pulse@' + process.env.npm_package_version,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(
                    history
                ),
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

const AppContainer = () => (
    <Router>
        <Switch>
            <Route exact path={ROUTES.LOGIN} component={LoginPage} />
            <Route
                exact
                path={ROUTES.RESET_PASSWORD}
                component={RequestResetPasswordPage}
            />
            <Route
                exact
                path={ROUTES.SUCCESS_PASSWORD}
                component={SuccessNewPasswordPage}
            />
            <Route
                exact
                path={ROUTES.SUCCESS_REQUEST_PASSWORD}
                component={SuccessRequestPasswordPage}
            />
            <Route
                exact
                path={ROUTES.RESET_PASSWORD_HASH}
                component={ResetPasswordPage}
            />
            <Route
                exact
                path={ROUTES.DATA_PROTECTION_POLICY}
                component={DataProtectionPolicy}
            />
            <Route path={ROUTES.ACTIVATE_ACCOUNT} component={SetupAccount} />
            <Route path={ROUTES.SURVEY} component={SurveyPage} />
            <Route
                path={ROUTES.OLD_SURVEY}
                render={(props) => (
                    <Redirect
                        to={pathBuilder(
                            ROUTES.SURVEY,
                            ':id',
                            props.match.params.id
                        )}
                    />
                )}
            />
            <Redirect from={`/${ROUTES.OLD_SURVEY}`} to={ROUTES.OLD_SURVEY} />
            <Route path={ROUTES.FEEDBACK} component={FeedbackLoopPage} />
            <Route exact path={ROUTES.TBD} component={ToBeDefined} />
            <PrivateRoute path="" component={Layout} />
        </Switch>
    </Router>
);

export const App =
    process.env.NODE_ENV !== 'production'
        ? AppContainer
        : Sentry.withProfiler(AppContainer);
