import {
    Box,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Theme,
} from '@material-ui/core';
import { maxLimit } from 'core/constants/maxLimit';
import { pageSizeOptions } from 'core/constants/page-size-options';
import { useCurrentPageEntities } from 'core/hooks/current-page-entities.hook';
import { IScaleQuestion } from 'modules/scale-questions/models/scale-question.model';
import { scaleQuestionsQuery } from 'modules/scale-questions/state/scale-questions.query';
import React, { FC, useContext, useEffect } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { ListHeader } from '../../../core/components/shared/ListHeader';
import { ListItemContainer } from '../../../core/components/shared/ListItemContainer';
import { WithCurrentPageListener } from '../../../core/hocs/WithListContainer';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { useCrud } from '../../../core/hooks/crud.hook';
import {
    IUseCurrentPage,
    useCurrentPage,
} from '../../../core/hooks/current-page.hook';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    projectCardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
        columnGap: 24,
        rowGap: 24,
    },
    filters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableBackground: {
        backgroundColor: '#fff',
    },
    searchfield: {
        marginRight: theme.spacing(4),
        width: '240px',
        [theme.breakpoints.up('lg')]: {
            width: '440px',
        },
        '& input': {
            padding: `14.5px ${theme.spacing(2)}px 14.5px 0`,
        },
    },
}));

interface IQuestionsListProps {
    currentPageEntities: IScaleQuestion[];
    pulseId?: string;
    listSlugSuffix?: string;
    renderToolbar?: (props: IUseCurrentPage<IScaleQuestion>) => JSX.Element;
    HeaderListItem: () => JSX.Element;
    ListItem: (entity: IScaleQuestion, index: number) => JSX.Element;
    renderItemNavigation?: (entity: IScaleQuestion) => JSX.Element;
    renderLastColumn?: (entity: IScaleQuestion) => JSX.Element;
    renderPagination?: () => JSX.Element;
}

export const QuestionsListContainer: FC<IQuestionsListProps> = ({
    currentPageEntities,
    pulseId,
    listSlugSuffix: suffix,
    renderToolbar,
    renderPagination,
    HeaderListItem,
    ListItem,
    renderItemNavigation,
    renderLastColumn,
}: IQuestionsListProps) => {
    const { t } = useContext(MultiLanguageSupportContext);
    // const { currentPageEntities } = useCurrentPageEntities<IScaleQuestion>();
    const classes = useStyles();
    const { setListSlug } = useCrud();
    const currentPage = useCurrentPage();

    useEffect(() => {
        currentPage.clearCurrentPageEntities();
        setListSlug(
            !pulseId ? { suffix } : { suffix, prefix: `/pulses/${pulseId}` }
        );
        currentPage.setPageSize(pulseId ? maxLimit : pageSizeOptions[0]);
        currentPage.setOrderBy(
            pulseId ? 'position' : '',
            pulseId ? 'asc' : null
        );
    }, []);

    return (
        <Box width={'100%'}>
            {renderToolbar && renderToolbar(currentPage)}
            <Box mt={3}>
                <TableContainer
                    component={Paper}
                    variant="outlined"
                    className={classes.tableBackground}
                >
                    <Table>
                        <TableHead>
                            <ListHeader
                                renderLastColumn={
                                    !renderLastColumn
                                        ? undefined
                                        : () => (
                                              <span
                                                  style={{
                                                      visibility: 'hidden',
                                                  }}
                                              >
                                                  {t('actions')}
                                              </span>
                                          )
                                }
                            >
                                <HeaderListItem />
                            </ListHeader>
                        </TableHead>
                        <TableBody>
                            {currentPageEntities
                                .filter(({ deletedAt }) => !deletedAt)
                                .map((entity, index) => (
                                    <ListItemContainer
                                        key={entity.id}
                                        entityId={entity.id}
                                        enableHover={true}
                                        renderItemNavigation={
                                            !renderItemNavigation
                                                ? undefined
                                                : () =>
                                                      renderItemNavigation(
                                                          entity
                                                      )
                                        }
                                        renderLastColumn={
                                            !renderLastColumn
                                                ? undefined
                                                : () => renderLastColumn(entity)
                                        }
                                    >
                                        {ListItem(entity, index)}
                                    </ListItemContainer>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {renderPagination && <Box mt={2}>{renderPagination()}</Box>}
            </Box>
        </Box>
    );
};

export const QuestionsList = WithProvider(
    WithCurrentPageListener(QuestionsListContainer),
    scaleQuestionsQuery
) as FC<IQuestionsListProps>;
