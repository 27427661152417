import * as Yup from 'yup';

export const passwordValidation = Yup.string()
    .required('Please Enter your password')
    .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_^])[A-Za-z\d@$!%*#?&_^]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character (@$!%*#?&_^)'
    );
export const usernameValidation = Yup.string().required().min(3);
export const emailValidation = Yup.string().email().required();
export const requiredField = Yup.string().required();
export const hexColor = Yup.string().matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i);
