// import * as {Schema}from 'yup';
import { AnySchema } from 'yup';
import mapYupErrorsToFormikErrors from './mapYupErrorsToFormikErrors';

const validateSchema = <T>(values: T, schema: AnySchema) => {
    return schema
        .validate(values, {
            abortEarly: false,
            strict: false,
        })
        .then(() => {
            return {};
        })
        .catch(mapYupErrorsToFormikErrors);
};

export default validateSchema;
