import { StoreConfig } from '@datorama/akita';
import {
    EntitiesState,
    EntitiesStore,
} from '../../../core/state/entities.store';
import {
    createScaleQuestionCommand,
    createScaleQuestionFromResponse,
    IScaleQuestion,
    IScaleQuestionResponse,
} from '../models/scale-question.model';

export type ScaleQuestionsState = EntitiesState<IScaleQuestion>;

@StoreConfig({
    name: 'scale-questions',
    resettable: true,
})
class ScaleQuestionsStore extends EntitiesStore<
    IScaleQuestion,
    IScaleQuestionResponse
> {}

export const scaleQuestionsStore = new ScaleQuestionsStore({
    responseMapper: createScaleQuestionFromResponse,
    commandMapper: createScaleQuestionCommand,
    searchTermParamKeys: ['phrase'],
    crudIdKey: 'questionId',
});
