import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    closeButton: {
        minWidth: 0,
        padding: theme.spacing(1),
        borderRadius: '50%',
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    modalSubmit: {
        marginLeft: '10px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0',
            marginTop: '15px',
        },
    },
}));

export interface IAlertModalProps {
    title: string;
    contentText: JSX.Element | string;
    handleClose: () => void;
    handleSubmit?: () => void;
    closeModalText: string;
    submitModalText?: string;
    isActionDestructive?: boolean;
}

export const AlertModal: FC<IAlertModalProps> = ({
    title,
    contentText,
    handleClose,
    handleSubmit,
    closeModalText,
    submitModalText,
    isActionDestructive = false,
}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box flexGrow="5">
                    <Typography variant="h5">{title}</Typography>
                </Box>
                <Box>
                    <Button variant="text" className={classes.closeButton}>
                        <CloseIcon color="disabled" onClick={handleClose} />
                    </Button>
                </Box>
            </Box>
            <Box mt={5}>
                <Typography variant="body1">{contentText}</Typography>
            </Box>
            <Box className={classes.modalActions} mt={11}>
                <Button
                    onClick={handleClose}
                    disableElevation
                    variant="contained"
                    color="secondary"
                >
                    {closeModalText}
                </Button>
                {submitModalText && handleSubmit && (
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        className={`${
                            isActionDestructive ? 'MuiButton-destructive' : ''
                        }
                        ${classes.modalSubmit}`}
                        disableElevation
                    >
                        {submitModalText}
                    </Button>
                )}
            </Box>
        </Box>
    );
};
