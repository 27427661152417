import {
    Box,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
} from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { asDateString } from 'core/helpers/asDateString';
import { useSession } from 'core/hooks/session.hook';
import { useAffiliationTranslation } from 'modules/participants/hooks';
import React, { FC, useContext, useEffect, useState } from 'react';
import { ListHeader } from '../../../core/components/shared/ListHeader';
import { ListHeaderCell } from '../../../core/components/shared/ListHeaderCell';
import { IPulseAnswersData } from '../pages/ProjectDashboardPage';
import MessageIcon from '@material-ui/icons/QuestionAnswer';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { useDialog } from 'modules/projects/components/use-dialog.hook';
import { IFeedbackResult } from 'modules/pulse-answering/models/feedback-loop-models';
import { FeedbackLoopPageAdmin } from 'modules/feedback-loop/pages/FeedbackLoopPageAdmin';

export interface TotalsTableProps {
    headerCell: string[];
    rows: IPulseAnswersData[];
    action?: (id: string, feedbackId: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    projectCardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
        columnGap: 24,
        rowGap: 24,
    },
    filters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableBackground: {
        backgroundColor: '#fff',
    },
}));

export const TotalsTableDashboard: FC<TotalsTableProps> = ({
    rows,
    headerCell,
    action,
}) => {
    const classes = useStyles();

    rows.sort(({ date: dateA }, { date: dateB }) => {
        const a = dateA?.getTime() ?? 0;
        const b = dateB?.getTime() ?? 0;

        return a < b ? 1 : a > b ? -1 : 0;
    });

    return (
        <>
            <TableContainer
                component={Paper}
                variant="outlined"
                className={classes.tableBackground}
            >
                <Table>
                    <TableHead>
                        <ListHeader>
                            {headerCell.map((item, index) => (
                                <ListHeaderCell key={index} label={item} />
                            ))}
                        </ListHeader>
                    </TableHead>
                    <TableBody>
                        {rows.map((rowProps, index) => (
                            <TotalsTableDashboardRow
                                {...rowProps}
                                action={action}
                                key={index}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

const TotalsTableDashboardRow: FC<IPulseAnswersData> = ({
    pulse,
    date,
    role,
    organization,
    averages,
    great,
    improvable,
    feedback,
    action,
    pulseInstanceId,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { me } = useSession();

    const { getAffiliationTranslation } = useAffiliationTranslation(
        me?.organizationName ?? ''
    );

    return (
        <TableRow>
            <TableCell>{pulse}</TableCell>
            <TableCell>
                {!date
                    ? '-'
                    : asDateString(date, {
                          format: 'date',
                      })}
            </TableCell>
            <TableCell>{t(role)}</TableCell>
            <TableCell>{getAffiliationTranslation(organization)}</TableCell>
            {averages.map((avg, index) => {
                return <TableCell key={index}>{avg || '-'}</TableCell>;
            })}

            <TableCell>{great.join(', ') || '-'}</TableCell>
            <TableCell>{improvable.join(', ') || '-'}</TableCell>
            <TableCell>
                {!feedback.public && feedback.message && <VerifiedUserIcon />}
                <Box display="flex" justifyContent={'space-between'}>
                    <Tooltip
                        title={
                            <span style={{ fontSize: '14px' }}>
                                {feedback.message}
                            </span>
                        }
                    >
                        <span
                            style={{
                                maxWidth: '150px',
                                overflow: 'hidden',
                                display: 'inline-block',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                marginTop: '15px',
                            }}
                        >
                            {feedback.message}
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={
                            <span style={{ fontSize: '14px' }}>
                                Reply to the participant
                            </span>
                        }
                    >
                        <IconButton
                            onClick={() =>
                                action &&
                                pulseInstanceId &&
                                action(pulseInstanceId, feedback.id)
                            }
                        >
                            <MessageIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </TableCell>
        </TableRow>
    );
};
