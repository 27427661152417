import {
    IPulseDistributionGraphDataResponse,
    PulseDistributionGraphRanges,
} from '../models/dashboard.models';

interface IUsePortfolioProjectsData {
    pulseDistributionMap: Map<
        string,
        Record<PulseDistributionGraphRanges, number[]>
    >;
    pulseQuestions: string[];
}

const usePulseDistributionData = (
    arrayOfData: Record<
        string,
        Record<
            PulseDistributionGraphRanges,
            IPulseDistributionGraphDataResponse
        >
    >[]
): IUsePortfolioProjectsData => {
    const pulseDistributionMap = arrayOfData.reduce(
        (map, pulse_distribution_graph_data) => {
            Object.entries(pulse_distribution_graph_data).forEach(
                ([questionName, distributionGraphValue]) => {
                    const newValue: Record<
                        PulseDistributionGraphRanges,
                        number[]
                    > = map.get(questionName) ?? {
                        '4,5-5': [],
                        '3,5-4': [],
                        '2,5-3': [],
                        '1,5-2': [],
                        '0-1': [],
                    };

                    Object.entries(distributionGraphValue).forEach(
                        ([key, { count, percentage, ...rest }]) => {
                            Object.values(rest)
                                ?.filter((restValue) => restValue !== 0)
                                ?.forEach((restValue) =>
                                    newValue[
                                        key as PulseDistributionGraphRanges
                                    ].push(restValue)
                                );
                        }
                    );

                    map?.set(questionName, newValue);
                }
            );

            return map;
        },
        new Map<string, Record<PulseDistributionGraphRanges, number[]>>()
    );

    const pulseQuestions = Array.from(pulseDistributionMap.entries())
        .filter(([key, value]) =>
            Object.values(value).reduce(
                (flag, rangeValues) => flag || rangeValues.length > 0,
                false as boolean
            )
        )
        .map(([key]) => key);

    return { pulseDistributionMap, pulseQuestions };
};

export default usePulseDistributionData;
