import { Box, Button, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';

interface FormDialogActionsProps {
    handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    cursorPointer: {
        cursor: 'pointer',
    },
    buttonWrap: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            marginTop: `${theme.spacing(2)}px`,
        },
        '& button': {
            '&:last-child': {
                marginLeft: theme.spacing(2),

                [theme.breakpoints.down('xs')]: {
                    marginLeft: 0,
                    marginBottom: 0,
                },
            },

            [theme.breakpoints.down('xs')]: {
                width: '100%',
                marginLeft: '0',
                marginBottom: theme.spacing(2),
            },
        },
    },
}));

export const FormDialogActions: FC<FormDialogActionsProps> = ({
    handleClose,
    children,
}) => {
    const classes = useStyles();
    return (
        <>
            <Box>
                <Button
                    variant="text"
                    onClick={() => handleClose && handleClose()}
                >
                    Cancel
                </Button>
            </Box>
            <Box className={classes.buttonWrap}>{children}</Box>
        </>
    );
};
