import { Box, Button } from '@material-ui/core';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import React, { useContext } from 'react';
import { EntityListPagination } from '../../../core/components/EntityListPagination';
import { WithCurrentPageListener } from '../../../core/hocs/WithListContainer';
import { WithProvider } from '../../../core/hocs/WithProvider';
import {
    AccountDialogContext,
    AccountDialogProvider,
} from '../components/AccountDialogProvider';
import { AccountForm } from '../components/AccountForm';
import { AccountsList } from '../components/AccountsList';
import { accountsQuery } from '../state/accounts.query';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    addBtn: {
        textTransform: 'capitalize',
        margin: '10px 0',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

const AccountsPageContainer = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { isOpen, open, close, accountId } = useContext(AccountDialogContext);
    const classes = useStyles();

    return (
        <>
            <Box display="flex" justifyContent={'flex-end'}>
                <Button
                    onClick={() => open({})}
                    disableElevation
                    variant="contained"
                    color="primary"
                    className={classes.addBtn}
                >
                    Create new Account
                    {/*TODO: Translate*/}
                </Button>
            </Box>

            <FormDialogWrapper
                isOpen={isOpen}
                handleClose={close}
                title={!accountId ? t('create-new-account') : t('edit-account')}
            >
                <AccountForm />
            </FormDialogWrapper>
            <AccountsList />
            <EntityListPagination />
        </>
    );
};

export const AccountsPage = WithProvider(
    WithCurrentPageListener(() => (
        <AccountDialogProvider>
            <AccountsPageContainer />
        </AccountDialogProvider>
    )),
    accountsQuery
);
