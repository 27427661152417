import { FormikProvider, useFormik } from 'formik';
import React, {
    createContext,
    FC,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import * as Yup from 'yup';

import { tomorrow } from 'core/helpers/tomorrow';
import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { useCurrentPage } from 'core/hooks/current-page.hook';
import { apiService } from 'core/services/apiService';
import { requiredField } from 'core/validations';
import { IEmailResponse } from 'modules/customMessage/components/EmailDialogContent';
import { useLanguages } from 'modules/languages/hooks/languages.hook';
import { FrequencyValue } from 'modules/pulse-schedules/enums/frequency-value';
import { getEmptyPulseSchedule } from 'modules/pulse-schedules/models/pulse-schedule.model';
import { validateSchema } from 'shared/yup/utils';

import { emptyPulse, IPulse, IPulseDetails } from '../models/pulse.model';
import { pulsesQuery } from '../state/pulse.query';

export interface IPulseFormik extends IPulseDetails {
    enableSchedule: boolean;
    allowResponseAfterExpiration: boolean;
}

interface IPulseDialogContext {
    pulseId: string | null;
    isOpen: boolean;
    stepIndex: number;
    setStepIndex: (stepNumber: number) => void;
    close: () => void;
    open: (pulse: Partial<IPulse>, stepIndex?: number) => void;
    emailMessage: string;
    setEmailMessage: (message: string) => void;
    defaultEmailMessage: string;
    shouldRefetch: boolean;
    setShouldRefetch: (refetch: boolean) => void;
}

export const PulseDialogContext = createContext<IPulseDialogContext>(
    {} as IPulseDialogContext
);

const validationSchema = Yup.object().shape({
    name: requiredField,
    languageId: requiredField,
    daysToRespond: requiredField,
    reminder: requiredField,
    startDate: Yup.date()
        .when('enableSchedule', {
            is: true,
            then: Yup.date().required(),
        })
        .min(tomorrow().toISOString().substring(0, 10)),
    frequency: Yup.string().when('enableSchedule', {
        is: true,
        then: Yup.string().required(),
    }),
});

export const PulseDialogProvider: FC = WithProvider(({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [pulseId, setPulseId] = useState<string | null>(null);
    const [stepIndex, setStepIndex] = useState(0);
    const [emailMessage, setEmailMessage] = useState('');
    const [defaultEmailMessage, setDefaultEmailMessage] = useState('');

    const [languages] = useLanguages();

    const defaultInitialValues = useMemo(() => {
        const temp: IPulseFormik = {
            ...emptyPulse,
            scheduleId: '',
            enableSchedule: false,
            allowResponseAfterExpiration: false,
        };

        const defaultLanguage =
            languages.find(({ locale }) =>
                locale.toLowerCase().startsWith('en')
            ) ?? languages[0];

        if (defaultLanguage) {
            temp.languageId = defaultLanguage.id;
        }

        return temp;
    }, [languages]);

    const [initialValues, setInitialValues] = useState<IPulseFormik>({
        ...defaultInitialValues,
        ...getEmptyPulseSchedule(),
    });

    const close = () => {
        setIsOpen(false);
        setPulseId(null);
    };
    const [shouldRefetch, setShouldRefetch] = useState(false);

    useEffect(() => {
        pulseId &&
            apiService
                .get<IEmailResponse>(`email-translations/${pulseId}`)
                .then(({ data }) => {
                    setDefaultEmailMessage(data.defaultMessage);
                    setShouldRefetch(false);

                    if (data.customMessage.length > 1) {
                        setEmailMessage(data.customMessage);
                    } else setEmailMessage(data.defaultMessage);
                });
    }, [pulseId]);

    const open = (
        { id, startDate, ...details }: Partial<IPulse>,
        newStepIndex = 0
    ) => {
        setIsOpen(true);
        setTimeout(() => setPulseId(id ?? null));
        const tempInitialValues = {
            ...defaultInitialValues,
            ...getEmptyPulseSchedule(),
            ...details,
            enableSchedule: !!startDate,
        };

        if (startDate) {
            tempInitialValues.startDate = startDate;
        }

        setInitialValues(tempInitialValues);
        setStepIndex(newStepIndex);
    };

    const { createSingle, updateSingle } = useCrud<IPulse>();

    const { setPageNumber } = useCurrentPage();

    const onSubmit = useCallback(
        async ({ enableSchedule, ...data }: IPulseFormik) => {
            if (!enableSchedule) {
                data.startDate = null;
                data.frequency = FrequencyValue.NONE;
            }

            const pulse = pulseId
                ? await updateSingle({ id: pulseId, ...data })
                : await createSingle(data);

            setPageNumber(1, true);

            if (pulse) {
                const { id, startDate, ...details } = pulse;
                const tempInitialValues = {
                    ...defaultInitialValues,
                    ...getEmptyPulseSchedule(),
                    ...details,
                    enableSchedule: !!startDate,
                    allowResponseAfterExpiration: details.allowResponseAfterExpiration || false,
                };

                if (startDate) {
                    tempInitialValues.startDate = startDate;
                }

                setInitialValues(tempInitialValues);
                setPulseId(id);
            }

            return pulse;
        },
        [pulseId]
    );

    const formik = useFormik<IPulseFormik>({
        initialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: !!pulseId,
        onSubmit,
        validate: async (values) => {
            const errors = await validateSchema(values, validationSchema);

            const touched = initialValues.startDate !== values.startDate;

            if (!touched && errors.startDate) {
                delete errors.startDate;
            }

            return errors;
        },
    });

    return (
        <PulseDialogContext.Provider
            value={{
                isOpen,
                close,
                open,
                pulseId,
                stepIndex,
                setStepIndex,
                emailMessage,
                setEmailMessage,
                defaultEmailMessage,
                shouldRefetch,
                setShouldRefetch,
            }}
        >
            <FormikProvider value={formik}>{children}</FormikProvider>
        </PulseDialogContext.Provider>
    );
}, pulsesQuery);
