import { Tooltip } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ListActionIcon } from 'core/components/shared/ListActionIcon';
import { useCrud } from 'core/hooks/crud.hook';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import React, { FC, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { EntityListActions } from '../../../core/components/EntityListActions';
import {
    IComponentWrappedWithEntityByIdProps,
    WithEntityById,
} from '../../../core/hocs/WithEntityById';
import { IProject } from '../models/project.model';
import { ProjectDialogContext } from './ProjectDialogProvider';

export const ProjectListActions: FC<
    IComponentWrappedWithEntityByIdProps<IProject>
> = ({ entity: project }) => {
    const { open } = useContext(ProjectDialogContext);

    const { t } = useContext(MultiLanguageSupportContext);

    const { push } = useHistory();

    const { archiveSingle, restoreSingle } = useCrud();

    const onArchiveClick = () => {
        archiveSingle(project, { shouldFetchAfterSuccess: true });
    };

    const onUnArchiveClick = () => {
        restoreSingle(project, { shouldFetchAfterSuccess: true });
    };

    const getActions = useCallback(
        () => [
            {
                callback: () =>
                    push({
                        pathname: pathBuilder(
                            ROUTES.PROJECT,
                            ':id',
                            project.id
                        ),
                    }),
                component: (
                    <ListActionIcon
                        iconComponent={
                            <Tooltip title={t('tooltip-edit')}>
                                <EditIcon />
                            </Tooltip>
                        }
                    ></ListActionIcon>
                ),
            },
            {
                callback: () => console.log('edit', project.id), // TODO: implement proper logic/action
                component: (
                    <ListActionIcon
                        iconComponent={
                            project.deletedAt ? (
                                <Tooltip title={t('tooltop-unarchive')}>
                                    <UnarchiveIcon onClick={onUnArchiveClick} />
                                </Tooltip>
                            ) : (
                                <Tooltip title={t('tooltip-archive')}>
                                    <ArchiveIcon onClick={onArchiveClick} />
                                </Tooltip>
                            )
                        }
                    ></ListActionIcon>
                ),
            },
        ],
        [project, open]
    );

    return <EntityListActions actions={getActions()} />;
};

export const ProjectListActionsById = WithEntityById(ProjectListActions);
