import {
    Box,
    Button,
    Container,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import MailIcon from '@material-ui/icons/Mail';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import NeedHelpMail from 'core/components/shared/micro/NeedHelpMail';
import { Form, Formik, FormikProps } from 'formik';
import { ILoginCredentials } from 'modules/login/models/login.model';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import LogoWithText from 'styles/material-ui/elements/logo-with-text';
import * as Yup from 'yup';
import { emailValidation, requiredField } from '../../../../core/validations';

const initialValues: ILoginCredentials = {
    email: '',
    password: '',
};

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(14),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
        },
    },
    form: {
        width: '100%',
    },
}));

interface LoginFormProps {
    onSubmitClick: (data: ILoginCredentials) => void;
}

export const LoginForm: FC<LoginFormProps> = ({ onSubmitClick }) => {
    const { push } = useHistory();
    const { t } = useContext(MultiLanguageSupportContext);
    const [showPassword, setShowPassword] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const redirectToResetPassword = () => {
        push({ pathname: ROUTES.RESET_PASSWORD });
    };

    const validationSchema = Yup.object().shape({
        email: emailValidation,
        password: requiredField,
    });

    const classes = useStyles();

    return (
        <Container maxWidth="sm">
            <div className={classes.paper}>
                <LogoWithText />

                <Box mt={isMobile ? 3 : 10}>
                    <Typography
                        variant="h1"
                        align={isMobile ? 'center' : 'left'}
                    >
                        {t('log-in-to-project-pulse')}
                    </Typography>
                </Box>

                <Box mt={isMobile ? 2 : 4} mb={isMobile ? 2 : 6}>
                    <Typography variant="body1">
                        {t('use-credentials-access-account')}
                    </Typography>
                </Box>

                <Formik
                    initialValues={initialValues}
                    onSubmit={async (credentials: ILoginCredentials) => {
                        onSubmitClick(credentials);
                        //actions.resetForm();
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={validationSchema}
                >
                    {(props: FormikProps<ILoginCredentials>) => {
                        const {
                            values,
                            errors,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                            submitForm,
                        } = props;
                        return (
                            <Form className={classes.form}>
                                <TextField
                                    label={t('enter-email')}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="email"
                                    type="email"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    error={!!errors.email}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    helperText={
                                        !!errors.email &&
                                        'Please enter your email!'
                                    }
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    error={!!errors.password}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Box mt={4} mb={5}>
                                    <Link
                                        href=""
                                        onClick={redirectToResetPassword}
                                    >
                                        {t('forgot-password')}
                                    </Link>
                                </Box>
                                <div>
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                        disabled={isSubmitting}
                                        type="submit"
                                        disableElevation
                                        onClick={submitForm}
                                    >
                                        {t('log-in')}
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <NeedHelpMail />
        </Container>
    );
};
