import {
    Box,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Theme,
    Typography,
} from '@material-ui/core';
import { EntityListHeaderTemplate } from 'core/components/shared/EntityListHeaderTemplate';
import React, { FC, useContext, useEffect } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { ListHeader } from '../../../core/components/shared/ListHeader';
import { ListItemContainer } from '../../../core/components/shared/ListItemContainer';
import { defaultOrderByProperty } from '../../../core/constants/default-order-properties';
import { useCurrentPageEntities } from '../../../core/hooks/current-page-entities.hook';
import { useCurrentPage } from '../../../core/hooks/current-page.hook';
import { IActivity } from '../models/activity.model';
import { ActivityListItem } from './ActivityListItem';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    projectCardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
        columnGap: 24,
        rowGap: 24,
    },
    filters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableBackground: {
        backgroundColor: '#fff',
    },
}));

export const ActivityList: FC = () => {
    const { currentPageEntities } = useCurrentPageEntities<IActivity>();
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);
    const { setOrderBy } = useCurrentPage();

    useEffect(() => {
        setOrderBy(defaultOrderByProperty, 'desc');
    }, []);

    return (
        <>
            <Box display="flex" justifyContent={'center'}>
                <TableContainer
                    component={Paper}
                    variant="outlined"
                    className={classes.tableBackground}
                >
                    <Table>
                        <TableHead>
                            <ListHeader>
                                <EntityListHeaderTemplate
                                    name={{
                                        label: t('name'),
                                    }}
                                    email={{
                                        label: t('email'),
                                    }}
                                    date={{
                                        label: 'Date',
                                    }}
                                    time={{
                                        label: 'Time',
                                    }}
                                />
                            </ListHeader>
                        </TableHead>
                        <TableBody>
                            {currentPageEntities?.map((activity, index) => (
                                <ListItemContainer
                                    key={activity.id}
                                    entityId={activity.id}
                                >
                                    {
                                        <ActivityListItem
                                            entity={activity}
                                            index={index}
                                        />
                                    }
                                </ListItemContainer>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
};
