import { Box, Grid, Typography } from '@material-ui/core';
import { asDateString } from 'core/helpers/asDateString';
import { FC, useContext } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { IAnalyticData } from '../models/dashboard.models';

type OverviewDashboardProps = Pick<
    IAnalyticData,
    | 'pulses'
    | 'organization'
    | 'period'
    | 'lastEventDate'
    | 'pulsePerParticipant'
    | 'totalParticipants'
    | 'roleCount'
    | 'pulseResponsePercentage'
>;

export const OverviewDashboard: FC<OverviewDashboardProps> = (analyticData) => {
    const { t } = useContext(MultiLanguageSupportContext);

    return (
        <Box
            border={'1px solid #DFE1E6'}
            px={5}
            py={2}
            borderRadius="8px"
            mt={2}
        >
            <Grid container>
                <Grid item xs={12} md={1}>
                    <Box display="flex">
                        <Box>
                            <Typography component="span" variant="h5">
                                {t('overview')}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box display="flex" justifyContent={'center'}>
                        <Box>
                            <Typography>
                                <strong>{t('pulse')}: </strong>
                                {analyticData.pulses}
                            </Typography>
                            <Typography>
                                <strong>{t('organization')}: </strong>
                                {analyticData.organization}
                            </Typography>
                            <Typography>
                                <strong>{t('period')}: </strong>
                                {analyticData.period}
                            </Typography>
                            <Typography>
                                <strong>{t('last-pulse')}: </strong>
                                {analyticData.lastEventDate &&
                                    asDateString(analyticData.lastEventDate, {
                                        format: 'date',
                                    })}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        borderRight={'1px solid #DFE1E6'}
                        borderLeft={'1px solid #DFE1E6'}
                        height="100%"
                        alignItems={'center'}
                    >
                        <Box>
                            <Typography variant="h5" align="center">
                                {t('avg-per-pulse-participant')}
                            </Typography>
                            <Typography variant="h4" align="center">
                                {parseFloat(
                                    analyticData.pulsePerParticipant
                                ).toFixed(1)}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box display="flex" justifyContent={'center'}>
                        <Box>
                            <Typography>
                                <strong>{t('participants')}: </strong>
                                {analyticData.totalParticipants}
                            </Typography>
                            <Typography>
                                <strong>{t('steering')}: </strong>
                                {analyticData?.roleCount?.steering ?? ' - '}
                            </Typography>
                            <Typography>
                                <strong>{t('team')}: </strong>
                                {analyticData?.roleCount?.team ?? ' - '}
                            </Typography>
                            <Typography>
                                <strong>{t('stakeholder')}: </strong>
                                {analyticData?.roleCount?.stakeholder ?? ' - '}
                            </Typography>
                            <Typography>
                                <strong>{t('response-rate')}: </strong>
                                {analyticData?.pulseResponsePercentage}%
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
