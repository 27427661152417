import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { sessionQuery } from 'modules/login/state/session.query';
import { sessionStore } from 'modules/login/state/session.store';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { sessionAPIService } from './sessionAPIService';

export function initializeApiInterceptors(httpClient: AxiosInstance): void {
    interceptRequest(httpClient);
    interceptResponse(httpClient);
}

function appendToken(config: AxiosRequestConfig, token: string): void {
    config.headers.Authorization = `Bearer ${token}`;
}

function interceptRequest(httpClient: AxiosInstance): void {
    httpClient.interceptors.request.use(
        (config) => {
            sessionQuery.token && appendToken(config, sessionQuery.token);

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}

function interceptResponse(httpClient: AxiosInstance): void {
    httpClient.interceptors.response.use(
        (response) => response,
        (error) => {
            if (!error.response) {
                if (error.message === 'Network Error') {
                    sessionStore.setError(
                        'Network error - server is probably down'
                    );
                    throw error;
                } else {
                    sessionStore.setError('Unkown error');
                    throw error;
                }
            } else {
                switch (error.response.status) {
                    case 401:
                        if (!sessionQuery.refreshToken) {
                            window.location.href = ROUTES.LOGIN;
                        } else {
                            return sessionAPIService
                                .refreshToken(sessionQuery.refreshToken)
                                .then((data) => sessionStore.handleToken(data))
                                .then(() => {
                                    sessionQuery.token &&
                                        appendToken(
                                            error.config,
                                            sessionQuery.token
                                        );
                                    return axios.request(error.config);
                                })
                                .catch(() => {
                                    window.location.href = ROUTES.LOGIN;
                                });
                        }
                        break;
                }
            }

            return error.response;
        }
    );
}
