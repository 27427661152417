import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FormDialogActions } from 'core/components/shared/dialog/FormDialogActions';
import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { requiredField } from 'core/validations';
import { Form, useFormik } from 'formik';
import {
    emptyScaleQuestionDetails,
    IScaleQuestionDetails,
} from 'modules/scale-questions/models/scale-question.model';
import { scaleQuestionsQuery } from 'modules/scale-questions/state/scale-questions.query';
import React, { FC, useContext, useMemo } from 'react';
import css from 'styles/material-ui/cssVars';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
    inputsWrapper: {
        overflowY: 'auto',
        padding: `0 ${theme.spacing(3)}px`,
    },
    modalActions: {
        zIndex: 1,
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: css.extraColors.text.darkPrimary,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing(2)}px  ${theme.spacing(1)}px `,

            '& button + button': {
                marginTop: theme.spacing(2),
            },

            '& div:last-child': {
                marginTop: 0,
            },
        },
    },
}));

const validationSchema = Yup.object({
    name: requiredField,
    phrase: requiredField,
});

interface ICreateScaleQuestionFormProps {
    questionData?: Partial<IScaleQuestionDetails>;
    handleClose: () => void;
    onSubmitClick: (data: any) => void;
}

export const CreateScaleQuestionForm: FC<ICreateScaleQuestionFormProps> = WithProvider(
    ({ questionData = {}, handleClose, onSubmitClick }) => {
        const { t } = useContext(MultiLanguageSupportContext);
        const { createSingle } = useCrud();

        const initialValues = useMemo(
            () => ({ ...emptyScaleQuestionDetails, ...questionData }),
            [questionData]
        );

        const {
            values,
            handleBlur,
            handleChange,
            submitForm,
            errors,
        } = useFormik({
            initialValues,
            validateOnChange: false,
            validateOnBlur: true,
            onSubmit: async (values, actions) => {
                const newQuestion = await createSingle(values, {
                    shouldFetchAfterSuccess: true,
                });
                onSubmitClick(newQuestion);
                actions.resetForm();
                handleClose();
            },
            validationSchema,
        });

        const classes = useStyles();

        return (
            <Form>
                <Box className={classes.inputsWrapper}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            label={t('title')}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="name"
                            type="text"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            error={!!errors.name}
                        />
                    </Grid>
                    <Grid>
                        <Typography variant="body2">
                            {t('short-headline-for-use-in-graphs-and-dataset')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label={t('question')}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="phrase"
                            type="text"
                            name="phrase"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phrase}
                            error={!!errors.phrase}
                        />
                    </Grid>
                </Box>
                <Box className={classes.modalActions}>
                    <FormDialogActions handleClose={handleClose}>
                        <Button
                            style={{
                                textTransform: 'none',
                            }}
                            variant="contained"
                            disableElevation
                            color="default"
                            onClick={submitForm}
                        >
                            {t('save')}
                        </Button>
                    </FormDialogActions>
                </Box>
            </Form>
        );
    },
    scaleQuestionsQuery
);
