import { getEntityType, QueryEntity } from '@datorama/akita';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EntityStateWithHighlight } from './highlight.store';

export interface IQueryEntityWithHighlight<T> {
    selectHighlighted(): Observable<T | null>;
    getHighlighted(): T | null;
}

export class QueryEntityWithHighlight<S extends EntityStateWithHighlight>
    extends QueryEntity<S>
    implements IQueryEntityWithHighlight<getEntityType<S>> {
    selectHighlighted(): Observable<getEntityType<S> | null> {
        return this.select('highlightedId').pipe(
            switchMap((id) =>
                !id
                    ? of(null)
                    : this.selectEntity(id).pipe(map((x) => x ?? null))
            )
        );
    }

    getHighlighted(): getEntityType<S> | null {
        const { entities, highlightedId } = this.getValue();

        return entities?.[highlightedId] ?? null;
    }
}
