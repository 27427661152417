import { Tooltip } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import EditIcon from '@material-ui/icons/Edit';
import MuiAlert from '@material-ui/lab/Alert';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ListActionIcon } from 'core/components/shared/ListActionIcon';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import React, { FC, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EntityListActions } from '../../../core/components/EntityListActions';
import {
    IComponentWrappedWithEntityByIdProps,
    WithEntityById,
} from '../../../core/hocs/WithEntityById';
import { apiService } from '../../../core/services/apiService';
import { useDialog } from '../../projects/components/use-dialog.hook';
import { IPulse } from '../models/pulse.model';
import { PulseDialogContext } from './PulseDialogProvider';
import { SendPulse } from './SendPulse';

export const PulseListActions: FC<
    IComponentWrappedWithEntityByIdProps<IPulse>
> = ({ entity: pulse }) => {
    const { open: isOpen, handleClose, handleOpen } = useDialog(); // TODO: should not render separate dialogs for each button

    const { t } = useContext(MultiLanguageSupportContext);
    const { open } = useContext(PulseDialogContext);

    const { push } = useHistory();

    const [openSnackbar, setOpenSnackbar] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    function Alert(props: any) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleSnackbarClick = () => {
        setOpenSnackbar({ ...openSnackbar, open: true });
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar({ ...openSnackbar, open: false });
    };

    const sendPulse = async () => {
        await apiService.post(`/pulses/${pulse.id}/send`, {});
        handleSnackbarClick();
    };

    const getActions = useCallback(
        () => [
            {
                callback: () =>
                    push({
                        pathname: pathBuilder(ROUTES.PULSE, ':id', pulse.id),
                    }),
                component: (
                    <>
                        <ListActionIcon
                            iconComponent={
                                <Tooltip title={t('tooltip-edit')}>
                                    <EditIcon />
                                </Tooltip>
                            }
                        />
                        <Snackbar
                            open={openSnackbar.open}
                            autoHideDuration={3000}
                            onClose={() => handleSnackbarClose}
                        >
                            <Alert
                                onClose={handleSnackbarClose}
                                severity="success"
                            >
                                Successfully send Pulse!
                                {/*TODO: Translations*/}
                            </Alert>
                        </Snackbar>
                    </>
                ),
            },
        ],
        [pulse, open, isOpen]
    );

    return <EntityListActions actions={getActions()} />;
};

export const PulseListActionsById = WithEntityById(PulseListActions);
