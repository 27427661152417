import { IconName } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TableCell, Typography } from '@material-ui/core';
import { TranslateWarning } from 'core/components/shared/TranslationWarning';
import React, { FC } from 'react';
import { ITranslation } from '../models/success-factor.model';
import { FactorIcon } from './FactorIcon';

interface IFactorsListItemTemplate {
    fontAwesomeIcon: IconName | null;
    title: string;
    translation?: ITranslation;
    library?: 'Account' | 'System';
    mandatory?: boolean;
    defaultItem?: boolean;
    notification?: JSX.Element;
}

export const FactorsListItemTemplate: FC<IFactorsListItemTemplate> = ({
    fontAwesomeIcon,
    title,
    translation,
    library,
    mandatory,
    defaultItem,
    notification,
}) => {
    return (
        <>
            <TableCell>
                <FactorIcon fontAwesomeIcon={fontAwesomeIcon} />
            </TableCell>
            <TableCell>{title}</TableCell>
            {library && <TableCell>{library}</TableCell>}
            {mandatory !== undefined && (
                <TableCell>
                    {mandatory ? (
                        <FontAwesomeIcon
                            icon={['far', 'check-circle']}
                            size={'lg'}
                            color="green"
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={['far', 'minus-circle']}
                            size={'lg'}
                            color="red"
                        />
                    )}
                </TableCell>
            )}
            {defaultItem !== undefined && (
                <TableCell>
                    {defaultItem ? (
                        <FontAwesomeIcon
                            icon={['far', 'check-circle']}
                            size={'lg'}
                            color="green"
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={['far', 'minus-circle']}
                            size={'lg'}
                            color="red"
                        />
                    )}
                </TableCell>
            )}
            {notification && <TableCell>{notification}</TableCell>}
            {translation && (
                <TableCell>
                    {!translation.title ? (
                        <TranslateWarning label={title} />
                    ) : (
                        <Box display="flex">
                            <Typography>{translation.title}</Typography>
                        </Box>
                    )}
                </TableCell>
            )}
        </>
    );
};
