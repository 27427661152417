import { Avatar, Box, Hidden, Link, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { fade, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { HelpOutline } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import RestoreIcon from '@material-ui/icons/Restore';
import MobileMenu from 'core/components/shared/topBars/MobileMenu';
import { NavChips } from 'core/components/shared/topBars/NavChips';
import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { ActivityDialogContext } from 'modules/activity/components/ActivityDialogProvider';
import { Can } from 'modules/login/components/Can';
import { UserForm } from 'modules/users/components/UserForm';
import { UserFormContext } from 'modules/users/components/UserFormProvider';
import { IUser } from 'modules/users/models/user.model';
import { usersQuery } from 'modules/users/state/users.query';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import css from 'styles/material-ui/cssVars';
import LogoWithText from 'styles/material-ui/elements/logo-with-text';
import { ActivityPage } from '../../../../modules/activity/pages/ActivityPage';
import { HelpFeedbackModalContent } from '../../../../modules/help-feedback/components/HelpFeedbackModalContent';
import { useDialog } from '../../../../modules/projects/components/use-dialog.hook';
import { useSession } from '../../../hooks/session.hook';
import { MultiLanguageSupportContext } from '../../MultiLanguageSupportProvider';
import { FormDialogWrapper } from '../FormDialogWrapper';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    toolbar: {
        background: theme.palette.background.default,
        height: '64px',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            '& :not(last-child) > span': {
                height: '20px',
                width: '20px',
            },
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    profileMenuMobile: {
        textAlign: 'right',
        '& ul': {
            width: '240px',
            padding: `0 ${theme.spacing(1)}px ${theme.spacing(3)}px`,
        },
        '& li': {
            justifyContent: 'flex-end',
        },
    },
    profileMenuActions: {
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid #DFE1E6',
        paddingTop: '24px',
    },
    purple: {
        color: 'white',
        backgroundColor: '#FF9880',
        textTransform: 'uppercase',
    },
    navWrap: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    profileMenuItem: {
        width: '240px',
        padding: theme.spacing(3),
    },
    actions: {
        backgroundColor: css.extraColors.text.darkPrimary,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(3),
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    cursorPointer: {
        cursor: 'pointer',
    },
}));

const PulseAppBarContainer = () => {
    const { logout, me } = useSession();
    const { t } = useContext(MultiLanguageSupportContext);
    const { isOpen, close, open } = useContext(UserFormContext);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const {
        open: isHelpFeedbackModalOpen,
        handleOpen,
        handleClose,
    } = useDialog();
    const { updateSingle } = useCrud<IUser>();

    const closeHelp = useCallback(async () => {
        handleClose();
        me && (await updateSingle({ ...me, isNew: false }));
    }, [me, updateSingle, handleClose]);

    useEffect(() => {
        if (me && me.isNew) handleOpen();
    }, []);

    const userData = useMemo(
        () => ({
            avatarID: me?.name.substring(0, 1) ?? '',
            name: me?.name ?? '',
        }),
        [me]
    );

    const editUser = useCallback(() => {
        setAnchorEl(null);
        setMobileMoreAnchorEl(null);
        me && open(me);
    }, [me]);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = async () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const { isOpened, closeModal, openActivity } = useContext(
        ActivityDialogContext
    );

    const handleMobileMenuOpen = (event: any) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <Box
                display="flex"
                justifyContent="flex-end"
                className={classes.profileMenuItem}
            >
                <MenuItem>
                    <Typography variant="subtitle2">{userData.name}</Typography>
                </MenuItem>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                className={classes.profileMenuItem}
            >
                <MenuItem onClick={editUser}>
                    <Typography variant="subtitle2" color="textSecondary">
                        {t('edit-user')}
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => logout()}>
                    <ExitToAppIcon style={{ color: '#6B778C' }} />
                </MenuItem>
            </Box>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
            className={classes.profileMenuMobile}
        >
            <MenuItem>
                <Typography variant="subtitle2">{userData.name}</Typography>
            </MenuItem>
            <MenuItem>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('edit-user')}
                </Typography>
            </MenuItem>
            <Box className={classes.profileMenuActions}>
                <Can I="viewActivityOf" a="IUser">
                    <IconButton
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={() => openActivity()}
                        color="default"
                    >
                        <RestoreIcon />
                    </IconButton>
                </Can>
                <IconButton
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleOpen}
                    color="default"
                >
                    <HelpOutline />
                </IconButton>
                <MenuItem onClick={() => logout()}>
                    <Typography variant="subtitle2" color="textSecondary">
                        {t('logout')}
                    </Typography>
                    <ExitToAppIcon style={{ color: '#6B778C' }} />
                </MenuItem>
            </Box>
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar position="static" elevation={0}>
                <Toolbar className={classes.toolbar}>
                    <Hidden mdUp>
                        <MobileMenu />
                    </Hidden>

                    <Link>
                        <LogoWithText />
                    </Link>

                    <div className={classes.navWrap}>
                        <Hidden smDown>
                            <NavChips />
                        </Hidden>
                    </div>

                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <Can I="viewActivityOf" a="IUser">
                            <IconButton
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={() => openActivity()}
                                color="default"
                            >
                                <RestoreIcon />
                            </IconButton>
                        </Can>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleOpen}
                            color="default"
                        >
                            <HelpOutline />
                        </IconButton>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="primary"
                        >
                            <Avatar className={classes.purple}>
                                {userData.avatarID}
                            </Avatar>
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="default"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            <FormDialogWrapper isOpen={isOpen} handleClose={close}>
                <UserForm />
            </FormDialogWrapper>
            <FormDialogWrapper
                isOpen={isHelpFeedbackModalOpen}
                handleClose={closeHelp}
            >
                <HelpFeedbackModalContent closeHelp={closeHelp} />
            </FormDialogWrapper>
            <FormDialogWrapper isOpen={isOpened} handleClose={closeModal}>
                <ActivityPage />
            </FormDialogWrapper>
        </div>
    );
};

export const PulseAppBar = WithProvider(PulseAppBarContainer, usersQuery);
