import { FrequencyValue } from '../enums/frequency-value';

export const isFrequencyDisabledBuilder = (
    startDateAsString: string | null
) => (value: FrequencyValue): boolean => {
    if (startDateAsString) {
        const startDate = new Date(
            Date.parse(`${startDateAsString}T00:00:00.000+0000`)
        );

        if (
            value === FrequencyValue.MONTHLY_1 ||
            value === FrequencyValue.MONTHLY_2
        ) {
            const dayOfMonth = startDate.getDate();

            if (dayOfMonth < 8) {
                return value !== FrequencyValue.MONTHLY_1;
            } else if (dayOfMonth < 15) {
                return value !== FrequencyValue.MONTHLY_2;
            }

            return true;
        }
    }

    return false;
};
