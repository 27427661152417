const cssVariables = {
    extraColors: {
        background: {
            darkDefault: '#172B4D',
            darkPaper: '#091E42',
        },
        text: {
            // fyi: dark mode, ie text on dark background
            darkPrimary: '#F4F5F7',
            darkSecondary: '#97A0AF',
            darkDisabled: '#42526E',
        },
    },
};

export default cssVariables;
