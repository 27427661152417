import {
    Box,
    Button,
    InputAdornment,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { EntityListPagination } from 'core/components/EntityListPagination';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { EntityListHeaderTemplate } from 'core/components/shared/EntityListHeaderTemplate';
import { EntityInfiniteScroll } from 'core/components/shared/InfiniteScroll';
import { useCurrentPage } from 'core/hooks/current-page.hook';
import { useFilter } from 'core/hooks/filter.hook';
import React, { FC, useContext, useEffect, useState } from 'react';
import { ListHeader } from '../../../core/components/shared/ListHeader';
import { ListItemContainer } from '../../../core/components/shared/ListItemContainer';
import { primaryOrderByProperty } from '../../../core/constants/default-order-properties';
import { useCurrentPageEntities } from '../../../core/hooks/current-page-entities.hook';
import { useDebounce } from '../../../core/hooks/shared/debounce.hook';
import { IProject } from '../models/project.model';
import { ListView, ListViewOptionTypes } from './ListView';
import { ProjectCard } from './ProjectCard';
import { ProjectDialogContext } from './ProjectDialogProvider';
import { ProjectFilters, ProjectListFilter } from './ProjectFilters';
import { ProjectListActionsById } from './ProjectListActions';
import { ProjectListItem } from './ProjectListItem';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    projectCardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
        columnGap: 24,
        rowGap: 24,
    },
    filters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableBackground: {
        backgroundColor: '#fff',
    },
    searchField: {
        marginRight: theme.spacing(4),
        width: '240px',
        [theme.breakpoints.up('lg')]: {
            width: '440px',
        },
        '& input': {
            padding: `14.5px ${theme.spacing(2)}px 14.5px 0`,
        },
    },
}));

const SearchProjectInput = () => {
    const { setSearchTerm } = useCurrentPage();
    const [inputValue, setInputValue] = useState('');
    const debouncedSearchTerm = useDebounce(inputValue, 500);
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);
    const { setOrderBy } = useCurrentPage();

    useEffect(() => {
        setOrderBy(primaryOrderByProperty, 'desc');
    }, []);

    useEffect(() => {
        setSearchTerm(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    return (
        <TextField
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            variant="outlined"
            className={classes.searchField}
            label={t('search-user')}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export const ProjectList: FC = () => {
    const {
        currentPageEntities,
        allPagesEntities,
    } = useCurrentPageEntities<IProject>();
    const { t } = useContext(MultiLanguageSupportContext);
    const { open } = useContext(ProjectDialogContext);
    const classes = useStyles();
    const [listView, setListView] = useState<ListViewOptionTypes>(
        +(
            localStorage.getItem(`list-view_project-list`) ??
            ListViewOptionTypes.GRID.toString()
        )
    );

    const {
        setOrderBy,
        setPageNumber,
        clearCurrentPageEntities,
    } = useCurrentPage();

    useEffect(() => {
        setOrderBy(primaryOrderByProperty, 'desc');
    }, [listView]);

    const { filter, setFilter } = useFilter<ProjectListFilter>(
        ProjectListFilter.ACTIVE
    );

    useEffect(() => {
        localStorage.setItem(`list-view_project-list`, listView.toString());
        setPageNumber(1, true);

        return () => clearCurrentPageEntities();
    }, [listView]);

    if (currentPageEntities.length === 0 || allPagesEntities.length === 0) {
        return (
            <Box width={'100%'} className={classes.root}>
                <Box className={classes.filters}>
                    <ProjectFilters filter={filter} setFilter={setFilter} />
                </Box>
                <>
                    <Typography variant="h1" align="center">
                        {t('project-list-empty')}
                    </Typography>
                    <Typography variant="body1" align="center">
                        {t('projects-are-home')}
                    </Typography>

                    <Box display="flex" justifyContent={'center'}>
                        <Button
                            onClick={() => open({})}
                            disableElevation
                            variant="contained"
                            color="primary"
                            style={{
                                textTransform: 'capitalize',
                                margin: '10px 0',
                            }}
                        >
                            {t('create-new-project')}
                        </Button>
                    </Box>
                </>
            </Box>
        );
    } else {
        return (
            <Box width={'100%'} className={classes.root}>
                <Box className={classes.filters}>
                    <ProjectFilters filter={filter} setFilter={setFilter} />
                    <Box>
                        {/*<SearchProjectInput />*/}
                        <ListView
                            view={listView}
                            handleViewChange={setListView}
                        />
                    </Box>
                </Box>

                <Box mt={3}>
                    {listView === ListViewOptionTypes.GRID && (
                        <EntityInfiniteScroll>
                            <Box className={classes.projectCardsGrid}>
                                {allPagesEntities.map((project) => (
                                    <ProjectCard
                                        key={project.id}
                                        project={project}
                                    />
                                ))}
                            </Box>
                        </EntityInfiniteScroll>
                    )}

                    {listView === ListViewOptionTypes.LIST && (
                        <>
                            <TableContainer
                                component={Paper}
                                variant="outlined"
                                className={classes.tableBackground}
                            >
                                <Table>
                                    <TableHead>
                                        <ListHeader
                                            renderLastColumn={() => (
                                                <span
                                                    style={{
                                                        visibility: 'hidden',
                                                    }}
                                                >
                                                    {t('actions')}
                                                </span>
                                            )}
                                        >
                                            <EntityListHeaderTemplate
                                                projectName={{
                                                    label: t('project'),
                                                    sort: 'name',
                                                }}
                                                projectManagerName={{
                                                    label: t('project-manager'),
                                                    sort: 'projectManagerName',
                                                }}
                                                latestPulse={{
                                                    label: t('latest-pulse'),
                                                    sort: 'latest-pulse',
                                                }}
                                                nextPulse={{
                                                    label: t('next-pulse'),
                                                    sort: 'next-pulse',
                                                }}
                                                participants={{
                                                    label: t('participants'),
                                                }}
                                                customer={{
                                                    label: t('customer'),
                                                }}
                                                participation={{
                                                    label: t('participation'),
                                                }}
                                                score={{ label: t('score') }}
                                            />
                                        </ListHeader>
                                    </TableHead>
                                    <TableBody>
                                        {currentPageEntities.map(
                                            (project, index) => (
                                                <ListItemContainer
                                                    key={project.id}
                                                    entityId={project.id}
                                                    enableHover={true}
                                                    renderLastColumn={(
                                                        entityId: string
                                                    ) => (
                                                        <ProjectListActionsById
                                                            id={entityId}
                                                        />
                                                    )}
                                                >
                                                    <ProjectListItem
                                                        entity={project}
                                                        index={index}
                                                    />
                                                </ListItemContainer>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Box mt={2}>
                                <EntityListPagination />
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        );
    }
};
