import { ISurveyLabelTranslation } from 'modules/pulse-answering/models/feedback-loop-models';

export const filterTranslatesBuilder = (
    surveyLabelTranslations: ISurveyLabelTranslation[] = []
) => (keyword: string, fallback = ''): string => {
    const translation =
        surveyLabelTranslations.find(
            ({ translation_key }) => translation_key === keyword
        )?.translation_content ?? '';
    return !translation.replace(/,/g, '') ? fallback : translation;
};
