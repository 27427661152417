import { Box, Container, Dialog, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import LogoWithText from 'styles/material-ui/elements/logo-with-text';
import { ROUTES } from '../../navigation/enums/routes.enum';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#F2EBFF',
        height: '100vh',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
    },
    container: {
        backgroundColor: '#FFFFFF',
        padding: '30px 80px',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export const SuccessRequestPassword: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { push } = useHistory();

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Dialog
                open={true}
                BackdropProps={{ style: { backgroundColor: '#F4E7FE' } }}
            >
                <Container className={classes.container} maxWidth="sm">
                    <div className={classes.paper}>
                        <LogoWithText />

                        <Box mt={9}>
                            <Typography variant="h4" align="center">
                                {`${t('check-inbox')}`}
                            </Typography>
                        </Box>
                        <Box my={4}>
                            <Typography variant="body2" align="center">
                                {`${t('follow-instructions-for-reset-pass')}`}
                            </Typography>
                            <Typography variant="body2" align="center">
                                {`${process.env.REACT_APP_SUPPORT_EMAIL} ${t(
                                    'dont-forget-spam'
                                )}`}
                            </Typography>
                        </Box>
                        <Box my={8}>
                            <Link
                                onClick={() => push({ pathname: ROUTES.LOGIN })}
                            >
                                {t('go-to-login-page')}
                            </Link>
                        </Box>
                    </div>
                </Container>
                {/* <NeedHelpMail /> */}
            </Dialog>
        </div>
    );
};
