import { Snackbar, SnackbarProps } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useActionStatus } from 'core/hooks/action-status.hook';
import React, { useContext, useMemo, useState } from 'react';
import { ValidTranslationKeys } from 'react-i18next';
import { MultiLanguageSupportContext } from './MultiLanguageSupportProvider';

export const ActionStatuses = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>({
        open: false,
        autoHideDuration: 3000,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        },
    });

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleSnackbarClick = () => {
        setSnackbarProps({ ...snackbarProps, open: true });
    };

    const handleSnackbarClose = () => {
        setSnackbarProps({ ...snackbarProps, open: false });
    };

    const actionStatus = useActionStatus();

    const message: keyof ValidTranslationKeys | null = useMemo(() => {
        if (!actionStatus) {
            return null;
        }

        handleSnackbarClick();

        return `${actionStatus.action}-${actionStatus.type}-${
            actionStatus.success ? 'success' : 'failure'
        }` as keyof ValidTranslationKeys;
    }, [actionStatus]);

    return (
        <Snackbar {...snackbarProps} onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={
                    !actionStatus
                        ? 'info'
                        : actionStatus.success
                        ? 'success'
                        : 'error'
                }
            >
                {message && t(message)}
            </Alert>
        </Snackbar>
    );
};
