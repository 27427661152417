import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import css from 'styles/material-ui/cssVars';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        padding: theme.spacing(0.5),
        borderRadius: '96px',
        backgroundColor: css.extraColors.text.darkPrimary,
    },
    chip: {
        cursor: 'pointer',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        borderRadius: '64px',
        textTransform: 'uppercase',
        fontSize: '14px',
        '&.active': {
            backgroundColor: '#172B4D',
            color: '#fff',
        },
        '&.active:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

export interface IEntityTableFiltersProps<T> {
    filter: T;
    setFilter: (filter: T) => void;
    options: { label: string; type: T }[];
}

export const EntityFilters = <T extends string>({
    filter: selectedFilter,
    setFilter,
    options,
}: IEntityTableFiltersProps<T>): JSX.Element => {
    const classes = useStyles();

    const filterItem = (label: string, type: T) => {
        return (
            <Box
                key={type}
                onClick={() => setFilter(type)}
                className={
                    classes.chip +
                    ' ' +
                    (selectedFilter === type ? 'active' : '')
                }
            >
                {label}
            </Box>
        );
    };

    return (
        <Box className={classes.wrapper}>
            {options.map(({ label, type }) => filterItem(label, type))}
        </Box>
    );
};
