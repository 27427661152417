import { Grid, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { FC, useContext } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { CategoryAutocomplete } from './CategoryAutocomplete';
import { ICategoryFormik } from './CategoryFormProvider';

export const CategoryForm: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);

    const {
        values,
        setFieldValue,
        handleChange,
        errors,
    } = useFormikContext<ICategoryFormik>();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    label={t('name')}
                    error={Boolean(errors.name)}
                />
            </Grid>
            <Grid item xs={12}>
                <CategoryAutocomplete
                    dimensionId={values.dimensionId}
                    emptyName={values.dimensionName}
                    label={t('parent')}
                    value={values.parentId ?? ''}
                    onSelect={async (item) => {
                        setFieldValue('parentId', item?.id);
                    }}
                />
            </Grid>
        </Grid>
    );
};
