import { Grid, TextField } from '@material-ui/core';
import { EntityTranslationsPage } from 'core/components/shared/EntityTranslationsPage';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { ITranslationsFormik } from './SuccessFactorsFormProvider';

export interface SuccessFactorsTranslationsProps {
    disabled?: boolean;
    languageTranslationRelations: ITranslationsFormik;
}

export const SuccessFactorsTranslationsPage: FC<SuccessFactorsTranslationsProps> = ({
    disabled = false,
    languageTranslationRelations,
}) => {
    const {
        handleChange,
        handleBlur,
        values,
    } = useFormikContext<ITranslationsFormik>();

    return (
        <EntityTranslationsPage
            renderRow={
                !Object.keys(languageTranslationRelations).length
                    ? undefined
                    : (language) => {
                          const key = languageTranslationRelations[language.id];

                          if (!key) {
                              return <></>;
                          }

                          return (
                              <Grid item xs={12} key={language.id}>
                                  <TextField
                                      variant="outlined"
                                      margin="normal"
                                      fullWidth
                                      name={key}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      label={`${'title'} in ${language.name}`}
                                      value={values[key] ?? ''}
                                      disabled={disabled}
                                  />
                              </Grid>
                          );
                      }
            }
        />
    );
};
