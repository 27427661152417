import { IEntity } from '../../../core/interfaces/entity.interface';

export interface ILanguageDetails {
    locale: string;
    name: string;
}

export interface ILanguage extends IEntity, ILanguageDetails {}

export interface ILanguageResponse extends IEntity<number>, ILanguageDetails {}

export const emptyLanguage: ILanguageDetails = {
    locale: '',
    name: '',
};

export const createLanguage = ({
    id,
    ...props
}: Partial<ILanguage>): ILanguage => {
    if (!id) {
        throw new Error('Unable to create a user without an ID!');
    }

    return {
        id,
        ...emptyLanguage,
        ...props,
    };
};

export const createLanguageFromResponse = ({
    id,
    ...props
}: ILanguageResponse): ILanguage => {
    return createLanguage({
        id: id.toString(),
        ...props,
    });
};
