import {
    EntityFilters,
    IEntityTableFiltersProps,
} from 'core/components/EntityFilters';
import { FC } from 'react';

export enum ProjectListFilter {
    ALL = 'ALL',
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'DELETED',
}

type ProjectTableFiltersProps = Omit<
    IEntityTableFiltersProps<ProjectListFilter>,
    'options'
>;

export const ProjectFilters: FC<ProjectTableFiltersProps> = (props) => (
    <EntityFilters
        {...props}
        options={[
            { label: 'All', type: ProjectListFilter.ALL },
            { label: 'Active', type: ProjectListFilter.ACTIVE },
            { label: 'Archived', type: ProjectListFilter.ARCHIVED },
        ]}
    />
);
