import { IconName } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    Grid,
    IconButton,
    makeStyles,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { InfiniteScroll } from 'core/components/shared/InfiniteScroll';
import React, { FC, useContext } from 'react';
import {
    IconPickerContext,
    IFAIcon,
} from '../../../core/components/IconPickerProvider';
import { useIcons } from '../../../core/hooks/use-icons.hooks';

type IconPrefix = 'fas' | 'fab' | 'far' | 'fal' | 'fad' | any;

interface IconLookup {
    // IconName is defined in the code that will be generated at build time and bundled with this file.
    iconName: IconName;
    prefix: IconPrefix;
    label: string;
}

interface IconButtonProps {
    iconButtonValue?: string;
    onChangeHandler: (type: any) => any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '600px',
    },
    input: {
        position: 'absolute',
        opacity: 0,
        zIndex: 100,
        '&:checked + $label': {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        borderColor: theme.palette.text.secondary,
        height: 48,
        width: 48,
        marginRight: theme.spacing(2),
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        borderRadius: theme.spacing(0.5),
        '&:hover': {
            color: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
        },
    },
}));

function returnSets(response: IFAIcon): IconLookup[] {
    return response.styles.map((style) => ({
        iconName: response.name,
        prefix: `fa${style.substring(0, 1)}`,
        label: response.label,
    }));
}

export interface IconPickerProps {
    handleClose?: () => void;
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    iconPickerText?: string;
    value: IconName | null;
}

export const IconPicker: FC<IconPickerProps> = ({
    handleClose,
    handleChange,
    iconPickerText,
    value,
}) => {
    const { listOfIcons } = useContext(IconPickerContext);
    const [
        icons,
        setSearchTerm,
        _setPageIndex,
        _setPageSize,
        nextPage,
        _prevPage,
    ] = useIcons(listOfIcons);

    const classes = useStyles();

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h4">{iconPickerText}</Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box my={1}>
                <TextField
                    fullWidth
                    placeholder="Search Icon..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                    variant="outlined"
                />
            </Box>
            <Grid container spacing={2}>
                <InfiniteScroll
                    total={listOfIcons.length}
                    items={icons}
                    handleObserver={(entries: IntersectionObserverEntry[]) => {
                        const entry = entries[0];

                        if (entry.isIntersecting) {
                            nextPage();
                        }
                    }}
                    Loader={() => <h2 onClick={nextPage}>Load More</h2>}
                >
                    {icons.map((icon: IFAIcon) =>
                        returnSets(icon).map((entity, index) => (
                            <Grid item key={index}>
                                <Tooltip title={entity.label}>
                                    <Box>
                                        <label>
                                            <input
                                                type="radio"
                                                className={classes.input}
                                                value={`${entity.prefix}-${entity.iconName}`}
                                                checked={
                                                    value ===
                                                    `${entity.prefix}-${entity.iconName}`
                                                }
                                                name="fa-icon"
                                                id={`${entity.prefix}-${entity.iconName}`}
                                                onChange={handleChange}
                                            />
                                            <span className={classes.label}>
                                                <FontAwesomeIcon
                                                    icon={[
                                                        entity.prefix,
                                                        entity.iconName,
                                                    ]}
                                                    style={{ fontSize: '22px' }}
                                                />
                                            </span>
                                        </label>
                                    </Box>
                                </Tooltip>
                            </Grid>
                        ))
                    )}
                </InfiniteScroll>
            </Grid>
        </>
    );
};
