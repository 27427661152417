import { EntityState, EntityStore } from '@datorama/akita';

export interface EntityStateWithHighlight<E = any, IDType = any>
    extends EntityState<E, IDType> {
    highlightedId: IDType | null;
}

export abstract class EntityStoreWithHighlight<
    S extends EntityStateWithHighlight
> extends EntityStore<S> {
    /**
     * This is a hack function to bypass Typescript issue
     * https://github.com/microsoft/TypeScript/issues/19388
     * https://github.com/microsoft/TypeScript/issues/17071
     */
    private _createPartialState(partial: Partial<EntityStateWithHighlight>) {
        return partial as Partial<S>;
    }

    setHighlight(id?: string): void {
        if (!id) {
            this.update(this._createPartialState({ highlightedId: null }));
            return;
        }

        // const {entities} = this.getValue();
        // const highlighted = entities[id];

        // if (!highlighted) {
        //   throw new Error(`There is no entity with id ${id} in the store.`);
        // }

        this.update(this._createPartialState({ highlightedId: id }));
    }
}
