import {
    Box,
    Container,
    Grid,
    Typography,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ISuccessFactorsProps } from 'core/interfaces/survey-answering.interface';
import { useFormikContext } from 'formik';
import { FactorIcon } from 'modules/success-factors/components/FactorIcon';
import { FactorValue } from 'modules/success-factors/interfaces/FactorValue';
import React, { FC, useContext, useEffect } from 'react';
import { filterTranslatesBuilder } from './filterTranslatesBuilderHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    content: {
        minHeight: `calc(100vh - 212px)`,
        marginBottom: '20px',
    },
    factorsContainer: {
        maxWidth: '950px',
    },
    stepIntro: {
        padding: '48px 0',
        [theme.breakpoints.down('xs')]: {
            padding: '24px 0',
        },
    },
    stepHeading: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
            lineHeight: '24px',
        },
    },
    factorBox: {
        padding: '16px',
        position: 'relative',
        borderRadius: theme.spacing(0.5),
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.down('sm')]: {
            padding: '8px 16px',
        },
    },
    toggleButton: {
        fontSize: '12px',
        textTransform: 'none',
        '&:nth-child(1)': {
            '&.Mui-selected': {
                backgroundColor: '#FFEBE6',
                color: '#B22400',
            },
        },
        '&:nth-child(3)': {
            '&.Mui-selected': {
                backgroundColor: '#E8FDF2',
                color: '#006B47',
            },
        },
    },
    questionNumber: {
        color: '#C1C7D0',
        fontSize: '12px',
        lineHeight: '16px',
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: '8px',
            right: '8px',
        },
    },
    factorText: {
        marginBottom: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            maxWidth: '86%',
        },
    },
    factorButtons: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            '& button': {
                width: '33.33%',
            },
        },
    },
}));

export const SuccessFactors: FC<ISuccessFactorsProps> = ({
    surveyFactors,
    surveyLabelTranslations,
    nextComponent,
}) => {
    const { values, setFieldValue } = useFormikContext<{
        [key: string]: FactorValue | null;
    }>();

    useEffect(() => {
        surveyFactors.length > 0 ? '' : nextComponent();
    }, [surveyFactors]);

    const { t } = useContext(MultiLanguageSupportContext);

    const classes = useStyles();

    const filterTranslates = filterTranslatesBuilder(surveyLabelTranslations);

    const theme = useTheme();
    const breakpointSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={classes.root}>
            <Container className={classes.content}>
                <Box className={classes.stepIntro}>
                    <Typography
                        variant="h2"
                        align="center"
                        className={classes.stepHeading}
                    >
                        {filterTranslates(
                            'successFactorHeadline',
                            t('success-factor-headline')
                        )}
                    </Typography>
                </Box>
                <Container className={classes.factorsContainer}>
                    <Grid container spacing={2}>
                        {surveyFactors.map((factor, idx) => (
                            <Grid item xs={12} sm={6} md={4} key={idx}>
                                <Box className={classes.factorBox}>
                                    <Typography
                                        className={classes.questionNumber}
                                        align="right"
                                    >
                                        {breakpointSm
                                            ? `${idx + 1} / ${
                                                  surveyFactors.length
                                              }`
                                            : `${idx + 1}`}
                                    </Typography>
                                    <Box className={classes.factorText}>
                                        <Box mr={2}>
                                            <FactorIcon {...factor} />
                                        </Box>
                                        <Typography
                                            variant="h6"
                                            color="textSecondary"
                                        >
                                            {factor.title}
                                        </Typography>
                                    </Box>
                                    <ToggleButtonGroup
                                        className={classes.factorButtons}
                                        exclusive
                                        size="small"
                                        value={values[factor.id]}
                                        onChange={(_e, value) => {
                                            if (value !== null) {
                                                setFieldValue(factor.id, value);
                                            }
                                        }}
                                    >
                                        <ToggleButton
                                            value="improvable"
                                            className={classes.toggleButton}
                                        >
                                            {filterTranslates(
                                                'improveHTML',
                                                t('improve-html')
                                            )}
                                        </ToggleButton>
                                        <ToggleButton
                                            value="neutral"
                                            className={classes.toggleButton}
                                        >
                                            {filterTranslates(
                                                'neutralHTML',
                                                t('neutral-html')
                                            )}
                                        </ToggleButton>
                                        <ToggleButton
                                            value="great"
                                            className={classes.toggleButton}
                                        >
                                            {filterTranslates(
                                                'greatHTML',
                                                t('great-html')
                                            )}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Container>
        </Box>
    );
};
