import {
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Theme,
} from '@material-ui/core';
import { EntityListHeaderTemplate } from 'core/components/shared/EntityListHeaderTemplate';
import React, { FC, useContext, useEffect } from 'react';
import { EntityListItemCheckbox } from '../../../core/components/EntityListItemCheckbox';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { ListHeader } from '../../../core/components/shared/ListHeader';
import { ListItemContainer } from '../../../core/components/shared/ListItemContainer';
import { defaultOrderByProperty } from '../../../core/constants/default-order-properties';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { useCurrentPage } from '../../../core/hooks/current-page.hook';
import { IParticipant } from '../models/participant.model';
import { participantsQuery } from '../state/participants.query';
import { ParticipantDeleteDialog } from './ParticipantDeleteDialog';

const useStyles = makeStyles((theme: Theme) => ({
    tableBackground: {
        backgroundColor: '#fff',
    },
    actionWrapper: {
        minWidth: 0,
        padding: theme.spacing(1),
    },
}));

interface SelectParticipantsListProps {
    entities: IParticipant[];
    showFirstColumn?: boolean;
}

export const SelectParticipantsListContainer: FC<SelectParticipantsListProps> = ({
    entities,
    showFirstColumn,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();
    const { setOrderBy } = useCurrentPage();

    useEffect(() => {
        setOrderBy(defaultOrderByProperty, 'desc');
    }, []);

    return (
        <>
            <TableContainer
                component={Paper}
                variant="outlined"
                className={classes.tableBackground}
            >
                <Table>
                    <TableHead>
                        <ListHeader
                            renderFirstColumn={
                                !showFirstColumn ? undefined : () => <span />
                            }
                        >
                            <EntityListHeaderTemplate
                                name={{ label: t('name') }}
                                email={{ label: t('email') }}
                                department={{ label: t('department') }}
                            />
                        </ListHeader>
                    </TableHead>
                    <TableBody>
                        {entities.map(({ id, name, email, department }) => (
                            <ListItemContainer
                                key={id}
                                entityId={id}
                                enableHover={true}
                                renderFirstColumn={
                                    !showFirstColumn
                                        ? undefined
                                        : (entityId) => (
                                              <EntityListItemCheckbox
                                                  entityId={entityId}
                                              />
                                          )
                                }
                            >
                                <TableCell>{name}</TableCell>
                                <TableCell>{email}</TableCell>
                                <TableCell>{department}</TableCell>
                            </ListItemContainer>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <ParticipantDeleteDialog />
        </>
    );
};

export const SelectParticipantsList = WithProvider(
    SelectParticipantsListContainer,
    participantsQuery
);
