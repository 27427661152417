import { IconName } from '@fortawesome/fontawesome-svg-core';
import { IEntity } from '../../../core/interfaces/entity.interface';
import {
    createLanguageFromResponse,
    emptyLanguage,
    ILanguage,
    ILanguageResponse,
} from '../../languages/models/language.model';

export interface ISuccessFactorResponseDetails {
    mandatory: boolean;
    use_as_default: boolean;
    organization: IEntity<number> | null;
    factor: IFactorResponse;
}

export interface ISuccessFactorDetails {
    mandatory: boolean;
    useAsDefault: boolean;
    organizationId: string | null;
    languageId?: string;
    factor: IFactor;
}
export interface ISuccessFactor extends ISuccessFactorDetails, IEntity {}

export interface ISuccessFactorResponse
    extends ISuccessFactorResponseDetails,
        IEntity<number> {}

export interface IFactorResponseDetails {
    title: string;
    font_awesome_icon: string;
    archived: boolean;
    organization: IEntity<number> | null;
    translations: ITranslationResponse[];
}

export interface IFactorDetails {
    title: string;
    fontAwesomeIcon: IconName | null;
    archived: boolean;
    organizationId: string | null;
    translations: ITranslation[];
}

export interface IFactorResponse
    extends IFactorResponseDetails,
        IEntity<number> {}

export interface ITranslationResponseDetails {
    title: string | null;
    language: ILanguageResponse;
}

export interface ITranslationDetails {
    title: string;
    language: ILanguage;
}

export interface ITranslation extends ITranslationDetails, IEntity {}

export interface IFactor extends IFactorDetails, IEntity {}

export interface ITranslationResponse
    extends ITranslationResponseDetails,
        IEntity<number> {}

export const emptyFactor: IFactorDetails = {
    title: '',
    fontAwesomeIcon: null,
    archived: false,
    translations: [],
    organizationId: null,
};

export const createFactor = ({ id, ...props }: Partial<IFactor>): IFactor => {
    if (!id) {
        throw new Error('Unable to create a factor without an ID!');
    }

    return {
        id,
        ...emptyFactor,
        ...props,
    };
};

export const createFactorFromResponse = ({
    id,
    font_awesome_icon,
    organization,
    translations,
    ...props
}: IFactorResponse): IFactor => {
    const organizationId = organization?.id.toString() ?? null;

    const fontAwesomeIcon = !font_awesome_icon
        ? null
        : ((font_awesome_icon.match(/^fa.{1}-.*/m)
              ? font_awesome_icon
              : `far-${font_awesome_icon}`) as IconName); // temporary fix until we fix all DB values

    return createFactor({
        id: id.toString(),
        organizationId,
        fontAwesomeIcon,
        translations: translations?.map(createTranslationFromReponse) ?? [],
        ...props,
    });
};

export const emptyTranslation: ITranslationDetails = {
    title: '',
    language: { id: '', ...emptyLanguage },
};

export const createTranslation = ({
    id,
    ...props
}: Partial<ITranslation>): ITranslation => {
    if (!id) {
        throw new Error('Unable to create a translation without an ID!');
    }

    return {
        id,
        ...emptyTranslation,
        ...props,
    };
};

export const createTranslationFromReponse = ({
    id,
    title,
    language,
}: ITranslationResponse): ITranslation => {
    return createTranslation({
        id: id.toString(),
        language: createLanguageFromResponse(language),
        title: title ?? emptyTranslation.title,
    });
};

export const emptySuccessFactor: ISuccessFactorDetails = {
    factor: { id: '', ...emptyFactor },
    useAsDefault: false,
    mandatory: false,
    organizationId: null,
};

export const createSuccessFactor = ({
    id,
    ...props
}: Partial<ISuccessFactor>): ISuccessFactor => {
    if (!id) {
        throw new Error('Unable to create a success factor without an ID!');
    }

    return {
        id,
        ...emptySuccessFactor,
        ...props,
    };
};

export const createSuccessFactorFromResponse = ({
    id,
    use_as_default,
    factor,
    organization,
    ...props
}: ISuccessFactorResponse): ISuccessFactor => {
    const organizationId = organization?.id.toString() ?? null;

    return createSuccessFactor({
        id: id.toString(),
        useAsDefault: use_as_default,
        factor: createFactorFromResponse(factor),
        organizationId,
        ...props,
    });
};

interface IFactorCommand
    extends Omit<IFactorResponseDetails, 'translations' | 'organization'> {
    organization: IEntity<number | null>;
}

export interface ISuccessFactorCommand
    extends Omit<ISuccessFactorResponseDetails, 'organization' | 'factor'> {
    organization: IEntity<number | null>;
    factor: IFactorCommand;
}

export const createSuccessFactorCommand = ({
    useAsDefault,
    factor,
    organizationId,
    ...props
}: ISuccessFactorDetails): ISuccessFactorCommand => {
    const {
        title,
        archived,
        fontAwesomeIcon,
        organizationId: factorOrganizationId,
    } = factor;
    const organization = { id: organizationId ? +organizationId : null }; // id === null if super admin page, else account settgings page

    return {
        use_as_default: useAsDefault,
        organization,
        factor: {
            title,
            archived,
            font_awesome_icon: fontAwesomeIcon ?? '',
            organization: {
                id: factorOrganizationId ? +factorOrganizationId : null,
            }, // id === null if system, else account
        },
        ...props,
    };
};
