import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ICrumb } from 'core/components/shared/topBars/BreadcrumbsContainer';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import React, { FC, useContext } from 'react';
import {
    IPageTabsPropsItem,
    PageTabs,
} from '../../core/components/mock/tabs/PageTabs';
import { PageTitleBar } from '../../core/components/shared/topBars/PageTitleBar';
import { PortfolioDashboardPage } from '../dashboard/pages/PortfolioDashboardPage';

const items: IPageTabsPropsItem[] = [
    {
        headerLabel: 'overview',
        table: <PortfolioDashboardPage />,
    },
];

export const AnalyticsPage: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);

    const pageCrumbs: ICrumb[] = [
        { label: t('analytics'), link: ROUTES.ANALYTICS },
    ];
    return (
        <>
            <PageTitleBar
                title={t('portfolio')}
                icon={<SettingsApplicationsIcon />}
                crumbs={pageCrumbs}
            />
            <PageTabs items={items} />
        </>
    );
};
