import { Snackbar, Typography } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import { makeStyles } from '@material-ui/core/styles';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import React, { FC, useCallback, useContext, useState } from 'react';
import Dropzone from 'react-dropzone';
import { ImageUploadDropzoneState } from './ImageUploadDropzoneState';
import MuiAlert from '@material-ui/lab/Alert';

export enum SEVERITY {
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
}

interface IImageUploadProps {
    fileBinaryString: string;
    setFileBinaryString: (data: string) => void;
}

const useStyles = makeStyles(() => ({
    w160: {
        width: '160px',
    },
    icon: {
        fontSize: 16,
    },
}));

export const ImageUpload: FC<IImageUploadProps> = ({
    fileBinaryString,
    setFileBinaryString,
}) => {
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('');

    function Alert(props: any) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const validator = (file: File) => {
        const image = new Image();
        image.src = window.URL.createObjectURL(file);

        image.onload = () => {
            const height = image.height;
            const width = image.width;

            if (height > 128 || width > 512) {
                setMessage('Height must be up to 128px and Width must be up to 512px');
                handleOpen();
                setTimeout(() => {
                    handleClose();
                }, 3000);
                setSeverity(SEVERITY.ERROR);
                return false;
            }

            setMessage('File is uploaded!');
            handleOpen();
            setTimeout(() => {
                handleClose();
            }, 3000);
            setSeverity(SEVERITY.SUCCESS);

            return true;
        };

        return null;
    };

    const loadFiles = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.map((file) => {
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                setFileBinaryString(reader.result as string);
            };
            reader.readAsDataURL(file);
        });
    }, []);

    const handleClose = () => setOpen(false);

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <Dropzone
                onDrop={loadFiles}
                maxFiles={1}
                maxSize={125000}
                accept="image/*"
                validator={validator}
                onDropRejected={() => {
                    setMessage('Fle upload is rejected');
                    handleOpen();
                }}
                onDropAccepted={() => {
                    setMessage('Fle upload is accepted');
                    handleOpen();
                }}
            >
                {(props) => (
                    <ImageUploadDropzoneState {...props}>
                        {fileBinaryString ? (
                            <img
                                className={classes.w160}
                                src={fileBinaryString}
                            />
                        ) : (
                            <>
                                <Typography align="center" variant="body2">
                                    {t('dnd-text')}
                                </Typography>
                                <BackupIcon className={classes.icon} />
                                <Typography
                                    align="center"
                                    className="MuiTypography-badge"
                                >
                                    {t('file-type')}
                                </Typography>
                                <Typography
                                    align="center"
                                    className="MuiTypography-badge"
                                >
                                    {t('max-height')}
                                </Typography>
                            </>
                        )}
                    </ImageUploadDropzoneState>
                )}
            </Dropzone>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};
