import { Checkbox, CheckboxStatus } from '@qagency/react-shared-library';
import { FC, useEffect, useState } from 'react';
import { useActive } from '../hooks/active.hook';

export interface IEntityListItemCheckboxProps {
    entityId: string;
}

const getCheckboxStatusFromBoolean = (value: boolean) =>
    value ? CheckboxStatus.CHECKED : CheckboxStatus.EMPTY;

export const EntityListItemCheckbox: FC<IEntityListItemCheckboxProps> = ({
    entityId,
}) => {
    const { toggleActive, hasActive } = useActive();

    const [status, setStatus] = useState(
        getCheckboxStatusFromBoolean(hasActive(entityId))
    );

    useEffect(() => {
        setStatus(getCheckboxStatusFromBoolean(hasActive(entityId)));
    });

    return <Checkbox status={status} onClick={() => toggleActive(entityId)} />;
};
