import { TableCell } from '@material-ui/core';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import React, { FC, useContext } from 'react';
import { IEntityListItemProps } from '../../../core/interfaces/entity-list-item-props.interface';
import { useAffiliationTranslation } from '../hooks';
import { IParticipant } from '../models/participant.model';

export const ParticipantsListItem: FC<IEntityListItemProps<IParticipant>> = ({
    entity: {
        name,
        email,
        department,
        defaultAffiliation,
        organizationName,
        defaultRole,
    },
}) => {
    const { t } = useContext(MultiLanguageSupportContext);

    const { getAffiliationTranslation } = useAffiliationTranslation(
        organizationName
    );

    return (
        <>
            <TableCell>{name}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{department}</TableCell>
            <TableCell>
                {getAffiliationTranslation(defaultAffiliation)}
            </TableCell>
            <TableCell>{t(defaultRole)}</TableCell>
        </>
    );
};
