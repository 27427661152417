import { StoreConfig } from '@datorama/akita';
import { EntitiesStore } from '../../../core/state/entities.store';
import {
    createParticipantFromResponse,
    IParticipant,
    IParticipantResponse,
} from '../models/participant.model';

@StoreConfig({
    name: 'respondents',
    resettable: true,
})
class ParticipantsStore extends EntitiesStore<
    IParticipant,
    IParticipantResponse
> {}

export const participantsStore = new ParticipantsStore({
    responseMapper: createParticipantFromResponse,
});
