import {
    Box,
    Button,
    Chip,
    Grid,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import EditIcon from '@material-ui/icons/Edit';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ProjectDialogForm } from 'modules/projects/components/ProjectDialogForm';
import { ProjectDialogContext } from 'modules/projects/components/ProjectDialogProvider';
import { IProject } from 'modules/projects/models/project.model';
import React, { FC, useContext, useState } from 'react';
import { FormDialogWrapper } from '../FormDialogWrapper';
import { BreadcrumbsContainer, ICrumb } from './BreadcrumbsContainer';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderTop: '1px solid #DFE1E6',
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    icon: {
        fontSize: '40px',
        marginRight: theme.spacing(2),
        width: '40px',
        height: '40px',
    },
    buttonWrap: {
        '& button': {
            marginLeft: theme.spacing(1.5),
        },
        '& > :first-child': {
            marginLeft: '0',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'end',
        },
    },
    buttonSpacing: {
        marginLeft: theme.spacing(2),
        height: '35px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(2),
            fontSize: '12px',
        },
    },
}));
export interface PageTitleBarButton {
    label?: string;
    icon?: React.ReactElement<SvgIconProps>;
    callback?: () => void;
    color?: 'primary' | 'secondary' | 'default';
    component?: JSX.Element;
}

interface IPageTitleBarProps {
    title: string;
    noTabs?: boolean;
    icon?: JSX.Element;
    buttons?: PageTitleBarButton[];
    crumbs?: ICrumb[];
    component?: JSX.Element;
    isActive?: boolean;
    project: IProject;
}

export const ProjectPageTitleBar: FC<IPageTitleBarProps> = ({
    title,
    noTabs,
    icon,
    buttons,
    crumbs = [],
    isActive,
    project,
}) => {
    const classes = useStyles();
    const { open, isOpen, close } = useContext(ProjectDialogContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useContext(MultiLanguageSupportContext);

    const iconElWithClass = icon
        ? React.cloneElement(icon, {
              className: classes.icon,
          })
        : '';
    const [displayEdit, setDisplayEdit] = useState(false);

    return (
        <div
            className={classes.container}
            style={{ borderBottom: noTabs ? '1px solid #DFE1E6' : 'none' }}
        >
            <Grid container className={classes.flex}>
                <Grid item md={6}>
                    <Box
                        style={{ width: 'fit-content' }}
                        onMouseOver={() => setDisplayEdit(true)}
                        onMouseLeave={() => setDisplayEdit(false)}
                    >
                        <Box mb={2}>
                            <BreadcrumbsContainer crumbs={crumbs} />
                        </Box>

                        <Box
                            className={classes.flex}
                            style={{
                                justifyContent: 'flex-start',
                            }}
                        >
                            {iconElWithClass}
                            <Typography variant="h2">
                                <div>{title}</div>
                            </Typography>
                            {isActive === true && (
                                <Chip
                                    label="Active"
                                    style={{
                                        backgroundColor: '#35B07C',
                                        color: 'white',
                                        textTransform: 'uppercase',
                                    }}
                                />
                            )}
                            {isActive === false && (
                                <Chip
                                    label="Inactive"
                                    style={{
                                        backgroundColor: '#C1C7D0',
                                        color: 'white',
                                        textTransform: 'uppercase',
                                    }}
                                />
                            )}
                            {displayEdit && (
                                <Tooltip title={t('tooltip-edit')}>
                                    <Button
                                        style={{
                                            display: 'block',
                                        }}
                                        disableElevation
                                        onClick={() => {
                                            open(project);
                                        }}
                                    >
                                        <EditIcon
                                            style={{
                                                color: '#5C36B0',
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={6}>
                    <Box
                        display="flex"
                        justifyContent={isMobile ? 'flex-start' : 'flex-end'}
                    >
                        {buttons &&
                            buttons.map(
                                (
                                    {
                                        label,
                                        icon,
                                        callback,
                                        color = 'primary',
                                        component,
                                    },
                                    index
                                ) =>
                                    component ? (
                                        <Box
                                            className={classes.buttonSpacing}
                                            key={index}
                                        >
                                            {component}
                                        </Box>
                                    ) : (
                                        <Button
                                            className={classes.buttonSpacing}
                                            key={index}
                                            type="submit"
                                            variant="contained"
                                            color={color}
                                            onClick={callback}
                                            disableElevation
                                            style={{ height: '47px' }}
                                        >
                                            {icon}
                                            {label}
                                        </Button>
                                    )
                            )}
                    </Box>
                </Grid>
            </Grid>
            <FormDialogWrapper isOpen={isOpen} handleClose={close}>
                <Typography variant="h1" align="center">
                    {t('edit-project')}
                </Typography>
                <ProjectDialogForm />
            </FormDialogWrapper>
        </div>
    );
};
