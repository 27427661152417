// polyfills
import 'core-js';

import {
    akitaDevtools,
    persistState,
    PersistStateParams,
} from '@datorama/akita';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { MultiLanguageSupportProvider } from 'core/components/MultiLanguageSupportProvider';
import { GeneralDialogProvider } from 'core/components/shared/GeneralDialog/GeneralDialogProvider';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import theme from 'styles/material-ui/theme';
import { App } from './App';
import './core/utils/multi-language-support';
import './index.css';
import { reportWebVitals } from './reportWebVitals';
import { IconPickerProvider } from './core/components/IconPickerProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const persistStateParams: Partial<PersistStateParams> = {
    include: ['session'],
};

if (process.env.NODE_ENV !== 'production') {
    akitaDevtools();
} else {
    // persistStateParams.preStorageUpdateOperator = () => debounceTime(2000);
}

persistState(persistStateParams);

/**
 * Issue: https://www.gitmemory.com/issue/datorama/akita/634/789712235
 *
 * workaround is to define the resettable flag at store declaration via decorator
 */
// akitaConfig({ resettable: true });

/**
 * We need to wrap app with Suspense because of the translation support,
 * as it loads translation files asynchronously
 */

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={null}>
            <IconPickerProvider>
                <MultiLanguageSupportProvider>
                    <ThemeProvider theme={theme}>
                        <GeneralDialogProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <CssBaseline />
                                <App />
                            </MuiPickersUtilsProvider>
                        </GeneralDialogProvider>
                    </ThemeProvider>
                </MultiLanguageSupportProvider>
            </IconPickerProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
