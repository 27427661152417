import { IEntity } from 'core/interfaces/entity.interface';
import { FormikProvider, useFormik } from 'formik';
import React, {
    createContext,
    FC,
    useCallback,
    useContext,
    useState,
} from 'react';
import * as Yup from 'yup';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { useCrud } from '../../../core/hooks/crud.hook';
import { requiredField } from '../../../core/validations';
import {
    emptyCategory,
    ICategory,
    ICategoryDetails,
} from '../models/category.model';
import { categoriesQuery } from '../state/categories.query';
import { DimensionsContext } from './DimensionsProvider';

export interface ICategoryFormik extends ICategoryDetails {
    dimensionName: string;
}

interface ICategoryFormContext {
    categoryId: string | null;
    isOpen: boolean;
    close: () => void;
    open: (category: Partial<IEntity> & Partial<ICategoryFormik>) => void;
}

export const CategoryFormContext = createContext<ICategoryFormContext>(
    {} as ICategoryFormContext
);

const validationSchema = Yup.object().shape({
    name: requiredField,
});

const emptyFormikCategory: ICategoryFormik = {
    ...emptyCategory,
    dimensionName: '',
};

export const CategoryFormProvider: FC = WithProvider(({ children }) => {
    const { fetchDimensions } = useContext(DimensionsContext);
    const [isOpen, setIsOpen] = useState(false);
    const [categoryId, setCategoryId] = useState<string | null>(null);
    const [initialValues, setInitialValues] = useState({
        ...emptyFormikCategory,
    });

    const close = () => {
        setIsOpen(false);
        setCategoryId(null);
        setInitialValues({ ...emptyFormikCategory });
    };

    const open = ({
        id,
        ...category
    }: Partial<IEntity> & Partial<ICategoryFormik>) => {
        setIsOpen(true);
        setCategoryId(id ?? null);
        setInitialValues({ ...emptyFormikCategory, ...category });
    };

    const { createSingle, updateSingle } = useCrud<ICategory>();

    const onSubmit = useCallback(
        async (data: ICategoryFormik) => {
            const category = categoryId
                ? await updateSingle({ id: categoryId, ...data })
                : await createSingle(data);

            fetchDimensions();

            if (category) {
                close();
            }

            return category;
        },
        [categoryId]
    );

    const formik = useFormik<ICategoryFormik>({
        initialValues,
        enableReinitialize: true,
        validateOnMount: !!categoryId,
        onSubmit,
        validationSchema,
    });

    return (
        <CategoryFormContext.Provider
            value={{
                isOpen,
                close,
                open,
                categoryId,
            }}
        >
            <FormikProvider value={formik}>{children}</FormikProvider>
        </CategoryFormContext.Provider>
    );
}, categoriesQuery);
