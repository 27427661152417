import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useSession } from 'core/hooks/session.hook';
import React, { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import css from 'styles/material-ui/cssVars';
import { ROUTES } from '../../../../modules/navigation/enums/routes.enum';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 'fit-content',
        },
        list: {
            display: 'flex',
            justifyContent: 'center',
            listStyle: 'none',
            backgroundColor: css.extraColors.text.darkPrimary,
            padding: theme.spacing(0.5),
            borderRadius: '96px',
        },
        chip: {
            cursor: 'pointer',
            backgroundColor: 'transparent',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '16px',
            letterSpacing: '0.01em',
            textTransform: 'uppercase',
            textDecoration: 'none',
            color: theme.palette.text.primary,
            display: 'block',
            borderRadius: '64px',
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
            },
            '&:hover': {
                color: theme.palette.primary.light,
                backgroundColor: 'transparent',
            },
            '&.active': {
                color: theme.palette.common.white,
                backgroundColor: css.extraColors.background.darkDefault,
            },
            '&.active:hover': {
                backgroundColor: theme.palette.primary.light,
                cursor: 'default',
            },
        },
    })
);

const data = [
    { label: 'Projects', route: ROUTES.PROJECTS },
    { label: 'Analytics', route: ROUTES.ANALYTICS },
    { label: 'Account Settings', route: ROUTES.ACCOUNT_SETTINGS },
    { label: 'Super Admin', route: ROUTES.SUPER_ADMIN },
];

export const NavChips: FC = () => {
    const classes = useStyles();
    const { abilities } = useSession();

    const chipData = useMemo(
        () => data.filter(({ route }) => abilities.can('navigateTo', route)),
        [abilities]
    );

    return (
        <div className={classes.root}>
            <Box component="ul" className={classes.list}>
                {chipData.map(({ label, route }) => {
                    return (
                        <li key={route}>
                            <NavLink
                                to={route}
                                className={classes.chip}
                                color="primary"
                                activeClassName="active"
                            >
                                {label}
                            </NavLink>
                        </li>
                    );
                })}
            </Box>
        </div>
    );
};
