import { PulseResultsAveragesProps } from '../components/Graphs/PulseResultsAverages';
import {
    IGraphData,
    PulseDistributionGraphRanges,
} from '../models/dashboard.models';
import { getPulseResultsAvgOptions } from './getPulseResultsAvgOptions';

interface ISumAndCount {
    sum: number;
    count: number;
}

type PulseResultsAveragesRanges = '>4,5' | '>3,5' | '>2,5' | '>1,5' | '0-1,5';

type Include = 'lower' | 'upper' | 'none';

const isLimitIncludedBuilder = (
    lowerLimit: number,
    upperLimit: number,
    avg: number
) => (include: Include) => {
    switch (include) {
        case 'lower':
            return lowerLimit === avg;
        case 'upper':
            return upperLimit === avg;
        default:
            return false;
    }
};

const averageMapperBuilder = (sumsAndCounts: Map<string, ISumAndCount>) => (
    lowerLimit: number,
    upperLimit: number,
    include: Include | Omit<Include, 'none'>[] = 'none'
) => (name: string) => {
    const item = sumsAndCounts.get(name);

    if (item) {
        const { sum, count } = item;

        const avg = sum / count;

        const isLimitIncluded = isLimitIncludedBuilder(
            lowerLimit,
            upperLimit,
            avg
        );

        const includesLimit = (Array.isArray(include)
            ? (include as Include[])
            : [include]
        )
            .map(isLimitIncluded)
            .reduce((result, value) => result || value, false);

        if (includesLimit || (avg > lowerLimit && avg < upperLimit)) {
            return avg.toFixed(1);
        }
    }

    return null;
};

export const getPulseResultsAveragesProps = (
    categories: string[],
    pulseDistributionMap = new Map<
        string,
        Record<PulseDistributionGraphRanges, number[]>
    >()
): PulseResultsAveragesProps => {
    const sumsAndCounts = Array.from(pulseDistributionMap.entries()).reduce(
        (map, [pulseName, pulseDistributionData]) => {
            map.set(
                pulseName,
                Object.values(pulseDistributionData).reduce(
                    ({ sum, count }, rangeValues) => {
                        const values = rangeValues.filter(
                            (restValue) => restValue !== 0
                        );
                        sum += values.reduce(
                            (tempSum, value) => tempSum + value,
                            0
                        );

                        count += values.length;

                        return {
                            sum,
                            count,
                        };
                    },
                    { sum: 0, count: 0 }
                )
            );

            return map;
        },
        new Map<string, ISumAndCount>()
    );

    const averageMapper = averageMapperBuilder(sumsAndCounts);

    const data: IGraphData<string | null, PulseResultsAveragesRanges>[] = [
        {
            name: '>4,5',
            data: categories?.map(averageMapper(4.5, 5, 'upper')),
        },
        {
            name: '>3,5',
            data: categories?.map(averageMapper(3.5, 4.5, 'upper')),
        },
        {
            name: '>2,5',
            data: categories?.map(averageMapper(2.5, 3.5, 'upper')),
        },
        {
            name: '>1,5',
            data: categories?.map(averageMapper(1.5, 2.5, 'upper')),
        },
        {
            name: '0-1,5',
            data: categories?.map(averageMapper(0, 1.5, ['lower', 'upper'])),
        },
    ];

    return {
        data,
        options: getPulseResultsAvgOptions({
            xaxis: { categories },
        }),
    };
};
