import { Button, Tooltip } from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { usePagination } from 'core/hooks/pagination.hook';
import { pulseParticipantsStore } from 'modules/pulse-participants/state/pulse-participants.store';
import React, { FC, useContext, useState } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { FormDialogWrapper } from '../../../core/components/shared/FormDialogWrapper';
import { AlertModal } from './AlertModal';
import { pulseStore } from 'modules/pulse/state/pulse.store';

interface ISendPulseProps {
    handleOpen: () => void;
    handleClose: () => void;
    handleSubmit: () => Promise<void>;
    isOpen: boolean;
    isIcon?: boolean;
    hideButton: boolean;
    getProjectData: () => void;
}

export const SendPulse: FC<ISendPulseProps> = ({
    handleClose,
    handleOpen,
    handleSubmit,
    isOpen,
    isIcon,
    hideButton,
    getProjectData,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { currentPageNumber, currentPageSize } = usePagination();

    const senPulse = async () => {
        await handleSubmit();
        await handleClose();
        await getProjectData();
        await pulseStore.fetchEntities();
    };

    return (
        <>
            {!hideButton && (
                <>
                    {isIcon && (
                        <Tooltip title={t('tooltip-send')}>
                            <PlayCircleFilledIcon
                                color="primary"
                                onClick={handleOpen}
                            />
                        </Tooltip>
                    )}
                    {!isIcon && (
                        <Tooltip title={t('tooltip-send')}>
                            <Button
                                onClick={handleOpen}
                                variant="contained"
                                disableElevation
                                color="primary"
                            >
                                <PlayCircleFilledIcon />
                                {t('send-pulse-now')}
                            </Button>
                        </Tooltip>
                    )}
                </>
            )}
            <FormDialogWrapper
                isOpen={isOpen}
                handleClose={handleClose}
                alertModal
            >
                <AlertModal
                    title={'Are you sure you want to send this pulse now?'} // TODO: Translations
                    contentText={
                        'Send pulse immediately to all current participants within this pulse. Sending pulse will not affect the current settings of this pulse.' // TODO: Translations
                    }
                    handleClose={handleClose}
                    handleSubmit={senPulse}
                    submitModalText="Send pulse now" // TODO: Translations
                    closeModalText="Don’t send" // TODO: Translations
                />
            </FormDialogWrapper>
        </>
    );
};
