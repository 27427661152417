import { subject } from '@casl/ability';
import {
    Box,
    Button,
    Container,
    DialogContent,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { UserRoleRadioButtons } from 'core/components/shared/UserRoleRadioButtons';
import { useCrud } from 'core/hooks/crud.hook';
import { useSession } from 'core/hooks/session.hook';
import { Form, useFormikContext } from 'formik';
import { AccountAutocomplete } from 'modules/accounts/components/AccountAutocomplete';
import { DimensionsProvider } from 'modules/categories/components/DimensionsProvider';
import { DimenstionInputs } from 'modules/categories/components/DimenstionInputs';
import { Can } from 'modules/login/components/Can';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import css from 'styles/material-ui/cssVars';
import { IAutocompleteDefaultOption } from '../../../core/components/ImplementPulseAutoComplete';
import { IUser, USER_ROLE } from '../models/user.model';
import { IUserFormik, UserFormContext } from './UserFormProvider';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
    },
    selectWrap: {
        position: 'relative',
    },
    modalTitle: {
        padding: `${theme.spacing(6)}px 0`,
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(3)}px 0`,
        },
    },
    modalContent: {
        padding: 0,
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        backgroundColor: css.extraColors.text.darkPrimary,
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        },
    },
    inputsWrapper: {
        overflowY: 'auto',
    },
    buttonWrap: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            marginTop: `${theme.spacing(2)}px`,
        },
        '& button': {
            '&:last-child': {
                marginLeft: theme.spacing(2),

                [theme.breakpoints.down('xs')]: {
                    marginLeft: 0,
                    marginTop: theme.spacing(2),
                },
            },

            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
    },
    accSelect: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
}));

export const UserForm: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();
    const { me, abilities } = useSession();
    const { close, open, userId } = useContext(UserFormContext);
    const [isDeleting, setIsDeleting] = useState(false);

    const { fetchById } = useCrud<IUser>();

    useEffect(() => {
        userId && fetchById(userId).then((user) => user && open(user));
    }, [userId]);

    const {
        values,
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        submitForm,
        dirty,
        isValid,
        setFieldValue,
    } = useFormikContext<IUserFormik>();

    const { deleteMultiple } = useCrud();

    const onDelete = useCallback(async () => {
        if (userId) {
            setIsDeleting(true);
            await deleteMultiple([userId], { shouldFetchAfterSuccess: true });
            setIsDeleting(false);
            close();
        }
    }, [deleteMultiple, userId, close]);

    const selectedAccount: IAutocompleteDefaultOption | null = useMemo(
        () =>
            !values.organizationId
                ? null
                : {
                      id: values.organizationId,
                      name: values.organizationName,
                  },
        [values.organizationId, values.organizationName]
    );

    return (
        <>
            <DialogContent className={classes.modalContent}>
                <Form className={classes.form}>
                    <div className={classes.inputsWrapper}>
                        <Container maxWidth="md">
                            <Typography
                                variant="h2"
                                align="center"
                                className={classes.modalTitle}
                            >
                                {userId ? t('edit-user') : t('create-user')}
                            </Typography>
                            <TextField
                                autoFocus
                                label={t('fullname-placeholder')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="name"
                                type="text"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                error={!!errors.name}
                                disabled={
                                    !!userId &&
                                    abilities.cannot(
                                        'update',
                                        subject('IUser', {
                                            id: userId,
                                        }),
                                        'name'
                                    )
                                }
                                helperText={t('enter-fullname-placeholder')}
                            />
                            <TextField
                                label={t('email-placeholder')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                type="text"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={!!errors.email}
                                disabled={
                                    !!userId &&
                                    abilities.cannot(
                                        'update',
                                        subject('IUser', {
                                            id: userId,
                                        }),
                                        'email'
                                    )
                                }
                                helperText={t('enter-email-placeholder')}
                            />
                            <Box mb={2} />
                            <Box mb={6}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        className={classes.accSelect}
                                    >
                                        <AccountAutocomplete
                                            label={t('account')}
                                            onSelect={async (item) => {
                                                setFieldValue(
                                                    'organizationId',
                                                    item?.id ?? null
                                                );
                                                setFieldValue(
                                                    'organizationName',
                                                    item?.name ?? ''
                                                );
                                            }}
                                            value={selectedAccount}
                                            disabled={abilities.cannot(
                                                'update',
                                                subject('IUser', {
                                                    id: userId,
                                                }),
                                                'organizationId'
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mb={6}>
                                <UserRoleRadioButtons
                                    onChangeHandler={(value) =>
                                        setFieldValue('role', value)
                                    }
                                    radioButtonValue={values.role}
                                    disabled={
                                        !!userId &&
                                        abilities.cannot(
                                            'update',
                                            subject('IUser', {
                                                id: userId,
                                            }),
                                            'role'
                                        )
                                    }
                                />
                            </Box>
                            {values.role === USER_ROLE.PORTFOLIO_ADMIN &&
                                (me?.organizationId !==
                                values.organizationId ? (
                                    <span>
                                        You are unable to pick categories since
                                        you are from a different account.
                                    </span> // TODO: is this a realistic situation? if yes, add translation or better content
                                ) : (
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                {t('specify-category')}
                                            </Typography>
                                            <Typography
                                                className="MuiTypography-badge"
                                                color="textSecondary"
                                            >
                                                {t('user-only-see')}
                                            </Typography>
                                        </Grid>
                                        <DimensionsProvider>
                                            <DimenstionInputs
                                                onChange={async (
                                                    dimension,
                                                    category
                                                ) =>
                                                    setFieldValue(
                                                        `categoriesByDimensionId.${dimension.id}`,
                                                        category
                                                    )
                                                }
                                                emptyName={t('all')}
                                                categoriesByDimensionId={
                                                    values.categoriesByDimensionId
                                                }
                                                disabled={abilities.cannot(
                                                    'update',
                                                    'IUser',
                                                    'categories'
                                                )}
                                            />
                                        </DimensionsProvider>
                                    </Grid>
                                ))}
                        </Container>
                    </div>
                    <Box className={classes.modalActions}>
                        <Button type="reset" variant="text" onClick={close}>
                            {t('cancel')}
                        </Button>

                        <div className={classes.buttonWrap}>
                            {userId ? (
                                <Can I="delete" a="IUser">
                                    <Button
                                        style={{
                                            border: '1px solid red',
                                            color: 'red',
                                        }}
                                        disabled={isDeleting || isSubmitting}
                                        variant="outlined"
                                        disableElevation
                                        onClick={onDelete}
                                    >
                                        {t('delete-user')}
                                    </Button>
                                </Can>
                            ) : (
                                <Button
                                    disabled={
                                        isDeleting ||
                                        isSubmitting ||
                                        !dirty ||
                                        !isValid
                                    }
                                    color="secondary"
                                    variant="contained"
                                    disableElevation
                                    onClick={() => {
                                        setFieldValue('openNewUser', true);
                                        submitForm();
                                    }}
                                >
                                    {t('create-new-and-add-another')}
                                </Button>
                            )}

                            <Button
                                disabled={
                                    isDeleting ||
                                    isSubmitting ||
                                    !dirty ||
                                    !isValid
                                }
                                color="primary"
                                variant="contained"
                                disableElevation
                                onClick={submitForm}
                            >
                                {userId ? t('save-changes') : t('create-user')}
                            </Button>
                        </div>
                    </Box>
                </Form>
            </DialogContent>
        </>
    );
};
