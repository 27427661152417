import { Box, makeStyles, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import React, { FC } from 'react';

const useStyles = makeStyles(() => ({
    warningIcon: {
        color: '#FF961A',
        marginRight: '5px',
    },
}));

interface ITranslateWarningProps {
    label: string;
}

export const TranslateWarning: FC<ITranslateWarningProps> = ({ label }) => {
    const classes = useStyles();

    return (
        <Box display="flex">
            <WarningIcon className={classes.warningIcon} />
            <Typography className={classes.warningIcon}>{label}</Typography>
        </Box>
    );
};
