import { Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LanguageIcon from '@material-ui/icons/Language';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ListActionIcon } from 'core/components/shared/ListActionIcon';
import React, { FC, useCallback, useContext } from 'react';
import { EntityListActions } from '../../../../core/components/EntityListActions';
import {
    IComponentWrappedWithEntityByIdProps,
    WithEntityById,
} from '../../../../core/hocs/WithEntityById';
import { IScaleQuestion } from '../../../scale-questions/models/scale-question.model';
import { QuestionsDialogContext } from './QuestionsDialogProvider';

export const ScaleQuestionsListActions: FC<
    IComponentWrappedWithEntityByIdProps<IScaleQuestion>
> = ({ entity: question }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { open } = useContext(QuestionsDialogContext);

    const getActions = useCallback(
        () => [
            {
                callback: () => open(question, 1),
                component: (
                    <ListActionIcon
                        iconComponent={
                            <Tooltip title={t('tooltip-translations')}>
                                <LanguageIcon />
                            </Tooltip>
                        }
                    />
                ),
            },
            {
                callback: () => {
                    open(question);
                },
                component: (
                    <ListActionIcon
                        iconComponent={
                            <Tooltip title={t('tooltip-edit')}>
                                <EditIcon />
                            </Tooltip>
                        }
                    />
                ),
            },
        ],
        [question]
    );

    return <EntityListActions actions={getActions()} />;
};

export const ScaleQuestionsListActionsById = WithEntityById(
    ScaleQuestionsListActions
);
