import {
    EntityFilters,
    IEntityTableFiltersProps,
} from 'core/components/EntityFilters';
import { FC } from 'react';

export enum SuccessFactorsListFilter {
    ALL = 'ALL',
    SYSTEM = 'SYSTEM',
    ACCOUNT = 'ACCOUNT',
}

type SuccessFactorsFiltersProps = Omit<
    IEntityTableFiltersProps<SuccessFactorsListFilter>,
    'options'
>;

export const SuccessFactorsFilters: FC<SuccessFactorsFiltersProps> = (
    props
) => (
    <EntityFilters
        {...props}
        options={[
            { label: 'All', type: SuccessFactorsListFilter.ALL },
            { label: 'System', type: SuccessFactorsListFilter.SYSTEM },
            { label: 'Account', type: SuccessFactorsListFilter.ACCOUNT },
        ]}
    />
);
