import { makeStyles, Theme } from '@material-ui/core';
import css from '../../../styles/material-ui/cssVars';

export const usePulseCardStyles = makeStyles((theme: Theme) => ({
    card: {
        border: `1px solid ${theme.palette.divider}`,
        boxShadow: 'none',
        '&:hover $action $button:nth-child(1)': {
            opacity: 1,
        },
    },
    header: {
        backgroundColor: css.extraColors.text.darkPrimary,
        padding: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },
    action: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        padding: theme.spacing(1),
        minWidth: theme.spacing(4),
        height: 'min-content',
    },
    info: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    span: {
        display: 'inline',
    },
    spanWithPadd: {
        display: 'inline',
        paddingRight: '4px',
    },
}));
