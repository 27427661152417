import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import React from 'react';

export interface ICrumb {
    label: string;
    link?: string;
}

interface BreadcrumbsContainerProps {
    crumbs: ICrumb[];
}
export function BreadcrumbsContainer({ crumbs }: BreadcrumbsContainerProps) {
    return (
        <Breadcrumbs aria-label="breadcrumb" separator={<ArrowRightIcon />}>
            {crumbs.map(({ link, label }: ICrumb, index: number) =>
                link ? (
                    <Link color="textPrimary" href={link} key={index}>
                        {label}
                    </Link>
                ) : (
                    <Typography color="primary" key={index}>
                        {label}
                    </Typography>
                )
            )}
        </Breadcrumbs>
    );
}
