import { FC } from 'react';
import { CategoriesTreeView } from '../components/CategoriesTree';
import { CategoryFormProvider } from '../components/CategoryFormProvider';
import { DimensionsProvider } from '../components/DimensionsProvider';

export const CategoriesPage: FC = () => (
    <DimensionsProvider>
        <CategoryFormProvider>
            <CategoriesTreeView></CategoriesTreeView>
        </CategoryFormProvider>
    </DimensionsProvider>
);
