import { useObservable } from '@mindspace-io/react';
import { useCallback, useContext } from 'react';
import { EntitiesContext } from '../constants/entities.context';

export interface IUseActive {
    hasActive: (id: string) => boolean;
    toggleActive: (id: string) => void;
}

export const useActive = (): IUseActive => {
    const { query, store } = useContext(EntitiesContext);
    const [active] = useObservable(query.selectActive(), query.getActive());

    const hasActive = useCallback(
        (id) => {
            return query.hasActive(id);
        },
        [active]
    );

    return {
        hasActive,
        toggleActive: (id: string) => {
            store.toggleActive(id);
        },
    };
};
