import { useObservable } from '@mindspace-io/react';
import { AxiosResponse } from 'axios';
import { primaryOrderDirection } from 'core/constants/default-order-properties';
import { IEntitiesContext } from 'core/constants/entities.context';
import { IEntity } from 'core/interfaces/entity.interface';
import { IEntityUIState } from 'core/state/entities.store';
import { useEffect } from 'react';

const params: IEntityUIState = {
    searchTerm: '',
    pageNumber: 1,
    pageSize: 100,
    orderDirection: primaryOrderDirection,
};

interface IUseEntitiesProps<R> {
    responseHandler?: (res: AxiosResponse) => R[];
    forceFetch?: boolean;
}

export const useEntitiesBuilder = <T extends IEntity, R>({
    store,
    query,
}: IEntitiesContext<T, R>) => ({
    responseHandler,
    forceFetch,
}: IUseEntitiesProps<R> = {}): [T[], () => Promise<T[] | null>] => {
    const [entities] = useObservable(query.selectAll(), query.getAll());

    useEffect(() => {
        if (forceFetch || !entities.length) {
            store.fetchEntities(params, responseHandler, true);
        }
    }, []);

    return [entities, () => store.fetchEntities()];
};
