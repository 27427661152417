import React, { FC } from 'react';
import { IEntityListItemProps } from '../../../core/interfaces/entity-list-item-props.interface';
import { IProject } from '../models/project.model';
import { ProjectListItemTemplate } from './ProjectListItemTemplate';

export const ProjectListItem: FC<IEntityListItemProps<IProject>> = ({
    entity: project,
}) => {
    return (
        <ProjectListItemTemplate
            projectName={project.name}
            projectManagerName={project.projectManagerName}
            id={project.id}
            latestPulse={project.latestPulse ?? undefined}
            nextPulse={project.nextPulse ?? undefined}
            participants={project.numberOfParticipants}
            customer={project.numberOfCustomerParticipants}
            participation={project.latestResponseRate}
            score={project.latestAverageScore}
        />
    );
};
