import { FC } from 'react';
import { ResetPasswordForm } from '../components/ResetPasswordForm';
import { useSession } from '../../../core/hooks/session.hook';
import { INewResetPasswordCredentials } from '../models/new.password.model';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../navigation/enums/routes.enum';
import { useParams } from 'react-router-dom';
import { IEntity } from '../../../core/interfaces/entity.interface';

export const ResetPasswordPage: FC = () => {
    const { resetPasswordHash } = useSession();
    const { id } = useParams<IEntity>();
    const { push } = useHistory();

    const onSubmitClick = async (credentials: INewResetPasswordCredentials) => {
        const { password } = credentials;
        await resetPasswordHash(id, password).then(() =>
            push({ pathname: ROUTES.SUCCESS_PASSWORD })
        );
    };

    return <ResetPasswordForm onSubmitClick={onSubmitClick} />;
};
