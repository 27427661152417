import { useSession } from 'core/hooks/session.hook';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AbilityContext } from '../Can';

export interface ILocationState {
    from: {
        pathname: string;
        search: string;
    };
}

interface PrivateRouteProps {
    path: RouteProps['path'];
    component: React.ElementType;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({
    component: Component,
    children,
    ...rest
}) => {
    const { isLoggedIn, abilities } = useSession();

    return (
        <AbilityContext.Provider value={abilities}>
            <Route
                {...rest}
                render={(routeProps) => {
                    const locationState: ILocationState = {
                        from: {
                            pathname: location.pathname,
                            search: location.search,
                        },
                    };

                    return isLoggedIn ? (
                        children ?? <Component {...routeProps} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: ROUTES.LOGIN,
                                state: locationState,
                            }}
                        />
                    );
                }}
            />
        </AbilityContext.Provider>
    );
};
