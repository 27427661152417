import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    lighten,
    makeStyles,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EditIcon from '@material-ui/icons/Edit';
import PeopleIcon from '@material-ui/icons/People';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { asDateString } from 'core/helpers/asDateString';
import { useCrud } from 'core/hooks/crud.hook';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import css from 'styles/material-ui/cssVars';
import { ROUTES } from '../../navigation/enums/routes.enum';
import { IProject } from '../models/project.model';
import { ProjectDialogContext } from './ProjectDialogProvider';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    card: {
        border: `1px solid ${theme.palette.divider}`,
        boxShadow: 'none',
        '&:hover $action $button:nth-child(1)': {
            opacity: 1,
        },
    },
    header: {
        backgroundColor: css.extraColors.text.darkPrimary,
        padding: `0 ${theme.spacing(3)}px`,
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    clickableName: {
        width: '100%',
        padding: `${theme.spacing(3)}px 0`,
    },
    action: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(3),
    },
    button: {
        padding: theme.spacing(1),
        minWidth: theme.spacing(4),
        '&:nth-child(1)': {
            opacity: 0,
        },
    },
    info: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    span: {
        display: 'inline',
    },
    footer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(3),
    },
    textColorSucces: {
        color: theme.palette.success.main,
    },
    textColorWarn: {
        color: theme.palette.warning.main,
    },
    textColorError: {
        color: theme.palette.error.main,
    },
    chipColorSuccess: {
        marginTop: theme.spacing(1),
        color: theme.palette.success.dark,
        backgroundColor: lighten(theme.palette.success.light, 0.8),
    },
    chipColorWarn: {
        color: theme.palette.warning.dark,
        backgroundColor: lighten(theme.palette.warning.light, 0.8),
    },
    chipColorError: {
        color: theme.palette.error.dark,
        backgroundColor: lighten(theme.palette.error.light, 0.6),
    },
}));

interface ProjectCardProps {
    project: IProject;
}

export const ProjectCard = ({ project }: ProjectCardProps) => {
    const classes = useStyles();
    const { push } = useHistory();

    const { t } = useContext(MultiLanguageSupportContext);

    const { open } = useContext(ProjectDialogContext);

    const { archiveSingle, restoreSingle } = useCrud();

    const onArchiveClick = useCallback(() => {
        archiveSingle(project, { shouldFetchAfterSuccess: true });
    }, [project]);

    const onUnArchiveClick = useCallback(() => {
        restoreSingle(project, { shouldFetchAfterSuccess: true });
    }, [project]);

    const chipAverageScore = (score: number) =>
        score < 1.6
            ? classes.chipColorError
            : score < 3.2
            ? classes.chipColorWarn
            : classes.chipColorSuccess;

    const lastResponseRate = (lastResponse: number) =>
        lastResponse < 31
            ? classes.textColorError
            : lastResponse < 61
            ? classes.textColorWarn
            : classes.textColorSucces;

    return (
        <Card className={classes.card} variant="outlined">
            <Box className={classes.header}>
                <div
                    className={classes.clickableName}
                    onClick={() =>
                        push({
                            pathname: pathBuilder(
                                ROUTES.PROJECT,
                                ':id',
                                project.id
                            ),
                        })
                    }
                >
                    <Typography variant="h5">{project.name}</Typography>
                    <Typography variant="body1" color="textSecondary">
                        {project.projectManagerName}
                    </Typography>
                </div>
                <div className={classes.action}>
                    {project.deletedAt ? (
                        <Tooltip title={t('tooltop-unarchive')}>
                            <Button
                                variant="text"
                                className={classes.button}
                                color="primary"
                                onClick={onUnArchiveClick}
                            >
                                <UnarchiveIcon />
                            </Button>
                        </Tooltip>
                    ) : (
                        <Tooltip title={t('tooltip-archive')}>
                            <Button
                                variant="text"
                                className={classes.button}
                                color="primary"
                                onClick={onArchiveClick}
                            >
                                <ArchiveIcon />
                            </Button>
                        </Tooltip>
                    )}
                    <Tooltip title={t('tooltip-edit')}>
                        <Button
                            variant="text"
                            className={classes.button}
                            color="primary"
                            // onClick={() => open(project)}
                            onClick={() =>
                                push({
                                    pathname: pathBuilder(
                                        ROUTES.PROJECT,
                                        ':id',
                                        project.id
                                    ),
                                })
                            }
                        >
                            <EditIcon />
                        </Button>
                    </Tooltip>
                </div>
            </Box>
            <CardContent>
                <Box className={classes.info}>
                    <div>
                        <DateRangeIcon className={classes.icon} />
                    </div>
                    <div>
                        <Typography variant="h6">
                            {project.latestPulse &&
                                asDateString(project.latestPulse, {
                                    format: 'date',
                                })}
                        </Typography>
                        <Typography
                            className="MuiTypography-badge"
                            color="textSecondary"
                        >
                            {/* TODO: Need to add repeats */}
                            {/*repeats weekly •*/}

                            {project.nextPulse &&
                                t('next-pulse') +
                                    ': ' +
                                    asDateString(project.nextPulse, {
                                        format: 'date',
                                    })}
                        </Typography>
                    </div>
                </Box>

                <Box className={classes.info}>
                    <div>
                        <PeopleIcon className={classes.icon} />
                    </div>
                    <div>
                        <Typography variant="h6" className={classes.span}>
                            {project.numberOfParticipants}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.span}
                            color="textSecondary"
                        >
                            {' '}
                            {t('total-participants-in-all-pulse')}
                        </Typography>
                        <Typography
                            className="MuiTypography-badge"
                            color="textSecondary"
                        >
                            {project.numberOfCustomerParticipants}
                            {' ' + t('customers')}
                            {/* TODO: Need to add external identifier */}
                        </Typography>
                    </div>
                </Box>
            </CardContent>

            <Box className={classes.footer}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ display: 'inline' }}
                >
                    {t('participation-of') + ' '}
                    <span
                        className={lastResponseRate(project.latestResponseRate)}
                    >
                        {project.latestResponseRate.toFixed(0)}%
                    </span>
                    {' ' + t('with')}
                </Typography>{' '}
                <Chip
                    style={{ display: 'inline' }}
                    label={`${project.latestAverageScore.toFixed(1)} ${t(
                        'average-score'
                    )}`}
                    className={chipAverageScore(project.latestAverageScore)}
                />
            </Box>
        </Card>
    );
};
