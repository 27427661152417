import {
    Box,
    Button,
    Chip,
    Grid,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { BreadcrumbsContainer, ICrumb } from './BreadcrumbsContainer';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderTop: '1px solid #DFE1E6',
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    icon: {
        fontSize: '40px',
        marginRight: theme.spacing(2),
        width: '40px',
        height: '40px',
    },
    buttonWrap: {
        '& button': {
            marginLeft: theme.spacing(1.5),
        },
        '& > :first-child': {
            marginLeft: '0',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'end',
        },
    },
    buttonSpacing: {
        marginLeft: theme.spacing(2),
        height: '35px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(2),
            fontSize: '12px',
        },
    },
}));
export interface PageTitleBarButton {
    label?: string;
    icon?: React.ReactElement<SvgIconProps>;
    callback?: () => void;
    color?: 'primary' | 'secondary' | 'default';
    component?: JSX.Element;
}

interface IPageTitleBarProps {
    title: string;
    noTabs?: boolean;
    icon?: JSX.Element;
    buttons?: PageTitleBarButton[];
    crumbs?: ICrumb[];
    component?: JSX.Element;
    isActive?: boolean;
}

export const PageTitleBar: FC<IPageTitleBarProps> = ({
    title,
    noTabs,
    icon,
    buttons,
    crumbs = [],
    isActive,
}) => {
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const iconElWithClass = icon
        ? React.cloneElement(icon, {
              className: classes.icon,
          })
        : '';

    return (
        <div
            className={classes.container}
            style={{ borderBottom: noTabs ? '1px solid #DFE1E6' : 'none' }}
        >
            <Grid container className={classes.flex}>
                <Grid item md={6}>
                    <div style={{ width: 'fit-content' }}>
                        <Box mb={2}>
                            <BreadcrumbsContainer crumbs={crumbs} />
                        </Box>

                        <Box
                            className={classes.flex}
                            style={{ justifyContent: 'flex-start' }}
                        >
                            {iconElWithClass}
                            <Typography variant="h2">{title}</Typography>
                            {isActive === true && (
                                <Chip
                                    label="Active"
                                    style={{
                                        backgroundColor: '#35B07C',
                                        color: 'white',
                                        textTransform: 'uppercase',
                                    }}
                                />
                            )}
                            {isActive === false && (
                                <Chip
                                    label="Inactive"
                                    style={{
                                        backgroundColor: '#C1C7D0',
                                        color: 'white',
                                        textTransform: 'uppercase',
                                    }}
                                />
                            )}
                        </Box>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <Box
                        display="flex"
                        justifyContent={isMobile ? 'flex-start' : 'flex-end'}
                    >
                        {buttons &&
                            buttons.map(
                                (
                                    {
                                        label,
                                        icon,
                                        callback,
                                        color = 'primary',
                                        component,
                                    },
                                    index
                                ) =>
                                    component ? (
                                        <Box
                                            className={classes.buttonSpacing}
                                            key={index}
                                        >
                                            {component}
                                        </Box>
                                    ) : (
                                        <Button
                                            className={classes.buttonSpacing}
                                            key={index}
                                            type="submit"
                                            variant="contained"
                                            color={color}
                                            onClick={callback}
                                            disableElevation
                                            style={{ height: '47px' }}
                                        >
                                            {icon}
                                            {label}
                                        </Button>
                                    )
                            )}
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};
