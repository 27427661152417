import { Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { ListActionIcon } from 'core/components/shared/ListActionIcon';
import React, { FC, useCallback, useContext } from 'react';
import { EntityListActions } from '../../../core/components/EntityListActions';
import {
    IComponentWrappedWithEntityByIdProps,
    WithEntityById,
} from '../../../core/hocs/WithEntityById';
import { IAccount } from '../models/account.model';
import { AccountDialogContext } from './AccountDialogProvider';

export const AccountsListActions: FC<
    IComponentWrappedWithEntityByIdProps<IAccount>
> = ({ entity: account }) => {
    const { open } = useContext(AccountDialogContext);

    const getActions = useCallback(
        () => [
            {
                callback: () => open(account),
                component: (
                    <Box display="flex" justifyContent={'flex-end'}>
                        <ListActionIcon iconComponent={<EditIcon />} />
                    </Box>
                ),
            },
        ],
        [account]
    );

    return <EntityListActions actions={getActions()} />;
};

export const AccountsListActionsById = WithEntityById(AccountsListActions);
