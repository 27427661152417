import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { GeneralDialogContext } from 'core/components/shared/GeneralDialog/GeneralDialogProvider';
import { IScaleQuestion } from 'modules/scale-questions/models/scale-question.model';
import { useCallback, useContext } from 'react';

export const useAddQuestionToPulse = (
    currentPageEntities: IScaleQuestion[]
): ((
    callback: (questionId: string, deleteData: boolean | null) => Promise<void>
) => (entity: IScaleQuestion) => void) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { setDialogProps, clearDialogProps } = useContext(
        GeneralDialogContext
    );

    return useCallback(
        (callback) => ({ id, questionId }) => {
            const pulseQuestion =
                currentPageEntities.find(
                    ({ id: scaleQuestionId }) => id === scaleQuestionId
                ) ?? null;

            if (pulseQuestion?.deletedAt) {
                setDialogProps({
                    openDialog: true,
                    title: t('remove-question-from-pulse-confimation-title'),
                    contentText: t(
                        'remove-question-from-pulse-confimation-content-text'
                    ),
                    actions: [
                        {
                            callback: async () => {
                                await callback(questionId, false);
                                clearDialogProps();
                            },
                            color: 'secondary',
                            component: t(
                                'remove-question-from-pulse-confimation-cancel-text'
                            ),
                        },

                        {
                            callback: async () => {
                                await callback(questionId, true);
                                clearDialogProps();
                            },
                            color: 'primary',
                            component: t(
                                'remove-question-from-pulse-confimation-confirm-text'
                            ),
                        },
                    ],
                });
            } else {
                callback(questionId, null);
            }
        },
        [currentPageEntities]
    );
};
