import { StoreConfig } from '@datorama/akita';
import {
    EntitiesState,
    EntitiesStore,
} from '../../../core/state/entities.store';
import {
    createCategoryCommand,
    createCategoryFromResponse,
    ICategory,
    ICategoryResponse,
} from '../models/category.model';

export type CategoriesState = EntitiesState<ICategory>;

@StoreConfig({
    name: 'categories',
    resettable: true,
})
class CategoriesStore extends EntitiesStore<ICategory, ICategoryResponse> {}

export const categoriesStore = new CategoriesStore(
    {
        responseMapper: createCategoryFromResponse,
        commandMapper: createCategoryCommand,
    },
    'dimension-entries'
);
