import { Box } from '@material-ui/core';
import parse from 'html-react-parser';
import { FC, useContext, useEffect, useState } from 'react';

import { IUseCurrentPage, useCurrentPage } from 'core/hooks/current-page.hook';
import { apiService } from 'core/services/apiService';
import { PulseDialogContext } from 'modules/pulse/components/PulseDialogProvider';
import { ISuccessFactor } from 'modules/success-factors/models/success-factor.model';

import { IEmailResponse } from './EmailDialogContent';

interface EmailContainerProps {
    pulseId?: string;
    renderToolbar?: (props: IUseCurrentPage<ISuccessFactor>) => JSX.Element;
}

export const EmailContent: FC<EmailContainerProps> = ({
    pulseId,
    renderToolbar,
}: EmailContainerProps) => {
    const currentPage = useCurrentPage();

    const [pulseEmail, setPulseEmail] = useState('');
    const { shouldRefetch } = useContext(PulseDialogContext);

    useEffect(() => {
        pulseId &&
            apiService
                .get<IEmailResponse>(`email-translations/${pulseId}`)
                .then(({ data }) => {
                    setPulseEmail(data.emailBodyHtml);
                });
    }, [pulseId, shouldRefetch]);

    const parsedEmail = parse(pulseEmail);

    return (
        <Box width={'100%'}>
            {renderToolbar && renderToolbar(currentPage)}
            <Box>
                <p>{parsedEmail}</p>
            </Box>
        </Box>
    );
};
