import {
    Box,
    Container,
    FormControl,
    Grid,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, useFormikContext } from 'formik';
import React, { FC, useCallback, useContext, useEffect } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { FormDialogWrapper } from '../../../core/components/shared/FormDialogWrapper';
import { useDialog } from '../../projects/components/use-dialog.hook';
import { ISuccessFactor } from '../models/success-factor.model';
import { FactorIcon } from './FactorIcon';
import { IconPicker } from './IconPicker';
import { SuccessFactorsFormContext } from './SuccessFactorsFormProvider';

const useStyles = makeStyles(() => ({
    container: {
        maxWidth: '760px',
    },
    form: {
        width: '100%',
    },
    formControl: {
        minWidth: 120,
    },
}));

interface IFactorsFormProps {
    areFactorFieldsDisabled?: boolean;
}

export const FactorsForm: FC<IFactorsFormProps> = ({
    areFactorFieldsDisabled = false,
}) => {
    const { open, handleClose, handleOpen } = useDialog();
    const { t } = useContext(MultiLanguageSupportContext);
    const { factorsId } = useContext(SuccessFactorsFormContext);
    const {
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
    } = useFormikContext<ISuccessFactor>();

    const classes = useStyles();

    const handleIcon = useCallback((event) => {
        setFieldValue('factor.fontAwesomeIcon', event.target.value);
        handleClose();
    }, []);

    useEffect(() => {
        if (values.mandatory) {
            setFieldValue('useAsDefault', true);
        }
    }, [values.mandatory]);

    useEffect(() => {
        if (!values.useAsDefault) {
            setFieldValue('mandatory', false);
        }
    }, [values.useAsDefault]);

    return (
        <>
            <Box my={3}>
                <Typography variant="h1" align="center">
                    {!factorsId
                        ? t('create-success-factor')
                        : t('edit-success-factor')}
                </Typography>
            </Box>
            <Container className={classes.container}>
                <Form className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="factor.title"
                                name="factor.title"
                                required
                                disabled={areFactorFieldsDisabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.factor.title}
                                label={t('enter-title')}
                                error={!!errors.factor?.title}
                            />
                            <Typography variant="body2">
                                {t('short-headline-graphs')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="factor.font_awesome_icon"
                                name="factor.font_awesome_icon"
                                required
                                disabled={areFactorFieldsDisabled}
                                label={t('select-icon')}
                                error={!!errors.factor?.title}
                                InputProps={{
                                    startAdornment: (
                                        <FactorIcon {...values.factor} />
                                    ),
                                }}
                                onClick={() =>
                                    !areFactorFieldsDisabled && handleOpen()
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex">
                                <Typography>{t('mandatory-factor')}</Typography>
                                <Switch
                                    checked={values.mandatory}
                                    onChange={handleChange}
                                    name="mandatory"
                                    color="primary"
                                    inputProps={{
                                        'aria-label': 'secondary checkbox',
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex">
                                <Typography>{t('default-factor')}</Typography>
                                <Switch
                                    checked={values.useAsDefault}
                                    onChange={handleChange}
                                    name="useAsDefault"
                                    color="primary"
                                    inputProps={{
                                        'aria-label': 'secondary checkbox',
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            </Container>
            <FormDialogWrapper isOpen={open} handleClose={handleClose}>
                <IconPicker
                    handleClose={handleClose}
                    handleChange={handleIcon}
                    iconPickerText="Select Icon"
                    value={values.factor.fontAwesomeIcon}
                />
            </FormDialogWrapper>
        </>
    );
};
