import { StoreConfig } from '@datorama/akita';
import {
    EntitiesState,
    EntitiesStore,
} from '../../../core/state/entities.store';
import {
    createAccountCommand,
    createAccountFromResponse,
    IAccount,
    IAccountResponse,
} from '../models/account.model';

export type AccountsState = EntitiesState<IAccount>;

@StoreConfig({
    name: 'organizations',
    resettable: true,
})
class CreateNewAccountStore extends EntitiesStore<IAccount, IAccountResponse> {}

export const accountsStore = new CreateNewAccountStore({
    responseMapper: createAccountFromResponse,
    commandMapper: createAccountCommand,
});
