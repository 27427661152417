import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { emailValidation, requiredField } from 'core/validations';
import { FormikProvider, useFormik } from 'formik';
import { createContext, FC, useCallback, useState } from 'react';
import * as Yup from 'yup';
import {
    emptyParticipantData as emptyParticipant,
    IParticipant,
    IParticipantDetails,
} from '../models/participant.model';
import { participantsQuery } from '../state/participants.query';

interface IParticipantDialogContext {
    participantId: string | null;
    isOpen: boolean;
    close: () => void;
    open: (participant: Partial<IParticipant>) => void;
    openDeleteModal: (participant: Partial<IParticipant>) => void;
    deleteModalOpen: boolean;
    deleteItem: () => void;
    closeDeleteModal: () => void;
}

export const ParticipantFormContext = createContext<IParticipantDialogContext>(
    {} as IParticipantDialogContext
);

const validationSchema = Yup.object().shape({
    name: requiredField,
    email: emailValidation,
    defaultAffiliation: requiredField,
    defaultRole: requiredField,
});

export interface IParticipantFormProviderProps {
    afterSubmit?: (participant: IParticipant | null) => Promise<void>;
}

export const ParticipantFormProvider: FC<IParticipantFormProviderProps> = WithProvider(
    ({ afterSubmit, children }) => {
        const [isOpen, setIsOpen] = useState(false);
        const [deleteModalOpen, setDeleteModalOpen] = useState(false);
        const [participantId, setParticipantId] = useState<string | null>(null);
        const [initialValues, setInitialValues] = useState<IParticipantDetails>(
            {
                ...emptyParticipant,
            }
        );

        const close = () => {
            setIsOpen(false);
        };

        const open = ({ id, ...details }: Partial<IParticipant>) => {
            setIsOpen(true);
            setParticipantId(id ?? null);
            setInitialValues({ ...emptyParticipant, ...details });
        };

        const openDeleteModal = ({ id }: Partial<IParticipant>) => {
            setDeleteModalOpen(true);
            setParticipantId(id ?? null);
        };

        const closeDeleteModal = () => {
            setDeleteModalOpen(false);
        };

        const { deleteMultiple } = useCrud();

        const deleteItem = () => {
            if (participantId) {
                deleteMultiple([participantId], {
                    shouldFetchAfterSuccess: true,
                });
                setDeleteModalOpen(false);
            }
        };

        const { createSingle, updateSingle } = useCrud<IParticipant>();

        const onSubmit = useCallback(
            async (data: IParticipantDetails) => {
                const participant = participantId
                    ? await updateSingle(
                          { id: participantId, ...data },
                          {
                              shouldFetchAfterSuccess: true,
                          }
                      )
                    : await createSingle(data, {
                          shouldFetchAfterSuccess: true,
                      });

                afterSubmit && afterSubmit(participant);

                if (participant) {
                    const { id, ...details } = participant;
                    setInitialValues(details);
                    setParticipantId(id);
                    close();
                }

                return participant;
            },
            [participantId, afterSubmit]
        );

        const formik = useFormik<IParticipantDetails>({
            initialValues,
            enableReinitialize: true,
            validateOnMount: !!participantId,
            onSubmit,
            validationSchema,
        });

        return (
            <ParticipantFormContext.Provider
                value={{
                    isOpen,
                    close,
                    open,
                    participantId,
                    openDeleteModal,
                    deleteModalOpen,
                    deleteItem,
                    closeDeleteModal,
                }}
            >
                <FormikProvider value={formik}>{children}</FormikProvider>
            </ParticipantFormContext.Provider>
        );
    },
    participantsQuery
);
