import { subject } from '@casl/ability';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ICrumb } from 'core/components/shared/topBars/BreadcrumbsContainer';
import { useSession } from 'core/hooks/session.hook';
import { ImportsList } from 'modules/imports/components/ImportsList';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { QuestionsListFilter } from 'modules/pulse-questions/components/QuestionFilters';
import { QuestionsDialogProvider } from 'modules/pulse-questions/components/system-wide/QuestionsDialogProvider';
import { QuestionsPage } from 'modules/pulse-questions/components/system-wide/QuestionsPage';
import { SuccessFactorsListFilter } from 'modules/success-factors/components/FactorFilters';
import React, { FC, useContext, useMemo } from 'react';
import {
    IPageTabsPropsItem,
    PageTabs,
} from '../../../core/components/mock/tabs/PageTabs';
import { PageTitleBar } from '../../../core/components/shared/topBars/PageTitleBar';
import { AccountsPage } from '../../accounts/pages/AccountPage';
import { LanguagePage } from '../../languages/pages/LanguagePage';
import { SuccessFactorFormProvider } from '../../success-factors/components/SuccessFactorsFormProvider';
import { FactorsPage } from '../../success-factors/pages/FactorsPage';

const items: IPageTabsPropsItem[] = [
    {
        headerLabel: 'accounts',
        table: <AccountsPage />,
    },
    {
        headerLabel: 'pulse-questions',
        table: (
            <QuestionsDialogProvider isSystemWide>
                <QuestionsPage
                    initialFilter={QuestionsListFilter.SYSTEM}
                    createButtonLabelTranslationKey="create-question-system"
                />
            </QuestionsDialogProvider>
        ),
    },
    {
        headerLabel: 'success-factors',
        table: (
            <SuccessFactorFormProvider isSystemWide>
                <FactorsPage
                    initialFilter={SuccessFactorsListFilter.SYSTEM}
                    createButtonLabelTranslationKey="create-factor-system"
                />
            </SuccessFactorFormProvider>
        ),
    },
    {
        headerLabel: 'languages',
        table: <LanguagePage />,
    },
    {
        headerLabel: 'imports',
        table: <ImportsList />,
    },
];

export const SuperAdminPage: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { abilities } = useSession();

    const pageCrumbs: ICrumb[] = [
        { label: t('super-admin'), link: ROUTES.SUPER_ADMIN },
        { label: t('account'), link: '' },
    ];

    const filteredItems = useMemo(
        () =>
            items.filter(({ headerLabel }) => {
                switch (headerLabel) {
                    case 'accounts':
                        return abilities.can('crud', 'IAccount');
                    case 'pulse-questions':
                        return abilities.can(
                            'crud',
                            subject('IScaleQuestion', {
                                organizationId: null,
                            })
                        );
                    case 'success-factors':
                        return abilities.can(
                            'crud',
                            subject('ISuccessFactor', {
                                organizationId: null,
                            })
                        );
                    case 'languages':
                        return abilities.can('crud', 'ILanguage');
                    case 'imports':
                        return abilities.can('crud', 'IImport');
                    default:
                        return true;
                }
            }),
        [abilities]
    );

    return (
        <>
            <PageTitleBar
                title={t('super-admin')}
                icon={
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="40" height="40" rx="4" fill="#172B4D" />
                        <path
                            d="M18 20C20.2091 20 22 18.2091 22 16C22 13.7909 20.2091 12 18 12C15.7909 12 14 13.7909 14 16C14 18.2091 15.7909 20 18 20Z"
                            fill="white"
                        />
                        <path
                            d="M18.67 21.02C18.45 21.01 18.23 21 18 21C15.58 21 13.32 21.67 11.39 22.82C10.51 23.34 10 24.32 10 25.35V28H19.26C18.47 26.87 18 25.49 18 24C18 22.93 18.25 21.93 18.67 21.02Z"
                            fill="white"
                        />
                        <path
                            d="M28.7499 24C28.7499 23.78 28.7199 23.58 28.6899 23.37L29.8299 22.36L28.8299 20.63L27.3799 21.12C27.0599 20.85 26.6999 20.64 26.2999 20.49L25.9999 19H23.9999L23.6999 20.49C23.2999 20.64 22.9399 20.85 22.6199 21.12L21.1699 20.63L20.1699 22.36L21.3099 23.37C21.2799 23.58 21.2499 23.78 21.2499 24C21.2499 24.22 21.2799 24.42 21.3099 24.63L20.1699 25.64L21.1699 27.37L22.6199 26.88C22.9399 27.15 23.2999 27.36 23.6999 27.51L23.9999 29H25.9999L26.2999 27.51C26.6999 27.36 27.0599 27.15 27.3799 26.88L28.8299 27.37L29.8299 25.64L28.6899 24.63C28.7199 24.42 28.7499 24.22 28.7499 24ZM24.9999 26C23.8999 26 22.9999 25.1 22.9999 24C22.9999 22.9 23.8999 22 24.9999 22C26.0999 22 26.9999 22.9 26.9999 24C26.9999 25.1 26.0999 26 24.9999 26Z"
                            fill="white"
                        />
                    </svg>
                }
                crumbs={pageCrumbs}
            />
            {!filteredItems.length ? (
                <span>There are not tabs available. </span> // TODO: add translation or better content
            ) : (
                <PageTabs items={filteredItems} />
            )}
        </>
    );
};
