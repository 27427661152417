import { arrayAdd } from '@datorama/akita';
import { Box, Button, DialogActions, DialogContent } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos } from '@material-ui/icons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MuiAlert from '@material-ui/lab/Alert';
import { useObservable } from '@mindspace-io/react';
import { FormikProvider, useFormikContext } from 'formik';
import {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { ValidTranslationKeys } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { map } from 'rxjs/operators';

import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { StepperComponent } from 'core/components/shared/Stepper';
import { maxLimit } from 'core/constants/maxLimit';
import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { IReadAllEntitiesResponse } from 'core/interfaces/read-all-entities-response.interface';
import { apiService } from 'core/services/apiService';
import { EmailDialogContent } from 'modules/customMessage/components/EmailDialogContent';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import { createParticipantFromResponse } from 'modules/participants/models/participant.model';
import { participantsQuery } from 'modules/participants/state/participants.query';
import { participantsStore } from 'modules/participants/state/participants.store';
import { useDialog } from 'modules/projects/components/use-dialog.hook';
import { IPulseParticipantResponse } from 'modules/pulse-participants/models/pulse-participant.model';
import { pulseParticipantsStore } from 'modules/pulse-participants/state/pulse-participants.store';
import { CurrentPageQuestionsContext } from 'modules/pulse-questions/components/CurrentPageQuestionsProvider';
import { Questions } from 'modules/pulse-questions/components/Questions';
import { scaleQuestionsStore } from 'modules/scale-questions/state/scale-questions.store';
import css from 'styles/material-ui/cssVars';

import { SelectParticipants } from '../../participants/component/SelectParticpants';
import { Factors } from '../../success-factors/components/Factors';
import { IPulse, IPulseDetails } from '../models/pulse.model';
import { pulsesQuery } from '../state/pulse.query';
import { AlertModal } from './AlertModal';
import { PulseDialogContext } from './PulseDialogProvider';
import { PulseForm } from './PulseForm';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '760px',
    },
    modalNav: {
        marginBottom: theme.spacing(6),
    },
    form: {
        width: '100%',
    },
    modalActions: {
        position: 'relative',
        backgroundColor: css.extraColors.text.darkPrimary,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3),
        zIndex: 2,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: `${theme.spacing(2)}px  ${theme.spacing(1)}px `,
            height: theme.spacing(10),
        },

        '& button ': {
            marginLeft: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                width: '90%',
                marginLeft: 0,
                marginTop: theme.spacing(2),
            },
            '&:disabled': {
                backgroundColor: '#cccccc',
            },
        },
    },
    modalActionsLastStep: {
        position: 'relative',
        backgroundColor: css.extraColors.text.darkPrimary,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3),
        zIndex: 2,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: `${theme.spacing(2)}px  ${theme.spacing(1)}px `,
            height: theme.spacing(10),

            '& button': {
                width: '90%',
            },

            '& button:disabled': {
                backgroundColor: '#cccccc',
            },
        },

        '& button': {
            marginLeft: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                marginTop: theme.spacing(2),

                '&:last-child': {
                    marginTop: '0',
                    order: -1,
                },
            },
        },
    },
    visibleMobile: {
        'button&': {
            [theme.breakpoints.down('xs')]: {
                marginTop: '0',
                order: -1,
            },
        },
    },
    closeBtn: {
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginRight: 'unset',
        },
    },
    actionsMobile: {
        zIndex: 1,
        backgroundColor: css.extraColors.text.darkPrimary,
        position: 'absolute',
        left: 0,
        bottom: 0,
        transform: `translateY(calc(100% - ${theme.spacing(10)}px))`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(
            1
        )}px`,
        transition: 'transform 0.3s ease-in-out',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },

        '& button': {
            width: '90%',
            '&:first-child': {
                width: 'auto',
            },
        },

        '& button + button': {
            marginBottom: theme.spacing(2),
        },
    },
    actionsMobileOpen: {
        transform: `translateY(calc(0px - ${theme.spacing(7)}px))`,

        '& .arrowUp': {
            transform: 'rotate(180deg)',
        },
    },
    actionsToggle: {
        backgroundColor: css.extraColors.text.darkPrimary,
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        top: `-${theme.spacing(3)}px`,
        border: 'none',
        padding: `0 ${theme.spacing(2)}px `,
        borderRadius: '8px 8px 0px 0px',
        cursor: 'pointer',
        display: 'none',
        width: 'auto',
        transition: 'transform 0.3s ease-in-out',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },

        '&:hover': {
            backgroundColor: css.extraColors.text.darkPrimary,
        },
    },
    drop: {
        backgroundColor: 'transparent',
        width: '100%',
        height: '134px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px dashed',
        borderColor: theme.palette.divider,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    colors: {
        height: '134px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(0.5),
    },
    searchfield: {
        marginRight: theme.spacing(4),
        '& input': {
            padding: `14.5px ${theme.spacing(2)}px 14.5px 0`,
        },
    },
}));

interface IStep {
    title: keyof ValidTranslationKeys;
    description: keyof ValidTranslationKeys;
}

const steps: IStep[] = [
    {
        title: 'about-new-pulse',
        description: 'name-language-schedule',
    },
    {
        title: 'edit-participants',
        description: 'optional',
    },
    {
        title: 'edit-pulse-questions',
        description: 'optional',
    },
    {
        title: 'edit-success-factors',
        description: 'optional',
    },
];

interface IStepConfig {
    content: JSX.Element;
    submitLabel?: string;
    discardLabel?: string;
    backLabel?: string;
    nextLabel?: string;
    disableSubmit?: boolean;
    disableBackAndNextIfSubmitDisabled?: boolean;
    onSubmit?: (shouldCloseDialog: boolean) => Promise<void>;
    onDiscard?: () => void;
}

export const PulseDialogContent: FC = WithProvider(() => {
    const {
        close: hideDialog,
        pulseId,
        stepIndex: currentStepIndex,
        setStepIndex: setCurrentStepIndex,
        defaultEmailMessage,
    } = useContext(PulseDialogContext);
    const detailsFormik = useFormikContext<IPulseDetails>();
    const { t } = useContext(MultiLanguageSupportContext);
    const [participantIds, setParticipantIds] = useState<string[]>([]);
    const [openSnackbar, setOpenSnackbar] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { addMultiple, deleteMultiple } = useCrud();
    const { push } = useHistory();
    const { pathname } = useLocation();

    const [activeIds] = useObservable(
        participantsQuery.selectActiveId().pipe(map((x) => x ?? [])),
        participantsQuery.getActiveId() ?? []
    );

    const {
        editedPageEntities: editedPageQuestions,
        setEditedPageEntities: setEditedPageQuestions,
    } = useContext(CurrentPageQuestionsContext);

    const [mobileCtaIsOpen, setMobileCtaIsOpen] = useState(false);
    const { emailMessage, setEmailMessage } = useContext(PulseDialogContext);

    function Alert(props: any) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleSnackbarClick = () => {
        setOpenSnackbar({ ...openSnackbar, open: true });
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar({ ...openSnackbar, open: false });
    };

    const closeDialog = (id: string | null = null) => {
        participantsStore.setActive([]);

        hideDialog();
        setMobileCtaIsOpen(false);

        if (id) {
            push(pathBuilder(ROUTES.PULSE, ':id', id));
        }
    };

    const fetchAllPulseParticipants = useCallback(() => {
        if (pulseId) {
            apiService
                .get<IReadAllEntitiesResponse<IPulseParticipantResponse>>(
                    `/pulses/${pulseId}/respondents?limit=${maxLimit}`
                )
                .then(({ data }) => {
                    const respondents = data.results.map(({ respondent }) =>
                        createParticipantFromResponse(respondent)
                    );

                    addMultiple(respondents);
                    const ids = respondents.map(({ id }) => id);
                    setParticipantIds(ids);
                    participantsStore.setActive(ids);
                });
        }
    }, [pulseId]);

    useEffect(() => {
        fetchAllPulseParticipants();
    }, [pulseId]);

    const detailsStepConfig: IStepConfig = useMemo(() => {
        return {
            content: (
                <FormikProvider value={detailsFormik}>
                    <PulseForm />
                </FormikProvider>
            ),
            submitLabel: t('save-changes'),
            discardLabel: t('discard-changes'),
            backLabel: t('save-back'),
            nextLabel: t('save-next'),
            disableSubmit: !detailsFormik.dirty || !detailsFormik.isValid,
            disableNextIfSubmitDisabled: !pulseId,
            onDiscard: () => detailsFormik.resetForm(),
            onSubmit: async (shouldCloseDialog) => {
                const pulse = ((await detailsFormik.submitForm()) as unknown) as IPulse;

                shouldCloseDialog && closeDialog(pulse?.id ?? null);
            },
        };
    }, [detailsFormik, pulseId]);

    const disableParticipantsSubmit = useMemo(() => {
        const a = [...activeIds];
        const b = [...participantIds];
        a.sort();
        b.sort();
        return a.toString() === b.toString();
    }, [activeIds, participantIds]);

    const participantsStepConfig: IStepConfig = useMemo(
        () => ({
            content: !pulseId ? (
                <></>
            ) : (
                <SelectParticipants
                    afterSubmit={async (participant) => {
                        if (participant) {
                            if (!participantIds.includes(participant.id)) {
                                await apiService.post(
                                    `/pulses/${pulseId}/respondents/${participant.id}`,
                                    {}
                                );

                                setParticipantIds(
                                    arrayAdd(participantIds, [participant.id])
                                );
                                participantsStore.setActive(
                                    arrayAdd(activeIds, [participant.id])
                                );
                            }

                            pulseParticipantsStore.fetchEntities();

                            if (
                                participantsStore.getValue().ui.pageNumber === 1
                            ) {
                                participantsStore.fetchEntities();
                            } else {
                                participantsStore.patchUIState({
                                    pageNumber: 1,
                                });
                            }
                        }
                    }}
                />
            ),
            disableSubmit: disableParticipantsSubmit,
            submitLabel: t('save-changes'),
            discardLabel: t('discard-changes'),
            backLabel: t('back'),
            nextLabel: t('next'),
            onDiscard: () => participantsStore.setActive(participantIds),
            onSubmit: async (shouldCloseDialog) => {
                await Promise.all([
                    ...activeIds
                        .filter((id) => participantIds.indexOf(id) === -1)
                        .map((id) =>
                            apiService.post(
                                `/pulses/${pulseId}/respondents/${id}`,
                                {}
                            )
                        ),
                    ...participantIds
                        .filter((id) => activeIds.indexOf(id) === -1)
                        .map((id) =>
                            apiService.delete(
                                `/pulses/${pulseId}/respondents/${id}`
                            )
                        ),
                ]);

                setParticipantIds(activeIds);

                pulseParticipantsStore.fetchEntities();

                shouldCloseDialog && closeDialog(pulseId);
            },
        }),
        [pulseId, activeIds]
    );

    const questionsStepConfig: IStepConfig = useMemo(
        () => ({
            content: !pulseId ? <></> : <Questions id={pulseId} />,
            discardLabel: t('discard-changes'),
            backLabel: t('save-back'),
            nextLabel: t('save-next'),
            submitLabel: t('save-changes'),
            disableSubmit: !editedPageQuestions,
            onDiscard: () => setEditedPageQuestions(null),
            onSubmit: async (shouldCloseDialog) => {
                if (!editedPageQuestions) {
                    return;
                }

                // TODO: uncomment and add correct URL
                await apiService.put(
                    `/pulses/${pulseId}/scale-questions/order`,
                    { scaleQuestionPreferences: editedPageQuestions }
                );

                scaleQuestionsStore.setCurrentPageEntities(editedPageQuestions);

                shouldCloseDialog && closeDialog(pulseId);
            },
        }),
        [pulseId, editedPageQuestions]
    );

    const factorsStepConfig: IStepConfig = useMemo(
        () => ({
            content: !pulseId ? <></> : <Factors id={pulseId} />,
            backLabel: t('back'),
            nextLabel: t('next'),
            submitLabel: t('save-changes'),
            onSubmit: async (shouldCloseDialog) => {
                shouldCloseDialog && closeDialog(pulseId);
            },
        }),
        [pulseId]
    );

    const { setShouldRefetch } = useContext(PulseDialogContext);

    const emailMessageConfig: IStepConfig = useMemo(
        () => ({
            content: !pulseId ? <></> : <EmailDialogContent id={pulseId} />,
            submitLabel: 'Save',
            onSubmit: async (shouldCloseDialog) => {
                await apiService.put(`/pulses/${pulseId}`, {
                    emailMessage,
                });
                setShouldRefetch(true);

                shouldCloseDialog && closeDialog(pulseId);
            },
        }),
        [pulseId, emailMessage]
    );

    const {
        content,
        submitLabel,
        discardLabel,
        backLabel,
        nextLabel,
        disableSubmit,
        disableBackAndNextIfSubmitDisabled,
        onSubmit,
        onDiscard,
    }: IStepConfig = useMemo(() => {
        switch (currentStepIndex) {
            case 0:
                return detailsStepConfig;
            case 1:
                return participantsStepConfig;
            case 2:
                return questionsStepConfig;
            case 3:
                return factorsStepConfig;
            case 4:
                return emailMessageConfig;
            default:
                return {
                    content: <></>,
                    onSubmit: async () => console.log('onSubmit'),
                };
        }
    }, [
        currentStepIndex,
        detailsStepConfig,
        participantsStepConfig,
        questionsStepConfig,
        factorsStepConfig,
        emailMessageConfig,
    ]);

    const nextComponent = useCallback(() => {
        setMobileCtaIsOpen(false);
        setCurrentStepIndex(currentStepIndex + 1);
    }, [currentStepIndex]);

    const previousComponent = useCallback(() => {
        setMobileCtaIsOpen(false);
        setCurrentStepIndex(currentStepIndex - 1);
    }, [currentStepIndex]);

    const classes = useStyles();

    const {
        open: showDeleteConfirmation,
        handleClose: handleDeleteConfirmationClose,
        handleOpen: handleDeleteConfirmationOpen,
    } = useDialog();

    const toggleMobileCta = () => {
        setMobileCtaIsOpen(!mobileCtaIsOpen);
    };

    const mobileCtaClasses = mobileCtaIsOpen
        ? `${classes.actionsMobile} ${classes.actionsMobileOpen}`
        : `${classes.actionsMobile}`;

    const modalActionsClass =
        currentStepIndex === steps.length - 1
            ? classes.modalActionsLastStep
            : classes.modalActions;

    return (
        <>
            <DialogContent>
                {currentStepIndex === 4 ? (
                    <></>
                ) : (
                    <StepperComponent
                        activeStep={currentStepIndex}
                        steps={steps.map(({ title, description }) => ({
                            title: t(title),
                            description: t(description),
                        }))}
                        onClick={(index) =>
                            pulseId && setCurrentStepIndex(index)
                        }
                    />
                )}
                {content}
            </DialogContent>

            {/* mobile CTA start */}
            <div className={mobileCtaClasses}>
                <Button
                    type="button"
                    className={classes.actionsToggle}
                    onClick={toggleMobileCta}
                >
                    <ExpandLessIcon className="arrowUp" />
                </Button>
                <Button
                    type="reset"
                    disableElevation
                    onClick={hideDialog}
                    className={classes.closeBtn}
                >
                    {pulseId ? t('close') : t('cancel')}
                </Button>
                {pulseId && (
                    <Button
                        variant="contained"
                        disableElevation
                        className="MuiButton-colorWarn"
                        onClick={handleDeleteConfirmationOpen}
                    >
                        {t('delete-pulse')}
                    </Button>
                )}
                {!currentStepIndex ? null : (
                    <Button
                        variant="contained"
                        type="submit"
                        disableElevation
                        onClick={previousComponent}
                        startIcon={<ArrowBackIos />}
                        disabled={
                            disableSubmit && disableBackAndNextIfSubmitDisabled
                        }
                    >
                        {onSubmit && !disableSubmit ? backLabel : t('back')}
                    </Button>
                )}
                {onDiscard && (
                    <Button
                        variant="contained"
                        disableElevation
                        className="MuiButton-colorWarn"
                        disabled={disableSubmit}
                        onClick={onDiscard}
                    >
                        {discardLabel}
                    </Button>
                )}
                {currentStepIndex < steps.length - 1 && onSubmit && (
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disableElevation
                        disabled={disableSubmit}
                        onClick={async () => {
                            try {
                                await onSubmit(true);
                            } catch (e: any) {
                                console.log(e);
                                //TODO: handle error
                            }
                        }}
                    >
                        {submitLabel}
                    </Button>
                )}
            </div>
            {/* mobile CTA end */}

            <DialogActions disableSpacing={true} className={modalActionsClass}>
                {currentStepIndex === 4 ? (
                    <Box>
                        <Button
                            type="reset"
                            disableElevation
                            onClick={hideDialog}
                            className={classes.closeBtn}
                        >
                            {t('close')}
                        </Button>
                        <Button
                            type="reset"
                            disableElevation
                            onClick={() => setEmailMessage(defaultEmailMessage)}
                            className={classes.closeBtn}
                        >
                            {t('reset')}
                        </Button>
                    </Box>
                ) : (
                    <Button
                        type="reset"
                        disableElevation
                        onClick={hideDialog}
                        className={classes.closeBtn}
                    >
                        {pulseId ? t('close') : t('cancel')}
                    </Button>
                )}

                {pulseId && currentStepIndex !== 4 && (
                    <Button
                        variant="contained"
                        disableElevation
                        className="MuiButton-colorWarn"
                        onClick={handleDeleteConfirmationOpen}
                    >
                        {t('delete-pulse')}
                    </Button>
                )}
                {!currentStepIndex || currentStepIndex === 4 ? null : (
                    <Button
                        variant="contained"
                        type="submit"
                        disableElevation
                        onClick={previousComponent}
                        startIcon={<ArrowBackIos />}
                        disabled={
                            disableSubmit && disableBackAndNextIfSubmitDisabled
                        }
                    >
                        {onSubmit && !disableSubmit ? backLabel : t('back')}
                    </Button>
                )}
                {currentStepIndex < steps.length - 1 && (
                    <Button
                        className={classes.visibleMobile}
                        variant="contained"
                        disableElevation
                        endIcon={<ArrowForwardIosIcon />}
                        disabled={
                            disableSubmit && disableBackAndNextIfSubmitDisabled
                        }
                        onClick={async () => {
                            try {
                                onSubmit &&
                                    !disableSubmit &&
                                    (await onSubmit(false));
                            } catch (e: any) {
                                console.log(e);
                                //TODO: handle error
                            }

                            nextComponent();
                        }}
                    >
                        {onSubmit && !disableSubmit ? nextLabel : t('next')}
                    </Button>
                )}
                {onDiscard && (
                    <Button
                        variant="contained"
                        disableElevation
                        className="MuiButton-colorWarn"
                        disabled={disableSubmit}
                        onClick={onDiscard}
                    >
                        {discardLabel}
                    </Button>
                )}
                {onSubmit && (
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disableElevation
                        disabled={disableSubmit}
                        onClick={async () => {
                            try {
                                await onSubmit(true);
                            } catch (e: any) {
                                console.log(e);
                                //TODO: handle error
                            }
                        }}
                    >
                        {submitLabel}
                    </Button>
                )}
            </DialogActions>
            {pulseId && (
                <FormDialogWrapper
                    isOpen={showDeleteConfirmation}
                    handleClose={handleDeleteConfirmationClose}
                    alertModal
                >
                    <AlertModal
                        title={t('delete-pulse-confirmation-title')}
                        isActionDestructive={true}
                        contentText={t('delete-pulse-confirmation-desctiption')}
                        closeModalText={t('delete-pulse-decline')}
                        submitModalText={t('delete-pulse-accept')}
                        handleClose={handleDeleteConfirmationClose}
                        handleSubmit={async () => {
                            const pulsePathname = pathBuilder(
                                ROUTES.PULSE,
                                ':id',
                                pulseId
                            );

                            const isPulsePage = pathname.startsWith(
                                pulsePathname
                            );

                            try {
                                await deleteMultiple([pulseId], {
                                    shouldFetchAfterSuccess: isPulsePage,
                                });

                                handleDeleteConfirmationClose();
                                handleSnackbarClick();

                                setTimeout(() => {
                                    closeDialog();
                                }, 3000);

                                if (
                                    detailsFormik?.values.projectId &&
                                    isPulsePage
                                ) {
                                    push(
                                        pathBuilder(
                                            ROUTES.PROJECT,
                                            ':id',
                                            detailsFormik.values.projectId
                                        )
                                    );
                                }
                            } catch (e: any) {
                                console.log(e);
                                //TODO: handle error
                            }
                        }}
                    />
                </FormDialogWrapper>
            )}

            <Snackbar
                open={openSnackbar.open}
                autoHideDuration={3000}
                onClose={() => handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="success">
                    Successfully deleted Pulse!
                    {/*TODO: Translations*/}
                </Alert>
            </Snackbar>
        </>
    );
}, pulsesQuery);
