import { Box, TableCell, Typography } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import { OrderDirection } from 'core/state/entities.store';
import React, { FC } from 'react';

interface IListHeaderCell {
    label?: string;
    sortBy?: string;
    direction?: OrderDirection;
    activateSort?: (orderBy: string) => void;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
}

export const ListHeaderCell: FC<IListHeaderCell> = ({
    label = '',
    sortBy = '',
    direction = null,
    activateSort,
    align = undefined,
}) => {
    return (
        <TableCell align={align}>
            <Box
                display="flex"
                justifyContent={align}
                onClick={() => activateSort && activateSort(sortBy)}
            >
                <Typography variant="h5">{label}</Typography>
                <Box>
                    {sortBy &&
                        (direction === 'asc' ? (
                            <ArrowUpwardIcon style={{ fontSize: 18 }} />
                        ) : direction === 'desc' ? (
                            <ArrowDownwardIcon style={{ fontSize: 18 }} />
                        ) : (
                            <SwapVertIcon style={{ fontSize: 18 }} />
                        ))}
                </Box>
            </Box>
        </TableCell>
    );
};
