import {
    Box,
    Button,
    Container,
    Grid,
    List,
    ListItem,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PersonIcon from '@material-ui/icons/Person';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useSession } from 'core/hooks/session.hook';
import { apiService } from 'core/services/apiService';
import { requiredField } from 'core/validations';
import { useFormik } from 'formik';
import { IFeedbackReturn } from 'modules/pulse-answering/models/feedback-loop-models';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FeedbackAnsweredSvg } from 'styles/assets/svg/feedback-answered';
import css from 'styles/material-ui/cssVars';
import * as Yup from 'yup';

interface IFeedbackListProps {
    feedbackId: string;
    handleClose: () => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    content: {
        minHeight: `calc(100vh - 212px)`,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        backgroundColor: theme.palette.background.paper,
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        left: 0,
        bottom: 0,
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        },
    },
    buttonWrap: {
        '& button': {
            marginLeft: theme.spacing(2),
        },
        '& > :first-child': {
            marginLeft: '0',
        },
    },
    user: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(2),
        '& $avatar': {
            marginRight: theme.spacing(3),
        },
        '& $message': {
            backgroundColor: css.extraColors.text.darkPrimary,
        },
    },
    admin: {
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(2),
        '& $avatar': {
            order: 2,
            marginLeft: theme.spacing(3),
        },
        '& $message': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    avatar: {
        height: theme.spacing(6),
        width: theme.spacing(6),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e5e5e5',
        borderRadius: '50%',
        flex: '0 0 48px',
        marginTop: '8px',
    },
    message: {
        width: 'calc(100% - 144px)',
        padding: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    input: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            order: '-1',
            margin: `0 0 ${theme.spacing(2)}px 0`,
        },
    },
    messageDate: {
        margin: '0',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            marginBottom: '5px',
        },
    },
    messageText: {
        margin: '0',
        width: 'calc(100% - 150px)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

export const FeedbackLoopPageAdmin: FC<IFeedbackListProps> = ({
    feedbackId,
    handleClose,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const [
        returnedFeedbacks,
        setReturnedFeedbacks,
    ] = useState<IFeedbackReturn>();

    const [feedbackAnswered, setFeedbackAnswered] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        feedbackId &&
            apiService.get(`/feedbacks/${feedbackId}`).then(({ data }) => {
                setReturnedFeedbacks(data);
            });
    }, [feedbackId]);

    const formatDate = (date: any) => {
        const options: any = { month: 'long', year: 'numeric', day: 'numeric' };
        date = new Date(date);
        const time = date.toISOString().split('T')[1].slice(0, 5);
        const dateFormated = date.toISOString().split('T')[0];
        const newDateFirnated = new Date(dateFormated).toLocaleDateString(
            'en-US',
            options
        );
        return (
            <>
                {time}, {newDateFirnated}
            </>
        );
    };

    //TODO: Check, seems like this is not useed.
    // const { me } = useSession();
    // const canReplyToFeedback = useMemo(
    //     () =>
    //         returnedFeedbacks && me && returnedFeedbacks.manager?.id === +me.id,
    //     [returnedFeedbacks, me]
    // );

    return !feedbackAnswered ? (
        <>
            <Box
                className={classes.root}
                style={{ overflowY: 'scroll', height: '100%' }}
            >
                <Container className={classes.content}>
                    <Box py={6}>
                        <Typography variant="h3" align="center">
                            {t('admin-feedback-loop')}
                        </Typography>
                    </Box>

                    <List>
                        {returnedFeedbacks?.message.replace(/\s/g, '') ? (
                            <ListItem className={classes.user}>
                                <Box className={classes.avatar}>
                                    {returnedFeedbacks.public ? (
                                        <Tooltip
                                            title={
                                                returnedFeedbacks.respondent
                                                    ?.name ?? 'Respondent'
                                            }
                                        >
                                            <span>
                                                <PersonIcon color="disabled" />
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={'Respondent'}>
                                            <span>
                                                <VerifiedUserIcon color="disabled" />
                                            </span>
                                        </Tooltip>
                                    )}
                                </Box>
                                <Box className={classes.message}>
                                    <p className={classes.messageDate}>
                                        {formatDate(
                                            returnedFeedbacks
                                                .pulse_event_respondent
                                                .created_at
                                        )}
                                    </p>
                                    <p className={classes.messageText}>
                                        {returnedFeedbacks.message}
                                    </p>
                                </Box>
                            </ListItem>
                        ) : null}

                        {returnedFeedbacks?.feedback_replies.map(
                            (item, idx) => {
                                return (
                                    <ListItem
                                        key={idx}
                                        className={
                                            item.reply_from === 'respondent'
                                                ? classes.user
                                                : classes.admin
                                        }
                                    >
                                        <Box className={classes.avatar}>
                                            {item.reply_from ===
                                            'respondent' ? (
                                                !returnedFeedbacks.public ? (
                                                    <VerifiedUserIcon color="disabled" />
                                                ) : (
                                                    <PersonIcon color="disabled" />
                                                )
                                            ) : (
                                                <Tooltip
                                                    title={
                                                        returnedFeedbacks
                                                            .manager.name
                                                    }
                                                >
                                                    <Typography variant="button">
                                                        {t('pm')}
                                                    </Typography>
                                                </Tooltip>
                                            )}
                                        </Box>
                                        <Box className={classes.message}>
                                            <p className={classes.messageDate}>
                                                {formatDate(item.created_at)}
                                            </p>
                                            <p className={classes.messageText}>
                                                {item.message}
                                            </p>
                                        </Box>
                                    </ListItem>
                                );
                            }
                        )}
                    </List>
                </Container>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}></Grid>
                    <Box className={classes.buttons}>
                        <Button
                            type="reset"
                            variant="text"
                            onClick={handleClose}
                        >
                            {t('cancel')}
                        </Button>
                        <FeedbackLoopPageAdminForm
                            setFeedbackAnswered={setFeedbackAnswered}
                            feedbackId={feedbackId}
                        />
                    </Box>
                </Grid>
            </Box>
        </>
    ) : (
        <Box className={classes.root}>
            <Container className={classes.content}>
                <Box py={6}>
                    <Typography variant="h3" align="center">
                        {t('your-answers-have-been-registered')}
                        <br />
                        <FeedbackAnsweredSvg />
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

const validationSchema = Yup.object().shape({
    message: requiredField,
});

interface IFeedbackLoopPageAdminFormProps {
    feedbackId: string;
    setFeedbackAnswered: (value: boolean) => void;
}

const FeedbackLoopPageAdminForm: FC<IFeedbackLoopPageAdminFormProps> = ({
    feedbackId,
    setFeedbackAnswered,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();

    const {
        values,
        handleChange,
        handleBlur,
        isValid,
        submitForm,
    } = useFormik<{
        message: string;
    }>({
        initialValues: { message: '' },
        validationSchema,
        onSubmit: async (values, actions) => {
            apiService
                .post(`feedbacks/${feedbackId}/reply`, values)
                .then(() => setFeedbackAnswered(true));

            actions.resetForm();
        },
    });

    return (
        <>
            <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                fullWidth
                id="message"
                name="message"
                value={values.message}
                label={t('type-message-here')}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <Button
                variant="contained"
                color="primary"
                disableElevation
                disabled={!isValid}
                endIcon={<ArrowForwardIosIcon />}
                onClick={submitForm}
            >
                {t('submit')}
            </Button>
        </>
    );
};
