import {
    createCategoryFromResponse,
    ICategory,
    ICategoryResponse,
} from 'modules/categories/models/category.model';
import { convertDate } from 'shared/yup/utils/convertDate';
import { IEntity } from '../../../core/interfaces/entity.interface';
import { ProjectManagerResponse } from './project-manager.model';

export interface IProjectDetails {
    name: string;
    projectManagerId: string | null;
    projectManagerName: string;
    externalIdentifier: string;
    numberOfParticipants: number;
    numberOfCustomerParticipants: number;
    latestResponseRate: number;
    latestAverageScore: number;
    latestPulse: Date | null;
    nextPulse: Date | null;
    createdAt: Date | null;
    updatedAt: Date | null;
    categories?: ICategory[];
    deletedAt: string | null;
}

export interface IProject extends IEntity, IProjectDetails {}

export const emptyProject: IProjectDetails = {
    name: '',
    projectManagerId: null,
    projectManagerName: '',
    externalIdentifier: '',
    numberOfParticipants: 0,
    numberOfCustomerParticipants: 0,
    latestResponseRate: 0,
    latestAverageScore: 0,
    latestPulse: null,
    nextPulse: null,
    createdAt: null,
    updatedAt: null,
    deletedAt: '',
};

export interface IProjectResponseSharedDetails {
    name: string;
    external_identifier: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export interface IProjectResponseSingleDetails {
    access_level: string;
    dimension_entries: ICategoryResponse[];
    project_manager: ProjectManagerResponse;
}

export interface IProjectResponseListItemDetails {
    latest_average_score: string | null;
    latest_pulse: string | null;
    latest_response_rate: string | null;
    next_pulse: string | null;
    number_of_participants: string;
    number_of_customer_participants: string;
    organization_id: string;
    project_manager_id: number;
    project_manager_name: string;
}

export interface IProjectResponse
    extends IEntity<number>,
        IProjectResponseSharedDetails,
        Partial<IProjectResponseSingleDetails>,
        Partial<IProjectResponseListItemDetails> {}

export const createProject = ({
    id,
    ...props
}: Partial<IProject>): IProject => {
    if (!id) {
        throw new Error('Unable to create a project without an ID!');
    }

    return {
        id,
        ...emptyProject,
        ...props,
    };
};

export const createProjectFromResponse = ({
    id,
    project_manager,
    project_manager_id,
    project_manager_name,
    external_identifier,
    deleted_at,
    latest_pulse,
    next_pulse,
    created_at,
    updated_at,
    number_of_participants,
    number_of_customer_participants,
    latest_response_rate,
    latest_average_score,
    dimension_entries,
    ...props
}: IProjectResponse): IProject => {
    return createProject({
        id: id.toString(),
        projectManagerId:
            (project_manager
                ? project_manager.id.toString()
                : project_manager_id?.toString()) ??
            emptyProject.projectManagerId,
        projectManagerName:
            (project_manager ? project_manager.name : project_manager_name) ??
            emptyProject.projectManagerName,
        externalIdentifier: external_identifier,
        deletedAt: deleted_at,
        latestPulse: !latest_pulse ? null : convertDate(latest_pulse),
        nextPulse: !next_pulse ? null : convertDate(next_pulse),
        updatedAt: !updated_at ? null : convertDate(updated_at),
        createdAt: !created_at ? null : convertDate(created_at),
        numberOfParticipants: !number_of_participants
            ? emptyProject.numberOfParticipants
            : +number_of_participants,
        numberOfCustomerParticipants: !number_of_customer_participants
            ? emptyProject.numberOfCustomerParticipants
            : +number_of_customer_participants,
        latestResponseRate: !latest_response_rate
            ? emptyProject.latestResponseRate
            : +latest_response_rate,
        latestAverageScore: !latest_average_score
            ? emptyProject.latestAverageScore
            : +latest_average_score,
        categories: dimension_entries?.map((response) =>
            createCategoryFromResponse(response)
        ),
        ...props,
    });
};

export interface IProjectCommand {
    name: string;
    project_manager: IEntity<number>;
    external_identifier: string;
    dimension_entries: IEntity<number>[];
    access_level: string; // TODO: remove when BE removes it
}

export const createProjectCommand = ({
    name,
    projectManagerId,
    externalIdentifier,
    categories,
}: IProjectDetails): IProjectCommand => {
    const dimension_entries =
        categories?.map((category) => ({
            id: +category.id,
        })) ?? [];

    return {
        name,
        project_manager: { id: +(projectManagerId ?? 0) },
        external_identifier: externalIdentifier,
        dimension_entries,
        access_level: 'none',
    };
};
