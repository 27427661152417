import {
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core';
import { FC } from 'react';
import { ListHeader } from '../../../core/components/shared/ListHeader';
import { ListHeaderCell } from '../../../core/components/shared/ListHeaderCell';
import { usePulseQuestions } from '../hooks';
import {
    IPortfolioData,
    IProjectsDataResponse,
} from '../models/dashboard.models';
import { IPortfolioAnswerData } from '../pages/PortfolioDashboardPage';

const createPortfolioAnswerData = (
    name: string,
    {
        participants,
        response_rate: responseRate,
        questions = {},
        great = [],
        improvable = [],
    }: IProjectsDataResponse
): IPortfolioAnswerData => ({
    name,
    participants,
    responseRate,
    great,
    improvable,
    questions,
});

export type TotalsTableProps = Pick<IPortfolioData, 'projects_data'>;

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    projectCardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
        columnGap: 24,
        rowGap: 24,
    },
    filters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableBackground: {
        backgroundColor: '#fff',
    },
}));

const formatNumber = (value?: number | null): string =>
    value?.toFixed(1) ?? '-';

export const TotalsTablePortfolio: FC<TotalsTableProps> = ({
    projects_data,
}) => {
    const classes = useStyles();

    const pulseQuestions = usePulseQuestions(
        Object.values(projects_data).map(({ questions = {} }) => questions)
    );

    const rows = Object.entries(projects_data).map(([key, value]) =>
        createPortfolioAnswerData(key, value)
    );

    return (
        <>
            <TableContainer
                component={Paper}
                variant="outlined"
                className={classes.tableBackground}
            >
                <Table>
                    <TableHead>
                        <ListHeader>
                            {[
                                'Project name',
                                'Participants',
                                'Response Rate',
                                ...pulseQuestions,
                                'Great',
                                'Improve',
                            ].map((item, index) => (
                                <ListHeaderCell key={index} label={item} />
                            ))}
                        </ListHeader>
                    </TableHead>
                    <TableBody>
                        {rows.map(
                            (
                                {
                                    name,
                                    participants,
                                    responseRate,
                                    great,
                                    improvable,
                                    questions,
                                },
                                index
                            ) => (
                                <TableRow key={index}>
                                    <TableCell>{name}</TableCell>
                                    <TableCell>{participants}</TableCell>
                                    <TableCell>{responseRate}</TableCell>
                                    {pulseQuestions.map((pulseQuestionName) => (
                                        <TableCell key={pulseQuestionName}>
                                            {formatNumber(
                                                questions[pulseQuestionName] ||
                                                    null
                                            )}
                                        </TableCell>
                                    ))}
                                    <TableCell>{great.join(', ')}</TableCell>
                                    <TableCell>
                                        {improvable.join(', ')}
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
