import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useCallback, useContext } from 'react';
import { ParticipantAffiliation } from '../models/participant.model';

interface IUseAffiliationTranslation {
    getAffiliationTranslation: (affiliation: ParticipantAffiliation) => string;
}

const useAffiliationTranslation = (
    organizationName: string
): IUseAffiliationTranslation => {
    const { t } = useContext(MultiLanguageSupportContext);

    const getAffiliationTranslation = useCallback(
        (affiliation: ParticipantAffiliation) => {
            switch (affiliation) {
                case ParticipantAffiliation.CUSTOMER:
                    return t('external-customer');
                case ParticipantAffiliation.SUPPLIER:
                    return t('external-supplier');
                case ParticipantAffiliation.OTHER:
                    return t('other');
                case ParticipantAffiliation.ORGANIZATION:
                default:
                    return organizationName;
            }
        },
        [t, organizationName]
    );

    return { getAffiliationTranslation };
};

export default useAffiliationTranslation;
