import { Box } from '@material-ui/core';

export const DataProtectionPolicy = () => {
    return (
        <Box padding={3}>
            {/* TODO: translations for whole component */}
            <h1>Data Protection Policy</h1>
            <p>
                Welcome to ProjectPulse, a platform designed to help
                organizations succeed with projects.
            </p>
            <p>
                This Policy explains how we collect, use and disclose your
                personal data when you use the Services, and what choices you
                have with respect to your data.
            </p>
            <p>
                First a summary of the basics in case you are not here for the
                details:
            </p>
            <ul>
                <li>
                    <i>
                        ProjectPulse collects input from stakeholders to improve
                        the performance of teams and projects, and establishes a
                        channel for actionable feedback from the team, receivers
                        and other key stakeholders.
                    </i>
                </li>
                <li>
                    <i>
                        In general, ProjectPulse only processes non-sensitive
                        personal data, such as name and e-mail addresses of
                        participants.
                    </i>
                </li>
                <li>
                    <i>
                        Anonymity and privacy: ProjectPulse does not reveal
                        which responses are associated with which e-mail address
                        or personal identity. Participants will therefore be
                        anonymous unless there are very few participants. This
                        will be stated clearly: “Your responses will be visible
                        as one of X participants”. When you give feedback in
                        writing you can protect your privacy further by not
                        submitting any personal information and you can even opt
                        to make it for the project manager’s eyes only.
                    </i>
                </li>
                <li>
                    <i>
                        Sharing of information: Responses will, naturally, be
                        provided to the project manager who will often share or
                        discuss results with the team. Responses might also be
                        used at aggregated levels for senior level managers or
                        groups of projects.
                    </i>
                </li>
            </ul>
            <p>
                If you want to better understand how your responses are shared,
                or if you have any other questions or concerns regarding your
                data protection, please contact the administrator or project
                manager who has invited you to ProjectPulse.
            </p>
            <h2>1. ABOUT THIS PRIVACY POLICY</h2>
            <p>
                This Policy applies to ProjectPulse’s online tools and platform,
                including the associated mobile and desktop applications and
                content (together, the “Services”), ProjectPulses.com and other
                ProjectPulse websites (collectively, the “Websites”) and other
                interactions (e.g., customer service inquiries, user
                conferences, etc.) you may have with the Services.
            </p>
            <p>
                The Policy explains how we collect, use and disclose your
                personal data when you use the Services, and what choices you
                have with respect to your data. We have strived to present it in
                clear, plain language rather than in complex legal terms. We are
                under an obligation to protect the confidentiality, integrity
                and accessibility of our users’ personal data. Protecting
                personal data is essential to us, and we continuously ensure
                compliance with applicable data protection legislation.
            </p>
            <h2>2. WHO WE ARE</h2>
            <p>
                When we process personal data in delivering the services, we act
                as a data processor on behalf of our Customers.
            </p>
            <p>
                Our Customer is the entity who have signed up to use the
                services of ProjectPulse (whether partly, in full or as a
                trial). The customer is the data controller and may, for
                example, use the services to grant and remove access to the
                services, assign roles and configure settings, access, modify,
                export, share and remove customer data and otherwise reinforce
                customer’s own data policies to the services. We are processing
                your personal information on behalf of the Customer and in
                accordance with a data processing agreement entered into between
                Customer and Implement Consulting Group P/S, when signing up for
                the Services.
            </p>
            <p>
                We recognize that transparency is an ongoing responsibility.
                Therefore, we will review and update this Data Protection Policy
                from time to time, but if we do so, we will notify you by
                publishing the changes and, if we determine that changes are
                material, we will provide you with additional information as
                appropriate under the circumstances, including via email.
            </p>
            <h2>3. WHAT PERSONAL DATA WE PROCESS</h2>
            <p>
                We collect and process personal data in the context of
                collaboration with our Customers and to deliver the data
                processing Services to our Customers. In general, we only
                process general – non-sensitive – personal data such as name and
                email address of users.
            </p>
            <p>
                We collect and process the following information about you,
                depending on how you interact with ProjectPulse.
            </p>
            <h3>3.1 Information provided by you or the customer</h3>
            <ul>
                <li>
                    As a <b>customer</b>, we collect your name, email, address
                    and other information about you and your organization
                    required for us to give access to and deliver the services
                    requested, including managing your account and payments, and
                    providing service.
                </li>
                <li>
                    As a <b>registered user</b> you are given administrative or
                    manager access privileges by customer to use ProjectPulse’s
                    tools and services. You are then able to access content and
                    responses submitted from other users (Participants) based on
                    the particular service(s) and your permitted level of
                    access. We keep records of your name, email and login
                    credentials in order to provide you with the access and user
                    rights assigned, and to deliver the services in general,
                    including sending email notifications. This applies to all
                    types of users, including system administrators, and
                    individuals assigned access rights as managers, project
                    managers or portfolio managers.
                </li>
                <li>
                    As an <b>invited participant</b>, invited to respond to
                    surveys and submit other information we may collect your
                    name, email address, login credentials, employment
                    information (such as organizational affiliation, department
                    and office location), and all the information you submit
                    when using the ProjectPulse’s services, such as votings,
                    ratings, feedback, comments, action items and messages
                    (collectively “Responses”).
                </li>
            </ul>
            <h3>3.2 Information we collect automatically</h3>
            <ul>
                <li>
                    We collect navigation and technical information about your
                    use of ProjectPulse, such as device type, computer browser
                    type, Internet protocol address, pages visited, and average
                    time spent. We use this information to alert you to
                    compatibility issues, and to analyse and optimize our
                    websites and services.
                </li>
                <li>
                    We use cookies and Google Analytics to analyse how our
                    websites are being used and to save your selections and
                    entries with the purpose of optimizing your user experience
                    and to provide you with personalized content, and for
                    occasionally displaying different versions of content for
                    you. You may disable cookies on your computer by changing
                    the relevant settings in your browser. Only you can see,
                    change or delete these cookies, since they are stored on
                    your device. However, we hope that you will keep the cookies
                    we set, as they help us make your experience more
                    user-friendly and more personalized.
                </li>
            </ul>
            <p>
                In the following sections you can read more about how we process
                different categories of personal data, for which purposes, and
                on which legal grounds.
            </p>
            <h2>4. HOW WE PROCESS PERSONAL DATA</h2>
            <p>
                Personal data is defined as “any information about an identified
                or identifiable natural person”. This may include, for example,
                names and email addresses provided to ProjectPulse in the
                performance of our Services. Personal information that is
                aggregated and cannot be associated with an identifiable
                individual is not considered to be personal data.
            </p>
            <p>
                Regardless of the data collected, rest assured that it is only
                used for legitimate reasons to provide or optimize our services,
                and never sold nor disclosed to any third parties without your
                consent, except for a very limited number of reasons (see
                further in next section).
            </p>
            <p>
                First and foremost we process personal data in order to deliver
                the ProjectPulse Services, including to provide customer and its
                users with access to the ProjectPulse platform and customer
                support.
            </p>
            <ul>
                <li>
                    As an integral part of the services we collect, store and
                    process Responses submitted by participants and users and
                    make those Responses available to other users from your
                    organization (our customer), such as administrators and
                    managers, based upon their permitted level of access.
                </li>
                <li>
                    Individual and consolidated Responses are meant to be shared
                    or discussed within your team and with other relevant
                    stakeholders. Your Responses might be personally
                    identifiable or kept anonymous depending on the
                    configuration chosen. Your Responses might also be used at
                    aggregated levels, typically for senior level managers or
                    groups of projects.
                </li>
                <li>
                    Please contact the administrator who signed you up for
                    ProjectPulse, or the registered user who invited you to
                    participate, in order to understand how responses are shared
                    in your case.
                </li>
            </ul>
            <p>
                We process personal data for the purposes of being able to
                collaborate with our customers and to deliver our Services,
                including:
            </p>
            <ul>
                <li>
                    to create and administer customer’s account, to respond to
                    customer’s inquiries related to its account and to contact
                    customer about our services or account-related matters;
                </li>
                <li>
                    to measure and analyze user behavior in order to monitor,
                    maintain and improve the Services
                </li>
                <li>
                    to personalise or customise the experience when using the
                    services;
                </li>
                <li>
                    to meet legal and regulatory requirements and to meet
                    contractual requirements relating to the services provided;
                </li>
                <li>
                    to provide Customers with offers for additional products and
                    services that may be of interest.
                </li>
            </ul>
            <p>
                In addition, processing is necessary in order for us to pursue
                our interest in administering, managing and developing our
                business and services, including:
            </p>
            <ul>
                <li>strengthening our relationship with customers</li>
                <li>
                    developing our business and services (e.g. identifying
                    customer needs and improvements in service delivery)
                </li>
                <li>
                    managing operations and maintenance of systems that are
                    provided for or used as part of servicing the customer, as
                    well as hosting systems
                </li>
                <li>
                    conducting statistical processing and business development,
                    e.g. by creating anonymized data for marketing purposes and
                    for statistical purposes and research including for example
                    to provide benchmark comparisons with similar organizations.
                </li>
            </ul>
            <h2>5. DISCLOSURE OF PERSONAL DATA</h2>
            <p>
                We only disclose personal data to third parties when there are
                legal grounds for doing so and only for a limited number of
                reasons:
            </p>
            <h3>
                5.1 When data is needed to deliver the services by third party
                service providers
            </h3>
            <p>
                We use third parties in connection with provision of our
                services and as part of our day-to-day operations of the
                Services, such as IT hosting services, business partners, and
                search engine providers such as Google Analytics. This means
                that personal data are processed by our sub data processors.
                These sub data processors are located within the EU and there
                are data processing agreements in place to ensure that all
                processing comply with data protection laws and takes place in
                accordance with our instructions and is subject to the necessary
                security requirements.
            </p>
            <h3>5.2 When doing statistical research</h3>
            <p>
                We may disclose aggregated or anonymized data to third parties
                for market research, academic research, benchmarking, or any
                other purpose. When doing so, we do not disclose any
                identifiable information about our users or customers.
            </p>
            <h3>5.3 When required or permitted by law</h3>
            <p>
                As part of our work, it may be necessary to share personal data
                with public authorities in connection with investigations of
                unlawful activities or for other legal reasons. And we may
                disclose information (including personal information) in order
                to protect our rights, property or enforce our Terms of Use, or
                to prevent any imminent damage or threat of personal injury.
                This will only occur if and to the extent required by applicable
                legislation and will always take place within the framework of
                the applicable personal data legislation.
            </p>
            <h3>5.4 If we change business ownership or structure</h3>
            <p>
                If ownership of all or substantial parts of our business is
                transferred to a third party all data, including personal
                information of customers and users, will be part of the
                transferred assets. Or if we buy or sell assets or receive
                investments, we may disclose personal data of customers to
                prospective or actual buyer, seller or investee of such assets
                or investments.
            </p>
            <h2>6. DELETING PERSONAL DATA</h2>
            <p>
                In general, we retain personal data as long as a customer
                relationship exists and as long as necessary to comply with our
                legal obligations. Within three months after the customer
                relationship has been terminated or your data is no longer
                needed, we anonymize and aggregate your data and delete your
                personal data permanently. However, we reserve the right to
                delete or purge all such data.
            </p>
            <h2>7. YOUR RIGHTS AS A DATA SUBJECT</h2>
            <p>
                As a data subject, you have certain rights pursuant to the
                General Data Protection Regulation. Please contact the Data
                Controller to exercise these rights, i.e. the administrator or
                registered user who signed you up to participate in
                ProjectPulse.
            </p>
            <p>Your rights may be summarised as follows:</p>
            <ul>
                <li>
                    The <b>right to have access</b>. You have the right to gain
                    access to the personal data concerning you that we process.
                </li>
                <li>
                    The right to <b>have incorrect personal data rectified</b>.
                    You have the right to have incorrect personal data about
                    yourself rectified without undue delay.
                </li>
                <li>
                    The right to <b>have personal data erased</b>. You have the
                    right to have your personal data erased at an earlier point
                    in time than when our ordinary erasure takes place. Please
                    note that if such personal data is required for us to
                    provide you with access the ProjectPulse platform and
                    services, you may no longer be able to use the Services.
                    This right does not apply to non- personally identifiable or
                    anonymized data, and may be limited if your personal
                    information is required for us to process lawful business
                    transactions such as billing.
                </li>
                <li>
                    The right to <b>restriction of data processing</b>. You have
                    the right to restrict our processing of your personal data,
                    where appropriate. Again, this may hinder your continued use
                    of the Services, and may be restricted by law.
                </li>
                <li>
                    The <b>right to data portability</b>. You have the right to
                    receive your personal data in a structured, commonly used
                    and machine-readable format. You may also request that we
                    transmit your personal data to another data controller
                    without hindrance.
                </li>
                <li>
                    The <b>right to withdraw consent</b>. When we process
                    personal data based on your consent, you have the right to
                    withdraw your consent at any time without affecting the
                    lawfulness of processing based on consent before it is
                    withdrawn.
                </li>
            </ul>
            <p>
                For further information on your rights as a data subject, please
                refer to the Danish Data Protection Agency’s website
                (www.datatilsynet.dk), where you will find further guidelines.
            </p>
            <h2>8. DATA SECURITY</h2>
            <p>
                Data security is a high priority of ours. We work seriously and
                professionally with information security, and we base our work
                on internationally recognised security standards including
                regular internal follow-ups in relation to the adequacy and
                compliance of policies and measures. We have implemented
                security measures to ensure data protection of customer data,
                personal data and other confidential data appropriate to the
                nature of the information submitted to us, intended to protect
                it from unauthorized access, destruction, use, modification, or
                disclosure. However, please be aware that no method of
                transmission over the internet, or method of electronic storage
                is 100% secure, and we are unable to guarantee the absolute
                security of your data and information.
            </p>
            <h2>9. CONTACT</h2>
            <p>
                If you would like to exercise your rights as described above or
                if you have questions about the processing of your personal data
                or this data protection policy:
            </p>
            <ul>
                <li>
                    If you are a registered user or an invited participant in
                    ProjectPulse: Please contact the administrator who signed
                    you up for ProjectPulse, or the registered user who invited
                    you to participate.
                </li>
                <li>
                    If you are a customer of ProjectPulse, please contact us
                    directly at{' '}
                    <a href="mailto:legal@implementconsultinggroup.com">
                        legal@implementconsultinggroup.com
                    </a>
                </li>
            </ul>
            <p>
                The data processor is: Implement Consulting Group CBR: 32767788
                Attn.:{' '}
                <a href="mailto:legal@implementconsultinggroup.com">
                    legal@implementconsultinggroup.com
                </a>
                Strandvejen 54, 2900 Hellerup Tel: +45 4586 7900
            </p>
            <h2>10. COMPLAINTS</h2>
            <p>
                If you wish to complain about our processing of personal data,
                please send an email with the details of your complaint to{' '}
                <a href="mailto:legal@implementconsultinggroup.com">
                    legal@implementconsultinggroup.com
                </a>
                . We will then process your complaint and get back to you.
            </p>
            <p>
                You also have the right to lodge a complaint about your rights
                and Implement’s processing of your personal data to the Danish
                Data Protection Agency. For further information on how to lodge
                a complaint to the Danish Data Protection Agency, please consult
                their website:{' '}
                <a
                    href="https://www.datatilsynet.dk/"
                    target="_blank"
                    rel="noreferrer"
                >
                    www.datatilsynet.dk
                </a>
                .
            </p>
            <h2>11. STATUTORY RULES</h2>
            <p>
                The applicable statutory rules for Implement’s processing of
                your personal data may be found in:
            </p>
            <ul>
                <li>
                    The Danish Personal Data Processing Act (until 25 May 2018)
                </li>
                <li>
                    The General Data Protection Regulation (GDPR) (after 25 May
                    2018)
                </li>
                <li>The Danish Data Protection Act (after 25 May 2018)</li>
            </ul>
        </Box>
    );
};
