import {
    createLanguageFromResponse,
    emptyLanguage,
    ILanguage,
    ILanguageResponse,
} from 'modules/languages/models/language.model';
import { IEntity } from '../../../core/interfaces/entity.interface';

export interface IScaleQuestionDetails {
    archived: boolean;
    deletedAt: Date | null;
    pulseQuestionId?: string;
    mandatory: boolean;
    useAsDefault: boolean;
    name: string;
    phrase: string;
    organizationId: string | null;
    preferenceOrganizationId: string | null;
    translations: ITranslation[];
    questionId: string;
    order: number;
}

export interface ITranslationDetails {
    name: string;
    phrase: string;
    language: ILanguage;
}

export interface ITranslation extends ITranslationDetails, IEntity {}

export interface IScaleQuestion extends IEntity, IScaleQuestionDetails {}

export const emptyScaleQuestionDetails: IScaleQuestionDetails = {
    archived: false,
    name: '',
    phrase: '',
    organizationId: null,
    mandatory: false,
    useAsDefault: false,
    preferenceOrganizationId: null,
    translations: [],
    questionId: '',
    deletedAt: null,
    order: -1,
};

export const createScaleQuestion = ({
    id,
    ...props
}: Partial<IScaleQuestion>): IScaleQuestion => {
    if (!id) {
        throw new Error('Unable to create a question without an ID!');
    }

    return {
        id,
        ...emptyScaleQuestionDetails,
        ...props,
    };
};

export interface IScaleQuestionPreferenceResponseDetails {
    mandatory: boolean;
    use_as_default: boolean;
    organization: IEntity<number> | null;
    deleted_at?: string | null;
    position: number;
}

export interface IScaleQuestionResponseDetails {
    archived: boolean;
    name: string;
    phrase: string;
    organization: IEntity<number> | null;
    translations: ITranslationResponse[];
}

export interface IScaleQuestionResponse
    extends IScaleQuestionPreferenceResponseDetails,
        IEntity<number> {
    scale_question: IScaleQuestionResponseDetails & IEntity<number>;
}

export interface ITranslationResponseDetails {
    name: string | null;
    phrase: string | null;
    language: ILanguageResponse;
}

export interface ITranslationResponse
    extends ITranslationResponseDetails,
        IEntity<number> {}

export const emptyTranslation: ITranslationDetails = {
    name: '',
    phrase: '',
    language: {
        id: '',
        ...emptyLanguage,
    },
};

export const createTranslation = ({
    id,
    ...props
}: Partial<ITranslation>): ITranslation => {
    if (!id) {
        throw new Error('Unable to create a translation without an ID!');
    }
    return {
        id,
        ...emptyTranslation,
        ...props,
    };
};
export const createTranslationFromReponse = ({
    id,
    name,
    phrase,
    language,
}: ITranslationResponse): ITranslation => {
    return createTranslation({
        id: id.toString(),
        language: createLanguageFromResponse(language),
        phrase: phrase ?? emptyTranslation.phrase,
        name: name ?? emptyTranslation.name,
    });
};

export const createScaleQuestionFromResponse = ({
    id,
    organization: preference_organization,
    scale_question,
    use_as_default,
    deleted_at,
    mandatory,
    position,
}: IScaleQuestionResponse): IScaleQuestion => {
    const {
        id: question_id,
        organization,
        translations,
        ...scaleQuestion
    } = scale_question;

    return createScaleQuestion({
        id: id.toString(),
        questionId: question_id.toString(),
        organizationId: organization?.id.toString() ?? null,
        preferenceOrganizationId:
            preference_organization?.id.toString() ?? null,
        useAsDefault: use_as_default,
        deletedAt: deleted_at ? new Date(deleted_at) : null,
        translations: translations
            ? translations.map(createTranslationFromReponse)
            : [],
        mandatory,
        order: position,
        ...scaleQuestion,
    });
};

interface IScaleQuestionCommandTemp
    extends Omit<
        IScaleQuestionResponseDetails,
        'translations' | 'preferenceId' | 'archived' | 'organization'
    > {
    organization: IEntity<number | null>;
}

export interface IScaleQuestionCommand
    extends Omit<IScaleQuestionPreferenceResponseDetails, 'organization'> {
    organization: IEntity<number | null>;
    scale_question: IScaleQuestionCommandTemp;
}

export interface IScaleQuestionsPost {
    mandatory: false;
    use_as_default: false;
    scale_question: {
        name: string;
        phrase: string;
    };
}

export const createScaleQuestionCommand = ({
    useAsDefault,
    mandatory,
    organizationId,
    name,
    phrase,
    preferenceOrganizationId,
    order,
}: IScaleQuestionDetails): IScaleQuestionCommand => ({
    mandatory,
    use_as_default: useAsDefault,
    organization: {
        id: preferenceOrganizationId ? +preferenceOrganizationId : null,
    }, // id === null if super admin page, else account settgings page,
    scale_question: {
        name,
        phrase,
        organization: { id: organizationId ? +organizationId : null },
    },
    position: order,
});
