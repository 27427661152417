import { FrequencyValue } from '../enums/frequency-value';
import { IFrequency } from '../interfaces/frequency';

export const frequencies: IFrequency[] = [
    {
        label: 'Once',
        value: FrequencyValue.ONCE,
    },
    {
        label: 'Every Week',
        value: FrequencyValue.EVERY_WEEK,
    },
    {
        label: 'Every 2 Week',
        value: FrequencyValue.EVERY_2_WEEK,
    },
    {
        label: 'Every 3 Week',
        value: FrequencyValue.EVERY_3_WEEK,
    },
    {
        label: 'Every 4 Week',
        value: FrequencyValue.EVERY_4_WEEK,
    },
    {
        label: 'Monthly on 1st occurrence of chosen weekday',
        value: FrequencyValue.MONTHLY_1,
    },
    {
        label: 'Monthly on 2nd occurrence of chosen weekday',
        value: FrequencyValue.MONTHLY_2,
    },
    {
        label: 'Every 2nd month on 1st occurrence of chosen weekday',
        value: FrequencyValue.BI_MONTHLY,
    },
    {
        label: 'Every 3rd month on 1st occurrence of chosen weekday',
        value: FrequencyValue.QUARTERLY,
    },
];
