import {
    applyTransaction,
    combineQueries,
    QueryConfigOptions,
    selectPersistStateInit,
} from '@datorama/akita';
import { Subject, Subscription } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import { defaultUnsubscribeTimoutPeriod } from '../constants/clear-timeout-period';
import { IEntity } from '../interfaces/entity.interface';
import { EntitiesState, EntitiesStore } from './entities.store';
import { QueryEntityWithHighlight } from './highlight.query';

export abstract class EntitiesQuery<
    T extends IEntity
> extends QueryEntityWithHighlight<EntitiesState<T>> {
    private _instanceCounter = 0;
    private _shuoldFetchEntitiesSubscription: Subscription | null = null;
    private _unsubscribeTimeout: NodeJS.Timeout | null = null;

    private _init$ = new Subject();
    private _hasInitialized = false;

    get hasInitialized() {
        return this._hasInitialized;
    }

    readonly init$ = this._init$.asObservable();
    readonly searchTerm$ = this.select(({ ui }) => ui.searchTerm);
    readonly pageNumber$ = this.select(({ ui }) => ui.pageNumber);
    readonly pageSize$ = this.select(({ ui }) => ui.pageSize);
    readonly filter$ = this.select(({ ui }) => ui.filter);
    readonly orderBy$ = this.select(({ ui }) => ui.orderBy);
    readonly orderDirection$ = this.select(({ ui }) => ui.orderDirection);
    readonly total$ = this.select(({ total }) => total);
    readonly currentPageIds$ = this.select(
        ({ currentPageIds }) => currentPageIds
    );
    readonly pagesIds$ = this.select(({ pagesIds }) => pagesIds);
    readonly currentPageEntities$ = combineQueries([
        this.currentPageIds$,
        this.selectAll({ asObject: true }),
    ]).pipe(map(([ids, entities]) => ids.map((id) => entities[id])));
    readonly allPageEntities$ = combineQueries([
        this.pagesIds$,
        this.selectAll({ asObject: true }),
    ]).pipe(
        map(([pagesIds, entities]) =>
            Array.from(new Set(([] as string[]).concat(...pagesIds))).map(
                (id) => entities[id]
            )
        )
    );
    readonly shuoldFetchEntities$ = combineQueries([
        this.searchTerm$,
        this.pageSize$,
        this.pageNumber$,
        this.filter$,
        this.orderBy$,
        this.orderDirection$,
    ]);

    readonly actionStatus$ = this.select('actionStatus');

    constructor(
        public store: EntitiesStore<T, any, any>,
        options?: Partial<QueryConfigOptions<any>>
    ) {
        super(store, options);

        selectPersistStateInit().subscribe(() => {
            this._hasInitialized = true;
            this._init$.next();
        });
    }

    /**
     * Start listening to shuoldFetchEntities$ and call fetch on every emit
     * This is used in WithCurrentPageListener and called on component did mount
     */
    startCurrentPageListener = () => {
        if (this._unsubscribeTimeout) {
            clearTimeout(this._unsubscribeTimeout);
            this._unsubscribeTimeout = null;
        }

        this._instanceCounter++;

        setTimeout(async () => {
            if (!this.hasInitialized) {
                this.init$.subscribe(async () => {
                    const { currentPageIds } = this.store.getValue();

                    if (!currentPageIds.length) {
                        await this.store.fetchEntities();
                    }
                });
            } else {
                const { currentPageIds } = this.store.getValue();

                if (!currentPageIds.length) {
                    await this.store.fetchEntities();
                }
            }

            if (!this._shuoldFetchEntitiesSubscription) {
                this._shuoldFetchEntitiesSubscription = this.shuoldFetchEntities$
                    .pipe(skip(1))
                    .subscribe(async () => {
                        if (this.hasInitialized) {
                            await this.store.fetchEntities();
                        }
                    });
            }
        });
    };

    /**
     * Stop listening to shuoldFetchEntities$ and clear current page entities after unsubscribeTimoutPeriod
     * This is used in WithCurrentPageListener and called on component unmount
     */
    stopCurrentPageListener = (
        unsubscribeTimoutPeriod = defaultUnsubscribeTimoutPeriod
    ): void => {
        if (--this._instanceCounter) {
            return;
        }

        if (this._shuoldFetchEntitiesSubscription) {
            this._shuoldFetchEntitiesSubscription.unsubscribe();
            this._shuoldFetchEntitiesSubscription = null;
        }

        if (!this._unsubscribeTimeout) {
            this._unsubscribeTimeout = setTimeout(() => {
                applyTransaction(() => {
                    this.store.setCurrentPageEntities([]);
                    this.store.patchUIState({ searchTerm: '', pageNumber: 1 });
                });
            }, unsubscribeTimoutPeriod);
        }
    };
}
