import { FC, useContext } from 'react';
import Chart from 'react-apexcharts';
import { Typography } from '@material-ui/core';
import { MultiLanguageSupportContext } from '../../../../core/components/MultiLanguageSupportProvider';
import { ApexOptions } from 'apexcharts';
import { IGraphData } from '../../models/dashboard.models';

export interface TimeSeriesGraphProps {
    data: IGraphData<string | null>[];
    options: ApexOptions;
}

export const TimeSeriesGraph: FC<TimeSeriesGraphProps> = ({
    data,
    options,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);

    return (
        <>
            <Typography align="center" variant="h4">
                {t('time-series')}
            </Typography>
            <Chart
                series={data}
                options={options}
                type="line"
                height={'300px'}
            />
           
        </>
    );
};
