import { IEntity } from '../../../core/interfaces/entity.interface';

export interface IActivity extends IEntity {
    author_id: {
        id: number;
        name: string;
        email: string;
        access_profile: string;
        is_new: boolean;
        created_at: Date;
        updated_at: Date;
    };
    event_type_id: boolean;
    severity_type_id: boolean;
    message: string;
    created_at: string;
    updated_at: Date;
}

export interface IActivityResponse extends IEntity {
    author_id: {
        id: number;
        name: string;
        email: string;
        access_profile: string;
        is_new: boolean;
        created_at: string;
        updated_at: string;
    };
    event_type_id: boolean;
    severity_type_id: boolean;
    message: string;
    created_at: string;
    updated_at: string;
}

export const createNewActivity = ({
    id,
    ...props
}: Partial<IActivity>): IActivity => {
    if (!id) {
        throw new Error('Unable to create a Activity an ID!');
    }

    return {
        id,
        author_id: {
            id: 0,
            name: '',
            email: '',
            access_profile: '',
            is_new: false,
            created_at: new Date(),
            updated_at: new Date(),
        },
        event_type_id: false,
        severity_type_id: false,
        message: '',
        created_at: '',
        updated_at: new Date(),
        ...props,
    };
};

export const createNewActivityFromResponse = ({
    id,
    author_id,
    updated_at: activityUpdateAt,
    ...props
}: IActivityResponse): IActivity => {
    const { created_at, updated_at, ...author } = author_id;
    return createNewActivity({
        id: id.toString(),
        author_id: {
            ...author,
            created_at: new Date(created_at),
            updated_at: new Date(updated_at),
        },
        updated_at: new Date(activityUpdateAt),
        ...props,
    });
};
