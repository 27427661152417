import { Grid, TextField } from '@material-ui/core';
import { EntityTranslationsPage } from 'core/components/shared/EntityTranslationsPage';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import {
    ITranslatesFormik,
    ITranslatesQuestionsPhrasesFormik,
} from './QuestionsDialogContent';

export interface QuestionsTranslationsProps {
    disabled?: boolean;
    languageTranslationRelations: ITranslatesFormik;
}

export const QuestionsTranslationsPage: FC<QuestionsTranslationsProps> = ({
    disabled = false,
    languageTranslationRelations,
}) => {
    const {
        handleChange,
        handleBlur,
        values,
    } = useFormikContext<ITranslatesQuestionsPhrasesFormik>();

    return (
        <EntityTranslationsPage
            renderRow={
                !Object.keys(languageTranslationRelations).length
                    ? undefined
                    : (language) => {
                          const key = languageTranslationRelations[language.id];

                          if (!key) {
                              return <></>;
                          }

                          return (
                              <Grid container spacing={3} key={language.id}>
                                  <Grid item md={4}>
                                      <TextField
                                          variant="outlined"
                                          margin="normal"
                                          fullWidth
                                          type="text"
                                          name={`name.${key}`}
                                          required
                                          disabled={disabled}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          label={`${'title'} in ${
                                              language.name
                                          }`}
                                          value={values.name?.[key] ?? ''}
                                      />
                                  </Grid>
                                  <Grid item md={8}>
                                      <TextField
                                          variant="outlined"
                                          margin="normal"
                                          fullWidth
                                          type="text"
                                          name={`phrase.${key}`}
                                          required
                                          disabled={disabled}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          label={`${'question'} in ${
                                              language.name
                                          }`}
                                          value={values.phrase?.[key] ?? ''}
                                      />
                                  </Grid>
                              </Grid>
                          );
                      }
            }
        />
    );
};
