import { Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { IEntity } from 'core/interfaces/entity.interface';
import React, { useEffect, useMemo, useState } from 'react';
import { apiService } from '../services/apiService';

export interface IAutocompleteDefaultOption extends IEntity {
    name: string;
}

export interface ImplementPulseAutoCompleteProps<T, R> {
    url?: string;
    data?: T[];
    label: string;
    stringify: (option: T) => string;
    responseMapper?: (entities: R) => T[];
    onSelect?: (value: T | null) => Promise<void>;
    description?: string;
    value?: any | null;
    valueProperty?: keyof T;
    disabled?: boolean;
}

export const ImplementPulseAutoComplete = <T, R>({
    url,
    data = [],
    label,
    description,
    stringify,
    responseMapper,
    onSelect,
    value = null,
    valueProperty,
    disabled = false,
}: ImplementPulseAutoCompleteProps<T, R>): JSX.Element => {
    const [responseData, setResponseData] = useState<R | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [tempDisabled, setTempDisabled] = useState(false);

    useEffect(() => {
        !disabled &&
            !responseData &&
            url &&
            apiService
                .get<R>(`${url}`)
                .then(({ data }) => setResponseData(data));
    }, [disabled, responseData, url]);

    const options = useMemo(
        () =>
            !responseData || !responseMapper
                ? data
                : responseMapper(responseData),
        [responseMapper, responseData, data]
    );

    const realValue = useMemo(() => {
        return (
            (valueProperty
                ? options.find((option) => option[valueProperty] === value)
                : value) ?? null
        );
    }, [options, value, valueProperty]);

    return (
        <Box>
            <Autocomplete
                fullWidth
                options={options}
                onInputChange={(_e, newInputValue) =>
                    setInputValue(newInputValue)
                }
                disabled={disabled || tempDisabled}
                onChange={async (_e, newValue) => {
                    setTempDisabled(true);
                    if (newValue) {
                        setInputValue(stringify(newValue));
                    }
                    onSelect && (await onSelect(newValue));
                    setTempDisabled(false);
                }}
                getOptionLabel={stringify}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                value={realValue}
                inputValue={inputValue}
            />
            {description && (
                <Typography
                    className="MuiTypography-badge"
                    color="textSecondary"
                >
                    {description}
                </Typography>
            )}
        </Box>
    );
};
