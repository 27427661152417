import { StoreConfig } from '@datorama/akita';
import {
    EntitiesState,
    EntitiesStore,
} from '../../../core/state/entities.store';
import {
    createLanguageFromResponse,
    ILanguage,
    ILanguageResponse,
} from '../models/language.model';

export type LanguagesState = EntitiesState<ILanguage>;

@StoreConfig({
    name: 'languages',
    resettable: true,
})
class LanguagesStore extends EntitiesStore<ILanguage, ILanguageResponse> {}

export const languagesStore = new LanguagesStore({
    responseMapper: createLanguageFromResponse,
});
