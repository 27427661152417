import { IAccountResponse } from 'modules/accounts/models/account.model';
import {
    createCategoryFromResponse,
    ICategory,
    ICategoryResponse,
} from 'modules/categories/models/category.model';
import { IEntity } from '../../../core/interfaces/entity.interface';

export enum USER_ROLE {
    SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
    ACCOUNT_ADMIN = 'ROLE_ACCOUNT_ADMIN',
    PORTFOLIO_ADMIN = 'ROLE_PORTFOLIO_ADMIN',
    PROJECT_ADMIN = 'ROLE_PROJECT_ADMIN',
    // DEFAULT = 'ROLE_USER',
}

export interface IUserDetails {
    email: string;
    name: string;
    role: USER_ROLE;
    password: string;
    isNew: boolean;
    shouldResetPassword: boolean;
    organizationId: string | null;
    organizationName: string;
    categories?: ICategory[];
}

export interface IUser extends IEntity, IUserDetails {}

export interface IUserResponse extends IEntity<number> {
    email: string;
    name: string;
    role: USER_ROLE;
    password: string;
    is_new: boolean;
    access_level: string; // FE does not handle this property
    access_profile: string; // FE does not handle this property
    should_reset_password: boolean;
    organization?: IAccountResponse;
    dimension_entries?: ICategoryResponse[];
}

export const emptyUser: IUserDetails = {
    email: '',
    name: '',
    role: USER_ROLE.PROJECT_ADMIN,
    password: '',
    isNew: false,
    shouldResetPassword: false,
    organizationId: null,
    organizationName: '',
};

export const createUser = ({ id, ...props }: Partial<IUser>): IUser => {
    if (!id) {
        throw new Error('Unable to create a user without an ID!');
    }

    return {
        id,
        ...emptyUser,
        ...props,
    };
};

export const createUserFromResponse = ({
    id,
    is_new,
    access_level,
    access_profile,
    should_reset_password,
    organization,
    dimension_entries,
    ...props
}: IUserResponse): IUser => {
    const organizationId = organization ? organization.id.toString() : null;
    const organizationName = organization?.name ?? '';
    const categories = dimension_entries?.map((response) =>
        createCategoryFromResponse(response)
    );

    return createUser({
        id: id.toString(),
        isNew: is_new,
        shouldResetPassword: should_reset_password,
        organizationId,
        organizationName,
        categories,
        ...props,
    });
};

export interface IUserCommand {
    email: string;
    name: string;
    role: string;
    password: string;
    is_new: boolean;
    should_reset_password: boolean;
    organization?: IEntity<number>;
    dimension_entries: IEntity<number>[];
}

export const createUserCommand = ({
    isNew,
    shouldResetPassword,
    organizationId,
    organizationName,
    categories,
    ...props
}: IUserDetails): IUserCommand => {
    const organization = organizationId ? { id: +organizationId } : undefined;
    const dimension_entries =
        categories?.map((category) => ({
            id: +category.id,
        })) ?? [];

    return {
        is_new: isNew,
        should_reset_password: shouldResetPassword,
        organization,
        dimension_entries,
        ...props,
    };
};
