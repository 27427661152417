import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback, useContext } from 'react';
import { Form, useFormikContext } from 'formik';
import { ILanguageDetails } from '../models/language.model';
import { Box, Button, Grid, TextField, DialogContent, Container } from '@material-ui/core';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { LanguageDialogContext } from './LanguageDialogProvider';
import { useCrud } from '../../../core/hooks/crud.hook';
import css from 'styles/material-ui/cssVars';
import { FormDialogWrapper } from '../../../core/components/shared/FormDialogWrapper';
import { useDialog } from '../../projects/components/use-dialog.hook';
import { AlertModal } from '../../pulse/components/AlertModal';

const useStyles = makeStyles((theme) => ({
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        padding: 0
    },
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        backgroundColor: css.extraColors.text.darkPrimary,
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        },
    },
    btnWrapper: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            marginTop: `${theme.spacing(2)}px`,
        },
        '& button': {
            marginRight: theme.spacing(2),

            '&:last-child': {
                marginRight: 0,
                [theme.breakpoints.down('xs')]: {
                    marginBottom: 0,
                },
            },

            [theme.breakpoints.down('xs')]: {
                width: '100%',
                marginRight: '0',
                marginBottom: theme.spacing(2),
            },
        },
    },
}));

export const LanguageForm: FC = () => {
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);
    const { open, handleOpen, handleClose } = useDialog();

    const { close, languageId } = useContext(LanguageDialogContext);

    const { deleteMultiple } = useCrud();

    const onDelete = useCallback(async () => {
        languageId && (await deleteMultiple([languageId]));
        close();
    }, []);

    const {
        values,
        handleChange,
        isSubmitting,
        submitForm,
        errors,
    } = useFormikContext<ILanguageDetails>();

    return (
        <DialogContent className={classes.modalContent}>
            <Form className={classes.form}>
                <Grid container>
                    <Container maxWidth="md">
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="name"
                                name="name"
                                onChange={handleChange}
                                value={values.name}
                                // label={t('account-name')}
                                label={'Language Name'}
                                error={Boolean(errors.name)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="locale"
                                name="locale"
                                onChange={handleChange}
                                value={values.locale}
                                // label={t('locale')}
                                label={'Locale'}
                                error={Boolean(errors.locale)}
                            />
                        </Grid>
                    </Container>
                </Grid>
                <Box className={classes.modalActions} mt={4}>
                    <Button type="reset" variant="text" onClick={close}>
                        {t('cancel')}
                    </Button>

                    <Box className={classes.btnWrapper}>
                        {languageId && (
                            <>
                                <Button
                                    variant="contained"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginRight: '15px',
                                        padding: '10px',
                                        color: 'red',
                                    }}
                                    disableElevation
                                    disabled={isSubmitting}
                                    onClick={handleOpen}
                                >
                                    {t('delete-language')}
                                </Button>
                                <FormDialogWrapper
                                    isOpen={open}
                                    handleClose={handleClose}
                                    alertModal
                                >
                                    <AlertModal
                                        title={t('delete-language')}
                                        isActionDestructive={true}
                                        contentText={t(
                                            'delete-language-content-message'
                                        )}
                                        closeModalText={t('keep-language')}
                                        submitModalText={t('yes-delete-language')}
                                        handleClose={handleClose}
                                        handleSubmit={onDelete}
                                    />
                                </FormDialogWrapper>
                            </>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                textTransform: 'capitalize',
                            }}
                            disableElevation
                            disabled={isSubmitting}
                            onClick={submitForm}
                        >
                            {/* TODO: Translate */}
                            {!languageId ? 'Create Language' : t('save-changes')}
                        </Button>
                    </Box>
                </Box>

            </Form>
        </DialogContent>
    );
};
