import { EntitiesQuery } from 'core/state/entities.query';
import { EntitiesStore, IActionStatus } from 'core/state/entities.store';
import { projectsQuery } from 'modules/projects/state/projects.query';
import { projectsStore } from 'modules/projects/state/projects.store';
import { pulsesQuery } from 'modules/pulse/state/pulse.query';
import { pulseStore } from 'modules/pulse/state/pulse.store';
import { useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { accountsQuery } from '../../modules/accounts/state/accounts.query';
import { accountsStore } from '../../modules/accounts/state/accounts.store';
import { activityQuery } from '../../modules/activity/state/activity.query';
import { activityStore } from '../../modules/activity/state/activity.store';
import { participantsQuery } from '../../modules/participants/state/participants.query';
import { participantsStore } from '../../modules/participants/state/participants.store';
import { pulseParticipantsQuery } from '../../modules/pulse-participants/state/pulse-participants.query';
import { pulseParticipantsStore } from '../../modules/pulse-participants/state/pulse-participants.store';
import { scaleQuestionsQuery } from '../../modules/scale-questions/state/scale-questions.query';
import { scaleQuestionsStore } from '../../modules/scale-questions/state/scale-questions.store';
import { factorsQuery } from '../../modules/success-factors/state/factors.query';
import { factorsStore } from '../../modules/success-factors/state/factors.store';
import { usersQuery } from '../../modules/users/state/users.query';
import { usersStore } from '../../modules/users/state/users.store';
import { languagesQuery } from '../../modules/languages/state/languages.query';
import { languagesStore } from '../../modules/languages/state/languages.store';

const entityStates: {
    store: EntitiesStore<any, any>;
    query: EntitiesQuery<any>;
}[] = [
    { query: projectsQuery, store: projectsStore },
    { query: pulsesQuery, store: pulseStore },
    { query: accountsQuery, store: accountsStore },
    { query: participantsQuery, store: participantsStore },
    { query: activityQuery, store: activityStore },
    { query: pulseParticipantsQuery, store: pulseParticipantsStore },
    { query: scaleQuestionsQuery, store: scaleQuestionsStore },
    { query: factorsQuery, store: factorsStore },
    { query: usersQuery, store: usersStore },
    { query: languagesQuery, store: languagesStore },
];

interface ITypedActionStatus extends IActionStatus {
    type: string;
}

export const useActionStatus = (): ITypedActionStatus | null => {
    const [actionStatus, setActionStatus] = useState<ITypedActionStatus | null>(
        null
    );

    useEffect(() => {
        combineLatest(
            entityStates.map(({ store, query: { actionStatus$ } }) =>
                actionStatus$.pipe(
                    tap((status) => {
                        if (status) {
                            setActionStatus({
                                ...status,
                                type: store.storeName,
                            });
                            store.update({ actionStatus: undefined });
                        }
                    })
                )
            )
        ).subscribe();
    }, []);

    return actionStatus;
};
