import { useObservable } from '@mindspace-io/react';
import React, { FC, useContext, useEffect } from 'react';
import { EntitiesContext } from '../constants/entities.context';
import { IUseCrud, useCrud } from '../hooks/crud.hook';
import { IEntity } from '../interfaces/entity.interface';

export interface IWithEntityByIdProps<T> {
    id: string;
    successCallback?: (entity: T) => any;
    failureCallback?: (id: string, e: any) => any;
}

export interface IComponentWrappedWithEntityByIdProps<T>
    extends Partial<IUseCrud<T>> {
    entity: T;
    [key: string]: any;
}

export const WithEntityById = <T extends IEntity>(
    WrappedComponent: FC<IComponentWrappedWithEntityByIdProps<T>>
) => {
    const HocComponent: FC<IWithEntityByIdProps<T> & IUseCrud<T>> = ({
        id,
        successCallback,
        failureCallback,
        ...props
    }) => {
        const { getById } = useCrud<T>();
        const { query } = useContext(EntitiesContext);
        const [entity] = useObservable(query.selectEntity(id));

        useEffect(() => {
            getById(id)
                .then((result) => {
                    if (successCallback) {
                        successCallback(result);
                    }
                })
                .catch((e) => {
                    if (failureCallback) {
                        failureCallback(id, e);
                    }
                });
        }, [failureCallback, id, props, successCallback]);

        return !entity ? (
            <span>Loading...</span>
        ) : (
            <WrappedComponent entity={entity as T} {...props} />
        );
    };

    Object.defineProperty(HocComponent, 'name', {
        value: `WithEntityById(${WrappedComponent.name})`,
        configurable: true,
    });

    return HocComponent as FC<IWithEntityByIdProps<T>>;
};
