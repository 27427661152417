import { convertDate } from 'shared/yup/utils/convertDate';

export interface IBrandingSettings {
    action_text_color: string;
    header_background_color: string;
    logo: string;
    logo_link: string;
    primary_color: string;
    project_name: string;
}

export interface IStyleProps {
    primaryColor?: string;
    headerBackground?: string;
    actionText?: string;
}

interface IFeedbackReturnManager {
    access_profile: string;
    email: string;
    id: number;
    is_new: boolean;
    name: string;
}

interface IFeedbackReturnPulseEventRespondent {
    answered: boolean;
    created_at: string;
    id: string;
    lab_time: number;
    updated_at: string;
}

interface IFeedbackReturnRespondent {
    name: string;
}

export interface IFeedbackReturn {
    feedback_replies: IFeedbackReply[];
    id: string;
    message: string;
    public: boolean;
    pulse_event_respondent: IFeedbackReturnPulseEventRespondent;
    respondent: IFeedbackReturnRespondent | null;
    manager: IFeedbackReturnManager;
}

export interface ISurveyLabelTranslation {
    id: number;
    translation_key: string;
    translation_content: string;
}

export interface IFeedbackReply {
    id: number;
    message: string;
    reply_from: string;
    created_at: string;
}

export interface IFeedbackResult {
    feedbackId: string;
    feedbackMessage: string;
    pulseId: string;
    pulseDate: Date;
    pulseName: string;
    projectId: string;
    lastMessage: string;
    isPublic: boolean;
}

export interface IFeedbackResultResponse {
    feedback_id: string;
    feedback_message: string;
    pulse_id: string;
    pulse_date: string;
    pulse_name: string;
    project_id: string;
    last_message: string;
    public: '0' | '1';
}

export const createFeedbackResultFromResponse = ({
    feedback_id,
    feedback_message,
    pulse_id,
    pulse_date,
    pulse_name,
    project_id,
    last_message,
    public: _public, // If not renamed, the following error is thrown: Identifier expected. 'public' is a reserved word in strict mode. Modules are automatically in strict mode.ts
}: IFeedbackResultResponse): IFeedbackResult => {
    return {
        feedbackId: feedback_id,
        feedbackMessage: feedback_message,
        pulseId: pulse_id,
        pulseDate: convertDate(pulse_date),
        // pulseDate: new Date(
        //     Date.parse(`${pulse_date.split(' ').join('T')}+0000`) // Making sure the Date is GMT/UTC
        // ),
        pulseName: pulse_name,
        projectId: project_id,
        lastMessage: last_message,
        isPublic: _public === '1',
    };
};

export interface ISendMessage {
    message: string;
}
