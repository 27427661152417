import { Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LanguageIcon from '@material-ui/icons/Language';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ListActionIcon } from 'core/components/shared/ListActionIcon';
import { FC, useCallback, useContext } from 'react';
import { EntityListActions } from '../../../core/components/EntityListActions';
import {
    IComponentWrappedWithEntityByIdProps,
    WithEntityById,
} from '../../../core/hocs/WithEntityById';
import { ISuccessFactor } from '../models/success-factor.model';
import { SuccessFactorsFormContext } from './SuccessFactorsFormProvider';

export const FactorsListActions: FC<
    IComponentWrappedWithEntityByIdProps<ISuccessFactor>
> = ({ entity: factor }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { open } = useContext(SuccessFactorsFormContext);

    const getActions = useCallback(
        () => [
            {
                callback: () => open(factor, 1),
                component: (
                    <ListActionIcon
                        iconComponent={
                            <Tooltip title={t('tooltip-translations')}>
                                <LanguageIcon />
                            </Tooltip>
                        }
                    />
                ),
            },
            {
                callback: () => open(factor),
                component: (
                    <ListActionIcon
                        iconComponent={
                            <Tooltip title={t('tooltip-edit')}>
                                <EditIcon />
                            </Tooltip>
                        }
                    />
                ),
            },
        ],
        [factor]
    );

    return <EntityListActions actions={getActions()} />;
};

export const FactorsListActionsById = WithEntityById(FactorsListActions);
