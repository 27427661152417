import { subject } from '@casl/ability';
import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { useSession } from 'core/hooks/session.hook';
import { ICreateEntityOptions } from 'core/interfaces/create-entity-options.interface';
import { emailValidation, requiredField } from 'core/validations';
import { FormikProvider, useFormik } from 'formik';
import {
    getCategoriesByDimensionId,
    ICategoriesByDimensionId,
} from 'modules/categories/components/DimenstionInputs';
import { ICategory } from 'modules/categories/models/category.model';
import React, { createContext, FC, useCallback, useState } from 'react';
import * as Yup from 'yup';
import {
    createUser,
    emptyUser,
    IUser,
    IUserDetails,
} from '../models/user.model';
import { usersQuery } from '../state/users.query';

export interface IUserFormik extends Omit<IUserDetails, 'categories'> {
    openNewUser?: boolean;
    categoriesByDimensionId: ICategoriesByDimensionId;
}

interface IUserFormContext {
    userId: string | null;
    isOpen: boolean;
    close: () => void;
    open: (
        user: Partial<IUser>,
        options?: Partial<ICreateEntityOptions>
    ) => void;
}

export const UserFormContext = createContext<IUserFormContext>(
    {} as IUserFormContext
);

const validationSchema = Yup.object().shape({
    name: requiredField,
    email: emailValidation,
    organizationId: requiredField,
    role: requiredField,
});

export const UserFormProvider: FC = WithProvider(({ children }) => {
    const { me, abilities } = useSession();
    const [isOpen, setIsOpen] = useState(false);
    const [userId, setUserId] = useState<string | null>(null);
    const [initialValues, setInitialValues] = useState<IUserFormik>({
        ...emptyUser,
        categoriesByDimensionId: {},
    });
    const [crudEntityOptions, setCrudEntityOptions] = useState<{
        shouldFetchAfterSuccess?: boolean;
    }>({});

    const close = () => {
        setIsOpen(false);
    };

    const open = (
        { id, categories, ...details }: Partial<IUser>,
        options: Partial<ICreateEntityOptions> = {}
    ) => {
        setIsOpen(true);
        setUserId(id ?? null);
        setInitialValues({
            ...emptyUser,
            ...details,
            categoriesByDimensionId: getCategoriesByDimensionId(categories),
        });
        setCrudEntityOptions(options);
    };

    const { createSingle, updateSingle } = useCrud<IUser>();

    const onSubmit = useCallback(
        async ({
            openNewUser,
            categoriesByDimensionId,
            ...data
        }: IUserFormik) => {
            const details: IUserDetails = {
                ...data,
                categories: Object.keys(categoriesByDimensionId)
                    .map((dimensionId) => categoriesByDimensionId[dimensionId])
                    .filter((x) => !!x) as ICategory[],
            };

            const user = userId
                ? abilities.can(
                      'update',
                      subject('IUser', {
                          id: userId,
                      })
                  ) &&
                  (await updateSingle(
                      createUser({
                          id: userId,
                          ...details,
                      }),
                      crudEntityOptions
                  ))
                : abilities.can('create', 'IUser') &&
                  (await createSingle(details, crudEntityOptions));

            setInitialValues({
                ...emptyUser,
                categoriesByDimensionId: {},
            });

            if (!openNewUser) {
                close();
            } else {
                open(
                    {
                        organizationId: me?.organizationId,
                        organizationName: me?.organizationName,
                    },
                    { shouldFetchAfterSuccess: true }
                );
            }

            return user;
        },
        [userId]
    );

    const formik = useFormik<IUserFormik>({
        initialValues,
        enableReinitialize: true,
        validateOnMount: !!userId,
        onSubmit,
        validationSchema,
    });

    return (
        <UserFormContext.Provider
            value={{
                isOpen,
                close,
                open,
                userId,
            }}
        >
            <FormikProvider value={formik}>{children}</FormikProvider>
        </UserFormContext.Provider>
    );
}, usersQuery);
