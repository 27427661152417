import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useSession } from 'core/hooks/session.hook';
import { Form, useFormikContext } from 'formik';
import React, { FC, useContext } from 'react';
import {
    IParticipantDetails,
    ParticipantAffiliation,
    ParticipantRole,
} from '../models/participant.model';

interface IParticipantFormProps {
    participantId: string | null;
}

export const ParticipantForm: FC<IParticipantFormProps> = ({
    participantId,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);

    const { me, abilities } = useSession();

    const {
        values,
        errors,
        handleBlur,
        handleChange,
    } = useFormikContext<IParticipantDetails>();

    return (
        <Form>
            <Box>
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        label={t('name')}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        type="text"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        error={!!errors.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t('email')}
                        variant="outlined"
                        margin="normal"
                        required
                        disabled={
                            participantId
                                ? abilities.cannot(
                                      'update',
                                      'IParticipant',
                                      'email'
                                  )
                                : false
                        }
                        fullWidth
                        id="email"
                        type="text"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={!!errors.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t('department')}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="department"
                        type="text"
                        name="department"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.department}
                        error={!!errors.department}
                    />
                </Grid>
            </Box>

            <Box mt={2}>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {t('select-organization')}
                </Typography>
                <Typography variant="body2">{t('allows-segment')}</Typography>
            </Box>

            <Box mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>{t('organization')}</InputLabel>
                            <Select
                                value={values.defaultAffiliation}
                                onChange={handleChange}
                                label={t('organization')}
                                name="defaultAffiliation"
                                id="defaultAffiliation"
                            >
                                <MenuItem
                                    value={ParticipantAffiliation.ORGANIZATION}
                                >
                                    {me?.organizationName}
                                </MenuItem>
                                <MenuItem
                                    value={ParticipantAffiliation.CUSTOMER}
                                >
                                    {t('external-customer')}
                                </MenuItem>
                                <MenuItem
                                    value={ParticipantAffiliation.SUPPLIER}
                                >
                                    {t('external-supplier')}
                                </MenuItem>
                                <MenuItem value={ParticipantAffiliation.OTHER}>
                                    {t('other')}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>{t('role-placeholder')}</InputLabel>
                            <Select
                                value={values.defaultRole}
                                onChange={handleChange}
                                label={t('role-placeholder')}
                                name="defaultRole"
                                id="defaultRole"
                            >
                                <MenuItem value={ParticipantRole.STEERING}>
                                    {t('steering')}
                                </MenuItem>
                                <MenuItem value={ParticipantRole.TEAM}>
                                    {t('team')}
                                </MenuItem>
                                <MenuItem value={ParticipantRole.STAKEHOLDER}>
                                    {t('stakeholder')}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Form>
    );
};
