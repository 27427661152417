import { StoreConfig } from '@datorama/akita';
import {
    EntitiesState,
    EntitiesStore,
    IEntityUIState,
} from '../../../core/state/entities.store';
import {
    createProjectCommand,
    createProjectFromResponse,
    IProject,
    IProjectResponse,
} from '../models/project.model';

// export type ProjectsState = EntitiesState<IProject>;

type ProjectFilter = 'active' | 'all' | 'deleted';

interface IProjectUIState extends IEntityUIState {
    filter: ProjectFilter;
}

export type ProjectsState = EntitiesState<IProject, IProjectUIState>;

@StoreConfig({
    name: 'projects',
    resettable: true,
})
class ProjectsStore extends EntitiesStore<IProject, IProjectResponse> {}

export const projectsStore = new ProjectsStore({
    responseMapper: createProjectFromResponse,
    commandMapper: createProjectCommand,
});
