import { Box, Button, Container, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import React, { FC, useCallback, useContext } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { FormDialogWrapper } from '../../../core/components/shared/FormDialogWrapper';
import { useCrud } from '../../../core/hooks/crud.hook';
import { useDialog } from '../../projects/components/use-dialog.hook';
import css from 'styles/material-ui/cssVars';
import { AlertModal } from '../../pulse/components/AlertModal';
import { CategoryFormContext, ICategoryFormik } from './CategoryFormProvider';
import { DimensionsContext } from './DimensionsProvider';

const useStyles = makeStyles((theme) => ({
    modalActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        backgroundColor: css.extraColors.text.darkPrimary,
        alignItems: 'center',
        width: '100%',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        },
    },
    buttonWrap: {
        [theme.breakpoints.down('xs')]: {
            marginTop: `${theme.spacing(2)}px`,
        },
    }
}));

export const CategoryFormDialogWrapper: FC = ({ children }) => {
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);
    const { open, handleOpen, handleClose } = useDialog();

    const { fetchDimensions } = useContext(DimensionsContext);
    const { close, categoryId } = useContext(CategoryFormContext);

    const { deleteMultiple } = useCrud();

    const onDelete = useCallback(async () => {
        categoryId && (await deleteMultiple([categoryId]));
        await fetchDimensions();
        close();
    }, []);

    const {
        dirty,
        isSubmitting,
        submitForm,
    } = useFormikContext<ICategoryFormik>();

    return (
        <>
            <DialogContent>
                <Container maxWidth="md">
                    <Box marginY={4}>
                        <Typography variant="h2" align="center">
                            {categoryId ? t('edit-category') : t('add-category')}
                        </Typography>
                    </Box>

                    {children}
                </Container>
            </DialogContent>
            <DialogActions className={classes.modalActions}>
                <Button type="reset" variant="text" onClick={close}>
                    {t('cancel')}
                </Button>

                <Box className={classes.buttonWrap}>
                    {categoryId && (
                        <>
                            <Button
                                variant="contained"
                                style={{
                                    textTransform: 'capitalize',
                                    marginRight: '15px',
                                    padding: '10px',
                                    color: 'red',
                                }}
                                disableElevation
                                disabled={isSubmitting}
                                onClick={handleOpen}
                            >
                                {t('delete-category')}
                            </Button>
                            <FormDialogWrapper
                                isOpen={open}
                                handleClose={handleClose}
                                alertModal
                            >
                                <AlertModal
                                    title={t('delete-category')}
                                    isActionDestructive={true}
                                    contentText={t(
                                        'delete-category-content-message'
                                    )}
                                    closeModalText={t('keep-category')}
                                    submitModalText={t('yes-delete-category')}
                                    handleClose={handleClose}
                                    handleSubmit={onDelete}
                                />
                            </FormDialogWrapper>
                        </>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            textTransform: 'capitalize',
                        }}
                        disableElevation
                        disabled={isSubmitting || !dirty}
                        onClick={submitForm}
                    >
                        {!categoryId ? t('create-category') : t('save-changes')}
                    </Button>
                </Box>
            </DialogActions>
        </>
    );
};
