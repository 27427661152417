import { Box, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { EntityListHeaderTemplate } from 'core/components/shared/EntityListHeaderTemplate';
import { asDateString } from 'core/helpers/asDateString';
import { IReadAllEntitiesResponse } from 'core/interfaces/read-all-entities-response.interface';
import { apiService } from 'core/services/apiService';
import { FeedbackLoopPageAdmin } from 'modules/feedback-loop/pages/FeedbackLoopPageAdmin';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import { useDialog } from 'modules/projects/components/use-dialog.hook';
import { createFeedbackResultFromResponse, IFeedbackResult, IFeedbackResultResponse } from 'modules/pulse-answering/models/feedback-loop-models';

import { FormDialogWrapper } from '../FormDialogWrapper';
import { ListHeader } from '../ListHeader';
import { ListItemContainer } from '../ListItemContainer';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    projectCardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
        columnGap: 24,
        rowGap: 24,
    },
    filters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableBackground: {
        backgroundColor: '#fff',
    },
}));

interface IFeedbackListProps {
    projectId: string;
    feedbackId?: string;
}

export const FeedbacksList: FC<IFeedbackListProps> = ({
    projectId,
    feedbackId = null,
}) => {
    const classes = useStyles();
    const { open, handleOpen, handleClose } = useDialog();
    const [feedbackItems, setFeedbackItems] = useState<IFeedbackResult[]>();
    const [feedbackLongId, setFeedbackLongId] = useState(feedbackId);
    const { push } = useHistory();

    const handleCustomClose = () => {
        setFeedbackLongId(null);
        handleClose();
        apiService
            .get<IReadAllEntitiesResponse<IFeedbackResultResponse>>(
                `/projects/${window.location.pathname.split('/')[2]}/feedbacks`
            )
            .then(({ data }) => {
                setFeedbackItems(
                    data.results?.map(createFeedbackResultFromResponse)
                );
            });

        //added this logic to remove the feedback id after the user has closed the in-app feedback modal
        push({
            pathname: pathBuilder(ROUTES.PROJECT, ':id', projectId),
        });
    };

    useEffect(() => {
        feedbackLongId && handleOpen();
    }, [feedbackLongId]);

    useEffect(() => {
        if (projectId) {
            apiService
                .get<IReadAllEntitiesResponse<IFeedbackResultResponse>>(
                    `/projects/${projectId}/feedbacks`
                )
                .then(({ data }) => {
                    setFeedbackItems(
                        data.results?.map(createFeedbackResultFromResponse)
                    );
                });
        }
    }, [projectId]);

    return (
        <>
            <Box width={'100%'} className={classes.root}>
                <Box mt={3}>
                    <TableContainer
                        component={Paper}
                        variant="outlined"
                        className={classes.tableBackground}
                    >
                        <Table>
                            <TableHead>
                                <ListHeader>
                                    <EntityListHeaderTemplate
                                        pulseName={{
                                            label: 'Pulse Name', // TODO: translate
                                        }}
                                        pulseDate={{
                                            label: 'Pulse Date', // TODO: translate
                                        }}
                                        privacy={{
                                            label: 'Privacy', // TODO: translate
                                        }}
                                        lastFeedbackMessage={{
                                            label: 'Last Feedback Message', // TODO: translate
                                        }}
                                    />
                                </ListHeader>
                            </TableHead>
                            <TableBody>
                                {!feedbackItems ? (
                                    <TableRow>
                                        <TableCell>Loading...</TableCell>
                                    </TableRow> // TODO: replace with spinner
                                ) : (
                                    feedbackItems
                                        .filter(
                                            (item) =>
                                                item.lastMessage !== ' ' ||
                                                item.feedbackMessage !== ' '
                                        )
                                        .map(
                                            ({
                                                pulseId,
                                                feedbackId,
                                                pulseName,
                                                pulseDate,
                                                isPublic,
                                                feedbackMessage,
                                                lastMessage,
                                            }) => (
                                                <ListItemContainer
                                                    key={feedbackId}
                                                    entityId={pulseId}
                                                    enableHover={true}
                                                    onClick={() => {
                                                        setFeedbackLongId(
                                                            feedbackId
                                                        );
                                                    }}
                                                >
                                                    <TableCell>
                                                        {pulseName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {asDateString(
                                                            pulseDate,
                                                            {
                                                                format: 'date',
                                                            }
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            !isPublic
                                                                ? 'Private' // TODO: translate
                                                                : 'Public' // TODO: translate
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {lastMessage ||
                                                            feedbackMessage}
                                                    </TableCell>
                                                </ListItemContainer>
                                            )
                                        )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>

            <FormDialogWrapper isOpen={open} handleClose={handleCustomClose}>
                {feedbackLongId && (
                    <FeedbackLoopPageAdmin
                        feedbackId={feedbackLongId}
                        handleClose={handleCustomClose}
                    />
                )}
            </FormDialogWrapper>
        </>
    );
};
