import { createContext } from 'react';
import { IEntity } from '../interfaces/entity.interface';
import { EntitiesQuery } from '../state/entities.query';
import { EntitiesStore } from '../state/entities.store';

export interface IEntitiesContext<
    T extends IEntity,
    R,
    Q extends EntitiesQuery<T> = EntitiesQuery<T>,
    S extends EntitiesStore<T, R> = EntitiesStore<T, R>
> {
    query: Q;
    store: S;
}

export const EntitiesContext = createContext<
    IEntitiesContext<IEntity, IEntity>
>((null as unknown) as IEntitiesContext<IEntity, IEntity>);
