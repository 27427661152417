import {
    IAutocompleteDefaultOption,
    ImplementPulseAutoComplete,
} from 'core/components/ImplementPulseAutoComplete';
import { IReadAllEntitiesResponse } from 'core/interfaces/read-all-entities-response.interface';
import React, { FC, useCallback } from 'react';
import {
    createAccountFromResponse,
    IAccountResponse,
} from '../models/account.model';

interface IAccountAutocompleteProps {
    disabled?: boolean;
    label: string;
    value: IAutocompleteDefaultOption | null;
    onSelect: (value: IAutocompleteDefaultOption | null) => Promise<void>;
}

export const AccountAutocomplete: FC<IAccountAutocompleteProps> = (props) => {
    const responseMapper = useCallback(
        ({ results }: IReadAllEntitiesResponse<IAccountResponse>) => {
            const entities = results.map(createAccountFromResponse);

            if (props.value) {
                return entities.filter((item) => props.value?.id !== item.id);
            }

            return entities;
        },
        [props.value]
    );

    return (
        <ImplementPulseAutoComplete<
            IAutocompleteDefaultOption,
            IReadAllEntitiesResponse<IAccountResponse>
        >
            url={'/organizations?dropdown=1'}
            stringify={({ name }) => name}
            responseMapper={responseMapper}
            {...props}
        />
    );
};
