import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ListActionIcon } from 'core/components/shared/ListActionIcon';
import { IEntityActionProps } from 'core/interfaces/entity-action.interface';
import React, { FC, useCallback, useContext } from 'react';
import { EntityListActions } from '../../../core/components/EntityListActions';
import { IComponentWrappedWithEntityByIdProps } from '../../../core/hocs/WithEntityById';
import { IPulseParticipant } from '../models/pulse-participant.model';
import { PulseParticipantFormContext } from './PulseParticipantFormProvider';

interface IPulseParticipantListActionsProps
    extends IComponentWrappedWithEntityByIdProps<IPulseParticipant> {
    showDeleteAction?: boolean;
}

export const PulseParticipantListActions: FC<IPulseParticipantListActionsProps> = ({
    entity: pulseParticipant,
    showDeleteAction,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { open, openDeleteModal } = useContext(PulseParticipantFormContext);

    const getActions = useCallback(() => {
        const toReturn: IEntityActionProps[] = [
            {
                callback: () => open(pulseParticipant),
                component: (
                    <ListActionIcon
                        iconComponent={
                            <Tooltip title={t('tooltip-edit')}>
                                <EditIcon />
                            </Tooltip>
                        }
                    ></ListActionIcon>
                ),
            },
        ];

        if (showDeleteAction) {
            toReturn.push({
                callback: () => openDeleteModal(pulseParticipant),
                component: (
                    <ListActionIcon
                        iconComponent={
                            <Tooltip title={t('tooltip-remove')}>
                                <CloseIcon />
                            </Tooltip>
                        }
                    />
                ),
            });
        }

        return toReturn;
    }, [pulseParticipant, open, showDeleteAction]);

    return <EntityListActions actions={getActions()} />;
};
