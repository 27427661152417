import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { asDateString } from 'core/helpers/asDateString';
import { apiService } from 'core/services/apiService';
import { FeedbackLoopPageAdmin } from 'modules/feedback-loop/pages/FeedbackLoopPageAdmin';
import { ParticipantAffiliation } from 'modules/participants/models/participant.model';
import { useDialog } from 'modules/projects/components/use-dialog.hook';
import { IFeedbackResult } from 'modules/pulse-answering/models/feedback-loop-models';
import { FactorValue } from 'modules/success-factors/interfaces/FactorValue';
import {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { ValidTranslationKeys } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { IEntity } from '../../../core/interfaces/entity.interface';
import { DimensionsProvider } from '../../categories/components/DimensionsProvider';
import { DashboardProvider } from '../components/DashboardProvider';
import { FeedbacksDashboard } from '../components/FeedbacksDashboard';
import { FiltersDashboard } from '../components/FiltersDashboard';
import { GraphDashboard } from '../components/GraphDashboard';
import { OverviewDashboard } from '../components/OverviewDashboard';
import { PulseAnswersDashboard } from '../components/PulseAnswersDashboard';
import { TotalsTableDashboard } from '../components/TotalsTableDashboard';
import {
    IAnalyticData,
    IAnalyticDataResponse,
    IPulseAnswersDataFeedback,
    IPulseAnswersFactor,
    createAnalyticsDataFromResponse,
} from '../models/dashboard.models';

export interface IAnswerAverage {
    name: string;
    average: number;
}

export interface IAnswerAverage {
    name: string;
    average: number;
}

export interface IAnswerAverage {
    name: string;
    average: number;
}

export interface IPulseAnswersData extends Record<FactorValue, string[]> {
    pulseInstanceId?: string;
    pulse: string;
    date: Date | null;
    role: keyof ValidTranslationKeys;
    organization: ParticipantAffiliation;
    feedback: IPulseAnswersDataFeedback;
    averages: number[];
    action?: (id: string, feedbackId: string) => void;
}

const factorsTitleMapper = (
    factors: IPulseAnswersFactor[],
    value?: FactorValue
) => {
    const temp = !value
        ? [...factors]
        : factors.filter((factorNames) => factorNames.value === value);

    return temp.map(({ title }) => title);
};

export const ProjectDashboardPage: FC = () => {
    const { id: projectId } = useParams<IEntity>();
    const { t } = useContext(MultiLanguageSupportContext);
    const [queryString, setQueryString] = useState('');
    const [analyticData, setAnalyticData] = useState<IAnalyticData | null>(
        null
    );
    const { push } = useHistory();

    const pulseQuestions = useMemo(
        () => analyticData?.questions?.map(({ name }) => name) ?? [],
        [analyticData]
    );

    const pulseAnswersData = useMemo(() => {
        const pulseAnswers: IPulseAnswersData[] = [];

        Object.entries(analyticData?.pulseAnswers ?? {}).forEach(
            ([pulseName, pulseInstances]) => {
                Object.entries(pulseInstances).map(
                    ([
                        mainItemID,
                        {
                            description: { organization, date, role },
                            feedback,
                            questions,
                            factors,
                        },
                    ]) => {
                        pulseAnswers.push({
                            pulseInstanceId: mainItemID,
                            pulse: pulseName,
                            organization,
                            date,
                            role,
                            feedback: feedback[0],
                            averages: pulseQuestions
                                .map((questionName) => {
                                    const question = questions.find(
                                        ({ name }) => name === questionName
                                    );

                                    return +(question?.value ?? 0);
                                })
                                .flat(),
                            great: factorsTitleMapper(factors, 'great'),
                            neutral: factorsTitleMapper(factors, 'neutral'),
                            improvable: factorsTitleMapper(
                                factors,
                                'improvable'
                            ),
                        });
                    }
                );
            }
        );
        return pulseAnswers;
    }, [analyticData, pulseQuestions]);

    const handleQueryString = useCallback(
        (newQueryString: string) => {
            setQueryString(newQueryString);

            projectId &&
                apiService
                    .get<IAnalyticDataResponse>(
                        `projects/${
                            projectId.split('?')[0]
                        }/dashboard?${newQueryString}`
                    )
                    .then(({ data }) => {
                        setAnalyticData(createAnalyticsDataFromResponse(data));
                    });
        },
        [projectId]
    );

    const exportCsvExcel = useCallback(
        async (exportType: 'excel' | 'csv') => {
            projectId &&
                (await apiService
                    .get<string>(
                        `/projects/${projectId}/dashboard/export/${exportType}?${queryString}`,
                        exportType === 'excel'
                            ? {
                                  responseType: 'arraybuffer',
                              }
                            : undefined
                    )
                    .then((resp) => {
                        /**
                         * works for EXCEL files becouse of this: https://github.com/axios/axios/issues/895
                         * the file name logic is also picked from the link but seems to need a BE tweek: https://stackoverflow.com/questions/43912862/axios-expose-response-headers-content-disposition
                         */
                        const dirtyFileName =
                            resp.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        const fileName =
                            dirtyFileName?.match(regex)[3] ?? 'export';

                        const file = new Blob([resp.data], {
                            type: resp.headers['content-type'] ?? 'text/plain',
                        });
                        const url = window.URL.createObjectURL(file);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = `${fileName}.${
                            exportType === 'excel' ? 'xls' : 'csv'
                        }`;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }));
        },
        [projectId, queryString]
    );

    const headerRows: string[] = [
        'Pulse',
        'Date',
        'Role',
        'Organization',
        ...pulseQuestions,
        'Great',
        'Improve',
        'Feedback',
    ];

    const endDate = new Date();
    const startDate = new Date(endDate);

    startDate.setDate(endDate.getDate() - 7);

    const { open, handleOpen, handleClose } = useDialog();
    const [feedbackItems, setFeedbackItems] = useState<IFeedbackResult[]>();
    const [feedbackLongId, setFeedbackLongId] = useState<string | null>(null);

    const handleCustomClose = () => {
        setFeedbackLongId(null);
        handleClose();
    };

    const genFeedback = (id: string, feedbackId: string) => {
        if (feedbackId) {
            setFeedbackLongId(feedbackId);
            handleOpen();
        } else {
            apiService
                .post(`/feedbacks/empty/${id}`, {})
                .then((resp: any) => setFeedbackLongId(resp.data.id));
        }
    };

    useEffect(() => {
        feedbackLongId && handleOpen();
    }, [feedbackLongId]);

    return (
        <>
            <FormDialogWrapper isOpen={open} handleClose={handleCustomClose}>
                {feedbackLongId && (
                    <FeedbackLoopPageAdmin
                        feedbackId={feedbackLongId}
                        handleClose={handleCustomClose}
                    />
                )}
            </FormDialogWrapper>

            <DashboardProvider
                onQueryStringChange={handleQueryString}
                initialFilters={{
                    startDate: asDateString(startDate, {
                        format: 'date',
                        dateOptions: { format: 'YYYY-MM-DD', separator: '-' },
                    }),
                    endDate: asDateString(endDate, {
                        format: 'date',
                        dateOptions: { format: 'YYYY-MM-DD', separator: '-' },
                    }),
                }}
            >
                <DimensionsProvider>
                    <FiltersDashboard projectId={projectId} />
                </DimensionsProvider>
                {analyticData && (
                    <>
                        <OverviewDashboard {...analyticData} />
                        <GraphDashboard {...analyticData} />{' '}
                        <FeedbacksDashboard feedback={analyticData.feedback} />
                    </>
                )}
                <PulseAnswersDashboard
                    label={t('pulse-answers')}
                    exportCsvExcel={exportCsvExcel}
                >
                    <TotalsTableDashboard
                        rows={pulseAnswersData}
                        headerCell={headerRows}
                        action={genFeedback}
                    />
                </PulseAnswersDashboard>
            </DashboardProvider>
        </>
    );
};
