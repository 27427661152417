import { TableCell } from '@material-ui/core';
import { asDateString } from 'core/helpers/asDateString';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../navigation/enums/routes.enum';
import { pathBuilder } from '../../navigation/helpers/path-builder.helper';

interface IProjectListItemTemplate {
    projectName: string;
    projectManagerName: string;
    participants?: number;
    customer?: number;
    latestPulse?: Date;
    nextPulse?: Date;
    participation?: number;
    score?: number;
    id?: string;
}

export const ProjectListItemTemplate: FC<IProjectListItemTemplate> = ({
    projectName,
    projectManagerName,
    participants,
    customer,
    latestPulse,
    nextPulse,
    participation,
    score,
    id,
}) => {
    const { push } = useHistory();
    const redirect = () => {
        if (id)
            push({
                pathname: pathBuilder(ROUTES.PROJECT, ':id', id),
            });
    };

    return (
        <>
            <TableCell onClick={redirect}>{projectName}</TableCell>
            <TableCell onClick={redirect}>{projectManagerName}</TableCell>
            <TableCell onClick={redirect}>
                {latestPulse
                    ? asDateString(new Date(latestPulse), {
                          format: 'date',
                      })
                    : '-'}
            </TableCell>
            <TableCell onClick={redirect}>
                {nextPulse
                    ? asDateString(new Date(nextPulse), {
                          format: 'date',
                      })
                    : '-'}
            </TableCell>
            <TableCell onClick={redirect}>{participants}</TableCell>
            <TableCell onClick={redirect}>{customer}</TableCell>
            <TableCell onClick={redirect}>
                {participation ? participation.toFixed(0) + '%' : '-'}
            </TableCell>
            <TableCell onClick={redirect}>
                {score ? score.toFixed(1) : '-'}
            </TableCell>
        </>
    );
};
