import { Box, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { MultiLanguageSupportContext } from '../../MultiLanguageSupportProvider';

const useStyles = makeStyles((theme) => ({
    micro: {
        width: '100%',
        marginBottom: theme.spacing(7),
    },
}));

export default function NeedHelpMail() {
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();

    return (
        <Box className={classes.micro} style={{ margin: '40px 0 20px 0' }}>
            <Typography display="block" align="center">
                {t('need-help')}
                <br />
                <Link href={'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL}>
                    {t('email-project-pulse-team')}
                </Link>
            </Typography>
        </Box>
    );
}
