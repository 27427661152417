import { TableCell } from '@material-ui/core';
import React, { FC } from 'react';
import { IAccount } from '../models/account.model';

export const AccountsListItemTemplate: FC<IAccount> = ({
    name,
    ownerName,
    email,
    subscription,
}) => (
    <>
        <TableCell>{name}</TableCell>
        <TableCell>{ownerName}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{subscription}</TableCell>
    </>
);
