import { ApexOptions } from 'apexcharts';
import { GraphBarColor } from '../constants/GraphBarColor';

export const getSuccessFactorsOptions = (
    partial?: Partial<ApexOptions>
): ApexOptions => {
    return {
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'center',
            fontSize: '16px',
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        xaxis: {
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        yaxis: {
            decimalsInFloat: 0,
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        dataLabels: { enabled: false },
        colors: [GraphBarColor.RED, GraphBarColor.GREEN],
        fill: {
            colors: [GraphBarColor.RED, GraphBarColor.GREEN],
        },
        ...partial,
    };
};
