import {
    Box,
    Button,
    Container,
    DialogActions,
    DialogContent,
    makeStyles,
    Typography,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FormDialogActions } from 'core/components/shared/dialog/FormDialogActions';
import { IStep, StepperComponent } from 'core/components/shared/Stepper';
import { useCrud } from 'core/hooks/crud.hook';
import { apiService } from 'core/services/apiService';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import css from 'styles/material-ui/cssVars';
import { IScaleQuestion } from 'modules/scale-questions/models/scale-question.model';
import { scaleQuestionsStore } from 'modules/scale-questions/state/scale-questions.store';
import {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { CreateScaleQuestionFormSystem } from './QuestionFormSystem';
import {
    IScaleQuestionFormik,
    QuestionsDialogContext,
} from './QuestionsDialogProvider';
import { QuestionsTranslationsPage } from './QuestionTranslationsPage';

interface IStepConfig {
    content: JSX.Element;
    submitLabel?: string;
    discardLabel?: string;
    backLabel?: string;
    nextLabel?: string;
    disableSubmit?: boolean;
    disableBackAndNextIfSubmitDisabled?: boolean;
    onSubmit?: (shouldCloseDialog: boolean) => Promise<void>;
    onDiscard?: () => void;
    onDelete?: () => void;
}

const useStyles = makeStyles((theme) => ({
    btn: {
        textTransform: 'none',
        marginLeft: 8,
    },
    stepWrapper: {
        width: '50%',
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        backgroundColor: css.extraColors.text.darkPrimary,
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}));

export interface ITranslatesFormik {
    [key: string]: string;
}

export interface ITranslatesQuestionsPhrasesFormik {
    name: ITranslatesFormik;
    phrase: ITranslatesFormik;
}

export const QuestionsDialogContent: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();
    const { deleteMultiple } = useCrud();
    const { fetch } = useCrud<IScaleQuestion>();
    const {
        scaleQuestionPreferenceId,
        close: hideDialog,
        preferenceOrganizationId,
        stepIndex,
        setStepIndex,
    } = useContext(QuestionsDialogContext);

    const [
        initialValues,
        setInitialValues,
    ] = useState<ITranslatesQuestionsPhrasesFormik>(
        {} as ITranslatesQuestionsPhrasesFormik
    );

    const [
        languageTranslationRelation,
        setLanguageTranslationRelation,
    ] = useState<ITranslatesFormik>({});

    const detailsFormik = useFormikContext<IScaleQuestionFormik>();

    const areQuestionFieldsDisabled = useMemo(
        () =>
            detailsFormik.values.organizationId === null &&
            preferenceOrganizationId !== null,
        [detailsFormik.values, preferenceOrganizationId]
    );

    useEffect(() => {
        const tempTranslations: ITranslatesFormik = {};
        const tempLanguageTranslationRelation: ITranslatesFormik = {};
        const tempLanguagePharse: ITranslatesFormik = {};

        detailsFormik.values.translations.forEach(
            ({ name, id, language, phrase }) => {
                tempLanguageTranslationRelation[language.id] = id;
                tempTranslations[id] = name;
                tempLanguagePharse[id] = phrase;
            }
        );
        setInitialValues({
            name: tempTranslations,
            phrase: tempLanguagePharse,
        });
        setLanguageTranslationRelation(tempLanguageTranslationRelation);
    }, [detailsFormik.values]);

    const steps: IStep[] = [
        {
            title: 'Create Question',
            description: !preferenceOrganizationId
                ? 'System wide'
                : 'Account wide',
        },
        {
            title: 'Add Translations',
            description: 'optional',
        },
    ];

    const nextComponent = useCallback(() => {
        setStepIndex(stepIndex + 1);
    }, [stepIndex]);

    const closeDialog = () => {
        scaleQuestionsStore.setActive([]);
        hideDialog();
    };

    const createScaleQuestionStepConfig: IStepConfig = useMemo(() => {
        return {
            content: (
                <FormikProvider value={detailsFormik}>
                    {!scaleQuestionPreferenceId ? (
                        <Box pb={4}>
                            <Typography variant="h3" align="center">
                                Create New Pulse Question
                                {/* TODO: add translation */}
                            </Typography>
                            <Typography variant="h5" align="center">
                                {!preferenceOrganizationId
                                    ? 'System'
                                    : 'Account'}
                                {/* TODO: add translation */}
                            </Typography>
                        </Box>
                    ) : (
                        <Box py={6}>
                            <Typography variant="h3" align="center">
                                Edit Pulse Question
                                {/* TODO: add translation */}
                            </Typography>
                            <Typography variant="h5" align="center">
                                {!preferenceOrganizationId
                                    ? 'System'
                                    : 'Account'}
                                {/* TODO: add translation */}
                            </Typography>
                        </Box>
                    )}

                    <CreateScaleQuestionFormSystem
                        areQuestionFieldsDisabled={areQuestionFieldsDisabled}
                    />
                </FormikProvider>
            ),
            onSubmit: async () => {
                await detailsFormik.submitForm();
            },
            onDiscard: () => detailsFormik.resetForm(),
            disableSubmit: !detailsFormik.dirty || !detailsFormik.isValid,
        };
    }, [detailsFormik, areQuestionFieldsDisabled]);

    const translationsFormik = useFormik<ITranslatesQuestionsPhrasesFormik>({
        initialValues,
        enableReinitialize: true,
        onSubmit: (values) => {
            const promises = Object.keys(values.name).map((translationId) => {
                const name = values.name[translationId];
                const phrase = values.phrase[translationId];

                return apiService.put(
                    `/scale-question-translations/${translationId}`,
                    {
                        name,
                        phrase,
                    }
                );
            });

            return Promise.all(promises);
        },
    });

    const translatesStepConfig: IStepConfig = useMemo(() => {
        return {
            content: !scaleQuestionPreferenceId ? (
                <></>
            ) : (
                <FormikProvider value={translationsFormik}>
                    <QuestionsTranslationsPage
                        disabled={areQuestionFieldsDisabled}
                        languageTranslationRelations={
                            languageTranslationRelation
                        }
                    />
                </FormikProvider>
            ),
            onSubmit: async (shouldCloseDialog) => {
                await translationsFormik.submitForm();

                await fetch();

                if (shouldCloseDialog) {
                    closeDialog();
                }
            },
        };
    }, [
        translationsFormik,
        scaleQuestionPreferenceId,
        languageTranslationRelation,
        areQuestionFieldsDisabled,
    ]);

    const { onSubmit, content, disableSubmit }: IStepConfig = useMemo(() => {
        switch (stepIndex) {
            case 0:
                return createScaleQuestionStepConfig;
            case 1:
                return translatesStepConfig;

            default:
                return {
                    content: <></>,
                    onSubmit: async () => {
                        return;
                    },
                };
        }
    }, [stepIndex, createScaleQuestionStepConfig, translatesStepConfig]);

    return (
        <>
            <DialogContent>
                <Box className={classes.stepWrapper}>
                    <StepperComponent
                        activeStep={stepIndex}
                        steps={steps.map(({ title, description }) => ({
                            title: title,
                            description: description,
                        }))}
                        onClick={(index) =>
                            scaleQuestionPreferenceId && setStepIndex(index)
                        }
                    />
                </Box>
                <Container maxWidth="md">
                    <div> {content} </div>
                </Container>
            </DialogContent>
            <DialogActions className={classes.modalActions}>
                <FormDialogActions handleClose={() => closeDialog()}>
                    {detailsFormik.values.questionId ? (
                        <>
                            <Button
                                className={classes.btn}
                                variant="contained"
                                disableElevation
                                color="default"
                                disabled={areQuestionFieldsDisabled}
                                onClick={async () => {
                                    await deleteMultiple(
                                        [detailsFormik.values.questionId],
                                        {
                                            shouldFetchAfterSuccess: true,
                                        }
                                    );

                                    closeDialog();
                                }}
                            >
                                {t('delete-pulse-question')}
                            </Button>
                            <Button
                                className={classes.btn}
                                variant="contained"
                                disableElevation
                                color="default"
                                onClick={async () => {
                                    onSubmit && (await onSubmit(true));
                                }}
                                disabled={disableSubmit}
                            >
                                {t('save-changes')}
                            </Button>
                        </>
                    ) : (
                        <Button
                            className={classes.btn}
                            variant="contained"
                            disableElevation
                            color="default"
                            onClick={async () => {
                                onSubmit && (await onSubmit(true));
                            }}
                            disabled={disableSubmit}
                        >
                            {t('create-pulse-question')}
                        </Button>
                    )}
                    {stepIndex < 1 ? (
                        <Button
                            className={classes.btn}
                            variant="contained"
                            disableElevation
                            color="primary"
                            onClick={async () => {
                                onSubmit && (await onSubmit(false));
                                nextComponent();
                            }}
                            endIcon={<ArrowForwardIosIcon />}
                            disabled={disableSubmit}
                        >
                            {t('next')}
                        </Button>
                    ) : null}
                </FormDialogActions>
            </DialogActions>
        </>
    );
};
