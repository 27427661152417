import React, { FC, useState } from 'react';
import { Box, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    tableRow: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#FAFBFC !important',
        },
    },
    visibilityHidden: {
        '& button:nth-child(2)': {
            visibility: 'hidden',
        },
    },
    visibilityVisible: {
        '& button:nth-child(2)': {
            visibility: 'visible',
        },
    },
}));

export interface IListItemContainerProps {
    entityId: string;
    renderFirstColumn?: (entityId: string) => JSX.Element;
    renderLastColumn?: (entityId: string) => JSX.Element;
    renderItemNavigation?: (entityId: string) => JSX.Element;
    enableHover?: boolean;
    handleHoverState?: (show: boolean) => void;
    onClick?: () => void;
}

export const ListItemContainer: FC<IListItemContainerProps> = ({
    entityId,
    renderFirstColumn,
    renderLastColumn,
    renderItemNavigation,
    children,
    enableHover = false,
    ...props
}) => {
    const classes = useStyles();

    const [actionsVisibilityClass, setActionsVisibilityClass] = useState<
        'visibilityVisible' | 'visibilityHidden'
    >('visibilityHidden');

    const handleHoverState = (show: boolean) => {
        setActionsVisibilityClass(
            show ? 'visibilityVisible' : 'visibilityHidden'
        );
    };

    return (
        <TableRow
            hover={enableHover}
            className={classes.tableRow}
            onMouseEnter={() => enableHover && handleHoverState(true)}
            onMouseLeave={() => enableHover && handleHoverState(false)}
            {...props}
        >
            {renderFirstColumn && (
                <TableCell>{renderFirstColumn(entityId)}</TableCell>
            )}
            {children}
            {renderItemNavigation && (
                <TableCell className="MuiTableCell-actions">
                    <Box>{renderItemNavigation(entityId)}</Box>
                </TableCell>
            )}
            {renderLastColumn && (
                <TableCell className="MuiTableCell-actions">
                    <Box className={classes[actionsVisibilityClass]}>
                        {renderLastColumn(entityId)}
                    </Box>
                </TableCell>
            )}
        </TableRow>
    );
};
