export enum FrequencyValue {
    NONE = '',
    EVERY_WEEK = 0,
    MONTHLY_1 = 1,
    ONCE = 2,
    EVERY_2_WEEK = 3,
    EVERY_3_WEEK = 4,
    EVERY_4_WEEK = 5,
    MONTHLY_2 = 6,
    BI_MONTHLY = 7,
    QUARTERLY = 8,
}
