import { IEntity } from 'core/interfaces/entity.interface';

export interface ProjectManager extends IEntity {
    name: string;
}

export interface ProjectManagerResponse extends IEntity<number> {
    name: string;
}

export const emptyProjectManager: Omit<ProjectManager, 'id'> = {
    name: '',
};

export const createProjectManager = ({
    id,
    ...props
}: Partial<ProjectManager>): ProjectManager => {
    if (!id) throw new Error('Unable to create a Manager without an ID!');

    return {
        id,
        ...emptyProjectManager,
        ...props,
    };
};

export const createProjectManagerFromResponse = ({
    id,
    ...props
}: ProjectManagerResponse): ProjectManager => {
    return createProjectManager({
        id: id.toString(),
        ...props,
    });
};
