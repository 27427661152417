import { Box, Button, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ListActionIcon } from 'core/components/shared/ListActionIcon';
import { WithProvider } from 'core/hocs/WithProvider';
import { useSession } from 'core/hooks/session.hook';
import { IPulseResponse } from 'modules/pulse/models/pulse.model';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { PulseAutoComplete } from '../../../core/components/AutoComplete';
import { EntityListHeaderTemplate } from '../../../core/components/shared/EntityListHeaderTemplate';
import { useCrud } from '../../../core/hooks/crud.hook';
import { useCurrentPageEntities } from '../../../core/hooks/current-page-entities.hook';
import { IReadAllEntitiesResponse } from '../../../core/interfaces/read-all-entities-response.interface';
import { apiService } from '../../../core/services/apiService';
import {
    createSuccessFactorFromResponse,
    ISuccessFactor,
    ISuccessFactorResponse,
} from '../models/success-factor.model';
import { factorsQuery } from '../state/factors.query';
import { FactorsList } from './FactorsList';
import { FactorsListItemTemplate } from './FactorsListItemTemplate';

export interface IFactorsContainerProps {
    id: string;
}

const useStyles = makeStyles((theme) => ({
    warningIcon: {
        color: '#FF961A',
        marginRight: '5px',
    },
    actionWrapper: {
        minWidth: 0,
        padding: theme.spacing(1),
    },
}));

export const Factors: FC<IFactorsContainerProps> = WithProvider(
    ({ id: pulseId }) => {
        const { allPagesEntities } = useCurrentPageEntities();
        const { t } = useContext(MultiLanguageSupportContext);
        const { fetch } = useCrud();
        const classes = useStyles();
        const { me } = useSession();

        const addFactorsToList = async (select: ISuccessFactor) => {
            await apiService.post(
                `/pulses/${pulseId}/factors/${select.factor.id}`,
                {}
            );

            await fetch();
        };

        const deleteFactorsFromList = async (id: string) => {
            await apiService.delete(`/pulses/${pulseId}/factors/${id}`);

            await fetch();
        };

        const responseMapper = useCallback(
            ({ results }: IReadAllEntitiesResponse<ISuccessFactorResponse>) => {
                const entities = results.map(createSuccessFactorFromResponse);

                if (allPagesEntities.length) {
                    const allPagesEntityIds = allPagesEntities.map(
                        ({ id }) => id
                    );

                    return entities.filter(
                        // if already selected
                        (item) => allPagesEntityIds.indexOf(item.id) === -1
                    );
                }

                return entities;
            },
            [allPagesEntities]
        );

        const [pulseLanguageId, setPulseLanguageId] = useState('');

        useEffect(() => {
            pulseId &&
                apiService
                    .get<IPulseResponse>(`/pulses/${pulseId}`)
                    .then(({ data }) => {
                        setPulseLanguageId(data.language?.id.toString() ?? '');
                    });
        }, [pulseId]);

        return (
            <Container maxWidth="md">
                <Box marginY={5}>
                    <Typography variant="h1" align="center">
                        {t('edit-success-factor')}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-start" mb={2}>
                    <PulseAutoComplete<
                        ISuccessFactor,
                        IReadAllEntitiesResponse<ISuccessFactorResponse>
                    >
                        url={`/factors?organization=${
                            me?.organizationId ?? null
                        }`}
                        stringify={({ factor }) => factor.title}
                        label={t('success-factors')}
                        description={t('select-existing-success-factors')}
                        responseMapper={responseMapper}
                        onSelect={async (item) => {
                            item && (await addFactorsToList(item));
                        }}
                    />
                </Box>

                <Box>
                    <FactorsList
                        renderLastColumn={(entity) =>
                            entity.mandatory ? (
                                <></>
                            ) : (
                                <Button
                                    variant="text"
                                    onClick={() =>
                                        deleteFactorsFromList(entity.factor.id)
                                    }
                                    className={classes.actionWrapper}
                                >
                                    <ListActionIcon
                                        iconComponent={<CloseIcon />}
                                    />
                                </Button>
                            )
                        }
                        pulseId={pulseId}
                        HeaderListItem={() => (
                            <EntityListHeaderTemplate
                                icon={{ label: `${t('icon')}` }}
                                title={{ label: `${t('title')}` }}
                                title_translation={{
                                    label: `${t('title-translation')}`,
                                }}
                            />
                        )}
                        ListItem={(entity) => (
                            <FactorsListItemTemplate
                                fontAwesomeIcon={entity.factor.fontAwesomeIcon}
                                title={entity.factor.title}
                                translation={entity.factor.translations.find(
                                    (item) =>
                                        item.language.id === pulseLanguageId
                                )}
                            />
                        )}
                    />
                </Box>
            </Container>
        );
    },
    factorsQuery
);
