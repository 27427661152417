import { TableCell, TableRow } from '@material-ui/core';
import { Flex } from '@qagency/react-shared-library';
import React, { FC } from 'react';

interface IListHeader {
    renderFirstColumn?: () => JSX.Element;
    renderLastColumn?: () => JSX.Element;
}

export const ListHeader: FC<IListHeader> = ({
    renderFirstColumn,
    renderLastColumn,
    children,
}) => (
    <TableRow>
        {renderFirstColumn && <TableCell>{renderFirstColumn()}</TableCell>}
        {children}
        {renderLastColumn && <TableCell>{renderLastColumn()}</TableCell>}
    </TableRow>
);
