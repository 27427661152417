interface IHasChildren<T> {
    children?: T[];
}

export const deepFlat = <T extends IHasChildren<T>>(
    array: T[]
): Omit<T, 'children'>[] =>
    array
        .map(({ children, ...arrayItem }) => [
            arrayItem,
            ...deepFlat(children ?? []),
        ])
        .flat();
