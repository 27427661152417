import { Ability, AbilityBuilder, createAliasResolver } from '@casl/ability';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { IUser, USER_ROLE } from 'modules/users/models/user.model';
import { PulseAbilities } from '../constants/abilities';

const resolveAction = createAliasResolver({
    crud: ['create', 'read', 'update', 'delete'],
});

export const defineAbilities = (user?: IUser): Ability<PulseAbilities> => {
    const { can: allow, cannot: forbid, rules } = new AbilityBuilder<
        Ability<PulseAbilities>
    >(Ability);

    if (user) {
        allow('navigateTo', Object.values(ROUTES));

        switch (user.role) {
            case USER_ROLE.SUPER_ADMIN:
                allow('crud', [
                    'IUser',
                    'IParticipant',
                    'ICategory',
                    'IAccount',
                    'ILanguage',
                    'IImport',
                ]);
                allow('crud', 'IScaleQuestion', {
                    organizationId: { $in: [user.organizationId, null] },
                });
                allow(['crud'], 'ISuccessFactor', {
                    organizationId: { $in: [user.organizationId, null] },
                });
                allow('viewActivityOf', 'IUser');
                forbid('update', 'IUser', 'organizationId');
                allow('update', 'IUser', 'organizationId', { id: user.id });
                break;

            case USER_ROLE.ACCOUNT_ADMIN:
                forbid('navigateTo', ROUTES.SUPER_ADMIN);
                allow('crud', ['IUser', 'IParticipant', 'ICategory']);
                allow('crud', 'IScaleQuestion', {
                    organizationId: user.organizationId,
                });
                allow(['crud'], 'ISuccessFactor', {
                    organizationId: user.organizationId,
                });
                forbid(['create'], 'ISuccessFactor');
                allow('viewActivityOf', 'IUser');
                forbid('update', 'IUser', 'organizationId');
                forbid(['update', 'delete'], 'IUser', {
                    role: USER_ROLE.SUPER_ADMIN,
                }); // forbid user to update users with higher access level
                break;

            case USER_ROLE.PORTFOLIO_ADMIN:
                forbid('navigateTo', ROUTES.SUPER_ADMIN);
                allow('crud', ['IUser']);
                forbid('update', 'IUser', 'organizationId');
                forbid(['update'], 'IUser', 'categories');
                forbid(['update', 'delete'], 'IUser', {
                    role: USER_ROLE.SUPER_ADMIN || USER_ROLE.ACCOUNT_ADMIN,
                }); // forbid user to update users with higher access level
                allow('read', ['ICategory']);
                forbid('update', 'IParticipant', 'email');
                break;

            case USER_ROLE.PROJECT_ADMIN:
                forbid('navigateTo', [
                    ROUTES.SUPER_ADMIN,
                    ROUTES.ACCOUNT_SETTINGS,
                ]);
                forbid('update', 'IParticipant', 'email');
                break;
        }

        if (user.role !== USER_ROLE.PROJECT_ADMIN) {
            allow('read', 'IProjectManager');
        }

        allow('update', 'IUser', ['name'], { id: user.id }); // allow user to update his own data
    }

    return new Ability<PulseAbilities>(rules, {
        resolveAction,
    });
};
