import { WithProvider } from 'core/hocs/WithProvider';
import {
    IUseCurrentPageEntities,
    useCurrentPageEntities,
} from 'core/hooks/current-page-entities.hook';
import { IScaleQuestion } from 'modules/scale-questions/models/scale-question.model';
import { scaleQuestionsQuery } from 'modules/scale-questions/state/scale-questions.query';
import React, { createContext } from 'react';

export const CurrentPageQuestionsContext = createContext<
    IUseCurrentPageEntities<IScaleQuestion>
>(null as any);

export const CurrentPageQuestionsProvider = WithProvider(({ children }) => {
    const hook = useCurrentPageEntities<IScaleQuestion>(
        ({ order: a }, { order: b }) => {
            return a < b ? -1 : a > b ? 1 : 0;
        }
    );

    return (
        <CurrentPageQuestionsContext.Provider value={hook}>
            {children}
        </CurrentPageQuestionsContext.Provider>
    );
}, scaleQuestionsQuery);
