import { StoreConfig } from '@datorama/akita';
import {
    EntitiesState,
    EntitiesStore,
} from '../../../core/state/entities.store';
import {
    createPulseCommand,
    createPulseFromResponse,
    IPulse,
    IPulseResponse,
} from '../models/pulse.model';

export type PulseState = EntitiesState<IPulse>;

@StoreConfig({
    name: 'pulses',
    resettable: true,
})
export class PulseStore extends EntitiesStore<IPulse, IPulseResponse> {}

export const pulseStore = new PulseStore({
    responseMapper: createPulseFromResponse,
    commandMapper: createPulseCommand,
    searchTermParamKeys: [''],
});
