import { IAccountResponse } from 'modules/accounts/models/account.model';
import { IEntity } from '../../../core/interfaces/entity.interface';

export enum ParticipantAffiliation {
    ALL = 'all',
    CUSTOMER = 'customer',
    ORGANIZATION = 'organization',
    /*MY_ORGANIZATION = 'my-organization',*/
    OTHER = 'other',
    SUPPLIER = 'supplier',
}

export enum ParticipantRole {
    ALL = 'all',
    STEERING = 'steering',
    TEAM = 'team',
    STAKEHOLDER = 'stakeholder',
}

export interface IParticipantDetails {
    email: string;
    name: string;
    department: string;
    needsWelcomeEmail: boolean;
    imported: boolean;
    defaultAffiliation: ParticipantAffiliation;
    defaultRole: ParticipantRole;
    organizationName: string;
}

export interface IParticipant extends IEntity, IParticipantDetails {}

export interface IParticipantResponse extends IEntity<number> {
    email: string;
    name: string;
    department: string;
    needs_welcome_email: boolean;
    imported: boolean;
    default_affiliation: ParticipantAffiliation;
    default_role: ParticipantRole;
    deleted_at: any;
    organization?: IAccountResponse;
}

export const emptyParticipantData: IParticipantDetails = {
    email: '',
    name: '',
    department: '',
    needsWelcomeEmail: true,
    imported: false,
    defaultAffiliation: ParticipantAffiliation.ORGANIZATION,
    defaultRole: ParticipantRole.TEAM,
    organizationName: '',
};

export const createParticipant = ({
    id,
    ...props
}: Partial<IParticipant>): IParticipant => {
    if (!id) {
        throw new Error('Unable to create a user without an ID!');
    }

    return {
        id,
        ...emptyParticipantData,
        ...props,
    };
};

export const createParticipantFromResponse = ({
    id,
    needs_welcome_email,
    default_affiliation,
    default_role,
    organization,
    ...props
}: IParticipantResponse): IParticipant => {
    const organizationName = organization?.name ?? '';

    return createParticipant({
        id: id.toString(),
        needsWelcomeEmail: needs_welcome_email,
        defaultAffiliation: default_affiliation,
        defaultRole: default_role,
        organizationName,
        ...props,
    });
};
