import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { importsQuery } from '../state/imports.query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidTranslationKeys } from 'react-i18next';
//mui
import {
    Box,
    Button,
    CircularProgress,
    Container,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
//comps
import { EntityListPagination } from 'core/components/EntityListPagination';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { EntityListHeaderTemplate } from 'core/components/shared/EntityListHeaderTemplate';
import { ListHeader } from 'core/components/shared/ListHeader';
import { ListItemContainer } from 'core/components/shared/ListItemContainer';
//consts
import { primaryOrderByProperty } from 'core/constants/default-order-properties';
//helpers
import { asDateString } from 'core/helpers/asDateString';
//hocs
import { WithCurrentPageListener } from 'core/hocs/WithListContainer';
import { WithProvider } from 'core/hocs/WithProvider';
//hooks
import { useCrud } from 'core/hooks/crud.hook';
import { useCurrentPageEntities } from 'core/hooks/current-page-entities.hook';
import { useCurrentPage } from 'core/hooks/current-page.hook';
//services
import { apiService } from 'core/services/apiService';
//types
import { IImport, StateOfImport } from '../models/import.model';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    projectCardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
        columnGap: 24,
        rowGap: 24,
    },
    filters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableBackground: {
        backgroundColor: '#fff',
    },
    spacingLeft: {
        marginLeft: '5px',
    },

    right: {
        textAlign: 'right',
        marginRight: '-24px',
    },
    leftMr: {
        paddingRight: '3px',
    },
    loading: {
        width: '40px',
        height: '40px',
        position: 'absolute',
        left: '50%',
        top: '44%',
    },
}));

export enum SEVERITY {
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
}

interface IErrorTypes {
    language: number[];
    required: number[];
    pulse: number[];
    date: number[];
    frequency: number[];
    respondent: number[];
}

const ImportsListContainer: FC = () => {
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);
    const { currentPageEntities } = useCurrentPageEntities<IImport>();
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<SEVERITY>(
        SEVERITY.ERROR
    );

    const stateOfImport = (item: StateOfImport) => {
        switch (item) {
            case StateOfImport.IN_PROGRESS:
                return (
                    <>
                        <FontAwesomeIcon
                            icon={['far', 'arrow-up']}
                            color="orange"
                        />

                        <span className={classes.spacingLeft}>
                            {t('in-progress')}
                        </span>
                    </>
                );
            case StateOfImport.FINISHED:
                return (
                    <>
                        <FontAwesomeIcon
                            icon={['far', 'check-circle']}
                            color="green"
                        />

                        <span className={classes.spacingLeft}>
                            {t('finished')}
                        </span>
                    </>
                );
            case StateOfImport.FAILED:
                return (
                    <>
                        <FontAwesomeIcon
                            icon={['far', 'check-circle']}
                            color="red"
                        />

                        <span className={classes.spacingLeft}>
                            {t('failed')}
                        </span>
                    </>
                );

            default:
                return;
        }
    };
    const { fetch } = useCrud();

    const showErrorLines = (errorType: []) => {
        return `${t('error-on-line')} ${errorType}.`;
    };

    const generateErrorMessage = (err: IErrorTypes) => {
        return Object.entries(err).reduce((msg, [key, value]) => {
            const translationKey = ('import-errors.' +
                key) as keyof ValidTranslationKeys;
            const translationValue = t(translationKey);
            if (translationKey !== translationValue) {
                return msg + translationValue + ' ' + showErrorLines(value);
            }
            return msg;
        }, ''.trim());
    };

    const handleFileChange = (e: FormEvent<HTMLInputElement>) => {
        const formData = new FormData();
        const { files } = e.currentTarget;
        if (files && files.length > 0) {
            const file = files[0];
            formData.append('csv', file);
            setIsLoading(true);
            apiService
                .post(`/imports`, formData)
                .then(() => {
                    setIsLoading(false);
                    fetch();
                    setAlertMessage(t('uploaded-successfully'));
                    setAlertSeverity(SEVERITY.SUCCESS);
                })
                .catch((err) => {
                    const errorMessage = generateErrorMessage(
                        err.import_errors
                    );
                    setIsLoading(false);
                    setAlertMessage(errorMessage);
                    setAlertSeverity(SEVERITY.ERROR);
                });
        }
    };

    const { setOrderBy } = useCurrentPage();
    useEffect(() => {
        setOrderBy(primaryOrderByProperty, 'desc');
    }, []);

    return (
        <Box width={'100%'} className={classes.root}>
            <Container className={classes.right}>
                <label htmlFor="photo">
                    <input
                        accept=".csv"
                        style={{ display: 'none' }}
                        id="photo"
                        name="photo"
                        type="file"
                        multiple={false}
                        onChange={handleFileChange}
                    />
                    <Button
                        component="span"
                        variant="contained"
                        color="primary"
                    >
                        <FontAwesomeIcon
                            icon={['far', 'plus']}
                            color="white"
                            className={classes.leftMr}
                        />
                        {t('selectFile')}
                    </Button>
                </label>
            </Container>
            {alertMessage && (
                <Alert severity={alertSeverity} style={{ marginTop: '16px' }}>
                    {alertMessage}
                </Alert>
            )}

            <Box mt={3}>
                {isLoading ? (
                    <CircularProgress className={classes.loading} />
                ) : (
                    <></>
                )}
                <TableContainer
                    component={Paper}
                    variant="outlined"
                    className={classes.tableBackground}
                >
                    <Table>
                        <TableHead>
                            <ListHeader>
                                {
                                    <EntityListHeaderTemplate
                                        fileName={{
                                            label: 'File Name', // TODO: translate
                                        }}
                                        updatedAt={{
                                            label: 'Update At', // TODO: translate
                                        }}
                                        state={{
                                            label: 'State', // TODO: translate
                                        }}
                                    />
                                }
                            </ListHeader>
                        </TableHead>
                        <TableBody>
                            {!currentPageEntities ? (
                                <TableRow>
                                    <TableCell>Loading...</TableCell>
                                </TableRow> // TODO: replace with spinner
                            ) : (
                                currentPageEntities.map(
                                    ({ id, fileName, state, updatedAt }) => (
                                        <ListItemContainer
                                            key={id}
                                            entityId={id}
                                            enableHover={true}
                                        >
                                            {
                                                <>
                                                    <TableCell>
                                                        {fileName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {asDateString(
                                                            updatedAt,
                                                            {
                                                                format: 'date',
                                                            }
                                                        )}
                                                    </TableCell>

                                                    <TableCell>
                                                        {stateOfImport(state)}
                                                    </TableCell>
                                                </>
                                            }
                                        </ListItemContainer>
                                    )
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <EntityListPagination />
            </Box>
        </Box>
    );
};

export const ImportsList = WithProvider(
    WithCurrentPageListener(ImportsListContainer),
    importsQuery
);
