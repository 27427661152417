import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { useSession } from 'core/hooks/session.hook';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ILocationState } from '../components/privateRoute/PrivateRoute';
import { AcitavteAccountForm } from '../components/accountForm/AccountForm';
import { INewResetPasswordCredentials } from '../../resetpassword/models/new.password.model';
import { useParams } from 'react-router-dom';
import { IEntity } from '../../../core/interfaces/entity.interface';

export const SetupAccount: FC = () => {
    const { error } = useSession();
    const { push } = useHistory();
    const { id } = useParams<IEntity>();
    const [open, setOpen] = React.useState(false);
    const { resetPasswordHash } = useSession();

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function Alert(props: any) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const onSubmitClick = async (credentials: INewResetPasswordCredentials) => {
        const { password } = credentials;
        await resetPasswordHash(id, password).then(() =>
            push({ pathname: ROUTES.SUCCESS_PASSWORD })
        );
    };

    return (
        <>
            <AcitavteAccountForm onSubmitClick={onSubmitClick} />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Wrong credentials"
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                <Alert onClose={handleClose} severity="error">
                    {error?.main}
                </Alert>
            </Snackbar>
        </>
    );
};
