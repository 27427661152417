import {
    ImplementPulseAutoComplete,
    ImplementPulseAutoCompleteProps,
} from 'core/components/ImplementPulseAutoComplete';
import React, { FC, useCallback } from 'react';
import { deepFlat } from '../../../core/helpers/deepFlat';
import {
    createCategoryFromResponse,
    emptyCategory,
    ICategory,
    ICategoryResponse,
} from '../models/category.model';

interface ICategoryAutocompleteProps
    extends Omit<
        ImplementPulseAutoCompleteProps<
            Omit<ICategory, 'children'>,
            ICategoryResponse[]
        >,
        'url' | 'stringify' | 'responseMapper' | 'valueProperty'
    > {
    dimensionId?: string;
    emptyName?: string;
    categories?: ICategory[];
}

export const CategoryAutocomplete: FC<ICategoryAutocompleteProps> = ({
    dimensionId,
    emptyName,
    categories,
    ...props
}) => {
    const addDimensionAsAnOptionIfDefined = useCallback(
        (flatCategories: Omit<ICategory, 'children'>[]) => {
            if (emptyName) {
                flatCategories.unshift({
                    id: '',
                    ...emptyCategory,
                    name: emptyName,
                });
            }

            return flatCategories;
        },
        [emptyName]
    );

    return (
        <ImplementPulseAutoComplete<
            Omit<ICategory, 'children'>,
            ICategoryResponse[]
        >
            data={
                !categories
                    ? undefined
                    : addDimensionAsAnOptionIfDefined(deepFlat(categories))
            }
            stringify={({ name, level }) =>
                new Array(emptyName ? level : level - 1).fill('.').join(' ') +
                ' ' +
                name
            }
            url={
                !dimensionId
                    ? undefined
                    : `/dimension-entries/dimension/${dimensionId}`
            }
            responseMapper={
                !dimensionId
                    ? undefined
                    : (responses) =>
                          addDimensionAsAnOptionIfDefined(
                              deepFlat(
                                  responses.map((response) =>
                                      createCategoryFromResponse(
                                          {
                                              ...response,
                                              dimension: +dimensionId,
                                          },
                                          0
                                      )
                                  )
                              )
                          )
            }
            valueProperty="id"
            {...props}
        />
    );
};
