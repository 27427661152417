import { Box, Container, TextField, Typography } from '@material-ui/core';
import { FC, useContext } from 'react';

import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { WithProvider } from 'core/hocs/WithProvider';
import { PulseDialogContext } from 'modules/pulse/components/PulseDialogProvider';
import { pulsesQuery } from 'modules/pulse/state/pulse.query';

export interface IEmailContainerProps {
    id: string;
}

export interface IEmailResponse {
    customMessage: string;
    defaultMessage: string;
    emailBody: string;
    emailBodyHtml: string;
}

export const EmailDialogContent: FC<IEmailContainerProps> = WithProvider(
    ({ id: pulseId }) => {
        const { t } = useContext(MultiLanguageSupportContext);

        const {
            emailMessage,
            setEmailMessage,
            defaultEmailMessage,
        } = useContext(PulseDialogContext);

        return (
            <Container maxWidth="md">
                <Box marginY={5}>
                    <Typography variant="h1" align="center">
                        {t('customize-email-message')}
                    </Typography>
                </Box>

                <Box mt={5}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="filled"
                        id="outlined-required"
                        label={t('default-message-label')}
                        value={defaultEmailMessage}
                    />
                </Box>

                <Box mt={5}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="filled"
                        id="outlined-required"
                        label={t('custom-message-label')}
                        value={emailMessage}
                        onChange={(e) => setEmailMessage(e.target.value)}
                    />
                </Box>
            </Container>
        );
    },
    pulsesQuery
);
