import React, { FC } from 'react';
import { EntityProvider } from '../components/EntityProvider';
import { IEntity } from '../interfaces/entity.interface';
import { EntitiesQuery } from '../state/entities.query';

export const WithProvider = <P, T extends IEntity>(
    WrappedComponent: FC<P>,
    query: EntitiesQuery<T>
): FC<P> => {
    const HocComponent: FC<P> = ({ ...props }) => (
        <EntityProvider query={query as any}>
            <WrappedComponent {...props} />
        </EntityProvider>
    );

    Object.defineProperty(HocComponent, 'name', {
        value: `WithEntityProvider(${WrappedComponent.name})`,
        configurable: true,
    });

    return HocComponent;
};
