import { WithProvider } from '../../../core/hocs/WithProvider';
import { WithCurrentPageListener } from '../../../core/hocs/WithListContainer';
import { languagesQuery } from '../state/languages.query';
import {
    LanguageDialogContext,
    LanguageDialogProvider,
} from '../components/LanguageDialogProvider';
import { useContext } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { Box, Button } from '@material-ui/core';
import { FormDialogWrapper } from '../../../core/components/shared/FormDialogWrapper';
import { LanguagesList } from '../components/LanguagesList';
import { EntityListPagination } from '../../../core/components/EntityListPagination';
import { LanguageForm } from '../components/LanguageForm';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    addBtn: {
        textTransform: 'capitalize',
        margin: '10px 0',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

const LanguagePageContainer = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { isOpen, open, close, languageId } = useContext(
        LanguageDialogContext
    );
    const classes = useStyles();

    return (
        <>
            <Box display="flex" justifyContent="flex-end">
                <Button
                    onClick={() => open({})}
                    disableElevation
                    variant="contained"
                    color="primary"
                    className={classes.addBtn}
                >
                    Create New Language
                    {/* TODO: Translation */}
                </Button>
            </Box>

            <FormDialogWrapper
                isOpen={isOpen}
                handleClose={close}
                title={
                    // !languageId ? t('create-new-language') : t('edit-language') TODO: Translation
                    !languageId ? 'Create New Language' : 'Edit Language'
                }
            >
                <LanguageForm />
            </FormDialogWrapper>
            <LanguagesList />
            <EntityListPagination />
        </>
    );
};

export const LanguagePage = WithProvider(
    WithCurrentPageListener(() => (
        <LanguageDialogProvider>
            <LanguagePageContainer />
        </LanguageDialogProvider>
    )),
    languagesQuery
);
