import { ROUTES } from '../enums/routes.enum';

export function pathBuilder(
    path: ROUTES,
    paramToReplace: string,
    paramValue: string
): string {
    const newPath = path.replace(paramToReplace, paramValue);
    // console.log(newPath, path, paramToReplace, paramValue);
    return newPath;
}
