import {
    Box,
    Button,
    Container,
    DialogActions,
    DialogContent,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { SearchInput } from 'core/components/shared/SearchInput';
import { useCurrentPageEntities } from 'core/hooks/current-page-entities.hook';
import { useCurrentPage } from 'core/hooks/current-page.hook';
import { useFormikContext } from 'formik';
import { useContext } from 'react';
import css from 'styles/material-ui/cssVars';
import { EntityListPagination } from '../../../core/components/EntityListPagination';
import { WithCurrentPageListener } from '../../../core/hocs/WithListContainer';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { IParticipant } from '../models/participant.model';
import { participantsQuery } from '../state/participants.query';
import { ParticipantForm } from './ParticipantForm';
import { ParticipantFormContext } from './ParticipantFormProvider';
import { ParticipantsList } from './ParticipantsList';

const useStyles = makeStyles((theme: Theme) => ({
    searchfield: {
        marginRight: theme.spacing(4),
        width: '240px',
        [theme.breakpoints.up('lg')]: {
            width: '440px',
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(0),
            marginTop: theme.spacing(3),
            width: '100%',
        },
        '& input': {
            padding: `14.5px ${theme.spacing(2)}px 14.5px 0`,
        },
    },
    holderDiv: {
        float: 'right',
        paddingBottom: '25px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column-reverse',
        },
    },
    addBtn: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    modalActions: {
        backgroundColor: css.extraColors.text.darkPrimary,
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
    },
}));

const RespondentsPage = () => {
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);
    const { currentPageEntities } = useCurrentPageEntities<IParticipant>();
    const { setSearchTerm } = useCurrentPage();
    const { submitForm, isValid } = useFormikContext();
    const { isOpen, open, close, participantId } = useContext(
        ParticipantFormContext
    );

    return (
        <>
            <Box>
                <Box className={classes.holderDiv}>
                    <SearchInput
                        initialValue={
                            participantsQuery.getValue().ui.searchTerm
                        }
                        variant="outlined"
                        className={classes.searchfield}
                        label={t('search-participants')}
                        onChange={setSearchTerm}
                    />

                    <Button
                        className={classes.addBtn}
                        variant="contained"
                        disableElevation
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => open({})}
                    >
                        {t('add-participant')}
                    </Button>
                </Box>
                <ParticipantsList
                    entities={currentPageEntities}
                    onSelect={open}
                    showDeleteAction
                />

                <EntityListPagination />
            </Box>
            <FormDialogWrapper isOpen={isOpen} handleClose={close}>
                <DialogContent>
                    <Container maxWidth="md">
                        <Box marginY={4}>
                            <Typography variant="h2" align="center">
                                {isOpen
                                    ? !participantId
                                        ? t('add-new-participant')
                                        : t('edit-participant')
                                    : null}
                            </Typography>
                        </Box>

                        <ParticipantForm participantId={participantId} />
                    </Container>
                </DialogContent>

                <DialogActions className={classes.modalActions}>
                    <Box>
                        <Button disableElevation variant="text" onClick={close}>
                            {t('cancel')}
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            disableElevation
                            onClick={submitForm}
                            disabled={!isValid}
                            color="primary"
                        >
                            {t('save-participant')}
                        </Button>
                    </Box>
                </DialogActions>
            </FormDialogWrapper>
        </>
    );
};

export const RespondentsPageConatiner = WithProvider(
    WithCurrentPageListener(RespondentsPage),
    participantsQuery
);
