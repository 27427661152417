import { Box, Button, Container, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { filterTranslatesBuilder } from 'core/components/shared/pulse-survey/filterTranslatesBuilderHelper';
import { StarRating } from 'core/components/shared/pulse-survey/starRating';
import { SuccessFactors } from 'core/components/shared/pulse-survey/successFactors';
import { SurveyFeedback } from 'core/components/shared/pulse-survey/surveyFeedback';
import SurveyHeader from 'core/components/shared/pulse-survey/surveyHeader';
import { SurveyOverview } from 'core/components/shared/pulse-survey/surveyOverview';
import { IEntity } from 'core/interfaces/entity.interface';
import {
    IStepConfig,
    ISurveyPayload,
    ISurveyPayloadSuggestion,
    ISurveyScaleQuestionResponse,
} from 'core/interfaces/survey-answering.interface';
import { apiService } from 'core/services/apiService';
import { requiredField } from 'core/validations';
import { FormikProvider, useFormik } from 'formik';
import {
    createFactorFromResponse,
    IFactor,
    IFactorResponse,
} from 'modules/success-factors/models/success-factor.model';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useParams } from 'react-router';
import { SurveyAnsweredSvg } from 'styles/assets/svg/survey-answered';
import * as Yup from 'yup';
import {
    IBrandingSettings,
    IStyleProps,
    ISurveyLabelTranslation,
} from '../models/feedback-loop-models';
import { scrollAppToTop } from '../../../helpers';
import { FactorValue } from 'modules/success-factors/interfaces/FactorValue';
import { asDateString } from 'core/helpers/asDateString';

const validationSchema = Yup.object({
    text: requiredField,
});

const useStyles = makeStyles((theme) => ({
    surveyWrapper: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
    },
    svg: {
        position: 'relative',
        left: '50%',
        marginLeft: '-203px',
    },
    container: {
        background: (props: IStyleProps) => props.primaryColor,
        color: (props: IStyleProps) => props.actionText,
        '&:hover': {
            background: (props: IStyleProps) => props.primaryColor,
        },
    },
    stepIntro: {
        padding: '48px 0',
        [theme.breakpoints.down('xs')]: {
            padding: '24px 0',
        },
    },
    stepHeading: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
            lineHeight: '24px',
        },
    },
    surveyFooter: {
        borderTop: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3),
        marginTop: 'auto',
        [theme.breakpoints.down('sm')]: {
            padding: '8px 16px',
        },
    },
}));

enum SurveyErroType {
    NONE,
    ANSWERED,
    EXPIRED,
}

export const SurveyPage: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);

    const { id } = useParams<IEntity>();
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [surveyErrorType, setSurveyErrorType] = useState(SurveyErroType.NONE);
    const [surveyErrorText, setSurveyErrorText] = useState('');
    const [objectToPost, setObjectToPost] = useState<ISurveyPayload>();
    const [
        brandingSettings,
        setBrandingSettings,
    ] = useState<IBrandingSettings>();

    const props = !brandingSettings
        ? {}
        : {
              primaryColor: `#${brandingSettings.primary_color.replace(
                  '#',
                  ''
              )}`,
              actionText: `#${brandingSettings.action_text_color.replace(
                  '#',
                  ''
              )}`,
          };

    const classes = useStyles(props);

    const [surveyQuestions, setSurveyQuestions] = useState<
        ISurveyScaleQuestionResponse[]
    >([]);
    const [surveyFactors, setSurveyFactors] = useState<IFactor[]>([]);
    const [surveyLabelTranslations, setSurveyLabelTranslations] = useState<
        ISurveyLabelTranslation[]
    >();
    useEffect(() => {
        if (id) {
            apiService

                .get<ISurveyScaleQuestionResponse[]>(
                    `/surveys/${id}/questions?limit=1000`
                )
                .then(({ data }) => {
                    setSurveyQuestions(data);
                })
                .catch((error) => {
                    const date = !error.message
                        ? null
                        : new Date(error.message);

                    switch (error.code) {
                        case 409:
                            setSurveyErrorType(SurveyErroType.EXPIRED);
                            setSurveyErrorText(t('pulse-answered-already'));
                            break;
                        case 410:
                            setSurveyErrorType(SurveyErroType.ANSWERED);
                            setSurveyErrorText(
                                date instanceof Date
                                    ? t('pulse-has-expired-on', {
                                          date: asDateString(date, {
                                              format: 'date',
                                          }),
                                      })
                                    : t('pulse-has-expired')
                            );
                            break;
                    }
                });

            apiService
                .get<IFactorResponse[]>(`/surveys/${id}/factors?limit=1000`)
                .then(({ data }) => {
                    setSurveyFactors(data.map(createFactorFromResponse));
                })
                .catch((error) => {
                    console.log(error);
                });

            apiService.get(`/surveys/${id}/branding`).then(({ data }) => {
                setBrandingSettings(data);
            });

            apiService.get(`/surveys/${id}/translations`).then(({ data }) => {
                setSurveyLabelTranslations(data);
            });
        }
    }, [id]);

    const initialValues = useMemo(
        () =>
            surveyQuestions.reduce(
                (obj, question) => {
                    obj[question.id.toString()] = 0;
                    return obj;
                },
                {} as {
                    [key: string]: number;
                }
            ),
        [surveyQuestions]
    );

    const formik = useFormik<{
        [key: string]: number;
    }>({
        initialValues,
        enableReinitialize: true,
        validateOnChange: true,
        validate: (values) =>
            Object.keys(values)
                .filter((key) => !values[key])
                .reduce(
                    (obj, key) => {
                        obj[key] = 'Required';
                        return obj;
                    },
                    {} as {
                        [key: string]: string;
                    }
                ),
        onSubmit: () => {
            return;
        },
    });

    const starsStepConfig: IStepConfig = useMemo(() => {
        return {
            content: (
                <FormikProvider value={formik}>
                    <StarRating
                        surveyQuestions={surveyQuestions}
                        surveyLabelTranslations={surveyLabelTranslations}
                    ></StarRating>
                </FormikProvider>
            ),
            progressBarValue: 33,
        };
    }, [formik]);

    const factorsInitialValues = useMemo(
        () =>
            surveyFactors.reduce(
                (obj, factor) => {
                    obj[factor.id] = 'neutral';
                    return obj;
                },
                {} as {
                    [key: string]: FactorValue | null;
                }
            ),
        [surveyFactors]
    );

    const nextComponent = useCallback(() => {
        setCurrentStepIndex(currentStepIndex + 1);
        scrollAppToTop();
    }, [currentStepIndex]);

    const previousComponent = useCallback(() => {
        setCurrentStepIndex(currentStepIndex - 1);
        scrollAppToTop();
    }, [currentStepIndex]);

    const firstComponent = useCallback(() => {
        setCurrentStepIndex(currentStepIndex - 2);
        scrollAppToTop();
    }, [currentStepIndex]);

    const factorsFormik = useFormik<{
        [key: string]: FactorValue | null;
    }>({
        initialValues: factorsInitialValues,
        enableReinitialize: true,
        validateOnChange: true,
        validate: (values) =>
            Object.keys(values)
                .filter((key) => !values[key])
                .reduce(
                    (obj, key) => {
                        obj[key] = 'Required';
                        return obj;
                    },
                    {} as {
                        [key: string]: string;
                    }
                ),
        onSubmit: () => {
            return;
        },
    });

    const factorsStepConfig: IStepConfig | any = useMemo(() => {
        return {
            content: (
                <FormikProvider value={factorsFormik}>
                    <SuccessFactors
                        surveyFactors={surveyFactors}
                        surveyLabelTranslations={surveyLabelTranslations}
                        nextComponent={nextComponent}
                    ></SuccessFactors>
                </FormikProvider>
            ),

            disableSubmit: !factorsFormik.dirty || !factorsFormik.isValid,
            onSubmit: () => {
                return;
            },
            progressBarValue: 66,
        };
    }, [formik]);

    const feedbackInitialValues = {
        text: '',
        public: true,
        labTime: 28,
        pulse_event_respondent: {
            id: id,
        },
    };

    const feedbackFormik = useFormik<ISurveyPayloadSuggestion>({
        initialValues: feedbackInitialValues,
        validationSchema,
        enableReinitialize: true,
        validateOnChange: true,
        onSubmit: () => {
            return;
        },
    });

    const feedbackStepConfig: IStepConfig = useMemo(() => {
        return {
            content: (
                <FormikProvider value={feedbackFormik}>
                    <SurveyFeedback
                        surveyLabelTranslations={surveyLabelTranslations}
                    ></SurveyFeedback>
                </FormikProvider>
            ),
            onSubmit: () => {
                return;
            },
            disableSubmit: !feedbackFormik.dirty || !feedbackFormik.isValid,
            progressBarValue: 100,
        };
    }, [formik]);

    const overviewStepConfig: IStepConfig = useMemo(() => {
        return {
            content: (
                <FormikProvider value={formik}>
                    <SurveyOverview
                        objectToPost={objectToPost}
                        surveyQuestions={surveyQuestions}
                        surveyFactors={surveyFactors}
                        surveyLabelTranslations={surveyLabelTranslations}
                    ></SurveyOverview>
                </FormikProvider>
            ),
            onSubmit: () => {
                return;
            },
            progressBarValue: 100,
        };
    }, [formik]);

    const { content, progressBarValue }: IStepConfig = useMemo(() => {
        switch (currentStepIndex) {
            case 0:
                return starsStepConfig;
            case 1:
                return factorsStepConfig;
            case 2:
                return feedbackStepConfig;
            case 3:
                return overviewStepConfig;
            default:
                return {
                    content: <></>,
                    onSubmit: async () => {
                        return;
                    },
                };
        }
    }, [
        currentStepIndex,
        starsStepConfig,
        feedbackStepConfig,
        factorsStepConfig,
        overviewStepConfig,
    ]);

    const onSubmit = () => {
        const pulse_event_respondent = { id };
        const postObject: ISurveyPayload = {
            question_answers: Object.keys(formik.values).map((key) => {
                const value = formik.values[key];
                return {
                    value,
                    scale_question: { id: +key },
                    pulse_event_respondent,
                };
            }),
            factor_answers: Object.keys(factorsFormik.values).map((key) => {
                const value = factorsFormik.values[key];
                return {
                    value,
                    factor: { id: +key },
                    pulse_event_respondent,
                };
            }),
            suggestion: feedbackFormik.values,
        };

        setObjectToPost(postObject);
        nextComponent();
        apiService
            .post(`surveys/${id}`, postObject)
            .then((response) => console.log(response.data));
    };

    const filterTranslates = filterTranslatesBuilder(surveyLabelTranslations);
    return (
        <div className={classes.surveyWrapper}>
            {!brandingSettings ? (
                'loading'
            ) : (
                <>
                    {surveyErrorType === SurveyErroType.NONE ? (
                        <>
                            <SurveyHeader
                                progressBarValue={progressBarValue}
                                brandingSettings={brandingSettings}
                            ></SurveyHeader>
                            <div> {content} </div>
                        </>
                    ) : (
                        <>
                            <SurveyHeader
                                progressBarValue={100}
                                brandingSettings={brandingSettings}
                            ></SurveyHeader>
                            <Container>
                                <Box className={classes.stepIntro}>
                                    {surveyErrorType === 1 && (
                                        <Typography variant="h2" align="center">
                                            {surveyErrorText}
                                        </Typography>
                                    )}

                                    {surveyErrorType === 2 && (
                                        <Typography variant="h2" align="center">
                                            {surveyErrorText}
                                        </Typography>
                                    )}
                                    <SurveyAnsweredSvg />
                                </Box>
                            </Container>
                        </>
                    )}
                    {currentStepIndex < 3 && (
                        <Box className={`fixed-bottom ${classes.surveyFooter}`}>
                            {currentStepIndex === 0 ? (
                                <Box></Box>
                            ) : surveyFactors.length > 0 ? (
                                <Button
                                    disableElevation
                                    variant="outlined"
                                    startIcon={<ArrowBackIosIcon />}
                                    onClick={previousComponent}
                                    className={classes.container}
                                >
                                    {filterTranslates(
                                        'buttonPrevious',
                                        t('button-previous')
                                    )}
                                </Button>
                            ) : (
                                <Button
                                    disableElevation
                                    variant="outlined"
                                    startIcon={<ArrowBackIosIcon />}
                                    onClick={firstComponent}
                                    className={classes.container}
                                >
                                    {filterTranslates(
                                        'buttonPrevious',
                                        t('button-previous')
                                    )}
                                </Button>
                            )}
                            {surveyErrorType === SurveyErroType.NONE ? (
                                <Box px={3}>
                                    <Hidden smDown>
                                        <Typography
                                            className="MuiTypography-badge"
                                            color="textSecondary"
                                        >
                                            {filterTranslates(
                                                'securityNotification',
                                                t('security-notification')
                                            )}
                                        </Typography>
                                    </Hidden>
                                </Box>
                            ) : null}
                            {currentStepIndex !== 2 &&
                            surveyErrorType === SurveyErroType.NONE ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    endIcon={<ArrowForwardIosIcon />}
                                    onClick={nextComponent}
                                    className={classes.container}
                                >
                                    {filterTranslates(
                                        'buttonContinue',
                                        t('button-continue')
                                    )}
                                </Button>
                            ) : null}
                            {currentStepIndex === 2 ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    endIcon={<ArrowForwardIosIcon />}
                                    onClick={onSubmit}
                                    className={classes.container}
                                >
                                    {filterTranslates(
                                        'submitButton',
                                        t('submit')
                                    )}
                                </Button>
                            ) : null}
                        </Box>
                    )}
                </>
            )}
        </div>
    );
};
