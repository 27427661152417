import React, { FC } from 'react';
import { IEntityListItemProps } from '../../../core/interfaces/entity-list-item-props.interface';
import { IUser } from '../models/user.model';
import { UserListItemTemplate } from './UserListItemTemplate';

export const UserListItem: FC<IEntityListItemProps<IUser>> = ({
    entity: user,
    index,
}) => {
    return (
        <UserListItemTemplate
            name={user.name}
            email={user.email}
            key={index}
            role={user.role}
        />
    );
};
