import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'formik';
import { useLanguages } from 'modules/languages/hooks/languages.hook';
import { ILanguage } from 'modules/languages/models/language.model';
import React, { FC, useContext } from 'react';
import { MultiLanguageSupportContext } from '../MultiLanguageSupportProvider';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '760px',
    },
    form: {
        width: '100%',
    },
    block: {
        display: 'block',
    },
}));

export interface EntityTranslationsPageProps {
    renderRow?: (langiage: ILanguage) => JSX.Element;
}

export const EntityTranslationsPage: FC<EntityTranslationsPageProps> = ({
    renderRow,
}) => {
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);
    const [languages] = useLanguages();

    return (
        <>
            <Box my={3}>
                <Typography variant="h1" align="center">
                    {t('add-translations')}
                </Typography>
            </Box>
            <Container className={classes.container}>
                {!renderRow ? (
                    <></>
                ) : (
                    <Form className={classes.form}>
                        <Grid container spacing={3} className={classes.block}>
                            {languages.map((language) => (
                                <Box key={language.id} display="flex">
                                    {renderRow(language)}
                                </Box>
                            ))}
                        </Grid>
                    </Form>
                )}
            </Container>
        </>
    );
};
