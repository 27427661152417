import { TableCell } from '@material-ui/core';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { asDateString } from 'core/helpers/asDateString';
import React, { FC, useContext } from 'react';
import { ValidTranslationKeys } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../navigation/enums/routes.enum';
import { pathBuilder } from '../../navigation/helpers/path-builder.helper';
import { IPulse } from '../models/pulse.model';

type IPulseListItemTemplateProps = IPulse;

export const PulseListItemTemplate: FC<IPulseListItemTemplateProps> = ({
    id,
    name,
    numberOfParticipants,
    numberOfQuestions,
    startDate,
    frequency,
    lastPulseEvent,
    nextPulseEvent,
}) => {
    const { push } = useHistory();
    const { t } = useContext(MultiLanguageSupportContext);
    const redirect = () => {
        if (id)
            push({
                pathname: pathBuilder(ROUTES.PULSE, ':id', id),
            });
    };
    return (
        <>
            <TableCell onClick={redirect}>{name}</TableCell>
            <TableCell onClick={redirect}>
                {nextPulseEvent
                    ? asDateString(new Date(nextPulseEvent), {
                          format: 'date',
                      })
                    : '-'}
            </TableCell>
            <TableCell onClick={redirect}>
                {lastPulseEvent
                    ? asDateString(new Date(lastPulseEvent), {
                          format: 'date',
                      })
                    : '-'}
            </TableCell>
            <TableCell onClick={redirect}>
                {startDate
                    ? t(`frequency-${frequency}` as keyof ValidTranslationKeys)
                    : '-'}
            </TableCell>
            <TableCell onClick={redirect}>{numberOfParticipants}</TableCell>
            <TableCell onClick={redirect}>{numberOfQuestions}</TableCell>
        </>
    );
};
