import { PageNotFound } from 'core/pages/PageNotFound';
import { ProjectsPageConatiner } from 'modules/projects/pages/ProjectsPage';
import { AccountsSettingsPage } from '../../accounts-settings/pages/AccountsSettingsPage';
import { AnalyticsPage } from '../../analytics/Analytics';
import { ProjectPage } from '../../projects/pages/ProjectPage';
import { SinglePulsePage } from '../../pulse/pages/SinglePulsePage';
import { SuperAdminPage } from '../../super-admin/pages/SuperAdminPage';
import { ROUTES } from '../enums/routes.enum';
import { INavigationItem } from '../interfaces/navigationItem.interface';

export const navigationItems: INavigationItem[] = [
    {
        path: ROUTES.PROJECT,
        contentComponent: ProjectPage,
    },
    {
        path: ROUTES.PULSE,
        contentComponent: SinglePulsePage,
    },
    {
        path: ROUTES.PROJECTS,
        contentComponent: ProjectsPageConatiner,
    },
    {
        path: ROUTES.SUPER_ADMIN,
        contentComponent: SuperAdminPage,
    },
    {
        path: ROUTES.ANALYTICS,
        contentComponent: AnalyticsPage,
    },
    {
        path: ROUTES.ACCOUNT_SETTINGS,
        contentComponent: AccountsSettingsPage,
    },
    {
        path: ROUTES.NOT_FOUND,
        contentComponent: PageNotFound,
    },
];
