import { TableCell } from '@material-ui/core';
import { TranslateWarning } from 'core/components/shared/TranslationWarning';
import { ITranslation } from 'modules/scale-questions/models/scale-question.model';
import React, { FC } from 'react';

interface IScaleQuestionsListItemTemplate {
    index: number;
    phrase: string;
    name: string;
    translate?: ITranslation;
}

export const ScaleQuestionListItemTemplate: FC<IScaleQuestionsListItemTemplate> = ({
    index,
    phrase,
    name,
    translate,
}) => {
    return (
        <>
            <TableCell>{index}.</TableCell>
            <TableCell>{phrase}</TableCell>
            <TableCell>{translate?.name || name}</TableCell>
            <TableCell>{translate?.phrase || phrase}</TableCell>
        </>
    );
};
