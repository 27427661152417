import { makeStyles } from '@material-ui/core';
import { BackupSharp } from '@material-ui/icons';
import React, { FC } from 'react';
import { DropzoneState } from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
    drop: {
        backgroundColor: 'transparent',
        width: '100%',
        height: '134px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px dashed',
        borderColor: theme.palette.divider,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
}));

export const ImageUploadDropzoneState: FC<DropzoneState> = ({
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    children,
}) => {
    const classes = useStyles();
    // additional CSS depends on dragging status
    const additionalClass = isDragAccept
        ? 'accept'
        : isDragReject
        ? 'reject'
        : '';

    return (
        <div
            {...getRootProps({
                className: `dropzone ${additionalClass}, ${classes.drop}`,
            })}
        >
            <input {...getInputProps()} />
            <span>{isDragActive && <BackupSharp />}</span>
            {children}
        </div>
    );
};
