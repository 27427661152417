import { ParticipantAffiliation } from 'modules/participants/models/participant.model';
import { FactorValue } from 'modules/success-factors/interfaces/FactorValue';
import { ValidTranslationKeys } from 'react-i18next';
import { convertDate } from 'shared/yup/utils/convertDate';
import { IEntity } from '../../../core/interfaces/entity.interface';

interface IPulseAnswersScaleQuestionResponse {
    scaleQuestionId: string;
    average: string;
    name: string;
}

export type IPulseAnswersSuccessFactors = Record<
    string,
    Record<FactorValue, number>
>;

type IPulseAnswersSuccessFactorsResponseCollection = Record<
    string,
    [Record<FactorValue, number>]
>;

type IPulseResultsAverageTupleResponse = [
    IPulseAnswersScaleQuestionResponse[],
    IPulseAnswersSuccessFactorsResponseCollection
];

type IPulseResultsAveragesResponse = Record<
    string,
    IPulseResultsAverageTupleResponse
>;

export interface IProjectsDataResponse
    extends Partial<Record<Exclude<FactorValue, 'neutral'>, string[]>> {
    participants: number;
    response_rate: number;
    questions?: Record<string, number>;
    factors?: IAnalyticDataFactor[];
    pulse_distribution_graph_data?: IPulseDistributionGraph;
}

export interface ITimeSeriesData {
    date: string;
    average: number;
}

type ITimeSeriesGraphData = IGraphData<ITimeSeriesData> & IEntity<number>;

export interface IPortfolioData {
    pulse_answers: Record<string, number>;
    success_factors: IPulseAnswersSuccessFactors;
    projects_data: Record<string, IProjectsDataResponse>;
    time_series_graph_data: ITimeSeriesGraphData[];
}

interface IAnalyticDataRoleCount {
    team: string;
    steering: string;
    stakeholder: string;
}

interface ITimeDate {
    date: string;
    timezone_type: number;
    timezone: string;
}

export interface IPulseAnswersFactor {
    title: string;
    value: FactorValue;
}

interface IPulseAnswersQuestions {
    name: string;
    organization: null | string;
    scaleQuestionId: number;
    value: string;
}

interface IPulseAnswersDescription {
    date: Date;
    organization: ParticipantAffiliation;
    role: keyof ValidTranslationKeys;
}

interface IPulseAnswersDescriptionResponse {
    date: ITimeDate;
    organization: string;
    role: string;
}
export interface IPulseAnswersDataFeedback {
    id: string;
    public: boolean;
    message: string;
}

interface IPulseAnswer {
    description: IPulseAnswersDescription;
    factors: IPulseAnswersFactor[];
    feedback: [IPulseAnswersDataFeedback];
    questions: IPulseAnswersQuestions[];
}

type IPulseAnswers = Record<string, Record<string, IPulseAnswer>>;

interface IPulseAnswerResponse {
    description: [IPulseAnswersDescriptionResponse];
    factors: [IPulseAnswersFactor[]];
    feedback: string[];
    questions: [IPulseAnswersQuestions[]];
}

type IPulseAnswersResponse = Record<
    string,
    Record<string, IPulseAnswerResponse>
>;

export interface IPulseDistributionGraphDataResponse {
    count: number;
    percentage: number;
    [key: number]: number;
}

export type PulseDistributionGraphRanges =
    | '4,5-5'
    | '3,5-4'
    | '2,5-3'
    | '1,5-2'
    | '0-1';

type IPulseDistributionGraph = Record<
    string,
    Record<PulseDistributionGraphRanges, IPulseDistributionGraphDataResponse>
>;

interface IAnalyticDataQuestion {
    name: string;
    default: boolean;
    organization: string | null;
}

interface IAnalyticDataFactor {
    title: string;
    default: boolean;
    organization: string | null;
}

export interface IAnalyticData {
    pulses: string;
    organization: string;
    period: string;
    pulsePerParticipant: string;
    successFactorGraphData: IPulseAnswersSuccessFactors;
    roleCount: IAnalyticDataRoleCount;
    pulseAnswers: IPulseAnswers;
    pulseResultsAverage: IPulseResultsAveragesResponse;
    totalParticipants: number;
    pulseResponsePercentage: number;
    lastEventDate: Date | null;
    timeSeriesGraphData: ITimeSeriesGraphData[];
    feedback: string[];
    pulseDistributionGraphData: IPulseDistributionGraph;
    questions: IAnalyticDataQuestion[];
    factors: IAnalyticDataFactor[];
}

interface IAnalyticDataResponseLastEventDate {
    date: string;
    timezone_type: string;
    timezone: string;
}

export interface IAnalyticDataResponse {
    pulse: string;
    organization: string;
    period: string;
    pulse_per_participant: string;
    success_factor_graph_data: IPulseAnswersSuccessFactorsResponseCollection;
    role_count: IAnalyticDataRoleCount;
    pulse_answers: IPulseAnswersResponse;
    pulse_results_average: IPulseResultsAveragesResponse;
    total_participants: number;
    pulse_response_percentage: number;
    last_event_date: IAnalyticDataResponseLastEventDate | 'none';
    time_series_graph_data: ITimeSeriesGraphData[];
    feedback: string[];
    pulse_distribution_graph_data: IPulseDistributionGraph;
    questions: IAnalyticDataQuestion[];
    factors: IAnalyticDataFactor[];
}

export const createAnalyticsDataFromResponse = ({
    pulse,
    pulse_per_participant,
    success_factor_graph_data,
    role_count,
    pulse_answers,
    pulse_results_average,
    total_participants,
    pulse_response_percentage,
    last_event_date,
    time_series_graph_data,
    pulse_distribution_graph_data,
    ...response
}: IAnalyticDataResponse): IAnalyticData => {
    const pulseAnswers: IPulseAnswers =
        pulse_answers &&
        Object?.entries(pulse_answers)?.reduce(
            (temp1, [key1, value1]) => ({
                ...temp1,
                [key1]:
                    value1 &&
                    Object.entries(value1)?.reduce(
                        (
                            temp2,
                            [
                                key2,
                                {
                                    description: [{ date, ...rest }],
                                    factors,
                                    feedback,
                                    questions,
                                },
                            ]
                        ) => ({
                            ...temp2,
                            [key2]: {
                                description: {
                                    ...rest,
                                    date: convertDate(date.date),
                                },
                                factors: factors[0],
                                feedback: feedback,
                                questions: questions[0],
                            },
                        }),
                        {}
                    ),
            }),
            {}
        );

    const successFactorGraphData =
        success_factor_graph_data &&
        Object.entries(success_factor_graph_data).reduce(
            (obj, [key, [factorResult]]) => ({
                ...obj,
                [key]: factorResult,
            }),
            {}
        );

    return {
        pulses: pulse,
        pulsePerParticipant: pulse_per_participant,
        successFactorGraphData,
        roleCount: role_count,
        pulseAnswers,
        pulseResultsAverage: pulse_results_average,
        totalParticipants: total_participants,
        pulseResponsePercentage: pulse_response_percentage,
        timeSeriesGraphData: time_series_graph_data,
        lastEventDate:
            last_event_date === 'none'
                ? null
                : convertDate(last_event_date?.date),
        ...response,
        pulseDistributionGraphData: pulse_distribution_graph_data,
    };
};

export interface IGraphData<T = string, N = string> {
    name: N;
    data: T[];
}
