const mapYupErrorsToFormikErrors = (err: { inner: any[] }) => {
    return err.inner
        .filter((i: { path: any }) => !!i.path)
        .reduce(
            (curr: any, next: { path: any; errors: any[] }) => ({
                ...curr,
                [next.path]: next.errors[0],
            }),
            {}
        );
};

export default mapYupErrorsToFormikErrors;
