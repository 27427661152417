import {
    Box,
    Button,
    DialogContent,
    Grid,
    InputAdornment,
    OutlinedInput,
    TextField,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ImageUpload } from 'core/components/shared/ImageUpload';
import { useCrud } from 'core/hooks/crud.hook';
import { Form, useFormikContext } from 'formik';
import React, { FC, useCallback, useContext, useState } from 'react';
import { TwitterPicker } from 'react-color';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { OptionalTextField } from '../../../core/helpers/optionalTextField';
import { IAccountDetails } from '../models/account.model';
import { AccountDialogContext } from './AccountDialogProvider';
import { useDialog } from '../../projects/components/use-dialog.hook';
import { FormDialogWrapper } from '../../../core/components/shared/FormDialogWrapper';
import css from 'styles/material-ui/cssVars';
import { pathBuilder } from '../../navigation/helpers/path-builder.helper';
import { ROUTES } from '../../navigation/enums/routes.enum';
import { AlertModal } from '../../pulse/components/AlertModal';

const useStyles = makeStyles((theme) => ({
    modalContent: {
        padding: 0,
    },
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        backgroundColor: css.extraColors.text.darkPrimary,
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        },
    },
    inputsWrapper: {
        padding: theme.spacing(3),
        overflowY: 'auto',
    },
    buttonWrap: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            marginTop: `${theme.spacing(2)}px`,
        },
        '& button': {
            '&:last-child': {
                marginLeft: theme.spacing(2),

                [theme.breakpoints.down('xs')]: {
                    marginLeft: 0,
                    marginTop: theme.spacing(2),
                },
            },

            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
    },
    colors: {
        height: '134px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(0.5),
    },
    customTextField: {
        '& legend': {
            visibility: 'visible',
        },
    },
    colorAdornment: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.text.secondary,
    },
}));

export const AccountForm: FC = () => {
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);
    const [actionTextColorInsert, setActionTextColor] = useState(false);
    const [headerBackgroundColorInsert, setHeaderBackgroundColor] = useState(
        false
    );
    const [primaryColorInsert, setPrimaryColor] = useState(false);
    const { open, handleOpen, handleClose } = useDialog();

    const { close, accountId } = useContext(AccountDialogContext);

    const { deleteMultiple } = useCrud();

    const onDelete = useCallback(async () => {
        accountId && (await deleteMultiple([accountId]));
        close();
    }, [accountId, deleteMultiple, close]);

    const {
        values,
        setFieldValue,
        handleChange,
        isSubmitting,
        submitForm,
        errors,
    } = useFormikContext<IAccountDetails>();

    return (
        <DialogContent className={classes.modalContent}>
            <Form className={classes.form}>
                <div className={classes.inputsWrapper}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="name"
                                name="name"
                                onChange={handleChange}
                                value={values.name}
                                label={t('account-name')}
                                error={Boolean(errors.name)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {t('look-and-feel')}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <ImageUpload
                                fileBinaryString={values.logoLink}
                                setFileBinaryString={(bin: string) => {
                                    setFieldValue('logoLink', bin);
                                    setFieldValue('uploadedImage', bin);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Box
                                className={classes.colors}
                                style={{
                                    backgroundColor: `#${values.headerBackgroundColor}`,
                                }}
                            >
                                <Typography
                                    variant="overline"
                                    align="center"
                                    display="block"
                                >
                                    <span
                                        style={{
                                            color: `#${values.actionTextColor}`,
                                            display: 'block',
                                        }}
                                    >
                                        {t('action-text-color')}
                                    </span>
                                    <span
                                        style={{
                                            color: `#${values.primaryColor}`,
                                            display: 'block',
                                        }}
                                    >
                                        {t('primary-color')}
                                    </span>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <OutlinedInput
                                className={classes.customTextField}
                                type="text"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box
                                            className={classes.colorAdornment}
                                            style={{
                                                backgroundColor: `#${values.headerBackgroundColor}`,
                                            }}
                                        ></Box>
                                    </InputAdornment>
                                }
                                fullWidth
                                name="headerBackgroundColor"
                                id="headerBackgroundColor"
                                onChange={handleChange}
                                onClick={() => {
                                    setHeaderBackgroundColor(
                                        !headerBackgroundColorInsert
                                    );
                                    setActionTextColor(false);
                                    setPrimaryColor(false);
                                }}
                                value={values.headerBackgroundColor}
                                label={t('header-background-color')}
                                error={Boolean(errors.headerBackgroundColor)}
                            />

                            {headerBackgroundColorInsert && (
                                <TwitterPicker
                                    onChangeComplete={(color) => {
                                        values.headerBackgroundColor = color.hex
                                            .toString()
                                            .replace('#', '');
                                        setHeaderBackgroundColor(false);
                                    }}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <OutlinedInput
                                className={classes.customTextField}
                                fullWidth
                                name="actionTextColor"
                                id="actionTextColor"
                                onChange={handleChange}
                                onClick={() => {
                                    setActionTextColor(!actionTextColorInsert);
                                    setHeaderBackgroundColor(false);
                                    setPrimaryColor(false);
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box
                                            className={classes.colorAdornment}
                                            style={{
                                                backgroundColor: `#${values.actionTextColor}`,
                                            }}
                                        ></Box>
                                    </InputAdornment>
                                }
                                value={values.actionTextColor}
                                label={t('action-text-color')}
                                error={Boolean(errors.actionTextColor)}
                            />
                            {actionTextColorInsert && (
                                <TwitterPicker
                                    onChangeComplete={(color) => {
                                        values.actionTextColor = color.hex
                                            .toString()
                                            .replace('#', '');
                                        setActionTextColor(false);
                                    }}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <OutlinedInput
                                className={classes.customTextField}
                                id="primaryColor"
                                name="primaryColor"
                                fullWidth
                                onChange={handleChange}
                                value={values.primaryColor}
                                onClick={() => {
                                    setPrimaryColor(!primaryColorInsert);
                                    setActionTextColor(false);
                                    setHeaderBackgroundColor(false);
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box
                                            className={classes.colorAdornment}
                                            style={{
                                                backgroundColor: `#${values.primaryColor}`,
                                            }}
                                        ></Box>
                                    </InputAdornment>
                                }
                                label={t('primary-color-placeholder')}
                                error={Boolean(errors.primaryColor)}
                            />
                            {primaryColorInsert && (
                                <TwitterPicker
                                    onChangeComplete={(color) => {
                                        values.primaryColor = color.hex
                                            .toString()
                                            .replace('#', '');
                                        setPrimaryColor(false);
                                    }}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {t('additional-account-data')}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="owner_name"
                                type="ownerName"
                                name="ownerName"
                                onChange={handleChange}
                                value={values.ownerName}
                                label={OptionalTextField(t('owner-name'))}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                type="email"
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                                label={OptionalTextField(t('email-login'))}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="address"
                                type="address"
                                name="address"
                                onChange={handleChange}
                                value={values.address}
                                label={OptionalTextField(t('address'))}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="subscription"
                                type="subscription"
                                name="subscription"
                                onChange={handleChange}
                                value={values.subscription}
                                label={OptionalTextField(t('subscription'))}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="comments"
                                type="subscription"
                                name="comments"
                                onChange={handleChange}
                                value={values.comments}
                                label={OptionalTextField(t('comments'))}
                            />
                        </Grid>
                    </Grid>
                </div>
                <Box className={classes.modalActions}>
                    <Button type="reset" variant="text" onClick={close}>
                        {t('cancel')}
                    </Button>

                    <Box className={classes.buttonWrap}>
                        {accountId ? (
                            <>
                                <Button
                                    variant="contained"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginRight: '15px',
                                        padding: '10px',
                                        color: 'red',
                                    }}
                                    disableElevation
                                    disabled={isSubmitting}
                                    onClick={handleOpen}
                                >
                                    {t('delete-account')}
                                </Button>
                                <FormDialogWrapper
                                    isOpen={open}
                                    handleClose={handleClose}
                                    alertModal
                                >
                                    <AlertModal
                                        title={t('delete-account')}
                                        isActionDestructive={true}
                                        contentText={t(
                                            'delete-account-content-message'
                                        )}
                                        closeModalText={t('keep-account')}
                                        submitModalText={t(
                                            'yes-delete-account'
                                        )}
                                        handleClose={handleClose}
                                        handleSubmit={onDelete}
                                    />
                                </FormDialogWrapper>
                            </>
                        ) : (
                            <Button
                                variant="contained"
                                style={{
                                    textTransform: 'capitalize',
                                    marginRight: '15px',
                                }}
                                disableElevation
                                disabled={isSubmitting}
                                onClick={() => {
                                    setFieldValue('openNewAccount', true);
                                    submitForm();
                                }}
                            >
                                {t('create-new-and-add-new-user')}
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                textTransform: 'capitalize',
                            }}
                            disableElevation
                            disabled={isSubmitting}
                            // onClick={handleClose}
                            type="submit"
                        >
                            {!accountId
                                ? t('create-new-account')
                                : t('save-changes')}
                        </Button>
                    </Box>
                </Box>
            </Form>
        </DialogContent>
    );
};
