// <Box border={'1px solid #DFE1E6'} p={5} borderRadius="8px" mt={5}>

import { Box, Grid } from '@material-ui/core';
import { FC } from 'react';
import { getTimeSeriesGraphProps } from '../helpers/getTimeSeriesGraphProps';
import { getPulseDistributionGraphProps } from '../helpers/getPulseDistributionProps';
import { getPulseResultsAveragesProps } from '../helpers/getPulseResultsAveragesProps';
import { getSuccessFactorsGraphProps } from '../helpers/getSuccessFactorsGraphProps';
import { usePulseDistributionData, usePulseQuestions } from '../hooks';
import { IPortfolioData } from '../models/dashboard.models';
import { PulseDistributionGraph } from './Graphs/PulseDistributionGraph';
import { PulseResultsAverages } from './Graphs/PulseResultsAverages';
import { SuccessFactorsGraph } from './Graphs/SuccessFactorsGraph';
import { TimeSeriesGraph } from './Graphs/TimeSeriesGraph';

type GraphAnalyticsProps = Pick<
    IPortfolioData,
    'success_factors' | 'projects_data' | 'time_series_graph_data'
>;

export const GraphAnalytics: FC<GraphAnalyticsProps> = ({
    success_factors,
    projects_data,
    time_series_graph_data,
}) => {
    const pulseFactors = Array.from(Object.values(projects_data))
        .map(
            ({ factors }) =>
                factors
                    ?.filter(({ default: isDefault }) => isDefault)
                    .map(({ title }) => title) ?? []
        )
        .flat();

    const successFactorsGraphProps = getSuccessFactorsGraphProps(
        !pulseFactors.length ? Object.keys(success_factors) : pulseFactors,
        success_factors
    );

    const pulseQuestions = usePulseQuestions(
        Object.values(projects_data).map(({ questions = {} }) => questions)
    );

    const { pulseDistributionMap } = usePulseDistributionData(
        Object.values(projects_data).map(
            ({ pulse_distribution_graph_data = {} }) =>
                pulse_distribution_graph_data
        )
    );

    const pulseDistributionGraph = getPulseDistributionGraphProps(
        pulseQuestions,
        pulseDistributionMap
    );

    const pulseResultsAveragesProps = getPulseResultsAveragesProps(
        pulseQuestions,
        pulseDistributionMap
    );

    const timeSeriesGraphProps = getTimeSeriesGraphProps(
        time_series_graph_data
    );

    return (
        <Box mt={5}>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <PulseResultsAverages {...pulseResultsAveragesProps} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PulseDistributionGraph {...pulseDistributionGraph} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SuccessFactorsGraph {...successFactorsGraphProps} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TimeSeriesGraph {...timeSeriesGraphProps} />
                </Grid>
            </Grid>
        </Box>
    );
};
