// <Box border={'1px solid #DFE1E6'} p={5} borderRadius="8px" mt={5}>

import { Box, Grid } from '@material-ui/core';
import { FC } from 'react';
import { getPulseDistributionGraphProps } from '../helpers/getPulseDistributionProps';
import { getPulseResultsAveragesProps } from '../helpers/getPulseResultsAveragesProps';
import { getSuccessFactorsGraphProps } from '../helpers/getSuccessFactorsGraphProps';
import { getTimeSeriesGraphProps } from '../helpers/getTimeSeriesGraphProps';
import {
    IAnalyticData,
    PulseDistributionGraphRanges,
} from '../models/dashboard.models';
import { PulseDistributionGraph } from './Graphs/PulseDistributionGraph';
import { PulseResultsAverages } from './Graphs/PulseResultsAverages';
import { SuccessFactorsGraph } from './Graphs/SuccessFactorsGraph';
import { TimeSeriesGraph } from './Graphs/TimeSeriesGraph';

type GraphDashboardProps = Pick<
    IAnalyticData,
    | 'pulseDistributionGraphData'
    | 'questions'
    | 'factors'
    | 'successFactorGraphData'
    | 'pulses'
    | 'timeSeriesGraphData'
>;

export const GraphDashboard: FC<GraphDashboardProps> = ({
    successFactorGraphData,
    factors,
    questions,
    pulseDistributionGraphData,
    timeSeriesGraphData,
}) => {
    const pulseDistributionMap = Object.entries(
        pulseDistributionGraphData ?? {}
    ).reduce((map, [questionName, distributionGraphValue]) => {
        const newValue: Record<
            PulseDistributionGraphRanges,
            number[]
        > = map.get(questionName) ?? {
            '4,5-5': [],
            '3,5-4': [],
            '2,5-3': [],
            '1,5-2': [],
            '0-1': [],
        };

        Object.entries(distributionGraphValue).forEach(
            ([key, { count, percentage, ...rest }]) => {
                Object.values(rest)
                    ?.filter((restValue) => restValue !== 0)
                    ?.forEach((restValue) =>
                        newValue[key as PulseDistributionGraphRanges].push(
                            restValue
                        )
                    );
            }
        );

        return map?.set(questionName, newValue);
    }, new Map<string, Record<PulseDistributionGraphRanges, number[]>>());

    const pulseFactors = factors?.map(({ title }) => title);

    const successFactorsGraphProps = getSuccessFactorsGraphProps(
        !pulseFactors?.length
            ? successFactorGraphData && Object.keys(successFactorGraphData)
            : pulseFactors,
        successFactorGraphData
    );

    const pulseQuestions = questions?.map(({ name }) => name);

    const pulseDistributionGraph = getPulseDistributionGraphProps(
        pulseQuestions,
        pulseDistributionMap
    );

    const pulseResultsAveragesProps = getPulseResultsAveragesProps(
        pulseQuestions,
        pulseDistributionMap
    );

    const timeSeriesGraphProps = getTimeSeriesGraphProps(timeSeriesGraphData);

    return (
        <Box mt={3}>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <PulseResultsAverages {...pulseResultsAveragesProps} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PulseDistributionGraph {...pulseDistributionGraph} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SuccessFactorsGraph {...successFactorsGraphProps} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TimeSeriesGraph {...timeSeriesGraphProps} />
                </Grid>
            </Grid>
        </Box>
    );
};
