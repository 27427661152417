import { StoreConfig } from '@datorama/akita';
import {
    EntitiesState,
    EntitiesStore,
} from '../../../core/state/entities.store';
import {
    createImportFromResponse,
    IImport,
    IImportResponse,
} from '../models/import.model';

export type ImportsState = EntitiesState<IImport>;

@StoreConfig({
    name: 'imports',
    resettable: true,
})
class ImportsStore extends EntitiesStore<IImport, IImportResponse> {}

export const importsStore = new ImportsStore({
    responseMapper: createImportFromResponse,
});
