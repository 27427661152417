import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FormDialogActions } from 'core/components/shared/dialog/FormDialogActions';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { useFormikContext } from 'formik';
import { PulseParticipantForm } from 'modules/pulse-participants/components/PulseParticipantForm';
import React, { useContext } from 'react';
import css from 'styles/material-ui/cssVars';
import { PulseParticipantFormContext } from './PulseParticipantFormProvider';

const useStyles = makeStyles((theme) => ({
    modalActions: {
        position: 'relative',
        backgroundColor: css.extraColors.text.darkPrimary,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3),
    },
}));

export const PulseParticipantFormDialog = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { isOpen, close } = useContext(PulseParticipantFormContext);
    const { submitForm, isValid, dirty } = useFormikContext();

    const classes = useStyles();

    return (
        <FormDialogWrapper isOpen={isOpen} handleClose={close}>
            <DialogContent>
                <Box marginY={5}>
                    <Typography variant="h1" align="center">
                        {t('edit-participant')}
                    </Typography>
                </Box>
                <PulseParticipantForm />
            </DialogContent>
            <DialogActions className={classes.modalActions}>
                <FormDialogActions handleClose={close}>
                    <Button
                        style={{
                            textTransform: 'none',
                            marginLeft: 8,
                        }}
                        variant="contained"
                        disableElevation
                        disabled={!isValid || !dirty}
                        color="primary"
                        onClick={submitForm}
                    >
                        {t('save')}
                    </Button>
                </FormDialogActions>
            </DialogActions>
        </FormDialogWrapper>
    );
};
