import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { LoseButtonFocus } from 'core/helpers/loseButtonFocus';
import { IEntityActionProps } from 'core/interfaces/entity-action.interface';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    paper: { position: 'relative', padding: theme.spacing(2) },
    closeButton: {
        minWidth: 0,
        padding: theme.spacing(1),
        borderRadius: '50%',
    },
}));

export interface IGeneralDialogProps {
    style: {
        paper?: string;
        title?: string;
        contentText?: string;
        content?: string;
        actions?: string;
    };
    title?: string;
    contentText?: string;
    content?: JSX.Element;
    renderContent?: (data: any) => JSX.Element;
    actions: IEntityActionProps[];
    openDialog: boolean;
    onClose?: (data?: any) => Promise<void> | void;
    data?: any;
}

export const GeneralDialog: FC<IGeneralDialogProps> = ({
    openDialog,
    style,
    title,
    contentText,
    content,
    renderContent,
    actions,
    onClose,
    data,
}) => {
    const classes = useStyles();

    const calculatedStyle = useMemo(() => ({ ...classes, ...style }), [style]);

    const [open, setOpen] = useState<boolean>(openDialog ?? false);

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    const onDialogClose = () => {
        if (onClose) {
            onClose();
        }
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            classes={{
                paper: calculatedStyle.paper,
            }}
            onExited={LoseButtonFocus}
        >
            {title && (
                <DialogTitle classes={{ root: calculatedStyle.title }}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box flexGrow="5">
                            <Typography variant="h5">{title}</Typography>
                        </Box>
                        <Box>
                            <Button
                                variant="text"
                                className={classes.closeButton}
                            >
                                <CloseIcon
                                    color="disabled"
                                    onClick={onDialogClose}
                                />
                            </Button>
                        </Box>
                    </Box>
                </DialogTitle>
            )}
            <DialogContent classes={{ root: calculatedStyle.content }}>
                {contentText && (
                    <DialogContentText
                        classes={{ root: calculatedStyle.contentText }}
                    >
                        <Typography variant="body1">{contentText}</Typography>
                    </DialogContentText>
                )}
                {renderContent ? renderContent(data) : content}
            </DialogContent>
            {actions && (
                <DialogActions classes={{ root: calculatedStyle.actions }}>
                    {actions
                        .filter(({ hide }) => !hide)
                        .map(
                            (
                                {
                                    callback,
                                    path,
                                    component,
                                    color,
                                    isDestructive,
                                },
                                index
                            ) => {
                                const button = (
                                    <Button
                                        key={index}
                                        variant="contained"
                                        color={color}
                                        className={
                                            isDestructive
                                                ? 'MuiButton-destructive'
                                                : ''
                                        }
                                        disableElevation
                                        style={{ marginLeft: 10 }}
                                        onClick={() =>
                                            callback && callback(data)
                                        }
                                    >
                                        {component}
                                    </Button>
                                );

                                return path ? (
                                    <Link to={path}>{button}</Link>
                                ) : (
                                    button
                                );
                            }
                        )}
                </DialogActions>
            )}
        </Dialog>
    );
};
