import {
    Box,
    Button,
    Card,
    CardContent,
    Snackbar,
    Tooltip,
    Typography,
} from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import EditIcon from '@material-ui/icons/Edit';
import PeopleIcon from '@material-ui/icons/People';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { useCrud } from '../../../core/hooks/crud.hook';
import { apiService } from '../../../core/services/apiService';
import { ROUTES } from '../../navigation/enums/routes.enum';
import { pathBuilder } from '../../navigation/helpers/path-builder.helper';
import { useDialog } from '../../projects/components/use-dialog.hook';
import { PulseCardScheduleSection } from '../../pulse-schedules/components/ScheduleSection';
import { usePulseCardStyles } from '../hooks/pulse-card-styles.hook';
import { IPulse } from '../models/pulse.model';
import { PulseDialogContext } from './PulseDialogProvider';
import { SendPulse } from './SendPulse';

interface IPulseGridCardProps {
    pulse: IPulse;
}

export const PulseGridCard: FC<IPulseGridCardProps> = ({ pulse }) => {
    const classes = usePulseCardStyles();
    const { push } = useHistory();

    const { t } = useContext(MultiLanguageSupportContext);

    const { restoreSingle } = useCrud<IPulse>();

    const { open: handleOpen } = useContext(PulseDialogContext);
    const {
        open: isOpenPulse,
        handleOpen: handleOpenSendPulse,
        handleClose: handleCloseSendPulse,
    } = useDialog(); // TODO: should not render separate dialogs for each buttons

    const onUnArchiveClick = async () => {
        await restoreSingle(pulse, { shouldFetchAfterSuccess: true });
    };

    const sendPulseNow = async () => {
        await apiService.post(`/pulses/${pulse.id}/send`, {});
        handleSnackbarClick();
    };

    const [openSnackbar, setOpenSnackbar] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleSnackbarClick = () => {
        setOpenSnackbar({ ...openSnackbar, open: true });
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar({ ...openSnackbar, open: false });
    };

    return (
        <Card className={classes.card} variant="outlined">
            <Box className={classes.header}>
                <div
                    onClick={() =>
                        push({
                            pathname: pathBuilder(
                                ROUTES.PULSE,
                                ':id',
                                pulse.id
                            ),
                        })
                    }
                >
                    <Typography variant="h5">{pulse.name}</Typography>
                </div>
                <div className={classes.action}>
                    {pulse.archived && (
                        <Tooltip title={t('tooltop-unarchive')}>
                            <Button
                                variant="text"
                                size="small"
                                className={classes.button}
                                color="primary"
                                onClick={onUnArchiveClick}
                            >
                                <UnarchiveIcon />
                            </Button>
                        </Tooltip>
                    )}

                    <Tooltip title={t('tooltip-edit')}>
                        <Button
                            variant="text"
                            size="small"
                            className={classes.button}
                            color="primary"
                            // onClick={() => handleOpen(pulse)}
                            onClick={() =>
                                push({
                                    pathname: pathBuilder(
                                        ROUTES.PULSE,
                                        ':id',
                                        pulse.id
                                    ),
                                })
                            }
                        >
                            <EditIcon />
                        </Button>
                    </Tooltip>
                </div>
            </Box>

            <CardContent>
                <PulseCardScheduleSection {...pulse} />
                <Box className={classes.info}>
                    <div>
                        <PeopleIcon className={classes.icon} />
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            className={classes.spanWithPadd}
                        >
                            {pulse.numberOfParticipants}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.span}
                            color="textSecondary"
                        >
                            {t('total-participants-in-this-pulse')}
                        </Typography>
                    </div>
                </Box>
                <Box className={classes.info}>
                    <div>
                        <ContactSupportIcon className={classes.icon} />
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            className={classes.spanWithPadd}
                        >
                            {pulse.numberOfQuestions}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.span}
                            color="textSecondary"
                        >
                            {t('questions-selected')}
                        </Typography>
                    </div>
                </Box>
            </CardContent>

            <Snackbar
                open={openSnackbar.open}
                autoHideDuration={3000}
                onClose={() => handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="success">
                    Successfully send Pulse!
                    {/*TODO: Translations*/}
                </Alert>
            </Snackbar>
        </Card>
    );
};
