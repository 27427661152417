import { Box, Button } from '@material-ui/core';
import { Flex } from '@qagency/react-shared-library';
import React from 'react';
import ListIcon from '@material-ui/icons/List';
import WidgetsIcon from '@material-ui/icons/Widgets';

export enum ListViewOptionTypes {
    GRID,
    LIST,
}

interface ListViewProps {
    view: ListViewOptionTypes;
    handleViewChange: (view: ListViewOptionTypes) => void;
}
const listViewOptionStyle = {
    borderRadius: 4,
    height: 40,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
};

export const ListView = ({
    view: slectedView,
    handleViewChange,
}: ListViewProps) => {
    const getIconColor = (view: ListViewOptionTypes) =>
        view === slectedView ? '#fff' : '#42526E';
    const getOptionColor = (view: ListViewOptionTypes) =>
        view === slectedView ? '#42526E' : 'transparent';

    const renderViewOption = (view: ListViewOptionTypes) => {
        return (
            <Box
                mr={1}
                onClick={() => handleViewChange(view)}
                style={{
                    ...listViewOptionStyle,
                    backgroundColor: getOptionColor(view),
                }}
            >
                {React.cloneElement(
                    view === ListViewOptionTypes.GRID ? (
                        <WidgetsIcon />
                    ) : (
                        <ListIcon />
                    ),
                    { style: { color: getIconColor(view) } }
                )}
            </Box>
        );
    };

    return (
        <Flex>
            {renderViewOption(ListViewOptionTypes.GRID)}
            {renderViewOption(ListViewOptionTypes.LIST)}
        </Flex>
    );
};
