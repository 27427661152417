import { TableCell } from '@material-ui/core';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import React, { FC, useContext, useMemo } from 'react';
import { USER_ROLE } from '../models/user.model';

interface IUserListItemTemplate {
    name: string;
    email: string;
    role: USER_ROLE;
}

export const UserListItemTemplate: FC<IUserListItemTemplate> = ({
    name,
    email,
    role,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);

    const roleLabel = useMemo(() => {
        switch (role) {
            case USER_ROLE.SUPER_ADMIN:
                return 'super';
            case USER_ROLE.PROJECT_ADMIN:
                return 'project';
            case USER_ROLE.PORTFOLIO_ADMIN:
                return 'portfolio';
            case USER_ROLE.ACCOUNT_ADMIN:
                return 'account';
            default:
                return 'none';
        }
    }, [role]);

    return (
        <>
            <TableCell>{name}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{t(roleLabel)}</TableCell>
        </>
    );
};
