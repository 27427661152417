import { useSession } from 'core/hooks/session.hook';
import { FormikProvider, useFormik } from 'formik';
import React, {
    createContext,
    FC,
    useCallback,
    useMemo,
    useState,
} from 'react';
import * as Yup from 'yup';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { useCrud } from '../../../core/hooks/crud.hook';
import { requiredField } from '../../../core/validations';
import {
    emptySuccessFactor,
    ISuccessFactor,
    ISuccessFactorDetails,
} from '../models/success-factor.model';
import { factorsQuery } from '../state/factors.query';

export type ISuccessFactorsFormik = ISuccessFactorDetails;

export interface ITranslationsFormik {
    [key: string]: string;
}

interface ISuccessFactorsFormContext {
    factorsId: string | null;
    isOpen: boolean;
    close: () => void;
    open: (factors: Partial<ISuccessFactor>, stepIndex?: number) => void;
    stepIndex: number;
    setStepIndex: (stepNumber: number) => void;
    preferenceOrganizationId: string | null;
}

export const SuccessFactorsFormContext = createContext<ISuccessFactorsFormContext>(
    {} as ISuccessFactorsFormContext
);

const validationSchema = Yup.object().shape({
    factor: Yup.object().shape({
        title: requiredField,
        fontAwesomeIcon: requiredField,
    }),
});

export interface SuccessFactorFormProviderProps {
    isSystemWide: boolean;
}

export const SuccessFactorFormProvider: FC<SuccessFactorFormProviderProps> = WithProvider(
    ({ children, isSystemWide }) => {
        const [isOpen, setIsOpen] = useState(false);
        const [factorsId, setFactorsId] = useState<string | null>(null);
        const [stepIndex, setStepIndex] = useState(0);
        const { me } = useSession();
        const [
            initialValues,
            setInitialValues,
        ] = useState<ISuccessFactorsFormik>({
            ...emptySuccessFactor,
        });

        const preferenceOrganizationId = useMemo(
            () => (isSystemWide ? null : me?.organizationId ?? null), // FIXME: should not be null unless isSystemWide === true
            [isSystemWide, me]
        );

        const close = () => {
            setIsOpen(false);
        };

        const open = (
            { id, ...details }: Partial<ISuccessFactor>,
            newStepIndex = 0
        ) => {
            setIsOpen(true);
            setFactorsId(details?.factor?.id ?? null);

            setInitialValues({
                ...emptySuccessFactor,
                organizationId: preferenceOrganizationId,
                factor: {
                    ...emptySuccessFactor.factor,
                    organizationId: preferenceOrganizationId,
                },
                ...details,
            });

            setStepIndex(newStepIndex);
        };

        const { createSingle, updateSingle } = useCrud<ISuccessFactor>();

        const onSubmit = useCallback(
            async ({
                organizationId: _temp,
                ...data
            }: ISuccessFactorsFormik) => {
                const factor = factorsId
                    ? await updateSingle(
                          {
                              ...data,
                              id: factorsId,
                              organizationId: preferenceOrganizationId,
                          },
                          { shouldFetchAfterSuccess: true }
                      )
                    : await createSingle(
                          { ...data, organizationId: preferenceOrganizationId },
                          {
                              shouldFetchAfterSuccess: true,
                          }
                      );

                if (factor) {
                    const { id, ...details } = factor;

                    setInitialValues(details);
                    setFactorsId(details.factor.id);
                }

                return factor;
            },
            [factorsId, preferenceOrganizationId]
        );

        const formik = useFormik<ISuccessFactorsFormik>({
            initialValues,
            enableReinitialize: true,
            validateOnMount: !!factorsId,
            onSubmit,
            validationSchema,
        });

        return (
            <>
                <SuccessFactorsFormContext.Provider
                    value={{
                        isOpen,
                        close,
                        open,
                        factorsId,
                        stepIndex,
                        setStepIndex,
                        preferenceOrganizationId,
                    }}
                >
                    <FormikProvider value={formik}>{children}</FormikProvider>
                </SuccessFactorsFormContext.Provider>
            </>
        );
    },
    factorsQuery
);
