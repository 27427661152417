import { Button } from '@material-ui/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IEntityActionProps } from '../interfaces/entity-action.interface';

interface IEntityListActionsProps {
    actions: IEntityActionProps[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunction = () => {};

const EntityListAction: FC<IEntityActionProps> = ({
    path,
    callback,
    component,
}) => {
    const button = (
        <Button
            variant="text"
            disableElevation
            style={{ minWidth: 0, padding: '8px' }}
            onClick={callback ?? emptyFunction}
        >
            {component}
        </Button>
    );

    if (path) {
        return <Link to={path}>{button}</Link>;
    }

    return button;
};

export const EntityListActions: FC<IEntityListActionsProps> = ({ actions }) => (
    <>
        {actions
            .filter(({ hide }) => !hide)
            .map((action, index) => (
                <EntityListAction key={index} {...action} />
            ))}
    </>
);
