import React, { createContext, FC, useCallback, useState } from 'react';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { languagesQuery } from '../state/languages.query';
import {
    emptyLanguage,
    ILanguage,
    ILanguageDetails,
} from '../models/language.model';
import { useCrud } from '../../../core/hooks/crud.hook';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { requiredField } from '../../../core/validations';

export interface ILanguageFormik extends ILanguageDetails {
    openNewLanguage?: boolean;
}

interface ILanguageDialogContext {
    languageId: string | null;
    isOpen: boolean;
    close: () => void;
    open: (language: Partial<ILanguage>) => void;
}

export const LanguageDialogContext = createContext<ILanguageDialogContext>(
    {} as ILanguageDialogContext
);

const validationSchema = Yup.object().shape({
    name: requiredField,
    locale: requiredField,
});

export const LanguageDialogProvider: FC = WithProvider(({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [languageId, setLanguageId] = useState<string | null>(null);
    const [initialValues, setInitialValues] = useState({
        ...emptyLanguage,
    });

    const close = () => {
        setIsOpen(false);
    };

    const open = ({ id, ...language }: Partial<ILanguage>) => {
        setIsOpen(true);
        setLanguageId(id ?? null);
        setInitialValues({ ...emptyLanguage, ...language });
    };

    const { createSingle, updateSingle } = useCrud<ILanguage>();

    const onSubmit = useCallback(
        async ({ openNewLanguage, ...data }: ILanguageFormik) => {
            const language = languageId
                ? await updateSingle({ id: languageId, ...data })
                : await createSingle(data, {
                      shouldFetchAfterSuccess: true,
                  });

            if (language) {
                const { id, ...details } = language;
                setInitialValues(details);
                setLanguageId(id);
                if (!openNewLanguage) {
                    close();
                } else {
                    open({});
                }
            }

            return language;
        },
        [languageId]
    );

    const formik = useFormik<ILanguageFormik>({
        initialValues,
        enableReinitialize: true,
        validateOnMount: !!languageId,
        onSubmit,
        validationSchema,
    });

    return (
        <LanguageDialogContext.Provider
            value={{
                isOpen,
                close,
                open,
                languageId,
            }}
        >
            <FormikProvider value={formik}>{children}</FormikProvider>
        </LanguageDialogContext.Provider>
    );
}, languagesQuery);
