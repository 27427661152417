import {
    Box,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Theme,
} from '@material-ui/core';
import { maxLimit } from 'core/constants/maxLimit';
import { pageSizeOptions } from 'core/constants/page-size-options';
import { WithCurrentPageListener } from 'core/hocs/WithListContainer';
import { useCrud } from 'core/hooks/crud.hook';
import { useCurrentPageEntities } from 'core/hooks/current-page-entities.hook';
import { IUseCurrentPage, useCurrentPage } from 'core/hooks/current-page.hook';
import React, { FC, useContext, useEffect } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { ListHeader } from '../../../core/components/shared/ListHeader';
import { ListItemContainer } from '../../../core/components/shared/ListItemContainer';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { ISuccessFactor } from '../models/success-factor.model';
import { factorsQuery } from '../state/factors.query';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    projectCardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
        columnGap: 24,
        rowGap: 24,
    },
    filters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableBackground: {
        backgroundColor: '#fff',
    },
    searchfield: {
        marginRight: theme.spacing(4),
        width: '240px',
        [theme.breakpoints.up('lg')]: {
            width: '440px',
        },
        '& input': {
            padding: `14.5px ${theme.spacing(2)}px 14.5px 0`,
        },
    },
}));

interface FactorsListProps {
    pulseId?: string;
    listSlugSuffix?: string;
    renderToolbar?: (props: IUseCurrentPage<ISuccessFactor>) => JSX.Element;
    HeaderListItem: () => JSX.Element;
    ListItem: (entity: ISuccessFactor, index: number) => JSX.Element;
    renderLastColumn?: (entity: ISuccessFactor) => JSX.Element;
    renderPagination?: () => JSX.Element;
}

const FactorsListContainer: FC<FactorsListProps> = ({
    pulseId,
    listSlugSuffix: suffix,
    renderToolbar,
    HeaderListItem,
    ListItem,
    renderLastColumn,
    renderPagination,
}: FactorsListProps) => {
    const { currentPageEntities } = useCurrentPageEntities<ISuccessFactor>();
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();
    const { setListSlug } = useCrud();
    const currentPage = useCurrentPage();

    useEffect(() => {
        currentPage.clearCurrentPageEntities();
        setListSlug(
            !pulseId ? { suffix } : { suffix, prefix: `/pulses/${pulseId}` }
        );
        currentPage.setPageSize(pulseId ? maxLimit : pageSizeOptions[0]);
    }, []);

    return (
        <Box width={'100%'}>
            {renderToolbar && renderToolbar(currentPage)}
            <Box mt={3}>
                <TableContainer
                    component={Paper}
                    variant="outlined"
                    className={classes.tableBackground}
                >
                    <Table>
                        <TableHead>
                            <ListHeader
                                renderLastColumn={
                                    !renderLastColumn
                                        ? undefined
                                        : () => (
                                              <span
                                                  style={{
                                                      visibility: 'hidden',
                                                  }}
                                              >
                                                  {t('actions')}
                                              </span>
                                          )
                                }
                            >
                                <HeaderListItem />
                            </ListHeader>
                        </TableHead>
                        <TableBody>
                            {currentPageEntities.map((entity, index) => (
                                <ListItemContainer
                                    key={entity.id}
                                    entityId={entity.id}
                                    enableHover={true}
                                    renderLastColumn={
                                        !renderLastColumn
                                            ? undefined
                                            : () => renderLastColumn(entity)
                                    }
                                >
                                    {ListItem(entity, index)}
                                </ListItemContainer>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {renderPagination && <Box mt={2}>{renderPagination()}</Box>}
            </Box>
        </Box>
    );
};

export const FactorsList = WithProvider(
    WithCurrentPageListener(FactorsListContainer),
    factorsQuery
) as FC<FactorsListProps>;
