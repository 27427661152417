import DateFnsAdapter from '@date-io/date-fns';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    InputLabel,
    MenuItem,
    Typography,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { IReadAllEntitiesResponse } from 'core/interfaces/read-all-entities-response.interface';
import { Can } from 'modules/login/components/Can';
import {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { useSession } from '../../../core/hooks/session.hook';
import { apiService } from '../../../core/services/apiService';
import { DimensionsContext } from '../../categories/components/DimensionsProvider';
import {
    DimenstionInputs,
    ICategoriesByDimensionId,
} from '../../categories/components/DimenstionInputs';
import { ICategory } from '../../categories/models/category.model';
import {
    ParticipantAffiliation,
    ParticipantRole,
} from '../../participants/models/participant.model';
import {
    IPulse,
    IPulseResponse,
    createPulseFromResponse,
} from '../../pulse/models/pulse.model';
import { DashboardContext } from './DashboardProvider';

const dateFns = new DateFnsAdapter();

export interface FiltersDashboardProps {
    projectId?: string;
}

export enum ProjectsActive {
    ALL = 'all',
    ACTIVE = 'active',
    DELETED = 'deleted',
}

const objectToQueryString = (params: Record<string, string>) =>
    Object.entries(params)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');

export const FiltersDashboard: FC<FiltersDashboardProps> = ({ projectId }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { me } = useSession();
    const { initialFilters, handleFilters } = useContext(DashboardContext);
    const { dimensions } = useContext(DimensionsContext);

    const [organization, setOrganization] = useState(
        initialFilters.organization || 'all'
    );
    const [projectActive, setProjectActive] = useState(
        initialFilters.projectType || 'all'
    );
    const [geography, setGeography] = useState<ICategory | null>(null);
    const [department, setDepartment] = useState<ICategory | null>(null);
    const [category, setCategory] = useState<ICategory | null>(null);
    const [customer, setCustomer] = useState<ICategory | null>(null);
    const [startDate, setStartDate] = useState(initialFilters.startDate ?? '');
    const [endDate, setEndDate] = useState(initialFilters.endDate ?? '');
    const [role, setRole] = useState(initialFilters.role ?? 'all');
    const [pulseId, setPulseId] = useState(initialFilters.pulseId ?? 'all');
    const [pulse, setPulse] = useState<IPulse[]>();
    const [isPptExportLoading, setIsPptExportLoading] = useState<boolean>(
        false
    );

    const clearFilters = useCallback(() => {
        handleFilters({ projectId });

        setOrganization('all');
        setProjectActive('all');
        setStartDate('');
        setEndDate('');
        setRole('all');

        setGeography(null);
        setDepartment(null);
        setCategory(null);
        setCustomer(null);
    }, [handleFilters, projectId]);

    const applyFilters = useCallback(() => {
        handleFilters({
            projectId,
            projectType: projectActive === 'all' ? '' : projectActive,
            organization: organization === 'all' ? '' : organization,
            role: role === 'all' ? '' : role,
            pulseId: pulseId === 'all' ? '' : pulseId,
            startDate,
            endDate,
            geography: geography?.id ?? '',
            department: department?.id ?? '',
            category: category?.id ?? '',
            customer: customer?.id ?? '',
        });
    }, [
        handleFilters,
        organization,
        projectActive,
        startDate,
        endDate,
        role,
        pulseId,
        geography,
        department,
        category,
        customer,
    ]);

    useEffect(() => {
        projectId &&
            apiService
                .get<IReadAllEntitiesResponse<IPulseResponse>>(
                    `/projects/${projectId}/pulses`
                )
                .then(({ data }) =>
                    setPulse(data?.results?.map(createPulseFromResponse))
                );
    }, []);

    const queryString: {
        startDate?: string;
        endDate?: string;
        pulse?: string;
        organization?: string;
        role?: string;
    } = {
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
    };

    const pptExport = () => {
        if (projectId) {
            setIsPptExportLoading(true);

            if (pulseId !== 'all') {
                queryString['pulse'] = pulseId;
            }
            if (organization !== 'all') {
                queryString['organization'] = organization;
            }
            if (role !== 'all') {
                queryString['role'] = role;
            }

            apiService
                .get(
                    `/projects/${projectId}/dashboard/export/presentation?${objectToQueryString(
                        queryString
                    )}`,
                    {
                        responseType: 'arraybuffer',
                    }
                )
                .then(({ data }) => {
                    // Create a Blob from the received data
                    const blob = new Blob([data], {
                        type:
                            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    });

                    // Create a URL for the Blob
                    const url = window.URL.createObjectURL(blob);

                    // Create an anchor element
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'presentation.pptx'); // Set the file name here

                    // Simulate a click event on the anchor to trigger the download
                    link.click();

                    // Clean up by revoking the Blob URL after download
                    window.URL.revokeObjectURL(url);

                    setIsPptExportLoading(false);
                })
                .catch((error) => {
                    setIsPptExportLoading(false);
                    // Handle any errors that occur during the API call
                    console.error('Error fetching presentation:', error);
                });
        }
    };

    const categoriesByDimensionId = useMemo(
        () =>
            dimensions.reduce((obj, dimension) => {
                switch (dimension.name) {
                    case 'Geography':
                        obj[dimension.id] = geography;
                        break;
                    case 'Department':
                        obj[dimension.id] = department;
                        break;
                    case 'Project Category':
                        obj[dimension.id] = category;
                        break;
                    case 'Project Customer':
                        obj[dimension.id] = customer;
                        break;
                }
                return obj;
            }, {} as ICategoriesByDimensionId),
        [geography, department, category, customer]
    );

    return (
        <Box border={'1px solid #DFE1E6'} px={5} py={2} borderRadius="8px">
            <Box mb={2}>
                <Typography>
                    <Typography component="span" variant="h5">
                        {t('filters') + ': '}
                    </Typography>
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {!projectId && (
                    <Can I="read" a="ICategory">
                        <DimenstionInputs
                            categoriesByDimensionId={categoriesByDimensionId}
                            inputSize={{ xs: 6, md: 3 }}
                            emptyName={t('all')}
                            onChange={async (dimension, category) => {
                                switch (dimension.name) {
                                    case 'Geography':
                                        setGeography(category);
                                        break;
                                    case 'Department':
                                        setDepartment(category);
                                        break;
                                    case 'Project Category':
                                        setCategory(category);
                                        break;
                                    case 'Project Customer':
                                        setCustomer(category);
                                        break;
                                }
                            }}
                        />
                    </Can>
                )}
                <Grid item xs={12} md={3}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Box mr={1}>
                                <KeyboardDatePicker
                                    label={t('pulse-sent-from')}
                                    fullWidth
                                    inputVariant="outlined"
                                    id="date"
                                    placeholder="Start Date"
                                    value={null}
                                    inputValue={
                                        startDate
                                            ? dateFns.format(
                                                  new Date(startDate),
                                                  'dd/MM/yyyy'
                                              )
                                            : ''
                                    }
                                    onChange={(date, _) => {
                                        const formatted =
                                            date instanceof Date &&
                                            !isNaN(date.getTime())
                                                ? dateFns.format(
                                                      date,
                                                      'yyyy-MM-dd'
                                                  )
                                                : '';

                                        setStartDate(formatted);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    format="dd/MM/yyyy"
                                    autoOk={true}
                                    cancelLabel={''}
                                    okLabel={''}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box ml={1}>
                                <KeyboardDatePicker
                                    label={t('to')}
                                    fullWidth
                                    inputVariant="outlined"
                                    id="date"
                                    placeholder="End Date"
                                    value={null}
                                    inputValue={
                                        endDate
                                            ? dateFns.format(
                                                  new Date(endDate),
                                                  'dd/MM/yyyy'
                                              )
                                            : ''
                                    }
                                    onChange={(date, _) => {
                                        const formatted =
                                            date instanceof Date &&
                                            !isNaN(date.getTime())
                                                ? dateFns.format(
                                                      date,
                                                      'yyyy-MM-dd'
                                                  )
                                                : '';

                                        setEndDate(formatted);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    format="dd/MM/yyyy"
                                    autoOk={true}
                                    cancelLabel={''}
                                    okLabel={''}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>{t('organization')}</InputLabel>
                        <Select
                            value={organization}
                            onChange={({ target: { value } }) =>
                                setOrganization(value as string)
                            }
                            label={t('organization')}
                            name="defaultAffiliation"
                            id="defaultAffiliation"
                        >
                            <MenuItem value={ParticipantAffiliation.ALL}>
                                {t('all')}
                            </MenuItem>
                            <MenuItem
                                value={ParticipantAffiliation.ORGANIZATION}
                            >
                                {me && me.organizationName}
                            </MenuItem>
                            <MenuItem value={ParticipantAffiliation.CUSTOMER}>
                                {t('external-customer')}
                            </MenuItem>
                            <MenuItem value={ParticipantAffiliation.SUPPLIER}>
                                {t('external-supplier')}
                            </MenuItem>
                            <MenuItem value={ParticipantAffiliation.OTHER}>
                                {t('other')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>{t('role-placeholder')}</InputLabel>
                        <Select
                            value={role}
                            onChange={({ target: { value } }) =>
                                setRole(value as string)
                            }
                            label={t('role-placeholder')}
                            name="defaultRole"
                            id="defaultRole"
                        >
                            <MenuItem value={ParticipantRole.ALL}>
                                {t('all')}
                            </MenuItem>
                            <MenuItem value={ParticipantRole.STEERING}>
                                {t('steering')}
                            </MenuItem>
                            <MenuItem value={ParticipantRole.TEAM}>
                                {t('team')}
                            </MenuItem>
                            <MenuItem value={ParticipantRole.STAKEHOLDER}>
                                {t('stakeholder')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {!projectId ? (
                    <Grid item xs={12} md={3}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>{t('projects')}</InputLabel>
                            <Select
                                value={projectActive}
                                onChange={({ target: { value } }) =>
                                    setProjectActive(value as string)
                                }
                                label={t('role-placeholder')}
                                name="defaultRole"
                                id="defaultRole"
                            >
                                <MenuItem value={ProjectsActive.ALL}>
                                    {t('all')}
                                </MenuItem>
                                <MenuItem value={ProjectsActive.ACTIVE}>
                                    {t('active')}
                                </MenuItem>
                                <MenuItem value={ProjectsActive.DELETED}>
                                    {t('archived')}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                ) : (
                    <Grid item xs={12} md={3}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>{t('pulse')}</InputLabel>
                            <Select
                                value={pulseId}
                                onChange={({ target: { value } }) =>
                                    setPulseId(value as string)
                                }
                                label={t('role-placeholder')}
                                name="defaultRole"
                                id="defaultRole"
                            >
                                <MenuItem value={ProjectsActive.ALL}>
                                    {t('all')}
                                </MenuItem>
                                {pulse?.map((item) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            <Box
                display="flex"
                justifyContent={'space-between'}
                m={2}
                mr={0}
                ml={0}
            >
                <Box m={2} ml={0}>
                    {projectId && (
                        <Button
                            disabled={isPptExportLoading}
                            variant="contained"
                            color="primary"
                            onClick={pptExport}
                        >
                            {'PPT Export'}
                            {isPptExportLoading && (
                                <CircularProgress
                                    size="2rem"
                                    style={{ position: 'absolute' }}
                                />
                            )}
                        </Button>
                    )}
                </Box>
                <Box m={2} mr={0}>
                    <Button onClick={clearFilters}>{t('clear-all')}</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={applyFilters}
                    >
                        {t('apply-filter')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
