import {
    Box,
    Button,
    Container,
    DialogContent,
    makeStyles,
    Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { pageRowNumber } from '@qagency/react-shared-library';
import { EntityListHeaderTemplate } from 'core/components/shared/EntityListHeaderTemplate';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { ListActionIcon } from 'core/components/shared/ListActionIcon';
import { usePagination } from 'core/hooks/pagination.hook';
import { useSession } from 'core/hooks/session.hook';
import { HttpClient } from 'core/services/httpClient';
import { IPulseResponse } from 'modules/pulse/models/pulse.model';
import {
    createScaleQuestionFromResponse,
    IScaleQuestion,
    IScaleQuestionResponse,
} from 'modules/scale-questions/models/scale-question.model';
import { scaleQuestionsQuery } from 'modules/scale-questions/state/scale-questions.query';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { PulseAutoComplete } from '../../../core/components/AutoComplete';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { useCrud } from '../../../core/hooks/crud.hook';
import { IEntity } from '../../../core/interfaces/entity.interface';
import { IReadAllEntitiesResponse } from '../../../core/interfaces/read-all-entities-response.interface';
import { apiService } from '../../../core/services/apiService';
import { useAddQuestionToPulse } from '../hooks/add-question-to-pulse';
import { useRemoveQuestionFromPulse } from '../hooks/remove-question-from-pulse';
import { CreateScaleQuestionForm } from './QuestionForm';
import { QuestionsList } from './QuestionsList';
import { ScaleQuestionsListItem } from './QuestionsListItem';
import { CurrentPageQuestionsContext } from './CurrentPageQuestionsProvider';

const useStyles = makeStyles((theme) => ({
    actionWrapper: {
        minWidth: 0,
        padding: theme.spacing(1),
        '&:disabled': {
            opacity: 0.5,
        },
    },
    mtmb: {
        marginTop: '40px',
    },
    modalContent: {
        padding: 0,
        height: '100%',
        marginBottom: theme.spacing(12),
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(18),
        },
    },
    questionActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    addQuestionBtn: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
            marginTop: theme.spacing(2),
        },
    },
    orderControls: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& button:disabled': {
            opacity: 0.4,
        },
    },
}));

const QuestionsContainer: FC<IEntity> = ({ id: pulseId }) => {
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);
    const {
        editedPageEntities,
        sortedPageEntities,
        setEditedPageEntities,
    } = useContext(CurrentPageQuestionsContext);
    const [showForm, setShowForm] = useState(false);
    const { fetch } = useCrud<IScaleQuestion>();
    const { me } = useSession();
    const addQuestionToListWrapper = useAddQuestionToPulse(sortedPageEntities);
    const removeQuestionFromListWrapper = useRemoveQuestionFromPulse(
        sortedPageEntities
    );
    const { currentPageNumber, currentPageSize } = usePagination();
    const [pulseLanguageId, setPulseLanguageId] = useState('');

    useEffect(() => {
        pulseId &&
            apiService
                .get<IPulseResponse>(`/pulses/${pulseId}`)
                .then(({ data }) => {
                    setPulseLanguageId(data.language?.id.toString() ?? '');
                });
    }, [pulseId]);

    const addQuestionToList = useMemo(
        () =>
            addQuestionToListWrapper(
                async (questionId: string, delete_data: boolean | null) => {
                    await apiService.post(
                        `/pulses/${pulseId}/scale-questions/${questionId}`,
                        { delete_data }
                    );

                    await fetch();
                }
            ),
        [pulseId]
    );

    const deleteQuestionFromList = useMemo(
        () =>
            removeQuestionFromListWrapper(
                async (questionId: string, hard_delete: boolean) => {
                    await HttpClient.getInstance().request({
                        url: `/pulses/${pulseId}/scale-questions/${questionId}`,
                        data: { hard_delete },
                        method: 'DELETE',
                    });

                    await fetch();
                }
            ),
        [pulseId]
    );

    const responseMapper = useCallback(
        ({ results }: IReadAllEntitiesResponse<IScaleQuestionResponse>) => {
            const entities = results.map(createScaleQuestionFromResponse);

            if (sortedPageEntities.length) {
                const currentPageEntityIds = sortedPageEntities
                    .filter(({ deletedAt }) => !deletedAt)
                    .map(({ id }) => id);

                return entities.filter(
                    ({ id }) => !currentPageEntityIds.includes(id)
                );
            }

            return entities;
        },
        [sortedPageEntities]
    );

    const moveQuestionUp = (entity: IScaleQuestion) => {
        if (entity.order === 0) {
            return;
        }

        setEditedPageEntities(
            sortedPageEntities.map((temp) =>
                temp.order === entity.order
                    ? { ...temp, order: temp.order - 1 }
                    : temp.order === entity.order - 1
                    ? { ...temp, order: entity.order }
                    : temp
            )
        );
    };

    const moveQuestionDown = (entity: IScaleQuestion) => {
        if (entity.order === sortedPageEntities.length - 1) {
            return;
        }

        setEditedPageEntities(
            sortedPageEntities.map((temp) =>
                temp.order === entity.order
                    ? { ...temp, order: temp.order + 1 }
                    : temp.order === entity.order + 1
                    ? { ...temp, order: entity.order }
                    : temp
            )
        );
    };

    return (
        <Container maxWidth="md">
            <Box marginY={5}>
                <Typography variant="h1" align="center">
                    {t('edit-pulse-questions')}
                </Typography>
            </Box>
            <QuestionsList
                currentPageEntities={sortedPageEntities}
                pulseId={pulseId}
                renderToolbar={() => (
                    <Box className={classes.questionActions}>
                        <PulseAutoComplete<
                            IScaleQuestion,
                            IReadAllEntitiesResponse<IScaleQuestionResponse>
                        >
                            url={`/scale-questions?organization=${
                                me?.organizationId ?? null
                            }`}
                            stringify={({ phrase }) => phrase}
                            label={t('pulse-questions')}
                            description={t('select-existing-pulse-querstions')}
                            responseMapper={responseMapper}
                            onSelect={async (item) => {
                                item && addQuestionToList(item);
                            }}
                            disabled={!!editedPageEntities}
                        />

                        <Button
                            className={classes.addQuestionBtn}
                            variant="contained"
                            disableElevation
                            disabled={!!editedPageEntities}
                            onClick={() => setShowForm(true)}
                        >
                            <AddIcon /> {t('add-custom-question')}
                        </Button>
                    </Box>
                )}
                HeaderListItem={() => (
                    <EntityListHeaderTemplate
                        index={{ label: '#' }}
                        phrase={{ label: t('question') }}
                        name={{
                            label: `${t('title')} (${t('survey-language')})`,
                        }}
                        transalte={{
                            label: `${t('question')} (${t('survey-language')})`,
                        }}
                        order={{ label: 'Order', align: 'center' }}
                    />
                )}
                ListItem={(entity, index) => (
                    <ScaleQuestionsListItem
                        entity={entity}
                        pulseLanguageId={pulseLanguageId}
                        index={pageRowNumber(
                            currentPageNumber,
                            currentPageSize,
                            index
                        )}
                    />
                )}
                renderItemNavigation={(entity) => (
                    <div className={classes.orderControls}>
                        <Button
                            disabled={
                                entity.order === sortedPageEntities.length - 1
                            }
                            variant="text"
                            onClick={() => moveQuestionDown(entity)}
                        >
                            <ListActionIcon
                                iconComponent={<ArrowDownwardIcon />}
                            />
                        </Button>
                        <Button
                            disabled={entity.order === 0}
                            variant="text"
                            onClick={() => moveQuestionUp(entity)}
                        >
                            <ListActionIcon
                                iconComponent={<ArrowUpwardIcon />}
                            />
                        </Button>
                    </div>
                )}
                renderLastColumn={(entity) =>
                    entity.mandatory ? (
                        <></>
                    ) : (
                        <Button
                            variant="text"
                            onClick={() => deleteQuestionFromList(entity)}
                            className={classes.actionWrapper}
                            disabled={!!editedPageEntities}
                        >
                            <ListActionIcon iconComponent={<CloseIcon />} />
                        </Button>
                    )
                }
            />
            <FormDialogWrapper
                isOpen={showForm}
                handleClose={() => setShowForm(false)}
            >
                <DialogContent className={classes.modalContent}>
                    <Typography
                        variant="h2"
                        align="center"
                        className={classes.mtmb}
                    >
                        {t('add-custom-question')}
                    </Typography>
                    <CreateScaleQuestionForm
                        questionData={{
                            organizationId: me?.organizationId,
                            preferenceOrganizationId: me?.organizationId,
                        }}
                        onSubmitClick={addQuestionToList}
                        handleClose={() => setShowForm(false)}
                    />
                </DialogContent>
            </FormDialogWrapper>
        </Container>
    );
};

export const Questions = WithProvider(QuestionsContainer, scaleQuestionsQuery);
