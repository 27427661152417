import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Tooltip,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CloudUpload, HelpOutline } from '@material-ui/icons';
import { apiService } from 'core/services/apiService';
import { PulseDialogContext } from 'modules/pulse/components/PulseDialogProvider';
import React, { FC, useContext, useRef, useState } from 'react';
import { MultiLanguageSupportContext } from './MultiLanguageSupportProvider';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #E3E5E9',
        margin: '24px 0',
        borderRadius: '4px 4px 0 0',
        position: 'relative',
    },
    content: {
        padding: '16px',
    },
    contentLoading: {
        padding: '16px',
        pointerEvents: 'none',
        opacity: '0.22',
    },
    browseButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '16px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 0,
        },
    },
    browseButtonWrapper__button: {
        padding: '0',
    },
    browseButtonWrapper__label: {
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        padding: '12px 16px',
    },
    uploadButtonWrapper: {
        borderTop: '1px solid #E3E5E9',
    },
    uploadButton: {
        marginTop: '12px',
    },
    remove: {
        color: '#DB340B',
        cursor: 'pointer',
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
        fontSize: '14px',
    },
    fileName: {
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#6B778C',
    },
    loadingWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    error: {
        display: 'block',
        padding: '16px',
        margin: '24px 0',
        backgroundColor: '#FFEBE6',
        borderRadius: '4px 4px 0 0',
    },
    errorContent: {
        fontFamily: 'Nunito Sans',
        fontSize: '16px',
        fontWeight: 400,
        color: '#DB340B',
        margin: 0,
    },
}));

export const ImportParticipants = ({ handleSuccess }: any) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();
    const { pulseId } = useContext(PulseDialogContext);

    const [file, setFile] = useState({} as any);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [radioChoice, setRadioChoice] = useState('import');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const csvFileRef = useRef({} as HTMLInputElement);

    const handleUpload = async () => {
        const isAddNew = radioChoice === 'import';
        const formData = new FormData();
        formData.append('csv', file);

        setIsLoading(true);
        try {
            const response = await apiService.post(
                `/pulses/${pulseId}/import?addNew=${isAddNew}&replaceAll=${!isAddNew}`,
                formData
            );
            if (response.status === 200) {
                handleSuccess(t('import-success'));
            }
        } catch (err: any) {
            handleSuccess(''); // Remove the success message if exists

            if (err.code === 422) {
                handleError(err?.errors.csv);
            } else {
                const message = generateErrorMessage(err);
                handleError(message);
            }
        }
        setIsLoading(false);
    };

    const handleCsvInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.files) {
            setFile(e.target.files[0]);
            setIsFileSelected(true);
        }
        setError('');
    };

    const removeCsvInput = () => {
        setFile({});
        setIsFileSelected(false);
        csvFileRef.current.value = '';
    };

    const handleError = (error: any) => {
        setError(error);
        removeCsvInput();
    };

    const tooltipTitle = (
        <div style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
            Valid file format: Save as &quot;CSV UTF-8&quot; using semicolon (;)
            as separator.
            <br />
            - Use the first row for headlines to each column: email, name, role
            and organization.
            <br />
            - In column &apos;role&apos; you can use the values: team, steering,
            or stakeholder (left blank, the role will be set to team).
            <br />- In column &apos;organization&apos; you can use the values:
            customer, supplier, organization, or other (left blank, the role
            will be set to your organization)
        </div>
    );

    return (
        <>
            <Box className={classes.container}>
                {isLoading && (
                    <div className={classes.loadingWrapper}>
                        <CircularProgress />
                    </div>
                )}
                <section
                    className={
                        isLoading ? classes.contentLoading : classes.content
                    }
                >
                    {error !== '' ? (
                        <Box className={classes.error}>
                            <p className={classes.errorContent}>{error}</p>
                        </Box>
                    ) : (
                        ''
                    )}
                    <Box className={classes.browseButtonWrapper}>
                        <Button
                            variant="contained"
                            component="label"
                            disableElevation
                            color="secondary"
                            className={classes.browseButtonWrapper__button}
                        >
                            <label
                                className={classes.browseButtonWrapper__label}
                                htmlFor="import-participants-input"
                            >
                                {t('browse-files')}
                            </label>
                        </Button>
                        <input
                            type="file"
                            accept=".csv"
                            onChange={handleCsvInput}
                            id="import-participants-input"
                            ref={csvFileRef}
                            hidden
                        />
                        {isFileSelected ? (
                            <>
                                <p className={classes.fileName}>{file.name}</p>
                                <a
                                    className={classes.remove}
                                    onClick={removeCsvInput}
                                >
                                    {t('remove')}
                                </a>
                            </>
                        ) : (
                            <>
                                <p className={classes.fileName}>
                                    {t('no-files-selected')}
                                </p>
                                <Tooltip color="disabled" title={tooltipTitle}>
                                    <HelpOutline />
                                </Tooltip>
                            </>
                        )}
                    </Box>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="import-participants"
                            name="import-participants"
                            defaultValue="import"
                            onChange={(e: any) =>
                                setRadioChoice(e.target.value)
                            }
                        >
                            <FormControlLabel
                                value="import"
                                control={<Radio color="primary" />}
                                label={t('import-all-participants')}
                            />
                            <FormControlLabel
                                value="replace"
                                control={<Radio color="primary" />}
                                label={t('replace-all-participants')}
                            />
                        </RadioGroup>
                    </FormControl>
                    <Box className={classes.uploadButtonWrapper}>
                        <Button
                            className={classes.uploadButton}
                            variant="contained"
                            disableElevation
                            color="primary"
                            startIcon={<CloudUpload />}
                            disabled={!isFileSelected}
                            onClick={handleUpload}
                        >
                            {t('upload')}
                        </Button>
                    </Box>
                </section>
            </Box>
        </>
    );
};

const generateErrorMessage = (error: any): string => {
    if ('email' in error) {
        return `Email is not valid on line:${error.email.map(
            (rowId: number) => ` ${rowId}`
        )}`;
    }

    if ('name' in error) {
        return `Name is empty on line: ${error.name.map(
            (rowId: number) => ` ${rowId}`
        )}`;
    }

    if ('role' in error) {
        return `Invalid role is provided on line: ${error.role.map(
            (rowId: number) => ` ${rowId}`
        )}`;
    }

    if ('organization' in error) {
        return `Invalid organization is provided on line: ${error.organization.map(
            (rowId: number) => ` ${rowId}`
        )}`;
    }

    if ('header count' in error || 'header values' in error) {
        return `Invalid CSV header is provided. Expecting the following values: email, name, organization, role`;
    }

    if ('utf-8' in error) {
        return 'The file must be encoded in UTF-8 format.';
    }

    console.error(error); // if a case was not handled
    return 'An unknown error occurred while processing the file.';
};
