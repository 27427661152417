import { IEntity } from 'core/interfaces/entity.interface';

export interface IImport extends IEntity {
    fileName: string;
    state: StateOfImport;
    createdAt: Date;
    updatedAt: Date;
}

export enum StateOfImport {
    IN_PROGRESS = 'in_progress',
    FINISHED = 'finished',
    FAILED = 'failed',
}

export interface IImportResponse extends IEntity<number> {
    file_name: string;
    state: StateOfImport;
    created_at: string;
    updated_at: string;
}

export const createImportFromResponse = ({
    id,
    file_name,
    state,
    created_at,
    updated_at,
}: IImportResponse): IImport => {
    return {
        id: id.toString(),
        fileName: file_name,
        state,
        createdAt: new Date(created_at),
        updatedAt: new Date(updated_at),
    };
};
