import AssignmentIcon from '@material-ui/icons/Assignment';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { FeedbacksList } from 'core/components/shared/pulse-survey/FeedbacksList';
import { ICrumb } from 'core/components/shared/topBars/BreadcrumbsContainer';
import { PageTitleBarButton } from 'core/components/shared/topBars/PageTitleBar';
import { ProjectPageTitleBar } from 'core/components/shared/topBars/ProjectPageTitleBar';
import { WithProvider } from 'core/hocs/WithProvider';
import { useEntityById } from 'core/hooks/entity-by-id.hook';
import { IEntity } from 'core/interfaces/entity.interface';
import { ProjectDashboardPage } from 'modules/dashboard/pages/ProjectDashboardPage';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { CurrentPageQuestionsProvider } from 'modules/pulse-questions/components/CurrentPageQuestionsProvider';
import { PulseDialogContent } from 'modules/pulse/components/PulseDialogContent';
import {
    PulseDialogContext,
    PulseDialogProvider,
} from 'modules/pulse/components/PulseDialogProvider';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import {
    IPageTabsPropsItem,
    PageTabs,
} from '../../../core/components/mock/tabs/PageTabs';
import { PulsesList } from '../../pulse/components/PulseList';
import { ProjectDialogProvider } from '../components/ProjectDialogProvider';
import { IProject } from '../models/project.model';
import { projectsQuery } from '../state/projects.query';

const ProjectPageContainer = WithProvider(() => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { id } = useParams<IEntity>();
    const { search } = useLocation();
    const [project] = useEntityById<IProject>({ id });
    const [feedbackId, setFeedbackId] = useState<string | null>(null);
    const CURRENT = localStorage.getItem('CURRENT')
    const [activeTabItem, setActiveTabItem] = useState<number>(0)

    const items: IPageTabsPropsItem[] = useMemo(
        () => [
            {
                headerLabel: 'dashboard',
                table: <ProjectDashboardPage />,
            },
            {
                headerLabel: 'feedback',
                table: (
                    <FeedbacksList
                        projectId={id}
                        feedbackId={feedbackId ?? undefined}
                    />
                ),
            },
            {
                headerLabel: 'pulses',
                table: <PulsesList projectId={id} />,
            },
        ],
        [id, feedbackId]
    );

    const { isOpen, close, open } = useContext(PulseDialogContext);

    const activeTab = useMemo(() => {
        const query = new URLSearchParams(search);

        if (query.get('openNewPulse')) {
            open({ projectId: id });
        } else {
            close();
        }

        const tempFeedbackId = query.get('feedback');

        setFeedbackId(tempFeedbackId);

        const tempActiveTab = tempFeedbackId
            ? 'feedback'
            : query.get('activeTab');

        switch (tempActiveTab) {
            case 'dashboard':
                setActiveTabItem(0);
                localStorage.removeItem('CURRENT')
                break;
            case 'feedback':
                setActiveTabItem(1);
                break;
            case 'pulses':
                localStorage.removeItem('CURRENT')
                setActiveTabItem(2);
                break;
            default:
                return;
        }
    }, [search]);

    const buttons: PageTitleBarButton[] = [
        {
            label: t('create-new-pulse'),
            callback: () => open({ projectId: id }),
        },
    ];

    const pageCrumbs = useMemo(() => {
        const breadcrumbs: ICrumb[] = [
            { label: t('projects'), link: ROUTES.PROJECTS },
        ];

        if (project) {
            breadcrumbs.push({ label: project.name });
        }

        return breadcrumbs;
    }, [project]);

    useEffect(() => {
        if(CURRENT) {
            const query = new URLSearchParams(window.location.search);

            if (query.get('openNewPulse')) {
                open({ projectId: id });
            } else {
                close();
            }

            const tempFeedbackId = query.get('feedback');

            setFeedbackId(tempFeedbackId);

            const tempActiveTab = tempFeedbackId
                ? 'feedback'
                : query.get('activeTab');

            switch (tempActiveTab) {
                case 'dashboard':
                    setActiveTabItem(0);
                    break;
                case 'feedback':
                    setActiveTabItem(1);
                    break;
                case 'pulses':
                    setActiveTabItem(2);
                    break;
                default:
                    return;
            }
        }
        return () => {
            localStorage.removeItem('CURRENT')
        }
    }, [])

    return !project ? (
        <></>
    ) : (
        <>
            <FormDialogWrapper isOpen={isOpen} handleClose={close}>
                <PulseDialogContent />
            </FormDialogWrapper>

            <ProjectDialogProvider>
                <ProjectPageTitleBar
                    title={project.name}
                    icon={<AssignmentIcon />}
                    buttons={buttons}
                    crumbs={pageCrumbs}
                    project={project}
                ></ProjectPageTitleBar>
            </ProjectDialogProvider>

            <PageTabs items={items} initialTabIndex={activeTabItem} />
        </>
    );
}, projectsQuery);

export const ProjectPage: FC = () => (
    <CurrentPageQuestionsProvider>
        <PulseDialogProvider>
            <ProjectPageContainer />
        </PulseDialogProvider>
    </CurrentPageQuestionsProvider>
);
