import { Ability } from '@casl/ability';
import { resetStores } from '@datorama/akita';
import { useObservable } from '@mindspace-io/react';
import { sessionAPIService } from 'core/services/sessionAPIService';
import { PulseAbilities } from 'modules/login/constants/abilities';
import { SessionContext } from 'modules/login/context/session.context';
import { defineAbilities } from 'modules/login/helpers/defineAbilities';
import { ILoginCredentials } from 'modules/login/models/login.model';
import { IUser } from 'modules/users/models/user.model';
import { useContext, useMemo } from 'react';
import { IResetPasswordCredentials } from '../../modules/resetpassword/models/reset.password.model';

interface ISessionError {
    [key: string]: string;
}

interface IUseSession {
    me: IUser | null;
    abilities: Ability<PulseAbilities>;
    error: ISessionError | null;
    isLoggedIn: boolean;
    login: (credentials: ILoginCredentials) => Promise<boolean>;
    requestResetPassword: (
        credentials: IResetPasswordCredentials
    ) => Promise<any>;
    resetPasswordHash: (hash: string, password: string) => Promise<any>;
    logout: () => void;
}

export const useSession = (): IUseSession => {
    const { query, store } = useContext(SessionContext);
    const [isLoggedIn] = useObservable(query.isLoggedIn$, query.isLoggedIn);
    const [me] = useObservable(query.me$, query.me);
    const [error] = useObservable(query.selectError<ISessionError>(), null);

    const login = async (credentials: ILoginCredentials) => {
        try {
            store.handleToken(await sessionAPIService.login(credentials));
        } catch (e: any) {
            if (Array.isArray(e.errors)) {
                if (e.errors.length === 1) {
                    store.setError({ main: e.errors[0] });
                }
            }
            return false;
        }

        return true;
    };

    const logout = () => {
        resetStores();
    };

    const requestResetPassword = async (
        credentials: IResetPasswordCredentials
    ) => {
        try {
            await sessionAPIService.requestPasswordReset(
                credentials.email,
                credentials.callback_url
            );
        } catch (e: any) {
            store.setError({ ...e.errors });
            return false;
        }
    };

    const resetPasswordHash = async (hash: string, password: string) => {
        try {
            await sessionAPIService.resetPassword(hash, password);
        } catch (e: any) {
            store.setError({ ...e.errors });
            return false;
        }
    };

    const abilities = useMemo(() => defineAbilities(me ?? undefined), [me]);

    return {
        me,
        abilities,
        error,
        isLoggedIn,
        login,
        logout,
        requestResetPassword,
        resetPasswordHash,
    };
};
