import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Typography,
} from '@material-ui/core';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { tomorrow } from 'core/helpers/tomorrow';
import { useFormikContext } from 'formik';
import { frequencies } from 'modules/pulse-schedules/constants/frequencies';
import { isFrequencyDisabledBuilder } from 'modules/pulse-schedules/constants/is-frequency-disabled-builder';
import { IFrequency } from 'modules/pulse-schedules/interfaces/frequency';
import { IPulseFormik } from 'modules/pulse/components/PulseDialogProvider';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FrequencyValue } from '../enums/frequency-value';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsAdapter from '@date-io/date-fns';

const dateFns = new DateFnsAdapter();

interface IAvailableFrequency extends IFrequency {
    isDisabled: boolean;
}

export const PulseScheduleForm: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const [availableFrequencies, setAvailableFrequencies] = useState<
        IAvailableFrequency[]
    >([]);

    const {
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
    } = useFormikContext<IPulseFormik>();

    const isFrequencyDisabled = useCallback(
        isFrequencyDisabledBuilder(values.startDate),
        [values.startDate]
    );

    useEffect(() => {
        if (isFrequencyDisabled(values.frequency)) {
            setTimeout(() => {
                setFieldValue('frequency', FrequencyValue.NONE);
            });
        }

        setAvailableFrequencies(
            frequencies.map((item) => ({
                ...item,
                isDisabled: isFrequencyDisabled(item.value),
            }))
        );
    }, [isFrequencyDisabled]);

    const toggleSchedule = () => {
        setFieldValue('enableSchedule', !values.enableSchedule);
    };

    const toggleResponseAfterDeadline = () => {
        setFieldValue('allowResponseAfterExpiration', !values.allowResponseAfterExpiration);
    };

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between" my={1}>
                <Typography variant="body2">
                    {t('enable-schedule')}
                    <Switch
                        checked={values.enableSchedule}
                        onChange={toggleSchedule}
                        name="checkedSchedule"
                        color="primary"
                    />
                </Typography>
                <Typography variant="body2">
                    {t('allow-response-after-deadline')}
                    <Switch
                        checked={values.allowResponseAfterExpiration}
                        onChange={toggleResponseAfterDeadline}
                        name="checkedAllowAfterDeadline"
                        color="primary"
                    />
                </Typography>
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={12}></Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <KeyboardDatePicker
                        value={null}
                        inputValue={
                            values.startDate
                                ? dateFns.format(
                                      new Date(values.startDate),
                                      'dd/MM/yyyy'
                                  )
                                : ''
                        }
                        disabled={!values.enableSchedule}
                        inputVariant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min: tomorrow().toISOString().substring(0, 10),
                        }}
                        name="startDate"
                        id="startDate"
                        onChange={(date, _) => {
                            const formatted =
                                date instanceof Date && !isNaN(date.getTime())
                                    ? dateFns.format(date, 'yyyy-MM-dd')
                                    : '';
                            setFieldValue('startDate', formatted);
                        }}
                        onBlur={handleBlur}
                        label={t('start-date')}
                        fullWidth
                        error={!!errors.startDate}
                        format="dd/MM/yyyy"
                        helperText=""
                        minDate={tomorrow()}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl
                        disabled={!values.enableSchedule}
                        variant="outlined"
                        fullWidth
                    >
                        <InputLabel>{t('frequency')}</InputLabel>
                        <Select
                            name="frequency"
                            id="frequency"
                            value={values.frequency ?? ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={t('frequency')}
                            error={!!errors.frequency}
                        >
                            {availableFrequencies.map(
                                ({ value, label, isDisabled }, index) => (
                                    <MenuItem
                                        key={index}
                                        disabled={isDisabled}
                                        value={value}
                                    >
                                        {label}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};
