import { Box, Button, makeStyles } from '@material-ui/core';
import { pageRowNumber } from '@qagency/react-shared-library';
import { EntityListPagination } from 'core/components/EntityListPagination';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { EntityListHeaderTemplate } from 'core/components/shared/EntityListHeaderTemplate';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { useCurrentPageEntities } from 'core/hooks/current-page-entities.hook';
import { useFilter } from 'core/hooks/filter.hook';
import { usePagination } from 'core/hooks/pagination.hook';
import { IScaleQuestion } from 'modules/scale-questions/models/scale-question.model';
import { scaleQuestionsQuery } from 'modules/scale-questions/state/scale-questions.query';
import { FC, useContext } from 'react';
import { ValidTranslationKeys } from 'react-i18next';
import { WithProvider } from '../../../../core/hocs/WithProvider';
import { QuestionFilters, QuestionsListFilter } from '../QuestionFilters';
import { QuestionsList } from '../QuestionsList';
import { QuestionsDialogContent } from './QuestionsDialogContent';
import { QuestionsDialogContext } from './QuestionsDialogProvider';
import { ScaleQuestionsListItem } from './QuestionsListItem';
import { ScaleQuestionsListActionsById } from './ScaleQuestionsListActionById';

const useStyles = makeStyles((theme) => ({
    innerFilter: {
        marginBottom: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    innerFilterBtn: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
    },
}));

interface IQuestionsPageContainerProps {
    showFilters?: boolean;
    initialFilter: QuestionsListFilter;
    createButtonLabelTranslationKey: keyof ValidTranslationKeys;
}

const QuestionsPageConatiner: FC<IQuestionsPageContainerProps> = ({
    showFilters,
    initialFilter,
    createButtonLabelTranslationKey,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { isOpen, close, open, preferenceOrganizationId } = useContext(
        QuestionsDialogContext
    );
    const { filter, setFilter } = useFilter<QuestionsListFilter>(
        initialFilter,
        preferenceOrganizationId === null
    );

    const { currentPageNumber, currentPageSize } = usePagination();

    const classes = useStyles();

    const { currentPageEntities } = useCurrentPageEntities<IScaleQuestion>();

    return (
        <Box>
            <Box className={classes.innerFilter}>
                {showFilters ? (
                    <QuestionFilters filter={filter} setFilter={setFilter} />
                ) : (
                    <Box />
                )}

                <Button
                    className={classes.innerFilterBtn}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => open({})}
                >
                    {t(createButtonLabelTranslationKey)}
                </Button>
            </Box>

            <QuestionsList
                currentPageEntities={currentPageEntities}
                listSlugSuffix={`?organization=${preferenceOrganizationId}`}
                renderLastColumn={(entity) => (
                    <ScaleQuestionsListActionsById id={entity.id} />
                )}
                HeaderListItem={() => (
                    <EntityListHeaderTemplate
                        index={{ label: '#' }}
                        name={{ label: t('title') }}
                        phrase={{ label: t('question') }}
                        library={{ label: 'Library' }}
                        mandatory={{ label: 'Mandatory' }}
                        default={{ label: 'Default' }}
                        notification={{ label: 'Notification' }}
                    />
                )}
                ListItem={(entity, index) => (
                    <ScaleQuestionsListItem
                        entity={entity}
                        index={pageRowNumber(
                            currentPageNumber,
                            currentPageSize,
                            index
                        )}
                    />
                )}
                renderPagination={() => <EntityListPagination />}
            />

            <FormDialogWrapper isOpen={isOpen} handleClose={close}>
                <QuestionsDialogContent></QuestionsDialogContent>
            </FormDialogWrapper>
        </Box>
    );
};

export const QuestionsPage = WithProvider(
    QuestionsPageConatiner,
    scaleQuestionsQuery
);
