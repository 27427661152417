import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { EntityListActions } from 'core/components/EntityListActions';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { ListActionIcon } from 'core/components/shared/ListActionIcon';
import React, { FC, useContext, useState } from 'react';
import { ICategory } from '../models/category.model';
import { IDimension } from '../models/dimension.model';
import { CategoryForm } from './CategoryForm';
import { CategoryFormDialogWrapper } from './CategoryFormDialogWrapper';
import { CategoryFormContext } from './CategoryFormProvider';
import { DimensionsContext } from './DimensionsProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'transparent',
        },
        '& .MuiTreeItem-label:hover': {
            backgroundColor: 'transparent',
        },
    },
    treeItem: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        margin: `${theme.spacing(1)}px 0`,
        border: '1px solid #DFE1E6',
        borderRadius: theme.spacing(0.5),
        '& .MuiTreeItem-content': {
            marginBottom: theme.spacing(1),
        },
        '& .MuiTreeItem-root': {
            backgroundColor: '#FFFFFF',
            '& .MuiTreeItem-root': {
                backgroundColor: '#FAFBFC',
                '& .MuiTreeItem-root': {
                    backgroundColor: '#FFFFF',
                    '& .MuiTreeItem-root': {
                        backgroundColor: '#F4F5F7',
                        '& .MuiTreeItem-root': {
                            backgroundColor: '#FFFFFF',
                            '& .MuiTreeItem-root': {
                                backgroundColor: '#F4F5F7',
                            },
                        },
                    },
                },
            },
        },
    },
    treeItemBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    treeItemBoxPath: {
        marginLeft: `${theme.spacing(2)}px`,
        fontSize: '70%',
    },
    visibilityHidden: {
        visibility: 'hidden',
    },
    visibilityVisible: {
        visibility: 'visible',
    },
}));

export const CategoriesTreeView: FC = () => {
    const classes = useStyles();
    const { dimensions } = useContext(DimensionsContext);
    const { isOpen, close } = useContext(CategoryFormContext);

    return (
        <>
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
            >
                {dimensions.map((dimension) => (
                    <DimensionTreeItem key={dimension.id} {...dimension} />
                ))}
            </TreeView>
            <FormDialogWrapper isOpen={isOpen} handleClose={close}>
                <CategoryFormDialogWrapper>
                    <CategoryForm />
                </CategoryFormDialogWrapper>
            </FormDialogWrapper>
        </>
    );
};

const DimensionTreeItem: FC<IDimension> = ({ id, name, categories }) => {
    const classes = useStyles();
    const { open } = useContext(CategoryFormContext);

    const onClick = (e: any) => {
        e.stopPropagation();

        open({ dimensionId: id, dimensionName: name });
    };

    return (
        <TreeItem
            className={classes.treeItem}
            nodeId={`dimension-${id}`}
            label={
                <Box className={classes.treeItemBox}>
                    <Typography variant="h5">{name}</Typography>

                    <Button startIcon={<AddIcon />} onClick={onClick}>
                        <Typography variant="subtitle2">Add Entry</Typography>
                    </Button>
                </Box>
            }
        >
            {categories.map((category) => (
                <CategoryTreeItem
                    key={category.id}
                    {...category}
                    dimensionName={name}
                />
            ))}
        </TreeItem>
    );
};

interface ICategoryTreeItemProps extends ICategory {
    dimensionName: string;
}

const CategoryTreeItem: FC<ICategoryTreeItemProps> = (props) => {
    const { id, name, calculatedPath, children, dimensionName } = props;
    const classes = useStyles();
    const { open } = useContext(CategoryFormContext);

    const callback = (e: any) => {
        e.stopPropagation();

        open(props);
    };

    const [actionsVisibilityClass, setActionsVisibilityClass] = useState<
        'visibilityVisible' | 'visibilityHidden'
    >('visibilityHidden');

    const handleHoverState = (show: boolean) => {
        setActionsVisibilityClass(
            show ? 'visibilityVisible' : 'visibilityHidden'
        );
    };

    return (
        <TreeItem
            className={classes.treeItem}
            key={id}
            nodeId={id}
            label={
                <Box
                    className={classes.treeItemBox}
                    onMouseEnter={() => handleHoverState(true)}
                    onMouseLeave={() => handleHoverState(false)}
                >
                    <Typography variant="h5">
                        {name}
                        <Typography
                            className={classes.treeItemBoxPath}
                            display="inline"
                            variant="subtitle1"
                        >
                            Path: {calculatedPath}
                        </Typography>
                    </Typography>

                    <Box className={classes[actionsVisibilityClass]}>
                        <EntityListActions
                            actions={[
                                {
                                    callback,
                                    component: (
                                        <ListActionIcon
                                            iconComponent={<EditIcon />}
                                        />
                                    ),
                                },
                            ]}
                        ></EntityListActions>
                    </Box>
                </Box>
            }
        >
            {children?.map((category) => (
                <CategoryTreeItem
                    key={category.id}
                    {...category}
                    dimensionName={dimensionName}
                />
            ))}
        </TreeItem>
    );
};
