import { Box, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useSession } from 'core/hooks/session.hook';
import { USER_ROLE } from 'modules/users/models/user.model';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { ValidTranslationKeys } from 'react-i18next';
import css from 'styles/material-ui/cssVars';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',

        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
        },
    },
    input: {
        position: 'absolute',
        opacity: 0,
        zIndex: 100,
        '&:checked + $labelSpan': {
            color: theme.palette.background.default,
            backgroundColor: css.extraColors.background.darkDefault,
        },
        '&:disabled + $labelSpan': {
            cursor: 'not-allowed',
        },
        '&:not(:disabled)': {
            '&:checked:hover + $labelSpan': {
                backgroundColor: theme.palette.primary.light,
                cursor: 'default',
            },
        },
    },
    label: {
        [theme.breakpoints.down('xs')]: {
            width: `calc(50% - ${theme.spacing(2)}px)`,
            marginRight: theme.spacing(4),
            marginBottom: theme.spacing(4),

            '&:nth-child(2n+2)': {
                marginRight: 0,
            },
        },
    },
    labelSpan: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        borderColor: theme.palette.text.secondary,
        height: 104,
        width: 120,
        marginRight: theme.spacing(2),
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        borderRadius: theme.spacing(1),
        '&:hover + $input:not(:disabled)': {
            color: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
        },
        [theme.breakpoints.down('xs')]: {
            height: '80px',
            width: '100%',
        },
    },
    icon: {
        fontSize: 24,
    },
}));

interface IUserRoleRadioButtonConfig {
    title: keyof ValidTranslationKeys;
    value: USER_ROLE;
    label: keyof ValidTranslationKeys;
}

const userRoleRadioButtonConfigs: IUserRoleRadioButtonConfig[] = [
    {
        title: 'tooltip-project',
        value: USER_ROLE.PROJECT_ADMIN,
        label: 'project',
    },
    {
        title: 'tooltip-portfolio',
        value: USER_ROLE.PORTFOLIO_ADMIN,
        label: 'portfolio',
    },
    {
        title: 'tooltip-account',
        value: USER_ROLE.ACCOUNT_ADMIN,
        label: 'account',
    },
    {
        title: 'tooltip-super-admin',
        value: USER_ROLE.SUPER_ADMIN,
        label: 'super-admin',
    },
];

const numberedRolesMap = Object.values(USER_ROLE)
    .reverse()
    .reduce((map, role, index) => map.set(role, index), new Map());

interface IUserRoleRadioButtonsProps {
    radioButtonValue: USER_ROLE;
    disabled?: boolean;
    onChangeHandler: (type: string) => void;
}

export const UserRoleRadioButtons: FC<IUserRoleRadioButtonsProps> = ({
    radioButtonValue,
    disabled,
    onChangeHandler,
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeHandler(event.target.value);
    };

    const classes = useStyles();

    const { t } = useContext(MultiLanguageSupportContext);

    const { me } = useSession();

    const canUpdateRole = useCallback(
        (role: USER_ROLE): boolean => {
            if (!me?.role) {
                return false;
            }

            if (
                numberedRolesMap.get(me.role) >
                numberedRolesMap.get(USER_ROLE.PORTFOLIO_ADMIN)
            ) {
                return (
                    numberedRolesMap.get(me.role) >= numberedRolesMap.get(role)
                );
            }

            return numberedRolesMap.get(me.role) > numberedRolesMap.get(role);
        },
        [me?.role]
    );

    const configs = useMemo(
        () =>
            userRoleRadioButtonConfigs.map((role) => ({
                ...role,
                enabled: canUpdateRole(role.value),
            })),
        [canUpdateRole]
    );

    return (
        <Box className={classes.root}>
            {configs.map(({ title, value, label, enabled }) => (
                <Tooltip title={t(title)} key={label}>
                    <label className={classes.label}>
                        <input
                            type="radio"
                            className={classes.input}
                            checked={radioButtonValue === value}
                            value={value}
                            name="user-type"
                            id={label}
                            onChange={handleChange}
                            disabled={disabled || !enabled}
                        />
                        <span className={classes.labelSpan}>
                            <FolderIcon className={classes.icon} />
                            <Typography
                                variant="overline"
                                style={{ textTransform: 'capitalize' }}
                            >
                                {t(label)}
                            </Typography>
                        </span>
                    </label>
                </Tooltip>
            ))}
        </Box>
    );
};
