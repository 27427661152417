import { ApexOptions } from 'apexcharts';

export const getTimeSeriesOptions = (
    partial?: Partial<ApexOptions>
): ApexOptions => {
    return {
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        xaxis: {
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        stroke: {
            show: true,
            width: 3,
            dashArray: 0,
        },
        markers: {
            size: 4,
            showNullDataPoints: false,
            hover: {
                size: 7,
            },
        },
        ...partial,
    };
};
