import {
    Box,
    Button,
    InputAdornment,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TextField,
    Theme,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { EntityListHeaderTemplate } from 'core/components/shared/EntityListHeaderTemplate';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { useCurrentPage } from 'core/hooks/current-page.hook';
import { useFilter } from 'core/hooks/filter.hook';
import { useSession } from 'core/hooks/session.hook';
import { useDebounce } from 'core/hooks/shared/debounce.hook';
import { ActivityDialogContext } from 'modules/activity/components/ActivityDialogProvider';
import { ActivityPage } from 'modules/activity/pages/ActivityPage';
import { Can } from 'modules/login/components/Can';
import React, { FC, useContext, useEffect, useState } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { ListHeader } from '../../../core/components/shared/ListHeader';
import { ListItemContainer } from '../../../core/components/shared/ListItemContainer';
import { primaryOrderByProperty } from '../../../core/constants/default-order-properties';
import { useCurrentPageEntities } from '../../../core/hooks/current-page-entities.hook';
import { IUser } from '../models/user.model';
import { UserFilters, UsersListFilter } from './UserFilters';
import { UserForm } from './UserForm';
import { UserFormContext } from './UserFormProvider';
import { UserListActionsById } from './UserListActions';
import { UserListItem } from './UserListItem';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    projectCardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
        columnGap: 24,
        rowGap: 24,
    },
    filters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    filterSearch: {
        display: 'flex',
        gap: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginTop: '24px',
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
        },
    },
    filterSearchBtn: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    tableBackground: {
        backgroundColor: '#fff',
    },
    searchfield: {
        width: '240px',
        [theme.breakpoints.up('lg')]: {
            width: '440px',
        },
        [theme.breakpoints.down('sm')]: {
            flex: 1,
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(0),
            marginTop: theme.spacing(3),
            width: '100%',
        },
        '& input': {
            padding: `14.5px ${theme.spacing(2)}px 14.5px 0`,
        },
    },
}));

const SearchUsersInput = () => {
    const { setSearchTerm } = useCurrentPage();
    const [inputValue, setInputValue] = useState('');
    const debouncedSearchTerm = useDebounce(inputValue, 500);
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);

    useEffect(() => {
        setSearchTerm(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    return (
        <TextField
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            variant="outlined"
            className={classes.searchfield}
            label={t('search-user')}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export const UserList: FC = () => {
    const { currentPageEntities } = useCurrentPageEntities<IUser>();
    const { t } = useContext(MultiLanguageSupportContext);
    const { isOpen, close, open } = useContext(UserFormContext);
    const { isOpened, closeModal } = useContext(ActivityDialogContext);
    const classes = useStyles();
    const { setOrderBy } = useCurrentPage();
    const { me } = useSession();

    useEffect(() => {
        setOrderBy(primaryOrderByProperty, 'desc');
    }, []);

    const { filter, setFilter } = useFilter<UsersListFilter>(
        UsersListFilter.ALL
    );

    const handleListFilterChange = (view: UsersListFilter) => {
        setFilter(view);
    };

    return (
        <Box width={'100%'}>
            <Box className={classes.filters} mb={3}>
                <UserFilters
                    filter={filter}
                    setFilter={handleListFilterChange}
                />
                <Box className={classes.filterSearch}>
                    <SearchUsersInput />
                    <Can I="create" a="IUser">
                        <Box>
                            <Button
                                className={classes.filterSearchBtn}
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={() =>
                                    open(
                                        {
                                            organizationId: me?.organizationId,
                                            organizationName:
                                                me?.organizationName,
                                        },
                                        { shouldFetchAfterSuccess: true }
                                    )
                                }
                            >
                                {t('create-user')}
                            </Button>
                        </Box>
                    </Can>
                </Box>
            </Box>

            <TableContainer
                component={Paper}
                variant="outlined"
                className={classes.tableBackground}
            >
                <Table>
                    <TableHead>
                        <ListHeader
                            renderLastColumn={() => (
                                <span style={{ visibility: 'hidden' }}>
                                    {t('actions')}
                                </span>
                            )}
                        >
                            <EntityListHeaderTemplate
                                name={{
                                    label: t('name'),
                                    sort: 'name',
                                }}
                                email={{
                                    label: t('email'),
                                    sort: 'email',
                                }}
                                accessProfile={{
                                    label: t('role-placeholder'),
                                    sort: 'role',
                                }}
                            />
                        </ListHeader>
                    </TableHead>
                    <TableBody>
                        {currentPageEntities.map((entity, index) => (
                            <ListItemContainer
                                key={entity.id}
                                entityId={entity.id}
                                enableHover={true}
                                renderLastColumn={(entityId: string) => (
                                    <UserListActionsById id={entityId} />
                                )}
                            >
                                <UserListItem entity={entity} index={index} />
                            </ListItemContainer>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <FormDialogWrapper isOpen={isOpen} handleClose={close}>
                <UserForm />
            </FormDialogWrapper>
            <FormDialogWrapper isOpen={isOpened} handleClose={closeModal}>
                <ActivityPage />
            </FormDialogWrapper>
        </Box>
    );
};
