import {
    Box,
    Button,
    Container,
    Link,
    TextField,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import NeedHelpMail from 'core/components/shared/micro/NeedHelpMail';
import { Form, Formik, FormikProps } from 'formik';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { IResetPasswordCredentials } from 'modules/resetpassword/models/reset.password.model';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router';
import LogoWithText from 'styles/material-ui/elements/logo-with-text';
import * as Yup from 'yup';
import { emailValidation } from '../../../core/validations';

const initialValues: IResetPasswordCredentials = {
    email: '',
    callback_url: '',
};

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(22),
    },
    form: {
        width: '100%',
    },
}));

interface IResetPasswordCredentialsFormProps {
    onSubmitClick: (data: IResetPasswordCredentials) => void;
}

export const RequestResetPasswordForm: FC<IResetPasswordCredentialsFormProps> = ({
    onSubmitClick,
}) => {
    const { push } = useHistory();
    const { t } = useContext(MultiLanguageSupportContext);

    const validationSchema = Yup.object().shape({
        email: emailValidation,
    });

    const redirectToLogin = () => {
        push({ pathname: ROUTES.LOGIN });
    };

    const classes = useStyles();

    return (
        <Container maxWidth="sm">
            <div className={classes.paper}>
                <LogoWithText />

                <Box mt={16}>
                    <Typography variant="h1">{t('reset-password')}</Typography>
                </Box>

                <Box mt={4} mb={6}>
                    <Typography variant="body1" align="center">
                        <span>{`${t('forgot-nothing')}`}</span>{' '}
                        <span>{`${t('forgot-spam')}`}</span>{' '}
                        <Link
                            href={
                                'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL
                            }
                        >
                            {process.env.REACT_APP_SUPPORT_EMAIL}.
                        </Link>
                    </Typography>
                </Box>

                <Formik
                    initialValues={initialValues}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(
                        credentials: IResetPasswordCredentials,
                        actions
                    ) => {
                        onSubmitClick({
                            ...credentials,
                            callback_url: `${process.env.REACT_APP_RESET_PASSWORD}`,
                        });
                        actions.resetForm();
                    }}
                    validationSchema={validationSchema}
                >
                    {(props: FormikProps<IResetPasswordCredentials>) => {
                        const {
                            values,
                            touched,
                            errors,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                            submitForm,
                        } = props;
                        return (
                            <Form className={classes.form}>
                                <TextField
                                    label={t('email-placeholder')}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    autoComplete="email"
                                    autoFocus
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    error={!!errors.email}
                                    helperText={t('enter-email-placeholder')}
                                />
                                <Box mt={8} mb={6}>
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                        disableElevation
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                    >
                                        {t('password-link')}
                                    </Button>
                                </Box>
                                <Box>
                                    <Link href="" onClick={redirectToLogin}>
                                        <span style={{ color: 'black' }}>
                                            {t('never-mind')}
                                        </span>
                                        {t('back-to-login')}
                                    </Link>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <NeedHelpMail />
        </Container>
    );
};
