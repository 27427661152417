import { WithProvider } from 'core/hocs/WithProvider';
import { fetchById, useCrud } from 'core/hooks/crud.hook';
import { useSession } from 'core/hooks/session.hook';
import { requiredField } from 'core/validations';
import { FormikProvider, useFormik } from 'formik';
import {
    getCategoriesByDimensionId,
    ICategoriesByDimensionId,
} from 'modules/categories/components/DimenstionInputs';
import { ICategory } from 'modules/categories/models/category.model';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { pathBuilder } from 'modules/navigation/helpers/path-builder.helper';
import React, { createContext, FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import {
    emptyProject,
    IProject,
    IProjectDetails,
    IProjectResponse,
} from '../models/project.model';
import { projectsQuery } from '../state/projects.query';
import { projectsStore } from '../state/projects.store';

export interface IProjectFormik extends Omit<IProjectDetails, 'categories'> {
    openNewPulse?: boolean;
    categoriesByDimensionId: ICategoriesByDimensionId;
}

interface IProjectDialogContext {
    projectId: string | null;
    isOpen: boolean;
    close: () => void;
    open: (project: Partial<IProject>) => void;
}

export const ProjectDialogContext = createContext<IProjectDialogContext>(
    {} as IProjectDialogContext
);

const validationSchema = Yup.object().shape({
    name: requiredField,
    projectManagerId: requiredField,
});

export const ProjectDialogProvider: FC = WithProvider(({ children }) => {
    const { push } = useHistory();
    const { me } = useSession();
    const [isOpen, setIsOpen] = useState(false);
    const [projectId, setProjectId] = useState<string | null>(null);
    const [initialValues, setInitialValues] = useState<IProjectFormik>({
        ...emptyProject,
        categoriesByDimensionId: {},
    });

    const close = () => {
        setIsOpen(false);
    };

    const open = useCallback(
        async ({ id, categories, ...details }: Partial<IProject>) => {
            setIsOpen(true);
            setProjectId(id ?? null);

            let tempInitialValues: IProjectFormik = {
                ...emptyProject,
                ...details,
                categoriesByDimensionId: {},
            };

            if (id) {
                const project = await fetchById<IProject, IProjectResponse>(
                    projectsStore,
                    projectsStore.crudApiService
                )(id);

                if (project) {
                    const {
                        id: _fetchedId,
                        categories: fetchedCategories,
                        ...fetchedDetails
                    } = project;

                    categories = fetchedCategories;

                    tempInitialValues = {
                        ...tempInitialValues,
                        ...fetchedDetails,
                    };
                }
            }

            tempInitialValues = {
                ...tempInitialValues,
                categoriesByDimensionId: getCategoriesByDimensionId(categories),
            };

            if (!tempInitialValues.projectManagerId && me) {
                tempInitialValues.projectManagerId = me.id;
                tempInitialValues.projectManagerName = me.name;
            }

            setInitialValues(tempInitialValues);
        },
        [me]
    );

    const { createSingle, updateSingle } = useCrud<IProject>();

    const onSubmit = useCallback(
        async ({
            openNewPulse,
            categoriesByDimensionId,
            ...data
        }: IProjectFormik) => {
            const details: IProjectDetails = {
                ...data,
                categories: Object.keys(categoriesByDimensionId)
                    .map((dimensionId) => categoriesByDimensionId[dimensionId])
                    .filter((x) => !!x) as ICategory[],
            };

            const project = projectId
                ? await updateSingle(
                      { id: projectId, ...details },
                      {
                          shouldFetchAfterSuccess: true,
                      }
                  )
                : await createSingle(details, {
                      shouldFetchAfterSuccess: true,
                  });

            if (project) {
                if (!projectId) {
                    let redirectUrl =
                        pathBuilder(ROUTES.PROJECT, ':id', project.id) +
                        `?activeTab=pulses`;
                    if (openNewPulse) {
                        redirectUrl += `&openNewPulse=true`;
                    }

                    setTimeout(() => {
                        push(redirectUrl);
                    });
                }

                close();
            }

            return project;
        },
        [projectId]
    );

    const formik = useFormik<IProjectFormik>({
        initialValues,
        enableReinitialize: true,
        validateOnMount: !!projectId,
        onSubmit,
        validationSchema,
    });

    return (
        <ProjectDialogContext.Provider
            value={{
                isOpen,
                close,
                open,
                projectId,
            }}
        >
            <FormikProvider value={formik}>{children}</FormikProvider>
        </ProjectDialogContext.Provider>
    );
}, projectsQuery);
