import {
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Theme,
} from '@material-ui/core';
import { EntityListHeaderTemplate } from 'core/components/shared/EntityListHeaderTemplate';
import { FC, useContext, useEffect } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { ListHeader } from '../../../core/components/shared/ListHeader';
import { ListItemContainer } from '../../../core/components/shared/ListItemContainer';
import { defaultOrderByProperty } from '../../../core/constants/default-order-properties';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { useCurrentPage } from '../../../core/hooks/current-page.hook';
import { IParticipant } from '../models/participant.model';
import { participantsQuery } from '../state/participants.query';
import { ParticipantDeleteDialog } from './ParticipantDeleteDialog';
import { ParticipantListActions } from './ParticipantListActions';
import { ParticipantsListItem } from './ParticipantsListItem';

const useStyles = makeStyles((theme: Theme) => ({
    tableBackground: {
        backgroundColor: '#fff',
    },
    actionWrapper: {
        minWidth: 0,
        padding: theme.spacing(1),
    },
}));

interface ParticipantsListProps {
    entities: IParticipant[];
    onSelect?: (participant: IParticipant) => Promise<void> | void;
    showDeleteAction?: boolean;
}

const ParticipantsListContainer: FC<ParticipantsListProps> = ({
    entities,
    onSelect,
    showDeleteAction,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();
    const { setOrderBy } = useCurrentPage();

    useEffect(() => {
        setOrderBy(defaultOrderByProperty, 'desc');
    }, []);

    return (
        <>
            <TableContainer
                component={Paper}
                variant="outlined"
                className={classes.tableBackground}
            >
                <Table>
                    <TableHead>
                        <ListHeader
                            renderLastColumn={
                                !onSelect ? undefined : () => <span></span>
                            }
                        >
                            <EntityListHeaderTemplate
                                name={{ label: t('name') }}
                                email={{ label: t('email') }}
                                department={{ label: t('department') }}
                                affiliation={{ label: t('organization') }}
                                role={{ label: t('role-placeholder') }}
                            />
                        </ListHeader>
                    </TableHead>
                    <TableBody>
                        {entities.map((entity, index) => (
                            <ListItemContainer
                                key={entity.id}
                                entityId={entity.id}
                                enableHover={true}
                                renderLastColumn={
                                    !onSelect
                                        ? undefined
                                        : () => (
                                              <ParticipantListActions
                                                  entity={entity}
                                                  showDeleteAction={
                                                      showDeleteAction
                                                  }
                                              />
                                          )
                                }
                            >
                                <ParticipantsListItem
                                    entity={entity}
                                    index={index}
                                />
                            </ListItemContainer>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <ParticipantDeleteDialog />
        </>
    );
};

export const ParticipantsList = WithProvider(
    ParticipantsListContainer,
    participantsQuery
);
