import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, TableCell } from '@material-ui/core';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { TranslateWarning } from 'core/components/shared/TranslationWarning';
import { FC, useContext } from 'react';
interface IScaleQuestionsListItemTemplateProps {
    phrase: string;
    name: string;
    library: 'Account' | 'System';
    mandatory: boolean;
    defaultItem: boolean;
    missTranslation: boolean;
    index: number;
}

const useStyles = makeStyles(() => ({
    warningIcon: {
        color: '#FF961A',
        marginRight: '5px',
    },
}));

export const ScaleQuestionListItemTemplate: FC<IScaleQuestionsListItemTemplateProps> = ({
    phrase,
    name,
    library,
    mandatory,
    defaultItem,
    missTranslation,
    index,
}) => {
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);
    return (
        <>
            <TableCell>{index}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{phrase}</TableCell>
            <TableCell>{library}</TableCell>
            <TableCell>
                {mandatory ? (
                    <FontAwesomeIcon
                        icon={['far', 'check-circle']}
                        color="green"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={['far', 'minus-circle']}
                        color="red"
                    />
                )}
            </TableCell>
            <TableCell>
                {defaultItem ? (
                    <FontAwesomeIcon
                        icon={['far', 'check-circle']}
                        color="green"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={['far', 'minus-circle']}
                        color="red"
                    />
                )}
            </TableCell>
            <TableCell>
                {!missTranslation ? (
                    <></>
                ) : (
                    <TranslateWarning label={t('translation-missing')} />
                )}
            </TableCell>
        </>
    );
};
