import React, { FC } from 'react';
import { IEntityListItemProps } from '../../../core/interfaces/entity-list-item-props.interface';
import { IAccount } from '../models/account.model';
import { AccountsListItemTemplate } from './AccountsListItemTemplate';

export const AccountsListItem: FC<IEntityListItemProps<IAccount>> = ({
    entity: account,
}) => {
    return (
        <>
            <AccountsListItemTemplate {...account} />
        </>
    );
};
