import {
    ImplementPulseAutoComplete,
    ImplementPulseAutoCompleteProps,
} from 'core/components/ImplementPulseAutoComplete';
import { ICategoryResponse } from 'modules/categories/models/category.model';
import React, { FC } from 'react';
import {
    createProjectManagerFromResponse,
    ProjectManager,
    ProjectManagerResponse,
} from '../models/project-manager.model';

type IProjectManagerAutocompleteProps = Omit<
    ImplementPulseAutoCompleteProps<ProjectManager, ICategoryResponse[]>,
    'url' | 'stringify' | 'responseMapper'
>;

export const ProjectManagerAutocomplete: FC<IProjectManagerAutocompleteProps> = (
    props
) => (
    <ImplementPulseAutoComplete<ProjectManager, ProjectManagerResponse[]>
        url={
            props.disabled
                ? undefined
                : '/organizations/project-managers?dropdown=1'
        }
        data={!props.disabled ? undefined : props.value ? [props.value] : []}
        stringify={({ name }) => name}
        responseMapper={(results: ProjectManagerResponse[]) =>
            results.map(createProjectManagerFromResponse)
        }
        {...props}
    />
);
