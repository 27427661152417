export const daysToRespond = [
    {
        item: '1',
        value: 1,
    },
    {
        item: '2',
        value: 2,
    },
    {
        item: '3',
        value: 3,
    },
    {
        item: '4',
        value: 4,
    },
    {
        item: '5',
        value: 5,
    },
    {
        item: '6',
        value: 6,
    },
    {
        item: '7',
        value: 7,
    },
];

export const remindOn = [
    {
        item: 'No reminder',
        value: 0,
    },
    {
        item: '1st day',
        value: 1,
    },
    {
        item: '2nd day',
        value: 2,
    },
    {
        item: '3rd day',
        value: 3,
    },
    {
        item: '4th day',
        value: 4,
    },
    {
        item: '5th day',
        value: 5,
    },
    {
        item: '6th day',
        value: 6,
    },
];

export const maxDaysToRespond = 7;

export const languages = [
    {
        value: 'EN',
        lang: 'English',
    },
    {
        value: 'DE',
        lang: 'German',
    },
];
