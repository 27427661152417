import { createContext, FC, useEffect, useState } from 'react';

interface IFilters {
    projectId?: string;
    projectType?: string;
    organization?: string;
    pulseId?: string;
    role?: string;
    startDate?: string;
    endDate?: string;
    geography?: string;
    department?: string;
    category?: string;
    customer?: string;
}

interface IDashboardContext {
    initialFilters: Partial<IFilters>;
    handleFilters: (filter: IFilters) => void;
}

export const DashboardContext = createContext<IDashboardContext>(
    {} as IDashboardContext
);

interface IDashboardProviderProps {
    initialFilters?: Partial<IFilters>;
    onQueryStringChange: (value: string) => void;
}

const getQueryStringFromFilters = (filters: Partial<IFilters>) => {
    return Object.entries(filters).reduce((temp, [filterKey, filterValue]) => {
        if (filterValue) {
            let queryKey = filterKey;

            switch (filterKey) {
                case 'organizationId':
                    queryKey = 'organization';
                    break;
                case 'pulseId':
                    queryKey = 'pulse';
                    break;
                case 'endDate':
                    filterValue += ' 23:59:59';
                    break;
                case 'projectId':
                    /**
                     * This filterKey ('projectId') is not used in the queryString but as part of the endpoint path
                     */
                    return temp;
            }

            if (temp.length) {
                temp += '&';
            }

            temp += `${queryKey}=${filterValue}`;
        }

        return temp;
    }, '');
};

export const DashboardProvider: FC<IDashboardProviderProps> = ({
    children,
    initialFilters = {},
    onQueryStringChange,
}) => {
    const [queryString, setQueryString] = useState(
        getQueryStringFromFilters(initialFilters)
    );

    useEffect(() => {
        onQueryStringChange(queryString);
    }, [queryString]);

    const handleFilters = (filters: Partial<IFilters>) => {
        setQueryString(getQueryStringFromFilters(filters));
    };

    return (
        <DashboardContext.Provider
            value={{
                initialFilters,
                handleFilters,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};
