import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { FC, useContext } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';

export interface PulseAnswersDashboardProps {
    label: string;
    exportCsvExcel: (exportType: 'excel' | 'csv') => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) => ({
    margin_right: {
        marginRight: theme.spacing(1),
    },
}));

export enum EXPORT_TYPE {
    CSV = 'csv',
    EXCEL = 'excel',
}

export const PulseAnswersDashboard: FC<PulseAnswersDashboardProps> = ({
    label,
    children,
    exportCsvExcel,
}) => {
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);

    return (
        <Box border={'1px solid #DFE1E6'} p={5} borderRadius="8px" mt={5}>
            <Box mb={2} display="flex" justifyContent="space-between">
                <Typography>
                    <Typography component="span" variant="h5">
                        {label}
                    </Typography>
                </Typography>
                <Box>
                    <Button
                        disableElevation
                        color="secondary"
                        variant="contained"
                        className={classes.margin_right}
                        onClick={() => exportCsvExcel(EXPORT_TYPE.EXCEL)}
                    >
                        {t('excel-export')}
                    </Button>
                    <Button
                        disableElevation
                        color="secondary"
                        variant="contained"
                        onClick={() => exportCsvExcel(EXPORT_TYPE.CSV)}
                    >
                        {t('csv-export')}
                    </Button>
                </Box>
            </Box>

            {children}
        </Box>
    );
};
