import {
    Box,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Theme,
    Typography,
} from '@material-ui/core';
import { useObservable } from '@mindspace-io/react';
import { pageRowNumber } from '@qagency/react-shared-library';
import { EntityListPagination } from 'core/components/EntityListPagination';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { EntityListHeaderTemplate } from 'core/components/shared/EntityListHeaderTemplate';
import { IUseCurrentPage, useCurrentPage } from 'core/hooks/current-page.hook';
import { usePagination } from 'core/hooks/pagination.hook';
import { PulseParticipantFormDialog } from 'modules/pulse-participants/components/PulseParticipantFormDialog';
import { pulseParticipantsQuery } from 'modules/pulse-participants/state/pulse-participants.query';
import { pulseParticipantsStore } from 'modules/pulse-participants/state/pulse-participants.store';
import { IPulse } from 'modules/pulse/models/pulse.model';
import React, { FC, useContext, useEffect } from 'react';
import { ListHeader } from '../../../core/components/shared/ListHeader';
import { ListItemContainer } from '../../../core/components/shared/ListItemContainer';
import { WithCurrentPageListener } from '../../../core/hocs/WithListContainer';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { useCrud } from '../../../core/hooks/crud.hook';
import { IParticipant } from '../../participants/models/participant.model';
import { PulseParticipantDeleteDialog } from './PulseParticipantDeleteDialog';
import { PulseParticipantFormProvider } from './PulseParticipantFormProvider';
import { PulseParticipantListActions } from './PulseParticipantListActions';
import { PulseParticipantsListItem } from './PulseParticipantsListItem';

const useStyles = makeStyles((theme: Theme) => ({
    projectCardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
        columnGap: 24,
        rowGap: 24,
    },
    filters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableBackground: {
        backgroundColor: '#fff',
    },
}));

interface SelectedParticipantsListProps {
    pulse: IPulse;
    triggerParticipantsRefetch: boolean;
    renderToolbar?: (props: IUseCurrentPage<IParticipant>) => JSX.Element;
}

const PulseParticipantsListContainer: FC<SelectedParticipantsListProps> = ({
    pulse,
    triggerParticipantsRefetch,
    renderToolbar,
}) => {
    const [currentPageEntities] = useObservable(
        pulseParticipantsQuery.currentPageParticipants$,
        pulseParticipantsQuery.getCurrentPageParticipants()
    );

    const { t } = useContext(MultiLanguageSupportContext);
    const { setListSlug, fetch } = useCrud();
    const classes = useStyles();
    const { currentPageNumber, currentPageSize } = usePagination();
    const currentPage = useCurrentPage();

    useEffect(() => {
        currentPage.clearCurrentPageEntities();
        pulse.id && setListSlug({ prefix: `pulses/${pulse.id}` });
    }, [pulse.id]);

    useEffect(() => {
        pulseParticipantsStore.fetchEntities();
        pulseParticipantsStore.patchUIState({
            pageNumber: currentPageNumber,
        });
    }, [triggerParticipantsRefetch]);

    return (
        <PulseParticipantFormProvider pulseId={pulse.id} onChange={fetch}>
            <Box width={'100%'}>
                {renderToolbar && (
                    <Box mb={3}>{renderToolbar(currentPage)}</Box>
                )}

                <TableContainer
                    component={Paper}
                    variant="outlined"
                    className={classes.tableBackground}
                >
                    <Table>
                        <TableHead>
                            <ListHeader
                                renderFirstColumn={() => (
                                    <Typography variant="h5">#</Typography>
                                )}
                                renderLastColumn={() => <span></span>}
                            >
                                <EntityListHeaderTemplate
                                    name={{ label: t('name') }}
                                    email={{ label: t('email') }}
                                    department={{ label: t('department') }}
                                    affiliation={{
                                        label: t('organization'),
                                    }}
                                    role={{ label: t('role-placeholder') }}
                                />
                            </ListHeader>
                        </TableHead>
                        <TableBody>
                            {currentPageEntities.map((entity, index) => (
                                <ListItemContainer
                                    key={entity.id}
                                    entityId={entity.id}
                                    enableHover={true}
                                    renderFirstColumn={() => (
                                        <>
                                            {pageRowNumber(
                                                currentPageNumber,
                                                currentPageSize,
                                                index
                                            )}
                                            .
                                        </>
                                    )}
                                    renderLastColumn={() => (
                                        <PulseParticipantListActions
                                            entity={entity}
                                            showDeleteAction
                                        />
                                    )}
                                >
                                    <PulseParticipantsListItem
                                        entity={entity}
                                        index={index}
                                    />
                                </ListItemContainer>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box mt={2}>
                    <EntityListPagination />
                </Box>

                <PulseParticipantFormDialog />

                <PulseParticipantDeleteDialog />
            </Box>
        </PulseParticipantFormProvider>
    );
};

export const PulseParticipantsList = WithProvider(
    WithCurrentPageListener(PulseParticipantsListContainer),
    pulseParticipantsQuery
) as FC<SelectedParticipantsListProps>;
