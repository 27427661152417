import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import React, { createContext, FC, useEffect, useMemo, useState } from 'react';

type FAIconStyle = 'solid' | 'regular' | 'light' | 'duotone' | 'brands';

enum LibraryDefinitionsKey {
    FAB = 'fab',
    FAD = 'fad',
    FAR = 'far',
}

type LibraryDefinition = {
    [key in IconName]: [number, number, any[], string, string[]];
};

type LibraryDefinitions = {
    [key in LibraryDefinitionsKey]: LibraryDefinition;
};

library.add(fab, fad, far);

const libraryDefinitions = JSON.parse(JSON.stringify(library))
    .definitions as LibraryDefinitions;

const getFAIconStyle = (key: LibraryDefinitionsKey): FAIconStyle => {
    switch (key) {
        case LibraryDefinitionsKey.FAB:
            return 'brands';
        case LibraryDefinitionsKey.FAD:
            return 'duotone';
        case LibraryDefinitionsKey.FAR:
            return 'regular';
    }
};

export interface IFAIcon {
    name: IconName;
    label: string;
    styles: FAIconStyle[];
}

export interface IIconPickerProviderContext {
    listOfIcons: IFAIcon[] | [];
}

export const IconPickerContext = createContext<IIconPickerProviderContext>(
    {} as IIconPickerProviderContext
);

export const IconPickerProvider: FC = ({ children }) => {
    const [mapOfIcons, setMapOfIcons] = useState<Map<IconName, IFAIcon>>(
        new Map()
    );

    useEffect(() => {
        const iconItems = new Map<IconName, IFAIcon>(mapOfIcons);

        Object.keys(libraryDefinitions).forEach((libraryDefinitionKey) => {
            const libraryDefinition =
                libraryDefinitions[
                    libraryDefinitionKey as LibraryDefinitionsKey
                ];

            const style = getFAIconStyle(
                libraryDefinitionKey as LibraryDefinitionsKey
            );

            Object.keys(libraryDefinition).forEach((key) => {
                const name = key as IconName;
                const existing = iconItems.get(name);

                if (!existing) {
                    iconItems.set(name, {
                        name,
                        styles: [style],
                        label: key.replace('-', ' '),
                    });
                } else {
                    const { styles, ...rest } = existing;

                    iconItems.set(name, {
                        ...rest,
                        styles: [...styles, style],
                    });
                }
            });
        });

        setMapOfIcons(iconItems);
    }, []);

    const listOfIcons = useMemo(
        () => Array.from(mapOfIcons.entries()).map(([kay, icon]) => icon),
        [mapOfIcons]
    );

    return (
        <>
            <IconPickerContext.Provider
                value={{
                    listOfIcons,
                }}
            >
                {children}
            </IconPickerContext.Provider>
        </>
    );
};
