import { useObservable } from '@mindspace-io/react';
import { useContext, useMemo } from 'react';
import { map } from 'rxjs/operators';
import { EntitiesContext } from '../constants/entities.context';

export interface IUseFilter<T> {
    filter: T;
    setFilter: (filter: T) => void;
}

export const useFilter = <T>(
    defaultValue?: T,
    force = false
): IUseFilter<T> => {
    const { query, store } = useContext(EntitiesContext);

    const initialValue = useMemo<T>(() => {
        const filterValue = query.getValue().ui.filter as T;

        if (defaultValue && (force || !filterValue)) {
            store.patchUIState({
                filter: defaultValue,
            });

            return defaultValue;
        }

        return filterValue;
    }, [query]);

    const [filter] = useObservable(
        query.select(({ ui }) => ui.filter).pipe(map((x) => x as T)),
        initialValue
    );

    return {
        filter,
        setFilter: (newFilter: T) => {
            store.patchUIState({
                filter: newFilter,
            });
        },
    };
};
