import { EntitiesState } from '../../core/state/entities.store';
import { pageSizeOptions } from './page-size-options';

export const initialEntitiesState: Partial<EntitiesState<any>> = {
    ui: {
        searchTerm: '',
        pageNumber: 1,
        pageSize: pageSizeOptions[0],
        orderDirection: null,
    },
    total: null,
    highlightNext: null,
    currentPageIds: [],
    pagesIds: [],
    active: [],
};
