import { asDateString } from 'core/helpers/asDateString';
import { TimeSeriesGraphProps } from '../components/Graphs/TimeSeriesGraph';
import { IGraphData, ITimeSeriesData } from '../models/dashboard.models';
import { getTimeSeriesOptions } from './getTimeSeriesOptions';

export const getTimeSeriesGraphProps = (
    timeSeriesGraphData: IGraphData<ITimeSeriesData>[] = []
): TimeSeriesGraphProps => {
    const categoriesSet = new Set<number>();
    const timeSeriesGraphMap = new Map<string, { [time: number]: number }>();

    timeSeriesGraphData.forEach(({ name, data }) => {
        data.forEach(({ date, average }) => {
            const time = new Date(date.split(' ')[0]).getTime();
            categoriesSet.add(time);

            const temp = { ...timeSeriesGraphMap.get(name) };
            timeSeriesGraphMap.set(name, { ...temp, [time]: average });
        });
    });

    const categories: number[] = Array.from(categoriesSet);

    categories.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));

    const data: IGraphData<string | null>[] = Array.from(
        timeSeriesGraphMap.entries()
    ).map(([name, data]) => ({
        name,
        data: [
            null,
            ...categories.map((time) =>
                data[time] ? data[time].toFixed(1) : null
            ),
            null,
        ],
    }));

    return {
        data,
        options: getTimeSeriesOptions({
            xaxis: {
                categories: [
                    '',
                    ...categories.map((time) =>
                        asDateString(new Date(time), {
                            format: 'date',
                        })
                    ),
                    '',
                ],
            },
            yaxis: {
                min: 0,
                max: 5,
                forceNiceScale: true,
                decimalsInFloat: 1,
            },
        }),
    };
};
