import { IScaleQuestion } from 'modules/scale-questions/models/scale-question.model';
import React, { FC } from 'react';
import { IEntityListItemProps } from '../../../core/interfaces/entity-list-item-props.interface';
import { ScaleQuestionListItemTemplate } from './QuestionListItemTemplate';

export const ScaleQuestionsListItem: FC<
    IEntityListItemProps<IScaleQuestion> & { pulseLanguageId: string }
> = ({ entity: { translations, ...props }, index, pulseLanguageId }) => (
    <ScaleQuestionListItemTemplate
        index={index}
        translate={translations.find(
            (item) => item.language.id === pulseLanguageId
        )}
        {...props}
    />
);
