import { TableCell } from '@material-ui/core';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useAffiliationTranslation } from 'modules/participants/hooks';
import React, { FC, useContext } from 'react';
import { IEntityListItemProps } from '../../../core/interfaces/entity-list-item-props.interface';
import { IPulseParticipant } from '../models/pulse-participant.model';

export const PulseParticipantsListItem: FC<
    IEntityListItemProps<IPulseParticipant>
> = ({
    entity: { name, email, department, affiliation, organizationName, role },
}) => {
    const { t } = useContext(MultiLanguageSupportContext);

    const { getAffiliationTranslation } = useAffiliationTranslation(
        organizationName
    );

    return (
        <>
            <TableCell>{name}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{department}</TableCell>
            <TableCell>{getAffiliationTranslation(affiliation)}</TableCell>
            <TableCell>{t(role)}</TableCell>
        </>
    );
};
