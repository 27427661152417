import {
    InputAdornment,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { maxLimit, scaleQuestionSearchLimit } from 'core/constants/maxLimit';
import React, { useEffect, useMemo, useState } from 'react';
import { apiService } from '../services/apiService';

const useStyles = makeStyles((theme) => ({
    searchDropdown: {
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    searchInput: {
        width: '300px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

export interface IAutocompleteProps<T, R> {
    url: string;
    label: string;
    stringify: (option: T) => string;
    responseMapper: (entities: R) => T[];
    onSelect?: (value: T | null) => Promise<void>;
    description?: string;
    disabled?: boolean;
}

export const PulseAutoComplete = <T, R>({
    url,
    label,
    description,
    stringify,
    responseMapper,
    onSelect,
    disabled: outsideDisabled = false,
}: IAutocompleteProps<T, R>): JSX.Element => {
    const [responseData, setResponseData] = useState<R | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<T | null>(null);
    const [disabled, setDisabled] = useState(outsideDisabled);

    useEffect(() => {
        setDisabled(outsideDisabled);
    }, [outsideDisabled]);

    useEffect(() => {
        apiService
            .get<R>(`${url}`, {
                params: {
                    limit: scaleQuestionSearchLimit,
                },
            })
            .then(({ data }) => setResponseData(data));
    }, []);

    const options = useMemo(
        () => (!responseData ? [] : responseMapper(responseData)),
        [responseMapper, responseData]
    );

    const classes = useStyles();

    return (
        <div className={classes.searchDropdown}>
            <Autocomplete
                options={options}
                filterOptions={createFilterOptions<T>({
                    stringify,
                })}
                onInputChange={(_e, newInputValue) =>
                    setInputValue(newInputValue)
                }
                disabled={disabled}
                onChange={async (_e, value) => {
                    setDisabled(true);
                    if (value) {
                        setInputValue(stringify(value));
                    }
                    onSelect && (await onSelect(value));
                    if (value) {
                        setValue(null);
                        setInputValue('');
                    }
                    setDisabled(false);
                }}
                getOptionLabel={stringify}
                className={classes.searchInput}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                value={value}
                inputValue={inputValue}
            />
            <Typography className="MuiTypography-badge" color="textSecondary">
                {description}
            </Typography>
        </div>
    );
};
