import { FrequencyValue } from 'modules/pulse-schedules/enums/frequency-value';
import {
    createPulseScheduleCommand,
    createPulseScheduleFromResponse,
    IPulseScheduleDetails,
    IPulseScheduleResponse,
    IPulseScheduleResponseDetails,
} from 'modules/pulse-schedules/models/pulse-schedule.model';
import { IEntity } from '../../../core/interfaces/entity.interface';

export interface IPulseDetails
    extends Pick<IPulseScheduleDetails, 'frequency'> {
    title: string;
    name: string;
    fontAwesomeIcon: string;
    daysToRespond: number;
    reminder: number;
    archived: boolean;
    languageId?: string;
    projectId: string | null;
    scheduleId?: string;
    numberOfParticipants?: number;
    numberOfQuestions?: number;
    lastPulseEvent?: string;
    nextPulseEvent?: string;
    startDate: string | null;
    active?: boolean;
    allowResponseAfterExpiration?: boolean;
}
export interface IPulse extends IEntity, IPulseDetails {}

export interface IPulseResponseDetails {
    title: string;
    name: string;
    font_awesome_icon: string;
    days_to_respond: number;
    reminder: null | number;
    language?: IEntity<number>;
    project?: IEntity<number>;
    archived: boolean;
    allow_response_after_expiration?: boolean;

}

export interface IPulseCommand extends IPulseResponseDetails {
    schedule?: IPulseScheduleResponseDetails;
}

export interface IPulseResponse extends IEntity<number>, IPulseResponseDetails {
    schedule?: IPulseScheduleResponse;
    number_of_participants?: number;
    number_of_questions?: number;
    last_pulse_event?: string;
    next_pulse?: string;
    active?: boolean;
    allow_response_after_expiration?: boolean;

}

export const emptyPulse: IPulseDetails = {
    title: '',
    name: '',
    fontAwesomeIcon: '',
    daysToRespond: 3, // default value
    reminder: 1, // default value
    archived: false,
    projectId: null,
    frequency: FrequencyValue.NONE,
    startDate: null,
};

export const createPulse = ({ id, ...props }: Partial<IPulse>): IPulse => {
    if (!id) {
        throw new Error('Unable to create a user without ID!');
    }

    return {
        id,
        ...emptyPulse,
        ...props,
    };
};

export const createPulseFromResponse = ({
    id,
    language,
    project,
    font_awesome_icon,
    days_to_respond,
    number_of_participants,
    number_of_questions,
    last_pulse_event,
    next_pulse,
    schedule,
    reminder,
    active,
    allow_response_after_expiration,
    ...props
}: IPulseResponse): IPulse => {
    let scheduleDetails: Pick<
        IPulseDetails,
        'frequency' | 'startDate' | 'scheduleId'
    > = {
        frequency: FrequencyValue.NONE,
        startDate: null,
    };

    if (schedule) {
        const { id, ...details } = createPulseScheduleFromResponse(schedule);

        scheduleDetails = details;
    }

    return createPulse({
        id: id.toString(),
        languageId: language?.id.toString() ?? emptyPulse.languageId,
        projectId: project?.id.toString() ?? emptyPulse.projectId,
        fontAwesomeIcon: font_awesome_icon,
        daysToRespond: days_to_respond,
        numberOfParticipants: number_of_participants,
        numberOfQuestions: number_of_questions,
        lastPulseEvent: last_pulse_event,
        nextPulseEvent: next_pulse,
        reminder: reminder || 1,
        active: active,
        allowResponseAfterExpiration: allow_response_after_expiration,
        ...scheduleDetails,
        ...props,
    });
};

export const createPulseCommand = ({
    languageId,
    projectId,
    daysToRespond,
    fontAwesomeIcon,
    numberOfParticipants,
    numberOfQuestions,
    lastPulseEvent,
    nextPulseEvent,
    scheduleId,
    startDate,
    reminder,
    frequency,
    ...props
}: IPulseDetails): IPulseCommand => {
    const schedule = startDate
        ? createPulseScheduleCommand({ startDate, frequency })
        : undefined;

    return {
        language: !languageId ? undefined : { id: +languageId },
        project: !projectId ? undefined : { id: +projectId },
        days_to_respond: daysToRespond,
        font_awesome_icon: fontAwesomeIcon,
        reminder: reminder < 2 ? null : reminder,
        schedule,
        ...props,
    };
};
