import { makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';

interface ListActionIconProps {
    iconComponent: JSX.Element;
    color?: 'primary' | 'secondary' | 'default';
}

const useStyles = makeStyles((theme: Theme) => ({
    iconStyle: {
        fontSize: 18,
    },
    cursorPointer: {
        cursor: 'pointer',
    },
}));

export const ListActionIcon: FC<ListActionIconProps> = ({
    iconComponent,
    color = 'primary',
}) => {
    const classes = useStyles();
    return React.cloneElement(iconComponent, {
        className: `${classes.iconStyle}`,
        color,
    });
};
