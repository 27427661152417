import { Box, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    IBrandingSettings,
    IStyleProps,
} from 'modules/pulse-answering/models/feedback-loop-models';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
    },
    progress: {
        backgroundColor: theme.palette.secondary.main,
        '& > .MuiLinearProgress-barColorPrimary': {
            backgroundColor: (props: IStyleProps) => props.primaryColor,
        },
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight: '64px',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(1),
            maxHeight: '44px',
        },
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(3),
        background: (props: IStyleProps) => props.headerBackground,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
            justifyContent: 'space-between',
        },
    },
}));

export interface SurveyHeaderProps {
    progressBarValue?: number;
    showProgressBar?: boolean;
    brandingSettings: IBrandingSettings;
}

export default function SurveyHeader({
    progressBarValue,
    showProgressBar = true,
    brandingSettings,
}: SurveyHeaderProps) {
    const props = {
        primaryColor: `#${brandingSettings.primary_color.replace('#', '')}`,
        headerBackground: `#${brandingSettings.header_background_color.replace(
            '#',
            ''
        )}`,
        actionText: `#${brandingSettings.action_text_color.replace('#', '')}`,
    };

    const classes = useStyles(props);
    return (
        <Box className={classes.root}>
            <Box className={classes.container}>
                <img
                    src={brandingSettings?.logo_link || ''}
                    alt=""
                    className={classes.logo}
                />
                <Typography variant="h5">
                    {brandingSettings?.project_name || ''}
                </Typography>
            </Box>
            {showProgressBar && (
                <LinearProgress
                    className={classes.progress}
                    variant="determinate"
                    value={progressBarValue || 0}
                />
            )}
        </Box>
    );
}
