import { Box } from '@material-ui/core';
import { ActivityDialogProvider } from 'modules/activity/components/ActivityDialogProvider';
import { EntityListPagination } from '../../../core/components/EntityListPagination';
import { WithCurrentPageListener } from '../../../core/hocs/WithListContainer';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { UserFormProvider } from '../components/UserFormProvider';
import { UserList } from '../components/UserList';
import { usersQuery } from '../state/users.query';

const UsersPage = () => (
    <Box>
        <ActivityDialogProvider>
            <UserFormProvider>
                <UserList />
            </UserFormProvider>
        </ActivityDialogProvider>
        <EntityListPagination />
    </Box>
);

export const UsersPageContainer = WithProvider(
    WithCurrentPageListener(UsersPage),
    usersQuery
);
