const usePulseQuestions = (
    arrayOfData: Record<string, unknown>[]
): string[] => {
    const questionNameSet = new Set<string>();

    arrayOfData.forEach((questions) =>
        Object.keys(questions).forEach((questionName) =>
            questionNameSet.add(questionName)
        )
    );

    return Array.from(questionNameSet);
};

export default usePulseQuestions;
