import { WithProvider } from 'core/hocs/WithProvider';
import { apiService } from 'core/services/apiService';
import { emailValidation, requiredField } from 'core/validations';
import { FormikProvider, useFormik } from 'formik';
import { IParticipantResponse } from 'modules/participants/models/participant.model';
import React, { createContext, FC, useCallback, useState } from 'react';
import * as Yup from 'yup';
import {
    emptyPulseParticipantData,
    IPulseParticipant,
    IPulseParticipantDetails,
} from '../models/pulse-participant.model';
import { pulseParticipantsQuery } from '../state/pulse-participants.query';

interface IPulseParticipantDialogContext {
    participantId: string | null;
    isOpen: boolean;
    close: () => void;
    open: (participant: IPulseParticipant) => void;
    openDeleteModal: (participant: IPulseParticipant) => void;
    deleteModalOpen: boolean;
    deleteItem: () => void;
    closeDeleteModal: () => void;
}

export const PulseParticipantFormContext = createContext<IPulseParticipantDialogContext>(
    {} as IPulseParticipantDialogContext
);

const validationSchema = Yup.object().shape({
    name: requiredField,
    email: emailValidation,
    affiliation: requiredField,
    role: requiredField,
});

interface IPulseParticipantFormProviderProps {
    pulseId: string;
    onChange?: () => Promise<any> | any;
}

export const PulseParticipantFormProvider: FC<IPulseParticipantFormProviderProps> = WithProvider(
    ({ pulseId, onChange, children }) => {
        const [isOpen, setIsOpen] = useState(false);
        const [deleteModalOpen, setDeleteModalOpen] = useState(false);
        const [participantId, setParticipantId] = useState<string | null>(null);
        const [
            initialValues,
            setInitialValues,
        ] = useState<IPulseParticipantDetails>({
            ...emptyPulseParticipantData,
        });

        const close = () => {
            setIsOpen(false);
        };

        const open = ({
            id,
            respondentId,
            ...details
        }: Partial<IPulseParticipant>) => {
            setIsOpen(true);
            setParticipantId(respondentId ?? null);
            setInitialValues({ ...emptyPulseParticipantData, ...details });
        };

        const openDeleteModal = ({ respondentId }: IPulseParticipant) => {
            setDeleteModalOpen(true);
            setParticipantId(respondentId ?? null);
        };

        const closeDeleteModal = () => {
            setDeleteModalOpen(false);
        };

        const deleteItem = useCallback(async () => {
            if (participantId) {
                await apiService.delete(
                    `/pulses/${pulseId}/respondents/${[participantId]}`
                );

                onChange && (await onChange());

                setDeleteModalOpen(false);
            }
        }, [pulseId, participantId, onChange]);

        const onSubmit = useCallback(
            async ({
                affiliation,
                role,
                name,
                department,
                email,
            }: IPulseParticipantDetails) => {
                try {
                    /**
                     * Due to time constrictions, there is not a dedicated endpoint to update pulse participant.
                     * So the endpoiint to update respondents is used, and by passing the pulseId the BE knows to update the participant
                     */
                    await apiService.put<IParticipantResponse>(
                        `/respondents/${participantId}`,
                        {
                            defaultAffiliation: affiliation,
                            defaultRole: role,
                            name,
                            department,
                            pulseId,
                            email,
                        }
                    );

                    onChange && onChange();
                } catch (e: any) {
                    console.error(e);
                }

                close();
            },
            [pulseId, participantId, onChange]
        );

        const formik = useFormik<IPulseParticipantDetails>({
            initialValues,
            enableReinitialize: true,
            onSubmit,
            validationSchema,
        });

        return (
            <PulseParticipantFormContext.Provider
                value={{
                    isOpen,
                    close,
                    open,
                    participantId,
                    openDeleteModal,
                    deleteModalOpen,
                    deleteItem,
                    closeDeleteModal,
                }}
            >
                <FormikProvider value={formik}>{children}</FormikProvider>
            </PulseParticipantFormContext.Provider>
        );
    },
    pulseParticipantsQuery
);
