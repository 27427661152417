import { ListHeaderCell } from 'core/components/shared/ListHeaderCell';
import {
    primaryOrderDirection,
    secundaryOrderDirection,
} from 'core/constants/default-order-properties';
import { useCurrentPage } from 'core/hooks/current-page.hook';
import { useOrder } from 'core/hooks/order.hook';
import React, { FC, useMemo } from 'react';

export interface IEntityListHeaderCellProps {
    label: string;
    sort?: string;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
}

export const EntityListHeaderTemplate: FC<{
    [key: string]: IEntityListHeaderCellProps;
}> = (props) => {
    const { orderBy, orderDirection } = useOrder();
    const { setOrderBy, setOrderDirection } = useCurrentPage();

    const handleSortByChange = (value: string) => {
        if (value === orderBy) {
            switch (orderDirection) {
                case primaryOrderDirection:
                    setOrderDirection(secundaryOrderDirection);
                    break;
                case secundaryOrderDirection:
                    setOrderBy('');
                    break;
                default:
                    setOrderDirection(primaryOrderDirection);
                    break;
            }
        } else {
            setOrderBy(value);
        }
    };

    const cellsProps = useMemo(
        () =>
            Object.keys(props).map((fieldName, index) => {
                const cellProps = props[fieldName];
                return cellProps.sort ? (
                    <ListHeaderCell
                        key={index}
                        label={cellProps?.label}
                        sortBy={cellProps?.sort}
                        align={cellProps?.align}
                        activateSort={handleSortByChange}
                        direction={
                            cellProps?.sort === orderBy ? orderDirection : null
                        }
                    />
                ) : (
                    <ListHeaderCell
                        key={index}
                        label={cellProps?.label}
                        align={cellProps?.align}
                    />
                );
            }),
        [props, handleSortByChange, orderBy, orderDirection]
    );

    return <>{cellsProps}</>;
};
