import {
    Box,
    Button,
    Card,
    CardContent,
    Tooltip,
    Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import TimerIcon from '@material-ui/icons/Timer';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useRemindOn } from 'core/hooks/shared/remind-on.hook';
import { useLanguages } from 'modules/languages/hooks/languages.hook';
import React, { FC, useContext, useMemo } from 'react';
import { PulseCardScheduleSection } from '../../pulse-schedules/components/ScheduleSection';
import { usePulseCardStyles } from '../hooks/pulse-card-styles.hook';
import { IPulse } from '../models/pulse.model';

interface IPulseDetailsCardProps {
    pulse: IPulse;
    onEditClick: () => void;
}

export const PulseDetailsCard: FC<IPulseDetailsCardProps> = ({
    pulse,
    onEditClick,
}) => {
    const classes = usePulseCardStyles();
    const { t } = useContext(MultiLanguageSupportContext);

    const [languages] = useLanguages();

    const languageName = useMemo(
        () => languages.find(({ id }) => pulse.languageId === id)?.name ?? '',
        [pulse, languages]
    );

    const daysToRemindOn = useRemindOn(pulse.daysToRespond);

    return (
        <Card className={classes.card}>
            <Box className={classes.header}>
                <div>
                    <Typography variant="h5">{t('pulse-details')}</Typography>
                </div>
                <div>
                    <Tooltip title={t('tooltip-edit')}>
                        <Button
                            variant="text"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={onEditClick}
                        >
                            {t('edit-pulse')}
                        </Button>
                    </Tooltip>
                </div>
            </Box>
            <CardContent>
                <PulseCardScheduleSection {...pulse} />

                <Box className={classes.info}>
                    <div>
                        <LocalLibraryIcon className={classes.icon} />
                    </div>
                    <div>
                        <Typography variant="h6" className={classes.span}>
                            {languageName}
                        </Typography>
                    </div>
                </Box>
                <Box className={classes.info}>
                    <div>
                        <TimerIcon className={classes.icon} />
                    </div>
                    <div>
                        <Typography variant="h6" className={classes.span}>
                            {`${pulse.daysToRespond} `}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.span}
                            color="textSecondary"
                        >
                            {t('days-to-respond', {
                                count: pulse.daysToRespond,
                            })}
                        </Typography>

                        <Typography
                            variant="body2"
                            className={classes.span}
                            color="textSecondary"
                        >
                            ,&nbsp; {t(pulse.allowResponseAfterExpiration ? 'reply-is-allowed' : 'reply-is-not-allowed')}
                        </Typography>
                        <Typography
                            className="MuiTypography-badge"
                            color="textSecondary"
                        >
                            {`${t('reminder')}: ${
                                daysToRemindOn[pulse.reminder - 1]
                            }`}
                        </Typography>
                    </div>
                </Box>
            </CardContent>
        </Card>
    );
};
