import { PulseDistributionGraphProps } from '../components/Graphs/PulseDistributionGraph';
import {
    IGraphData,
    PulseDistributionGraphRanges,
} from '../models/dashboard.models';
import { getPulseDistributionOptions } from './getPulseDistributionOptions';

export const getPulseDistributionGraphProps = (
    categories: string[],
    pulseDistributionMap = new Map<
        string,
        Record<PulseDistributionGraphRanges, number[]>
    >()
): PulseDistributionGraphProps => {
    const chartData: IGraphData<
        string | null,
        PulseDistributionGraphRanges
    >[] = [
        {
            name: '4,5-5',
            data: [],
        },
        {
            name: '3,5-4',
            data: [],
        },
        {
            name: '2,5-3',
            data: [],
        },
        {
            name: '1,5-2',
            data: [],
        },
        {
            name: '0-1',
            data: [],
        },
    ];

    categories?.forEach((questionName) => {
        const value = pulseDistributionMap.get(questionName);

        if (value) {
            const sum = Object.values(value).reduce(
                (tempSum, rangeValues) => tempSum + rangeValues.length,
                0
            );

            chartData.forEach(({ name, data }) => {
                const rangeValues = value[name];

                data.push(
                    !rangeValues?.length
                        ? null
                        : ((100 * rangeValues.length) / sum).toFixed(1)
                );
            });
        } else {
            chartData.forEach(({ data }) => {
                data.push(null);
            });
        }
    });

    return {
        data: chartData,
        options: getPulseDistributionOptions({
            xaxis: { categories },
        }),
    };
};
