import { Card, CardContent } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import React, { FC, useContext, useMemo } from 'react';
import { ValidTranslationKeys } from 'react-i18next';
import css from 'styles/material-ui/cssVars';
import theme from 'styles/material-ui/theme';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, index, ...other } = props;

    return (
        <Box
            p={2}
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {children}
        </Box>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        boxShadow: 'none',
        '&:hover $action': {
            opacity: 1,
        },
    },
    cardHeaderTabs: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tabs: {
        margin: `0 ${theme.spacing(6)}px`,
        '& .MuiTab--root': {
            margin: 0,
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0',
        },
    },
    tab: {
        textTransform: 'capitalize',
        padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    },
    action: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },
}));

export interface IPageTabsPropsItem {
    headerLabel: keyof ValidTranslationKeys;
    table?: JSX.Element;
}

interface IPageTabsProps {
    items: IPageTabsPropsItem[];
    initialTabIndex?: number;
}

export const PageTabs: FC<IPageTabsProps> = ({
    items,
    initialTabIndex = 0,
}) => {
    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);
    const [activeTabIndex, setActiveTabIndex] = React.useState(initialTabIndex);

    const handleChange = (
        event: any,
        selectedTabIndex: React.SetStateAction<number>
    ) => {
        setActiveTabIndex(selectedTabIndex);
    };

    const activeTabPanel = useMemo(() => {
        const item = items[activeTabIndex];

        if (!item) {
            return null;
        }

        return <TabPanel index={activeTabIndex}>{item.table}</TabPanel>;
    }, [activeTabIndex, items, classes]);

    return (
        <Card className={classes.card}>
            <Box className={classes.cardHeaderTabs}>
                <Tabs
                    value={activeTabIndex}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    scrollButtons="off"
                    className={classes.tabs}
                    TabIndicatorProps={{
                        style: { backgroundColor: theme.palette.primary.main },
                    }}
                >
                    {items.map(({ headerLabel }, index) => (
                        <Tab
                            className={classes.tab}
                            key={index}
                            label={t(headerLabel)}
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </Box>

            <CardContent>{activeTabPanel}</CardContent>
        </Card>
    );
};
