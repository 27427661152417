import { useObservable } from '@mindspace-io/react';
import { numberOfPages } from '@qagency/react-shared-library';
import { useContext, useEffect, useState } from 'react';
import { EntitiesContext } from '../constants/entities.context';
import { IEntity } from '../interfaces/entity.interface';
import { useCurrentPageEntities } from './current-page-entities.hook';
import { useCurrentPage } from './current-page.hook';
import { usePagination } from './pagination.hook';

const canHighlightPreviousValidator = <T extends IEntity>(
    currentPageEntities: T[],
    highlighted: T,
    currentPageNumber: number
) => currentPageNumber !== 1 || currentPageEntities[0].id !== highlighted.id;

const canHighlightNextValidator = <T extends IEntity>(
    currentPageEntities: T[],
    highlighted: T,
    currentPageNumber: number,
    lastPageNumber: number
) =>
    currentPageNumber !== lastPageNumber ||
    currentPageEntities[currentPageEntities.length - 1].id !== highlighted.id;

export interface IUseHighlight<T> {
    highlighted: T;
    canHighlightPrevious: boolean;
    canHighlightNext: boolean;
    highlight: (id: string) => void;
    highlightPrevious: () => void;
    highlightNext: () => void;
    unhighlight: () => void;
}

export const useHighlight = () => {
    const { query, store } = useContext(EntitiesContext);

    const { currentPageEntities } = useCurrentPageEntities();
    const {
        currentPageNumber,
        currentPageSize,
        totalNumberOfEntities,
    } = usePagination();
    const { setPageNumber } = useCurrentPage();

    const [highlighted] = useObservable(
        query.selectHighlighted(),
        query.getHighlighted()
    );

    const [canHighlightPrevious, setCanHighlightPrevious] = useState(
        !highlighted
            ? false
            : canHighlightPreviousValidator(
                  currentPageEntities,
                  highlighted,
                  currentPageNumber
              )
    );

    useEffect(
        () =>
            setCanHighlightPrevious(
                !highlighted
                    ? false
                    : canHighlightPreviousValidator(
                          currentPageEntities,
                          highlighted,
                          currentPageNumber
                      )
            ),
        [currentPageEntities, highlighted, currentPageNumber]
    );

    const [canHighlightNext, setCanHighlightNext] = useState(
        !totalNumberOfEntities || !highlighted
            ? false
            : canHighlightNextValidator(
                  currentPageEntities,
                  highlighted,
                  currentPageNumber,
                  numberOfPages(totalNumberOfEntities, currentPageSize)
              )
    );

    useEffect(
        () =>
            setCanHighlightNext(
                !totalNumberOfEntities || !highlighted
                    ? false
                    : canHighlightNextValidator(
                          currentPageEntities,
                          highlighted,
                          currentPageNumber,
                          numberOfPages(totalNumberOfEntities, currentPageSize)
                      )
            ),
        [
            currentPageEntities,
            highlighted,
            currentPageNumber,
            totalNumberOfEntities,
            currentPageSize,
        ]
    );

    return {
        highlighted,
        canHighlightPrevious,
        canHighlightNext,
        highlight: (id: string) => {
            store.setHighlight(id);
        },
        highlightPrevious: () => {
            const entity = query.getHighlighted();

            if (!entity) {
                throw new Error(
                    'There is no highlighted entity to find previous from. '
                );
            }

            const {
                currentPageIds,
                ui: { pageNumber },
            } = query.getValue();

            const index = currentPageIds.indexOf(entity.id);

            switch (index) {
                case -1:
                    store.setHighlight();
                    break;
                case 0:
                    if (pageNumber !== 1) {
                        store.update({
                            highlightNext: 'last',
                        });
                        setPageNumber(pageNumber - 1);
                    } else {
                        store.setHighlight();
                    }
                    break;
                default:
                    store.setHighlight(currentPageIds[index - 1]);
                    break;
            }
        },

        highlightNext: () => {
            const highlight = query.getHighlighted();

            if (!highlight) {
                throw new Error('There is no highlight to find next from. ');
            }

            const {
                currentPageIds,
                ui: { pageNumber },
            } = query.getValue();

            const {
                total,
                ui: { pageSize },
            } = store.getValue();

            const index = currentPageIds.indexOf(highlight.id);

            switch (index) {
                case -1:
                    store.setHighlight();
                    break;
                case currentPageIds.length - 1:
                    if (
                        total &&
                        pageNumber !== numberOfPages(total, pageSize)
                    ) {
                        store.update({
                            highlightNext: 'first',
                        });
                        setPageNumber(pageNumber + 1);
                    } else {
                        store.setHighlight();
                    }
                    break;
                default:
                    store.setHighlight(currentPageIds[index + 1]);
                    break;
            }
        },

        unhighlight: () => {
            store.setHighlight();
        },
    };
};
