import { Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ListActionIcon } from 'core/components/shared/ListActionIcon';
import { WithProvider } from 'core/hocs/WithProvider';
import { IEntityActionProps } from 'core/interfaces/entity-action.interface';
import { FC, useCallback, useContext } from 'react';
import { EntityListActions } from '../../../core/components/EntityListActions';
import {
    IComponentWrappedWithEntityByIdProps,
    WithEntityById,
} from '../../../core/hocs/WithEntityById';
import { IParticipant } from '../models/participant.model';
import { participantsQuery } from '../state/participants.query';
import { ParticipantFormContext } from './ParticipantFormProvider';

export const ParticipantListActions: FC<
    IComponentWrappedWithEntityByIdProps<IParticipant>
> = ({ entity: participant, showDeleteAction }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { open, openDeleteModal } = useContext(ParticipantFormContext);

    const getActions = useCallback(() => {
        const toReturn: IEntityActionProps[] = [
            {
                callback: () => open(participant),
                component: (
                    <ListActionIcon
                        iconComponent={
                            <Tooltip title={t('tooltip-edit')}>
                                <EditIcon />
                            </Tooltip>
                        }
                    ></ListActionIcon>
                ),
            },
        ];

        if (showDeleteAction) {
            toReturn.push({
                callback: () => openDeleteModal(participant),
                component: (
                    <ListActionIcon
                        iconComponent={
                            <Tooltip title={t('tooltip-delete')}>
                                <DeleteIcon />
                            </Tooltip>
                        }
                    />
                ),
            });
        }

        return toReturn;
    }, [participant, open]);

    return <EntityListActions actions={getActions()} />;
};

export const ParticipantListActionsById = WithProvider(
    WithEntityById(ParticipantListActions),
    participantsQuery
);
