import { subject } from '@casl/ability';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RestoreIcon from '@material-ui/icons/Restore';
import { ListActionIcon } from 'core/components/shared/ListActionIcon';
import { useSession } from 'core/hooks/session.hook';
import { ActivityDialogContext } from 'modules/activity/components/ActivityDialogProvider';
import React, { FC, useCallback, useContext } from 'react';
import { EntityListActions } from '../../../core/components/EntityListActions';
import {
    IComponentWrappedWithEntityByIdProps,
    WithEntityById,
} from '../../../core/hocs/WithEntityById';
import { useCrud } from '../../../core/hooks/crud.hook';
import { createUser, IUser } from '../models/user.model';
import { UserFormContext } from './UserFormProvider';

export const UserListActions: FC<
    IComponentWrappedWithEntityByIdProps<IUser>
> = ({ entity: user }) => {
    const { abilities } = useSession();

    const { open } = useContext(UserFormContext);
    const { openActivity } = useContext(ActivityDialogContext);

    const { deleteMultiple } = useCrud();

    const deleteItem = () =>
        deleteMultiple([user.id], {
            shouldFetchAfterSuccess: true,
        });

    const getActions = useCallback(() => {
        const actions = [];

        if (abilities.can('update', subject('IUser', createUser(user)))) {
            actions.push({
                callback: async () =>
                    open(user, {
                        shouldFetchAfterSuccess: true,
                    }),
                component: <ListActionIcon iconComponent={<EditIcon />} />,
            });
        }

        if (abilities.can('delete', subject('IUser', createUser(user)))) {
            actions.push({
                callback: deleteItem,
                component: <ListActionIcon iconComponent={<DeleteIcon />} />,
            });
        }

        if (
            abilities.can('viewActivityOf', subject('IUser', createUser(user)))
        ) {
            actions.push({
                callback: async () => openActivity(user),
                component: <ListActionIcon iconComponent={<RestoreIcon />} />,
            });
        }

        return actions;
    }, [user, abilities]);

    return <EntityListActions actions={getActions()} />;
};

export const UserListActionsById = WithEntityById(UserListActions);
