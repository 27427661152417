import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { requiredField } from 'core/validations';
import { FormikProvider, useFormik } from 'formik';
import React, { createContext, FC, useCallback, useState } from 'react';
import * as Yup from 'yup';
import {
    emptyAccount,
    IAccount,
    IAccountDetails,
} from '../models/account.model';
import { accountsQuery } from '../state/accounts.query';

export interface IAccountFormik extends IAccountDetails {
    openNewAccount?: boolean;
}
interface IAccountDialogContext {
    accountId: string | null;
    isOpen: boolean;
    close: () => void;
    open: (account: Partial<IAccount>) => void;
}

export const AccountDialogContext = createContext<IAccountDialogContext>(
    {} as IAccountDialogContext
);

const validationSchema = Yup.object().shape({
    name: requiredField,
    actionTextColor: requiredField,
    headerBackgroundColor: requiredField,
    primaryColor: requiredField,
});

export const AccountDialogProvider: FC = WithProvider(({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [accountId, setAccountId] = useState<string | null>(null);
    const [initialValues, setInitialValues] = useState<IAccountFormik>({
        ...emptyAccount,
    });

    const close = () => {
        setIsOpen(false);
    };

    const open = ({ id, ...details }: Partial<IAccount>) => {
        setIsOpen(true);
        setAccountId(id ?? null);
        setInitialValues({ ...emptyAccount, ...details });
    };

    const { createSingle, updateSingle } = useCrud<IAccount>();

    const onSubmit = useCallback(
        async ({ openNewAccount, ...data }: IAccountFormik) => {
            const account = accountId
                ? await updateSingle(
                      { id: accountId, ...data },
                      {
                          shouldFetchAfterSuccess: true,
                      }
                  )
                : await createSingle(data, {
                      shouldFetchAfterSuccess: true,
                  });

            if (account) {
                const { id, ...details } = account;
                setInitialValues(details);
                setAccountId(id);
                if (!openNewAccount) {
                    close();
                } else {
                    open({});
                }
            }

            return account;
        },
        [accountId]
    );

    const formik = useFormik<IAccountFormik>({
        initialValues,
        enableReinitialize: true,
        validateOnMount: !!accountId,
        onSubmit,
        validationSchema,
    });

    return (
        <AccountDialogContext.Provider
            value={{
                isOpen,
                close,
                open,
                accountId,
            }}
        >
            <FormikProvider value={formik}>{children}</FormikProvider>
        </AccountDialogContext.Provider>
    );
}, accountsQuery);
