import {
    Box,
    Container,
    Grid,
    List,
    ListItem,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import {
    IObjectToPostProps,
    ISurveyPayload,
    ISurveyPayloadFactorAnswers,
} from 'core/interfaces/survey-answering.interface';
import { FactorIcon } from 'modules/success-factors/components/FactorIcon';
import { FactorValue } from 'modules/success-factors/interfaces/FactorValue';
import { IFactorDetails } from 'modules/success-factors/models/success-factor.model';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import css from 'styles/material-ui/cssVars';
import { filterTranslatesBuilder } from './filterTranslatesBuilderHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        '& .MuiRating-root.Mui-disabled': {
            opacity: '1',
        },
    },
    overViewContainer: {
        maxWidth: '864px',
        marginBottom: theme.spacing(15),
        borderRadius: theme.spacing(0.5),
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.common.white,
    },
    stepIntro: {
        padding: '48px 0',
        [theme.breakpoints.down('xs')]: {
            padding: '24px 0',
        },
    },
    stepHeading: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
            lineHeight: '24px',
        },
    },
    ratingBox: {
        width: '450px',
        padding: `${theme.spacing(1)}px 0`,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    factorsContainer: {
        marginBottom: theme.spacing(4),
    },
    factorBox: {
        borderRadius: theme.spacing(0.5),
        border: `1px solid ${theme.palette.divider}`,
        '&.f-great': {
            '& $factorHeading': {
                backgroundColor: '#E8FDF2',
                color: '#006B47',
            },
        },
        '&.f-neutral': {
            '& $factorHeading': {
                backgroundColor: css.extraColors.text.darkPrimary,
            },
        },
        '&.f-improve': {
            '& $factorHeading': {
                backgroundColor: '#FFEBE6',
                color: '#B22400',
            },
        },
    },
    factorHeading: {
        padding: theme.spacing(2),
    },
    factorListItem: {
        padding: theme.spacing(1.5),
        '& svg': {
            marginRight: theme.spacing(2),
        },
    },
    feedbackBox: {
        borderRadius: theme.spacing(0.5),
        backgroundColor: css.extraColors.text.darkPrimary,
        padding: theme.spacing(2),
    },
    ratingScore: {
        marginLeft: '15px',

        svg: {
            width: '32px',
            height: '32px',
        },
    },
}));

interface ISurveyOverviewFactorsProps
    extends Partial<Pick<ISurveyPayload, 'factor_answers'>> {
    value: FactorValue;
    label: string;
    surveyFactorsMap: Map<string, IFactorDetails>;
}

const SurveyOverviewFactors: FC<ISurveyOverviewFactorsProps> = ({
    factor_answers,
    value,
    label,
    surveyFactorsMap,
}) => {
    const classes = useStyles();

    const className = useMemo(
        () => (value === 'great' ? 'f-great' : 'f-improve'),
        [value]
    );

    const factorAnswersMapper = useCallback(
        ({ factor: { id } }: ISurveyPayloadFactorAnswers) => {
            const factor = surveyFactorsMap.get(id.toString());

            return !factor ? (
                <></>
            ) : (
                <ListItem key={id} className={classes.factorListItem}>
                    <FactorIcon {...factor} />
                    <Typography variant="body2">{factor.title}</Typography>
                </ListItem>
            );
        },
        [surveyFactorsMap]
    );

    return (
        <Grid item xs={12} sm={6}>
            <Box className={`${classes.factorBox} ${className}`}>
                <Box className={classes.factorHeading}>
                    <Typography variant="overline">{label}</Typography>
                </Box>
                <List>
                    {factor_answers
                        ?.filter((item) => item.value?.toLowerCase() === value)
                        .map(factorAnswersMapper)}
                </List>
            </Box>
        </Grid>
    );
};

export const SurveyOverview: FC<IObjectToPostProps> = ({
    objectToPost,
    surveyQuestions,
    surveyFactors,
    surveyLabelTranslations,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);

    const classes = useStyles();

    const filterTranslates = filterTranslatesBuilder(surveyLabelTranslations);

    const surveyFactorsMap = useMemo(
        () =>
            surveyFactors.reduce(
                (map, { id, ...details }) => map.set(id, details),
                new Map<string, IFactorDetails>()
            ),
        [surveyFactors]
    );

    return (
        <Box className={classes.root}>
            <Container>
                <Box className={classes.stepIntro}>
                    <Typography
                        variant="h2"
                        align="center"
                        className={classes.stepHeading}
                    >
                        {filterTranslates(
                            'overviewTitle',
                            t('your-answers-have-been-registered')
                        )}
                    </Typography>
                </Box>

                <Container className={classes.overViewContainer}>
                    <Box py={3} display="flex" justifyContent="center">
                        <Typography variant="overline" color="textSecondary">
                            {filterTranslates(
                                'overviewSubtitle',
                                t('your-answers-summary')
                            )}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        mb={4}
                    >
                        {objectToPost?.question_answers.map((item, idx) => (
                            <Box
                                className={classes.ratingBox}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                key={idx}
                            >
                                <Typography variant="body2">
                                    {surveyQuestions
                                        .filter(
                                            ({ id }) =>
                                                id === item.scale_question.id
                                        )
                                        .map(({ translated_name }, idxx) => (
                                            <div key={idxx}>
                                                {translated_name}
                                            </div>
                                        ))}
                                </Typography>
                                <Rating
                                    className={classes.ratingScore}
                                    key={idx}
                                    value={item.value / 2}
                                    disabled
                                    precision={0.5}
                                />
                            </Box>
                        ))}
                    </Box>
                    {objectToPost && objectToPost.factor_answers.length > 0 ? (
                        <Grid
                            container
                            spacing={2}
                            className={classes.factorsContainer}
                        >
                            <SurveyOverviewFactors
                                {...objectToPost}
                                label={filterTranslates(
                                    'overviewGreat',
                                    t('overview-great')
                                )}
                                value="great"
                                surveyFactorsMap={surveyFactorsMap}
                            />

                            <SurveyOverviewFactors
                                {...objectToPost}
                                label={filterTranslates(
                                    'overviewImprove',
                                    t('overview-improve')
                                )}
                                value="improvable"
                                surveyFactorsMap={surveyFactorsMap}
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}

                    <Box className={classes.feedbackBox} mb={3}>
                        <Typography variant="overline" color="textSecondary">
                            {filterTranslates(
                                'overviewSuggestions',
                                t('your-feedback-and-suggestions')
                            )}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {objectToPost?.suggestion.text}
                        </Typography>
                    </Box>
                </Container>
            </Container>
        </Box>
    );
};
