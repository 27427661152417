import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { asDateString } from 'core/helpers/asDateString';
import { apiService } from 'core/services/apiService';
import { FactorValue } from 'modules/success-factors/interfaces/FactorValue';
import { FC, useCallback, useContext, useState } from 'react';
import { DimensionsProvider } from '../../categories/components/DimensionsProvider';
import { DashboardProvider } from '../components/DashboardProvider';
import { FiltersDashboard } from '../components/FiltersDashboard';
import { GraphAnalytics } from '../components/GraphAnalytics';
import { PulseAnswersDashboard } from '../components/PulseAnswersDashboard';
import { TotalsTablePortfolio } from '../components/TotalsTablePortfolio';
import { IPortfolioData } from '../models/dashboard.models';

export type IPortfolioAnswerData = Record<
    Exclude<FactorValue, 'neutral'>,
    string[]
> & {
    name: string;
    participants: number;
    responseRate: number;
    questions: Record<string, number>;
};

export const PortfolioDashboardPage: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const [queryString, setQueryString] = useState('');
    const [portfolioData, setPortfolioData] = useState<IPortfolioData>({
        success_factors: {},
        pulse_answers: {},
        projects_data: {},
        time_series_graph_data: [],
    });

    const handleQueryString = (newQueryString: string) => {
        setQueryString(newQueryString);

        apiService
            .get(`projects/portfolio?${newQueryString}`)
            .then(({ data }) => {
                setPortfolioData(data);
            });
    };

    const exportCsvExcel = useCallback(
        async (exportType: 'excel' | 'csv') => {
            await apiService
                .get<string>(
                    `/projects/portfolio/export/${exportType}?${queryString}`,
                    exportType === 'excel'
                        ? {
                              responseType: 'arraybuffer',
                          }
                        : undefined
                )
                .then((resp) => {
                    /**
                     * works for EXCEL files becouse of this: https://github.com/axios/axios/issues/895
                     * the file name logic is also picked from the link but seems to need a BE tweek: https://stackoverflow.com/questions/43912862/axios-expose-response-headers-content-disposition
                     */
                    const dirtyFileName = resp.headers['content-disposition'];
                    const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                    const fileName = dirtyFileName?.match(regex)[3] ?? 'export';

                    const file = new Blob([resp.data], {
                        type: resp.headers['content-type'] ?? 'text/plain',
                    });
                    const url = window.URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    // the filename you want
                    a.download = `${fileName}.${
                        exportType === 'excel' ? 'xls' : 'csv'
                    }`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                });
        },
        [queryString]
    );

    const endDate = new Date();
    const startDate = new Date(endDate);

    startDate.setDate(endDate.getDate() - 90);

    return (
        <DashboardProvider
            onQueryStringChange={handleQueryString}
            initialFilters={{
                startDate: asDateString(startDate, {
                    format: 'date',
                    dateOptions: { format: 'YYYY-MM-DD', separator: '-' },
                }),
                endDate: asDateString(endDate, {
                    format: 'date',
                    dateOptions: { format: 'YYYY-MM-DD', separator: '-' },
                }),
            }}
        >
            <DimensionsProvider>
                <FiltersDashboard />
            </DimensionsProvider>
            {portfolioData && <GraphAnalytics {...portfolioData} />}
            <PulseAnswersDashboard
                label={t('team-per-project')}
                exportCsvExcel={exportCsvExcel}
            >
                <TotalsTablePortfolio {...portfolioData} />
            </PulseAnswersDashboard>
        </DashboardProvider>
    );
};
