import React, { FC, useContext } from 'react';
import { useCurrentPageEntities } from '../../../core/hooks/current-page-entities.hook';
import { ILanguage } from '../models/language.model';
import {
    Box,
    Paper,
    TableBody,
    TableContainer,
    TableHead,
    Table,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { ListHeader } from '../../../core/components/shared/ListHeader';
import { EntityListHeaderTemplate } from '../../../core/components/shared/EntityListHeaderTemplate';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { ListItemContainer } from '../../../core/components/shared/ListItemContainer';
import { LanguageListItem } from './LanguageListItem';
import { LanguageActionById } from './LanguageActionById';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    projectCardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(448px, 1fr))',
        columnGap: 24,
        rowGap: 24,
    },
    filters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableBackground: {
        backgroundColor: '#fff',
    },
    searchfield: {
        marginRight: theme.spacing(4),
        width: '240px',
        [theme.breakpoints.up('lg')]: {
            width: '440px',
        },
        '& input': {
            padding: `14.5px ${theme.spacing(2)}px 14.5px 0`,
        },
    },
}));

export const LanguagesList: FC = () => {
    const { currentPageEntities } = useCurrentPageEntities<ILanguage>();
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();

    return (
        <Box width={'100%'}>
            <TableContainer
                component={Paper}
                variant="outlined"
                className={classes.tableBackground}
            >
                <Table>
                    <TableHead>
                        <ListHeader
                            renderLastColumn={() => (
                                <span style={{ visibility: 'hidden' }}>
                                    {t('actions')}
                                </span>
                            )}
                        >
                            <EntityListHeaderTemplate
                                language={{
                                    label: 'Language',
                                }}
                                locale={{
                                    label: 'Locale',
                                }}
                            />
                        </ListHeader>
                    </TableHead>
                    <TableBody>
                        {currentPageEntities.map((entity, index) => (
                            <ListItemContainer
                                key={entity.id}
                                entityId={entity.id}
                                enableHover={true}
                                renderLastColumn={(entityId: string) => (
                                    <LanguageActionById id={entityId} />
                                )}
                            >
                                <LanguageListItem
                                    entity={entity}
                                    index={index}
                                />
                            </ListItemContainer>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
