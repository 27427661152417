import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FormDialogActions } from 'core/components/shared/dialog/FormDialogActions';
import { FormDialogWrapper } from 'core/components/shared/FormDialogWrapper';
import { IEntity } from 'core/interfaces/entity.interface';
import { apiService } from 'core/services/apiService';
import { useFormikContext } from 'formik';
import { pulseParticipantsStore } from 'modules/pulse-participants/state/pulse-participants.store';
import { FC, useContext } from 'react';
import { useParams } from 'react-router';
import css from 'styles/material-ui/cssVars';
import { ParticipantForm } from './ParticipantForm';
import { ParticipantFormContext } from './ParticipantFormProvider';

const useStyles = makeStyles((theme) => ({
    modalActions: {
        position: 'relative',
        backgroundColor: css.extraColors.text.darkPrimary,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing(2)}px  ${theme.spacing(1)}px `,

            '& div:last-child': {
                marginTop: 0,
            },
        },
    },
}));

interface ParticipantFormDialogProps {
    isActive?: boolean;
    headerLabel?: string;
    handleSuccessMessage?: () => void;
}

export const ParticipantFormDialog: FC<ParticipantFormDialogProps> = ({
    isActive,
    headerLabel,
    handleSuccessMessage,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { isOpen, close, participantId } = useContext(ParticipantFormContext);
    const {
        submitForm,
        isValid,
        dirty,
        resetForm,
        values,
    } = useFormikContext();
    const { id } = useParams<IEntity>();

    const classes = useStyles();

    const triggerNewParticipant = () => {
        if (isValid) {
            apiService.post('/respondents', values).then((resp) => {
                console.log('resp', resp);
                apiService
                    .post(
                        `/pulses/${id}/respondents/${resp.data.id}?sendEmail=true`,
                        {}
                    )
                    .then(() => {
                        pulseParticipantsStore.fetchEntities();
                    });
            });

            handleSuccessMessage && handleSuccessMessage();
            close();
            resetForm();
        }
    };

    return (
        <FormDialogWrapper isOpen={isOpen} handleClose={close}>
            <DialogContent>
                <Box marginY={5}>
                    <Typography variant="h2" align="center">
                        {isActive
                            ? t('add-participant-to-pulse-in-progress')
                            : participantId
                            ? t('edit-respondent')
                            : t('create-new-participant')}
                    </Typography>
                    {isActive && (
                        <Box my={4}>
                            <Typography variant="h5">
                                {t('active-pulse')}
                            </Typography>
                            <Typography variant="h5">
                                {t('wish-to-add-participant')}
                            </Typography>
                            <Typography variant="h5">
                                {t('add-future-edit-pulse')}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <ParticipantForm participantId={participantId} />
            </DialogContent>
            <DialogActions className={classes.modalActions}>
                <FormDialogActions handleClose={close}>
                    <Button
                        style={{
                            textTransform: 'none',
                        }}
                        variant="contained"
                        disableElevation
                        disabled={!isValid || !dirty}
                        color="primary"
                        onClick={isActive ? triggerNewParticipant : submitForm}
                    >
                        {t('save')}
                    </Button>
                </FormDialogActions>
            </DialogActions>
        </FormDialogWrapper>
    );
};
