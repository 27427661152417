import { FC } from 'react';
import { TableCell } from '@material-ui/core';

interface ILanguageItemTemplate {
    name: string;
    locale: string;
}

export const LanguageListItemTemplate: FC<ILanguageItemTemplate> = ({
    name,
    locale,
}) => (
    <>
        <TableCell>{name}</TableCell>
        <TableCell>{locale}</TableCell>
    </>
);
