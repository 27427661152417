import {
    createParticipantFromResponse,
    emptyParticipantData,
    IParticipantDetails,
    IParticipantResponse,
    ParticipantAffiliation,
    ParticipantRole,
} from 'modules/participants/models/participant.model';
import { IEntity } from '../../../core/interfaces/entity.interface';

export interface IPulseParticipantDetails extends IParticipantDetails {
    role: ParticipantRole;
    affiliation: ParticipantAffiliation;
    imported: boolean;
    pulseId: string | null;
    respondentId: string | null;
    deletedAt: any;
}

export interface IPulseParticipant extends IEntity, IPulseParticipantDetails {}

export interface IPulseParticipantResponse extends IEntity<number> {
    role: ParticipantRole;
    affiliation: ParticipantAffiliation;
    imported: boolean;
    pulse: IEntity<number>;
    respondent: IParticipantResponse;
    deleted_at: any;
}

export const emptyPulseParticipantData: IPulseParticipantDetails = {
    ...emptyParticipantData,
    role: ParticipantRole.TEAM,
    affiliation: ParticipantAffiliation.ORGANIZATION,
    imported: false,
    pulseId: null,
    respondentId: null,
    deletedAt: null,
};

export const createPulseParticipant = ({
    id,
    ...props
}: Partial<IPulseParticipant>): IPulseParticipant => {
    if (!id) {
        throw new Error('Unable to create a user without an ID!');
    }

    return {
        id,
        ...emptyPulseParticipantData,
        ...props,
    };
};

export const createPulseParticipantFromResponse = ({
    id,
    deleted_at,
    pulse,
    respondent: respondentResponse,
    ...props
}: IPulseParticipantResponse): IPulseParticipant => {
    const {
        id: respondentId = null,
        ...respondentDetails
    } = !respondentResponse
        ? {}
        : createParticipantFromResponse(respondentResponse);

    return createPulseParticipant({
        ...respondentDetails,
        ...props,
        id: id.toString(),
        deletedAt: deleted_at,
        pulseId: pulse?.id.toString() ?? null,
        respondentId,
    });
};
