import { AxiosResponse } from 'axios';
import { primaryOrderDirection } from 'core/constants/default-order-properties';
import { IEntityUIState } from 'core/state/entities.store';
import { apiService, ResponseHandler } from './apiService';

interface ICrudApiServiceMethodOptions {
    slug?: string;
}

export interface ICrudApiServiceOptions<T extends D, R, D = any> {
    responseMapper: (x: R) => T;
    commandMapper?: (x: D) => any;
}

interface ICrudApiServiceSingleMethodOptions<T>
    extends ICrudApiServiceMethodOptions {
    responseHandler?: ResponseHandler<T>;
}

interface ICrudApiServiceListMethodOptions<I, O>
    extends ICrudApiServiceMethodOptions {
    responseHandler: ResponseHandler<I, O>;
}

const nonMapper = <T = any>(x: T): T => x;

export class CrudApiService<T extends D, R, D = any> {
    protected _responseMapper: (x: R) => T;
    protected _commandMapper!: (x: D) => any;

    constructor(
        protected readonly _slug: string,
        { responseMapper, commandMapper }: ICrudApiServiceOptions<T, R, D>
    ) {
        this._responseMapper = responseMapper;

        this._commandMapper = commandMapper ?? nonMapper;
    }

    async create(
        entity: D,
        {
            responseHandler = apiService.responseHandler,
            slug = this._slug,
        }: ICrudApiServiceSingleMethodOptions<R> = {
            responseHandler: apiService.responseHandler,
            slug: this._slug,
        }
    ): Promise<T> {
        const response = await apiService.post<R>(
            slug,
            this._commandMapper(entity)
        );
        return this._responseMapper(responseHandler(response));
    }

    async update(
        id: any,
        details: D,
        {
            responseHandler = apiService.responseHandler,
            slug = this._slug,
        }: ICrudApiServiceSingleMethodOptions<R> = {
            responseHandler: apiService.responseHandler,
            slug: this._slug,
        }
    ): Promise<T> {
        const response = await apiService.put<R, D>(
            `${slug}/${id}`,
            this._commandMapper(details)
        );
        return this._responseMapper(responseHandler(response));
    }

    async readAll<RA = R[]>(
        {
            searchTerm,
            pageNumber,
            pageSize,
            filter,
            orderBy,
            orderDirection,
        }: IEntityUIState,
        {
            responseHandler,
            slug = this._slug,
        }: ICrudApiServiceListMethodOptions<RA, R[]>
    ): Promise<T[]> {
        let params = {};

        if (searchTerm) {
            params = {
                ...params,
                query: searchTerm,
            };
        }

        if (filter) {
            params = { ...params, filter: filter.toLowerCase() };
        }

        if (pageNumber) {
            params = { ...params, page: pageNumber };
        }

        if (pageSize) {
            params = { ...params, limit: pageSize };
        }

        if (orderBy) {
            params = { ...params, orderBy };

            if (orderDirection) {
                params = {
                    ...params,
                    direction: orderDirection ?? primaryOrderDirection,
                };
            }
        }

        const response = await apiService.get<RA>(`${slug}`, {
            params,
        });

        return responseHandler(response).map(this._responseMapper);
    }

    async read(
        id: string,
        {
            responseHandler = apiService.responseHandler,
            slug = this._slug,
        }: ICrudApiServiceSingleMethodOptions<R> = {
            responseHandler: apiService.responseHandler,
            slug: this._slug,
        }
    ): Promise<T> {
        const response = await apiService.get<R>(`${slug}/${id}`);
        return this._responseMapper(responseHandler(response));
    }

    deleteById(
        id: string,
        { slug = this._slug }: ICrudApiServiceMethodOptions = {
            slug: this._slug,
        }
    ): Promise<AxiosResponse> {
        return apiService.delete(`${slug}/${id}`);
    }

    async restore(
        id: string,
        {
            responseHandler = apiService.responseHandler,
            slug = this._slug,
        }: ICrudApiServiceSingleMethodOptions<R> = {
            responseHandler: apiService.responseHandler,
            slug: this._slug,
        }
    ): Promise<T> {
        const response = await apiService.post<R>(`${slug}/${id}/restore`, {});
        return this._responseMapper(responseHandler(response));
    }
}
