import {
    Box,
    Button,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Theme,
    Typography,
} from '@material-ui/core';
import { EntityListHeaderTemplate } from 'core/components/shared/EntityListHeaderTemplate';
import { EntityInfiniteScroll } from 'core/components/shared/InfiniteScroll';
import { WithCurrentPageListener } from 'core/hocs/WithListContainer';
import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { useCurrentPage } from 'core/hooks/current-page.hook';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { EntityListPagination } from '../../../core/components/EntityListPagination';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { ListHeader } from '../../../core/components/shared/ListHeader';
import { ListItemContainer } from '../../../core/components/shared/ListItemContainer';
import { defaultOrderByProperty } from '../../../core/constants/default-order-properties';
import { useCurrentPageEntities } from '../../../core/hooks/current-page-entities.hook';
import { IEntity } from '../../../core/interfaces/entity.interface';
import {
    ListView,
    ListViewOptionTypes,
} from '../../projects/components/ListView';
import { IPulse } from '../models/pulse.model';
import { pulsesQuery } from '../state/pulse.query';
import { PulseDialogContext } from './PulseDialogProvider';
import { PulseGridCard } from './PulseGridCard';
import { PulseListActionsById } from './PulseListActionById';
import { PulseListItemTemplate } from './PulseListItemTemplate';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    projectCardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
        columnGap: 24,
        rowGap: 24,
    },
    filters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableBackground: {
        backgroundColor: '#fff',
    },
}));

interface IPulsesListProps {
    projectId: string;
}

export const PulsesListContainer: FC<IPulsesListProps> = ({ projectId }) => {
    const {
        currentPageEntities,
        allPagesEntities,
    } = useCurrentPageEntities<IPulse>();
    const { t } = useContext(MultiLanguageSupportContext);
    const classes = useStyles();
    const { push } = useHistory();
    const { setOrderBy } = useCurrentPage();

    const [listView, setListView] = useState<ListViewOptionTypes>(
        +(
            localStorage.getItem(`list-view_pulse-list`) ??
            ListViewOptionTypes.GRID.toString()
        )
    );

    useEffect(() => {
        if (listView === ListViewOptionTypes.GRID) {
            setOrderBy(defaultOrderByProperty, 'desc');
        }
    }, [listView]);

    const { setListSlug } = useCrud();

    useEffect(() => {
        projectId && setListSlug({ prefix: `projects/${projectId}` });
    }, [projectId]);
    const { id } = useParams<IEntity>();
    const { setPageNumber, clearCurrentPageEntities } = useCurrentPage();
    const { isOpen, close, open } = useContext(PulseDialogContext);

    useEffect(() => {
        localStorage.setItem(`list-view_pulse-list`, listView.toString());
        setPageNumber(1, true);

        return () => clearCurrentPageEntities();
    }, [listView]);

    return (
        <Box width={'100%'} className={classes.root}>
            <Box className={classes.filters}>
                <Box />
                <ListView view={listView} handleViewChange={setListView} />
            </Box>

            {currentPageEntities.length === 0 ? (
                <>
                    <Typography variant="h1" align="center">
                        {t('its-empty-here')}
                    </Typography>
                    <Typography align="center">
                        {t('you-dont-have-pulses')}
                    </Typography>
                    <Box display="flex" justifyContent="center">
                        <Button
                            color="primary"
                            variant="contained"
                            disableElevation
                            onClick={() => open({ projectId: id })}
                        >
                            {t('create-new-pulse')}
                        </Button>
                    </Box>
                </>
            ) : (
                <Box mt={3}>
                    {listView === ListViewOptionTypes.GRID && (
                        <EntityInfiniteScroll>
                            <Box className={classes.projectCardsGrid}>
                                {allPagesEntities.map((pulse, index) => (
                                    <PulseGridCard key={index} pulse={pulse} />
                                ))}
                            </Box>
                        </EntityInfiniteScroll>
                    )}

                    {listView === ListViewOptionTypes.LIST && (
                        <>
                            <TableContainer
                                component={Paper}
                                variant="outlined"
                                className={classes.tableBackground}
                            >
                                <Table>
                                    <TableHead>
                                        <ListHeader
                                            renderLastColumn={() => (
                                                <span
                                                    style={{
                                                        visibility: 'hidden',
                                                    }}
                                                >
                                                    {t('actions')}
                                                </span>
                                            )}
                                        >
                                            <EntityListHeaderTemplate
                                                name={{
                                                    label: t('pulse'),
                                                }}
                                                nextPulseEvent={{
                                                    label: t('next-pulse'),
                                                }}
                                                lastPulseEvent={{
                                                    label: t('latest-pulse'),
                                                }}
                                                frequency={{
                                                    label: t('schedule'),
                                                }}
                                                numberOfParticipants={{
                                                    label: t('participants'),
                                                }}
                                                numberOfQuestions={{
                                                    label: t('pulse-questions'),
                                                }}
                                            />
                                        </ListHeader>
                                    </TableHead>
                                    <TableBody>
                                        {currentPageEntities.map(
                                            (entity, index) => (
                                                <ListItemContainer
                                                    key={index}
                                                    entityId={entity.id}
                                                    enableHover={true}
                                                    renderLastColumn={(
                                                        entityId: string
                                                    ) => (
                                                        <PulseListActionsById
                                                            id={entityId}
                                                        />
                                                    )}
                                                >
                                                    <PulseListItemTemplate
                                                        {...entity}
                                                    />
                                                </ListItemContainer>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <EntityListPagination />
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};

export const PulsesList = WithProvider(
    WithCurrentPageListener(PulsesListContainer),
    pulsesQuery
) as FC<IPulsesListProps>;
