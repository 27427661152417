import { useMediaQuery, makeStyles } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FC } from 'react';


const useStyles = makeStyles((theme) => ({
    stepperNav: {
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(3),
        },
    }
}));

export interface IStep {
    title: string;
    description: string;
}

interface IStepperComponentProps {
    activeStep: number;
    steps: IStep[];
    onClick?: (currentIndex: number) => void;
}

export const StepperComponent: FC<IStepperComponentProps> = ({
    activeStep,
    steps,
    onClick,
}) => {
    const theme = useTheme();
    const classes = useStyles();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Stepper
            activeStep={activeStep}
            orientation={isSmallScreen ? 'vertical' : 'horizontal'}
            className={classes.stepperNav}
        >
            {steps.map((label, index) => {
                const stepProps = {
                    onClick: () => onClick && onClick(index),
                };
                const labelProps = {};

                return (
                    <Step key={index} {...stepProps}>
                        <StepLabel {...labelProps}>
                            <Typography variant="body2">
                                {label.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {label.description}
                            </Typography>
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};
