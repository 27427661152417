import {
    Box,
    Button,
    Container,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { useSession } from 'core/hooks/session.hook';
import { IDeleteEntitiesOptions } from 'core/interfaces/delete-entities-options.interface';
import { useFormikContext } from 'formik';
import { DimensionsProvider } from 'modules/categories/components/DimensionsProvider';
import { DimenstionInputs } from 'modules/categories/components/DimenstionInputs';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import css from 'styles/material-ui/cssVars';
import { ProjectManager } from '../models/project-manager.model';
import { IProject } from '../models/project.model';
import { IProjectFormik, ProjectDialogContext } from './ProjectDialogProvider';
import { ProjectManagerAutocomplete } from './ProjectManagerAutocomplete';

const useStyles = makeStyles((theme) => ({
    modalActions: {
        position: 'relative',
        backgroundColor: css.extraColors.text.darkPrimary,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3),
        zIndex: 2,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: `${theme.spacing(2)}px  ${theme.spacing(1)}px `,
            height: theme.spacing(10),

            '& button': {
                width: '90%',
            },

            '& button:last-child': {
                order: '-1',
                marginTop: 0,
            },

            '& button:disabled': {
                backgroundColor: '#cccccc',
            },
        },

        '& button + button': {
            marginLeft: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                marginTop: theme.spacing(2),
            },
        },
    },
    closeBtn: {
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginRight: 'unset',
        },
    },
    actionsMobile: {
        zIndex: 1,
        backgroundColor: css.extraColors.text.darkPrimary,
        position: 'absolute',
        left: 0,
        bottom: 0,
        transform: `translateY(calc(100% - ${theme.spacing(10)}px))`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(
            1
        )}px`,
        transition: 'transform 0.3s ease-in-out',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },

        '& button': {
            width: '90%',
            '&:first-child': {
                width: 'auto',
            },
        },

        '& button + button': {
            marginBottom: theme.spacing(2),
        },
    },
    actionsMobileOpen: {
        transform: `translateY(calc(-100% + ${theme.spacing(12)}px))`,

        '& .arrowUp': {
            transform: 'rotate(180deg)',
        },
    },
    actionsToggle: {
        backgroundColor: css.extraColors.text.darkPrimary,
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        top: `-${theme.spacing(3)}px`,
        border: 'none',
        padding: `0 ${theme.spacing(2)}px `,
        borderRadius: '8px 8px 0px 0px',
        cursor: 'pointer',
        display: 'none',
        width: 'auto',
        transition: 'transform 0.3s ease-in-out',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },

        '&:hover': {
            backgroundColor: css.extraColors.text.darkPrimary,
        },
    },
}));

export const ProjectDialogForm: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { abilities } = useSession();
    const { projectId, close, open } = useContext(ProjectDialogContext);
    const classes = useStyles();

    const [mobileCtaIsOpen, setMobileCtaIsOpen] = useState(false);

    const { archiveSingle, restoreSingle, fetchById } = useCrud<IProject>();

    useEffect(() => {
        projectId &&
            fetchById(projectId).then((project) => project && open(project));
    }, [projectId]);

    const {
        values,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        isSubmitting,
        submitForm,
        isValid,
    } = useFormikContext<IProjectFormik>();

    const toggleDelete = useCallback(
        async (
            action: (
                entity: IProject,
                options?: Partial<IDeleteEntitiesOptions>
            ) => Promise<IProject | null>
        ) => {
            if (!projectId) {
                return;
            }

            const project = await action(
                { id: projectId, ...values },
                { shouldFetchAfterSuccess: true }
            );

            if (!project) {
                return;
            }

            open(project);
        },
        [projectId, values]
    );

    const selectedProjectManager: ProjectManager | null = useMemo(
        () =>
            !values.projectManagerId
                ? null
                : {
                      id: values.projectManagerId,
                      name: values.projectManagerName,
                  },
        [values.projectManagerId, values.projectManagerName]
    );

    const toggleMobileCta = () => {
        setMobileCtaIsOpen(!mobileCtaIsOpen);
    };

    const mobileCtaClasses = mobileCtaIsOpen
        ? `${classes.actionsMobile} ${classes.actionsMobileOpen}`
        : `${classes.actionsMobile}`;

    return (
        <>
            <DialogContent>
                <Container maxWidth="md">
                    <Typography variant="h5">{t('basic-info')}</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                autoFocus
                                label={t('project-name')}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                type="text"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                error={!!errors.name}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t('external-project-id') + ' (if any)'}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="externalIdentifier"
                                type="text"
                                name="externalIdentifier"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.externalIdentifier}
                            />
                        </Grid>
                    </Grid>

                    <Box mt={2} mb={6}>
                        <ProjectManagerAutocomplete
                            label={t('project-manager')}
                            onSelect={async (item) => {
                                if (item) {
                                    setFieldValue('projectManagerId', item.id);
                                    setFieldValue(
                                        'projectManagerName',
                                        item.name
                                    );
                                }
                            }}
                            value={selectedProjectManager}
                            disabled={abilities.cannot(
                                'read',
                                'IProjectManager'
                            )}
                        />
                    </Box>

                    <Box mb={3}>
                        <Typography variant="h5">
                            {t('project-category')}
                        </Typography>
                    </Box>

                    <Box>
                        <Grid container spacing={3}>
                            <DimensionsProvider>
                                <DimenstionInputs
                                    onChange={async (dimension, category) =>
                                        setFieldValue(
                                            `categoriesByDimensionId.${dimension.id}`,
                                            category
                                        )
                                    }
                                    emptyName={t('none')}
                                    categoriesByDimensionId={
                                        values.categoriesByDimensionId
                                    }
                                />
                            </DimensionsProvider>
                        </Grid>
                    </Box>
                </Container>
            </DialogContent>

            {/* mobile CTA start */}
            <div className={mobileCtaClasses}>
                <Button
                    type="button"
                    className={classes.actionsToggle}
                    onClick={toggleMobileCta}
                >
                    <ExpandLessIcon className="arrowUp" />
                </Button>
                <Button
                    variant="text"
                    onClick={() => close()}
                    className={classes.closeBtn}
                >
                    Cancel
                </Button>

                {projectId ? (
                    <Button
                        style={{
                            textTransform: 'none',
                            color: 'red',
                        }}
                        variant="contained"
                        disableElevation
                        disabled={isSubmitting}
                        onClick={() =>
                            toggleDelete(
                                !values.deletedAt
                                    ? archiveSingle
                                    : restoreSingle
                            )
                        }
                    >
                        {!values.deletedAt
                            ? t('archive-project')
                            : t('unarchive-project')}
                    </Button>
                ) : (
                    <Button
                        style={{
                            textTransform: 'none',
                        }}
                        variant="contained"
                        disableElevation
                        disabled={!isValid}
                        color="default"
                        onClick={() => {
                            setFieldValue('openNewPulse', true);
                            submitForm();
                        }}
                    >
                        {t('create-new-and-start-pulse')}
                    </Button>
                )}
            </div>
            {/* mobile CTA end */}

            <DialogActions
                className={classes.modalActions}
                disableSpacing={true}
            >
                <Button
                    variant="text"
                    onClick={() => close()}
                    className={classes.closeBtn}
                >
                    Cancel
                </Button>

                {projectId ? (
                    <Button
                        style={{
                            textTransform: 'none',
                            color: 'red',
                        }}
                        variant="contained"
                        disableElevation
                        disabled={isSubmitting}
                        onClick={() =>
                            toggleDelete(
                                !values.deletedAt
                                    ? archiveSingle
                                    : restoreSingle
                            )
                        }
                    >
                        {!values.deletedAt
                            ? t('archive-project')
                            : t('unarchive-project')}
                    </Button>
                ) : (
                    <Button
                        style={{
                            textTransform: 'none',
                        }}
                        variant="contained"
                        disableElevation
                        disabled={!isValid}
                        color="default"
                        onClick={() => {
                            setFieldValue('openNewPulse', true);
                            submitForm();
                        }}
                    >
                        {t('create-new-and-start-pulse')}
                    </Button>
                )}

                <Button
                    variant="contained"
                    disableElevation
                    disabled={!isValid}
                    color="primary"
                    onClick={submitForm}
                >
                    {t(projectId ? 'save-changes' : 'create-new-project')}
                </Button>
            </DialogActions>
        </>
    );
};
