import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    makeStyles,
    Theme,
    Typography,
    Container,
} from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { LoseButtonFocus } from 'core/helpers/loseButtonFocus';

interface DialogWrapperProps {
    isOpen: boolean;
    title?: string;
    handleClose?: () => void;
    alertModal?: boolean;
    setShouldRefetchParticipants?: any
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '90%',
        height: '90%',
        maxWidth: '1360px',
        maxHeight: '1000px',
        margin: 'auto',
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.spacing(0.5),
    },
    modalContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
        position: 'relative',
    },
    titleCotainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    alert: {
        maxWidth: '600px',
        width: '100%',
        height: 'fit-content',
        margin: 'auto',
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.spacing(0.5),
        '& .MuiDialogContent-root:first-child': {
            margin: 0,
            padding: 0,
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
    },
}));

export const FormDialogWrapper: FC<DialogWrapperProps> = ({
    children,
    title,
    isOpen,
    handleClose,
    alertModal = false,
    setShouldRefetchParticipants
}) => {
    const classes = useStyles();

    return (
        <Dialog
            PaperProps={{
                style: {
                    position: 'relative',
                },
            }}
            fullScreen
            open={isOpen}
            onClose={() => handleClose && handleClose()}
            className={alertModal !== false ? classes.alert + ' alertModal' : classes.root}
            onExited={() => {
                LoseButtonFocus()
                if (setShouldRefetchParticipants) {
                    setShouldRefetchParticipants((shouldRefetchParticipants: any) => !shouldRefetchParticipants)
                }
            }}
        >
            <div className={classes.modalContent}>
                {title && (
                    <DialogTitle
                        disableTypography={true}
                        className={classes.titleCotainer}
                    >
                        <Typography variant="h2">{title}</Typography>
                    </DialogTitle>
                )}
                {children}
            </div>
        </Dialog>
    );
};
