import React, { FC, useContext } from 'react';
import { GeneralDialog } from '.';
import { GeneralDialogContext } from './GeneralDialogProvider';

export const GeneralDialogContainer: FC = () => {
    const { props, clearDialogProps } = useContext(GeneralDialogContext);

    if (!props.onClose) {
        props.onClose = clearDialogProps;
    }

    return <GeneralDialog {...props} />;
};
