import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import { useSession } from 'core/hooks/session.hook';
import { AlertModal } from 'modules/pulse/components/AlertModal';
import React, { FC, useContext, useState } from 'react';
import { ValidTranslationKeys } from 'react-i18next';
import { EntityListPagination } from '../../../core/components/EntityListPagination';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { EntityListHeaderTemplate } from '../../../core/components/shared/EntityListHeaderTemplate';
import { FormDialogWrapper } from '../../../core/components/shared/FormDialogWrapper';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { useFilter } from '../../../core/hooks/filter.hook';
import {
    SuccessFactorsFilters,
    SuccessFactorsListFilter,
} from '../components/FactorFilters';
import { FactorsDialogContent } from '../components/FactorsDialogContent';
import { FactorsList } from '../components/FactorsList';
import { FactorsListActionsById } from '../components/FactorsListActionById';
import { FactorsListItemTemplate } from '../components/FactorsListItemTemplate';
import { SuccessFactorsFormContext } from '../components/SuccessFactorsFormProvider';
import { factorsQuery } from '../state/factors.query';

const useStyles = makeStyles((theme) => ({
    warningIcon: {
        color: '#FF961A',
        marginRight: '5px',
    },
    innerFilter: {
        marginBottom: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    innerFilterBtn: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
    },
}));

interface IFactorsPageContainerProps {
    showFilters?: boolean;
    initialFilter: SuccessFactorsListFilter;
    createButtonLabelTranslationKey: keyof ValidTranslationKeys;
}

const FactorsPageContainer: FC<IFactorsPageContainerProps> = ({
    showFilters,
    initialFilter,
    createButtonLabelTranslationKey,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { isOpen, close, open, preferenceOrganizationId } = useContext(
        SuccessFactorsFormContext
    );

    const { filter, setFilter } = useFilter<SuccessFactorsListFilter>(
        initialFilter,
        preferenceOrganizationId === null
    );
    const classes = useStyles();
    const { abilities } = useSession();
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Box>
            <Box className={classes.innerFilter}>
                {showFilters ? (
                    <SuccessFactorsFilters
                        filter={filter}
                        setFilter={setFilter}
                    />
                ) : (
                    <Box />
                )}

                <Button
                    className={classes.innerFilterBtn}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() =>
                        abilities.can('create', 'ISuccessFactor')
                            ? open({})
                            : setIsModalOpen(true)
                    }
                >
                    {t(createButtonLabelTranslationKey)}
                </Button>
            </Box>

            <FormDialogWrapper
                isOpen={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                alertModal
            >
                <AlertModal
                    title={t('add-factors')}
                    isActionDestructive={true}
                    contentText={
                        <div>
                            {t('send-request-to')}{' '}
                            <a
                                href={`mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`}
                            >
                                {process.env.REACT_APP_SUPPORT_EMAIL}
                            </a>
                        </div>
                    }
                    closeModalText={'OK'}
                    handleClose={() => setIsModalOpen(false)}
                />
            </FormDialogWrapper>

            <FactorsList
                listSlugSuffix={`?organization=${preferenceOrganizationId}`}
                renderLastColumn={(entity) => (
                    <FactorsListActionsById id={entity.id} />
                )}
                HeaderListItem={() => (
                    <EntityListHeaderTemplate
                        icon={{ label: `${t('icon')}` }}
                        title={{ label: `${t('title')}` }}
                        library={{ label: t('library') }}
                        mandatory={{ label: t('mandatory') }}
                        default={{ label: t('default') }}
                        notification={{ label: t('notification') }}
                    />
                )}
                ListItem={(entity) => (
                    <FactorsListItemTemplate
                        fontAwesomeIcon={entity.factor.fontAwesomeIcon}
                        title={entity.factor.title}
                        mandatory={entity.mandatory}
                        library={
                            entity.factor.organizationId !== null
                                ? 'Account'
                                : 'System'
                        }
                        defaultItem={entity.useAsDefault}
                        notification={
                            entity.factor.translations.some(
                                (trans) => !trans.title
                            ) ? (
                                <Box display="flex">
                                    <WarningIcon
                                        className={classes.warningIcon}
                                    />
                                    <Typography className={classes.warningIcon}>
                                        {t('translation-missing')}
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )
                        }
                    />
                )}
                renderPagination={() => <EntityListPagination />}
            />

            <FormDialogWrapper isOpen={isOpen} handleClose={close}>
                <FactorsDialogContent />
            </FormDialogWrapper>
        </Box>
    );
};

export const FactorsPage = WithProvider(FactorsPageContainer, factorsQuery);
